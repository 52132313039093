<!-- Recherche avancée -->
<template>
  <div class="advanced-search-menu-parent popup-parent" >
    <div class="advanced-search-bg"  @click="closeMenu($event)"></div>
    <div class="advanced-search-menu">
      <div class="advanced-search-menu-close advanced-search-link">
        <a href="#" class="advanced-search-menu-close-button" @click="closeMenu($event)">{{ $t("Recherche avancée") }}</a>
      </div>
      <form method="GET" class="advanced-search-menu-panel">
        <input type="text"
               name="searchvalue"
               id="advancedSearchText"
               :value="state.searchText"
               class="fulltext-search"
               :placeholder="$t('Rechercher...')"
               @keyup="keyEnterSubmitSearch($event)"
        />
        <SearchFieldsPanel
                :advancedQuery="state.advancedQuery"
                :resetQuery="state.resetQuery"
                @change="updateFieldSets"
        />
        <div class="advanced-search-footer">
          <input type="submit" class="run-advanced-search" :value="$t('Rechercher')" @click="submitSearch($event)">
          <a href="#" class="reset-advanced-search reset-search-button" @click="resetSearch($event)">{{ $t('Réinitialiser')}}</a>
        </div>
      </form>
      <div class="advanced-search-menu-close advanced-search-menu-close-bottom advanced-search-link">
        <a href="#" class="advanced-search-menu-close-button" @click="closeMenu($event)"></a>
      </div>
    </div>
  </div>
</template>

<script>

import {reactive} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {getAdvancedSearchQuery} from "../../js/api";
import SearchFieldsPanel from "./SearchFieldsPanel";

export default {
  name: 'AdvancedSearchWindow',
  components: {
    SearchFieldsPanel
  },
  props: {
    advancedSearchMenu: {
      type: Boolean,
      required: false
    }
  },
  emits:[
    'close-advanced-search-menu'
  ],
  setup(props, context) {

    const router =  useRouter();
    const store = useStore();

    const encyclopedieCollectionInfos = store.getters.encyclopedieCollectionInfos();
    const categoriesCount = encyclopedieCollectionInfos.length;

    const findEncyclopedieCollectionId = function(category) {
      let i, collectionInfos;
      for(i=0;i<categoriesCount;i++) {
        collectionInfos = encyclopedieCollectionInfos[i];
        if (collectionInfos.name === category) {
          return collectionInfos.id;
        }
      }
    };

    const state = reactive({
      searchText:"",
      advancedQuery:"",
      resetQuery: 0
    });

    const updateFieldSets = function( fieldSets ) {
      state.fieldSets = fieldSets;
    };

    const keyEnterSubmitSearch = function($event) {
      if ($event.keyCode === 13 ) {
        submitSearch($event);
      }
    };

    const submitSearch = function($event) {
      $event.preventDefault();

      const searchText = document.getElementById('advancedSearchText').value;
      const query = getAdvancedSearchQuery(state.fieldSets, [], searchText);

      // console.log(query, state.fieldSets, searchText);

      router.push({
        name: "Recherche Avancée",
        params : { s: query },
        query : { sort_by: "title", sort_order: "asc"}
      });

      context.emit('close-advanced-search-menu');
    };

    const resetSearch = function($event) {
      $event.preventDefault();

      state.resetQuery ++;
    };

    return {
      state,
      findEncyclopedieCollectionId,
      submitSearch,
      keyEnterSubmitSearch,
      resetSearch,
      updateFieldSets
    };

  },
  computed: {

    advancedSearchMenuOpenedClass() {
      return this.advancedSearchMenu ? "advanced-search-menu-opened" : "";
    },

  },
  methods: {
    closeMenu($event) {
      $event.preventDefault();
      $event.stopPropagation();
      this.$emit('close-advanced-search-menu');
    },
  }
}

</script>
