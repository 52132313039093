<template>
    <div v-html="htmlContent"></div>
</template>

<script>
import {onMounted, watch} from "vue";
  import {useStore} from "vuex";
  import $ from "jquery";

  export default {
    name: 'Blocks',
    props: {
      htmlContent: {
        type: String,
        required: true,
      }
    },
    setup() {

      const store = useStore();

        // Traduction des titres ou liens :
      const translateTitles = function(){

          const titleSelectors = [
            'h1',
            'h2',
            'h3',
            'h4',
            '.list-links li a',
            '.item-set.resource a',
            'a.see-all-selections'
          ];

          $(titleSelectors.join(',')).each(function (no, item) {
            const $item = $(item);
            $item.html(store.getters.translation($item.html()));
          })
      }

      const $root = $('html');

      // Ancres
      const scrollToAnchor = function(){
        let href = $.attr(this, 'href');
        if (href !== "#") {
          let hrefOffset = $(href).offset();
          if (hrefOffset) {
            $root.animate({
              scrollTop: $(href).offset().top - 20
            }, 500, function() {
              window.location.hash = href;
            });
          }
        }
        return false;
      };

      // Fonctions à déclencher au chargement de la page
      const initJqueryFunctions = function()
      {
        // Traduction des termes venant des blocs Omeka
        translateTitles();

        // Comportement des ancres
        $('a[href^="#"]').on('click', scrollToAnchor);

        // Lien vers le publisher (cf logo du bloc html-publisher)
        const publisherURL = store.state.settings.paths.publisher;
        if (publisherURL) {
          $('a.publisher-logo').attr("href", publisherURL);
        }
      }

      //
      // Lifecycle Hooks
      //

      onMounted(() => {
        initJqueryFunctions();
      });

      watch(() => store.state.collections, () => {
        initJqueryFunctions();
      });
    }
  }
</script>
