<template >
  <div :class="navigationCssClass">
    <BreadCrumbItem
            v-for="path in paths"
            v-bind:key="path.label"
            v-bind:label="path.label"
            v-bind:params="path.params"
            v-bind:query="path.query"
            v-bind:name="path.name" />
  </div>
</template>

<script>

  import {ref, onMounted, watch} from "vue";
  import {useRoute} from "vue-router";

  import BreadCrumbItem from "./BreadCrumbItem";
  import EventDispatcher from "../../js/EventDispatcher";

  export default {
    name: 'Logo',
    components:{BreadCrumbItem},
    props: {
      paths: {
        type: Array,
        required: true
      }
    },
    setup(props) {

      const route = useRoute();

      // Titre de la page
      const updateWindowTitle = () => {

        const paths = props.paths;
        let title = "";

        if (paths.length > 2) {
          title += paths[paths.length - 2].label + " - " + paths[paths.length - 1].label;
        } else if (paths.length > 1) {
          title += paths[paths.length - 1].label;
        }

        EventDispatcher.emits("document-title", title);
      };

      // RQ : Le fil d'Ariane est masqué dans le cas d'une notice ouverte à partir d'un autre item via un lien externe ( target _blank )
      let navigationCssClass = ref('');

      onMounted(() => {
        navigationCssClass.value = route.query.from_item ? "hide-navigation" : "";
        updateWindowTitle();
      });

      watch(props, () => {
        updateWindowTitle();
      });

      return {
        navigationCssClass
      }

    }

  }
</script>
