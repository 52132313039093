<template>
  <router-link
      to="/"
      class="logo"
      :style="logoCssStyle">
  </router-link>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    logoUrl: {
      type: String,

    }
  },
  computed: {
    logoCssStyle(){
      return 'background-image:url(' +  this.logoUrl + ')';
    }
  }
}
</script>
