<template>
  <div class="annotations-thread-item">
    <p class="annotations-thread-author">{{ userName }}, {{ annotationDate }}</p>
    <div class="annotations-thread-edit-box">
      <div class="annotations-thread-text-content">{{ annotationContent }}</div>
    </div>
  </div>
</template>

<script>

  export default {
    components: {
    },
    props: {
      annotationId: {
        type: Number,
        required: false,
      },
      annotationContent: {
        type: String,
        required: false,
      },
      annotationDate: {
        type: String,
        required: false,
      },
      userId: {
        type: Number,
        required: false,
      },
      userName: {
        type: String,
        required: false,
      },
    }
  }

</script>
