<template>

  <article class="extrait">

    <Loading class="notice-item-content" v-if="state.isLoading" />

    <div class="notice-item-header" v-if="!state.isLoading">
      <a :href="getCollectionURL(collectionId)" class="notice-item-collection-name">
        <span class="collection-icon" />
        <span class="notice-item-collection-name-code">{{ collectionTitleCode }}</span>
        <span>{{ collectionTitle }}</span>
      </a>
      <div class="notice-tools" v-if="tools">
        <div>
          <a class="add-to-basket"
             href="#"
             v-if="state.isUserConnected"
             @click="toggleItemInBasket($event)">
          </a>
          <NoticePDFDownloadLink
              v-if="state.isUserConnected"
              class="download"
              :resource-id="state.itemId"
              :title="$t('Exporter en PDF')" />
        </div>
      </div>
    </div>

    <div class="notice-item-content" v-if="!state.isLoading && state.isJsonAndResourceTemplateLoaded">

      <!-- TITRE -->
      <div class="article-parent-content">
        <h1>{{ dctermsTitle }}</h1>
      </div>

      <div class="notice-item-columns document-with-media">

        <!-- PARTIE 1 à PARTIE 5 -->
        <div class="notice-item-column">

          <div v-for="term in getTermsAtPosition(1)" v-bind:key="term">
            <NoticeMediaItemMetadata
                    :term="term"
                    :label='getMetaDataLabel(term)'
                    :value='getMetaDataValue(term)'
                    :from-item="state.itemId"
                    class="notice-part-1"
            />
          </div>

          <div v-for="term in getTermsAtPosition(2)" v-bind:key="term">
            <NoticeMediaItemMetadata
                    :term="term"
                    :label='getMetaDataLabel(term)'
                    :value='getMetaDataValue(term)'
                    :from-item="state.itemId"
                    class="notice-part-2"
            />
          </div>

          <div v-for="term in getTermsAtPosition(3)" v-bind:key="term">
            <NoticeMediaItemMetadata
                    :term="term"
                    :label='getMetaDataLabel(term)'
                    :value='getMetaDataValue(term)'
                    :from-item="state.itemId"
                    class="notice-part-3"
            />
          </div>

          <div v-for="term in getTermsAtPosition(4)" v-bind:key="term">
            <NoticeMediaItemMetadata
                    :term="term"
                    :label='getMetaDataLabel(term)'
                    :value='getMetaDataValue(term)'
                    :from-item="state.itemId"
                    class="notice-part-4"
            />
          </div>

          <NoticeMediaItemMetadata
                  v-for="term in getTermsAtPosition(5)" v-bind:key="term"
                  :term="term"
                  :label='getMetaDataLabel(term)'
                  :value='getMetaDataValue(term)'
                  :linksInPopUp="inPopUp"
                  :from-item="state.itemId"
                  class="notice-part-5"
                  @clicknoticelink="emitClickOnNotice"
          />

          <NoticeMediaItemLinks
                  v-if="linksOfItems.total > 0"
                  :total="linksOfItems.total"
                  :itemsByTerm="linksOfItems.itemsByTerm"
                  :labelsByTerm="linksOfItems.labelsByTerm"
                  :termsOrder="linksOfItems.termsOrder"
                  :linkedElementsLabel="collectionLinkedElementsLabel"
                  :linksInPopUp="inPopUp"
                  :from-item="state.itemId"
                  class="notice-part-6"
                  @clicknoticelink="emitClickOnNotice"
                  @clickdocumentlink="emitClickOnDocument"
                  @clickarticlelink="emitClickOnArticle"
          />

        </div>

        <div class="notice-item-column"></div>

      </div>

      <!-- Citation -->
      <Citation
          :creator = "creator"
          :title = "dctermsTitle"
          :itemId = "state.itemId"
          :itemSetId = "collectionId"
      />

    </div>

    <Coins v-if="!state.isLoading && state.itemId" :query="state.coinQuery" />

    <NoticeFooter />

  </article>

</template>

<script>

  import {computed, onMounted, reactive, watch} from "vue";
  import { useStore } from "vuex";
  import axios from 'axios'

  import {getItemAdminURL, getItemApiURL, getMediaApiURL} from "../../js/api";
  import {JsonReader} from "../../js/jsonReader";

  import Loading from "../Loading";
  import Coins from "../Coins";
  import Citation from "./Citation";

  import NoticeMediaItemMetadata from "./NoticeMediaItemMetadata";
  import NoticeMediaItemLinks from "./NoticeMediaItemLinks";
  import NoticeFooter from "./NoticeFooter";
  import NoticePDFDownloadLink from "../Notices/NoticePDFDownloadLink";

  export default {

    name: 'NoticeExtrait',
    components: {
      Loading,
      NoticeMediaItemMetadata,
      NoticeMediaItemLinks,
      NoticePDFDownloadLink,
      NoticeFooter,
      Citation,
      Coins
    },
    props: {
      itemId: {
        type: Number,
        required: false,
        default:0
      },
      itemJson: {
        type: Object,
        required: false,
        default:null
      },
      tools: {
        type: Boolean,
        required: false,
        default:true
      },
      footer: {
        type: Boolean,
        required: false,
        default:true
      },
      inPopUp: {
        type:Boolean,
        required: false,
        default: false
      }
    },
    emits: [
      'change-item-notice',
      'item-loaded'
    ],
    setup(props, context) {

      const store = useStore();
      const apiRoot = store.getters.apiRoot;
      const keyIdentity = store.getters.identityAndCredentialParameters;

      let state = reactive({
        userId: computed(() => parseInt(store.getters.userId) ),
        isUserConnected: computed(() => store.getters.isUserConnected ),
        userCanEdit: computed(() => store.getters.userCanEdit ),

        itemId: props.itemId,
        isMediaLoadingFailed: false,
        isLoading: false,
        basketButtonLabel: computed(() => store.getters.itemInBasket(state.itemId, "item") ? "Retirer du panier" : "Ajouter au panier" ),
        coinQuery: computed(() => 'id=' + state.itemId ),
      });

      const loadArticle = function (itemId) {

        state.isLoading = true;
        state.isJsonAndResourceTemplateLoaded = false;

        // API de l'item :
        const apiURL = getItemApiURL(apiRoot, itemId);

        axios.get(apiURL + keyIdentity).then(function (response) {
          parseItemJson(response.data);
        });
      };

      const parseItemJson = function(itemJson) {

        state.jsonReader = new JsonReader(itemJson);
        state.isJsonAndResourceTemplateLoaded = false;

        if (!state.itemId) {
          state.itemId = state.jsonReader.getAsNumber("o:id");
        }

        // On renvoie le json au parent :
        // Sert notamment à la vue parente pour mettre à jour le fil d'Ariane
        context.emit('item-loaded', {
          id: state.itemId,
          title: state.jsonReader.get('o:title'),
          itemSetId: state.jsonReader.getLiteralFirstValue('o:item_set', "o:id"),
          jsonReader: state.jsonReader
        });

        // Média de l'items
        const medias = state.jsonReader.get('o:media');

        // Modèle de ressource de l'item :
        const resourceTemplateId = state.jsonReader.getFieldValue('o:resource_template', 'o:id');

        // Récupération du modèle ( stocké ou téléchargé )
        return store.dispatch("getResourceTemplate", resourceTemplateId).then(function (response) {

          // On stocke les données du modèle de ressource
          state.jsonReader.labelsByTerm = response.labels;
          state.resourceTemplateTitle = response.titleTerm;
          state.resourceTemplateTermByPosition = response.by_position;
          state.isJsonAndResourceTemplateLoaded = true;

          // Y'a-t-il un media associé ?
          if (medias.length > 0) {

            let firstMedia = medias[0];
            const mediaId = firstMedia['o:id'];

            // API du media de l'item
            const apiMediaURL = getMediaApiURL(apiRoot, mediaId);

            axios
              .get(apiMediaURL + keyIdentity)
              .then(function (response) {

                // Article => media HTML :
                const mediaJson = response.data;
                state.mediaHTML = mediaJson.data ? mediaJson.data.html : "";
                state.isLoading = false;

              })
              .catch(error => {
                console.log(error);
                state.isMediaLoadingFailed = true;
                state.isLoading = false;
              });

          } else {
            state.isMediaLoadingFailed = true;
            state.isLoading = false;
          }

        });
      };

      const getTermsAtPosition = function(position) {
        return state.resourceTemplateTermByPosition ? state.resourceTemplateTermByPosition[position] : [];
      };

      const hasTermsAtPosition = function(position) {
        return state.resourceTemplateTermByPosition[position].length > 0;
      };

      const toggleItemInBasket = function($event) {
        $event.preventDefault();
        store.dispatch( store.getters.itemInBasket(parseInt(state.itemId), "item") ? "removeFromBasket" : "addToBasket", { type: "item", id: state.itemId } );
      };

      watch( () => props.itemId, (itemId) => {
        loadArticle(itemId);
      });

      watch( () => props.itemJson, (itemJson) => {
        parseItemJson(itemJson);
      });

      onMounted(() => {
        if (props.itemId) {
          loadArticle(props.itemId);
        } else if (props.itemJson) {
          parseItemJson(props.itemJson);
        }
      });

      return {
        state,
        getTermsAtPosition,
        hasTermsAtPosition,
        toggleItemInBasket
      };

    },
    computed: {

      collectionId() {
        if (this.state.jsonReader) {
          const itemSet = this.state.jsonReader.getLiteralFirstValue('o:item_set', "o:id");
          if (! isNaN(itemSet)) {
            return itemSet;
          } else {
            return this.state.jsonReader.getLiteralFirstValue('rdf:type', "value_resource_id");
          }
        }
        return 0;
      },

      collectionTitle() {
        if (this.state.jsonReader) {
          const itemSetId  = this.state.jsonReader.getLiteralFirstValue('o:item_set', "o:id");
          if (itemSetId) {
            const store = useStore();
            const itemSetTitle = store.getters.collectionTitle(itemSetId);
            if (itemSetTitle) return itemSetTitle;
          }
          const collectionRdfType = this.state.jsonReader.getLiteralFirstValue('rdf:type', "display_title");
          if (collectionRdfType) {
            return collectionRdfType;
          }
        }
        return "";
      },

      collectionTitleCode() {
        const store = useStore();
        return store.getters.collectionCode(this.collectionId);
      },

      collectionLinkedElementsLabel() {
        return this.$store.getters.collectionsSettings(this.collectionId, "linkedElementsLabel");
      },

      dctermsTitle() {
        return this.state.jsonReader.getLiteralFirstValue('dcterms:title', "@value", this.title);
      },

      title() {
        return this.state.jsonReader.getMetaDataValue(this.state.resourceTemplateTitle);
      },

      issued() {
        return this.state.jsonReader.getLiteralFirstValue('dcterms:issued');
      },

      created() {
        return this.state.jsonReader('dcterms:created');
      },

      creator() {
        return this.state.jsonReader.getLiteralFieldStringValues('dcterms:creator', 'display_title').join(', ');
      },

      mediaHTML() {
        return this.state.mediaHTML;
      },

      thumbnail() {
        return this.getThumbnailURL('large');
      },
      editNoticeUrl() {
        return getItemAdminURL(this.$store.getters.adminRoot, this.itemId);
      },
    },
    methods: {
      clickOnNotice($event, item) {
        $event.preventDefault();
        this.emitClickOnNotice(item);
      },
      emitClickOnNotice(item) {
        this.$emit('change-item-notice', {
          "itemId"   : item.id,
          "itemType" : "item",
        });
      },
      clickOnDocument($event, item) {
        $event.preventDefault();
        this.emitClickOnDocument(item);
      },
      emitClickOnDocument(item) {
        this.$emit('change-item-notice', {
          "itemId"   : item.id,
          "itemType" : this.isCategoryInGraph ? "media" : "item",
        });
      },
      clickOnArticle($event, item) {
        $event.preventDefault();
        this.emitClickOnArticle(item);
      },
      emitClickOnArticle(item) {
        // EVENT --> PARENT
        this.$emit('change-item-notice', {
          "itemId"   : item.id,
          "itemType" : "article",
        });
      },
      downloadNotice($event) {
        $event.preventDefault();

        // EVENT --> PARENT
        this.$emit('downloaditemnotice');
      },
      blockLink($event) {
        $event.preventDefault();
      },
      getCollectionURL(collectionId) {
        if (collectionId) {
          return this.$router.resolve({
            name: 'Collection',
            params : { id: collectionId },
            query : { lang: this.$store.state.currentLanguage }
          }).href;
        } else {
          return "#";
        }
      },
      linksOfItems() {
        // Metadonnées de la partie 5 de l'interface : on retire les terms du vocabulaire "skos"
        let items, itemsByTerm = [], termsOrder = [], labelsByTerm = [], total = 0;
        let i, term, terms = this.getTermsAtPosition(6);
        const n = terms.length;
        for(i=0;i<n;i++)
        {
          term = terms[i];
          if (term.indexOf('skos') === -1) {
            termsOrder.push(term);
            items = this.getMetaDataValue(term);
            if (items.length > 0) {
              total += items.length;
              itemsByTerm[term] = items;
              labelsByTerm[term] = this.getMetaDataLabel(term);
            }
          }
        }
        return {
          termsOrder,
          itemsByTerm,
          labelsByTerm,
          total
        }
      },

      linkedItems() {
        // Metadonnées de la partie 5 de l'interface : uniquement celles du vocabulaire "skos"
        let items = [];
        let i, j, term, terms = this.getTermsAtPosition(6);
        const n = terms.length;
        for(i=0;i<n;i++) {
          term = terms[i];
          if (term.indexOf('skos') === 0) {
            const itemsForTerm = this.state.jsonReader.getObjectFieldValues(term, "display_title", "value_resource_id");
            for(j=0;j<itemsForTerm.length;j++) {
              items.push(itemsForTerm[j]);
            }
          }
        }
        return items
      },

      hasLinkedItems() {
        return this.linkedItems.length > 0;
      },

      getMetaDataLabel: function (metadata, defaultValue = '') {
        return this.state.jsonReader.getMetaDataLabel(metadata, defaultValue);
      },
      getMetaDataValue: function (metadata) {
        return this.state.jsonReader.getMetaDataValue(metadata);
      },
      getThumbnailURL: function (size){
        return this.state.jsonReader.getThumbnailURL(size);
      },
    }
  }

</script>
