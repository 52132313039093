<template>
    <div class="contribution-parent form-parent with-padding">
        <form class="contribution">
            <div class="contribute-header">
                <h3>Proposer une nouvelle notice</h3>
            </div>
            <div>
                <div class="contribution-search">
                    <label>Choisissez votre terme</label>
                    <input type="text" placeholder="Indiquez l'intitulé du terme proposé" v-model="state.userSubject" />
                    <div class="contribution-buttons contribution-new-entry-button buttons">
                        <a href="#">Proposer une nouvelle entrée</a>
                    </div>
                </div>
                <div class="contact-message-fields">
                    <label for="contact_message">Développez en quelques lignes votre proposition</label>
                    <textarea
                            id="contact_message"
                            placeholder="Entrez ici votre proposition"
                            v-model="state.userMessage">
                    </textarea>
                </div>
            </div>
            <div class="contribution-upload-panel">
                <h4>Illustrez votre notice</h4>
                <div class="contribution-upload-control">
                    <label class="contribution-upload">Parcourir…
                        <input id="contribution_file" name="contribution_file" type="file" @change="selectFileForUpload($event)">
                    </label>
                    <span class="contribution-upload-path" id="contribution_path">{{ state.filesCountMessage }}</span>
                </div>
                <p>Les fichiers doivent peser moins de 2 Mo. Extensions autorisées : png, pdf, jpg, jpeg</p>
                <label class="checkbox-label">
                    <input type="checkbox" v-model="state.userAuthorCheckbox" />
                    <span class="checkmark"></span>
                    <span class="checkbox-text-label">
                        En tant qu’auteur, je suis et demeure totalmente responsable du contenu de ma notice.
                        Je certifie qu’elle à été produit dans les respects des droits d’auteur. *
                    </span>
                </label>
                <label class="checkbox-label">
                    <input type="checkbox" v-model="state.userEnssib" />
                    <span class="checkmark"></span>
                    <span class="checkbox-text-label">
                        J’autorise le site, dans le cadre du respect et de la préservation du droit de la propriété intellectuelle, en formats HTML, et PDF, sous licence CC BY-NC-ND Attribution - Pas d’utilisation Commerciale - Pas de modification
                        <a href="https://créativecommons.org/licenses/by-nc-nd/4.0/deed.fr">https://créativecommons.org/licenses/by-nc-nd/4.0/deed.fr</a> *
                    </span>
                </label>
            </div>
            <div class="contact-contribution">
                <h4>Enregistrez votre notice</h4>
                <div class="contact-name-email-fields form-fields">
                    <div>
                        <label for="contact_email">Email</label>
                        <input type="text" id="contact_email" disabled="disabled" v-model="state.userEmail">
                    </div>
                </div>
                <div class="contact-name-email-fields form-fields">
                    <div>
                        <label for="contact_name">Prénom Nom</label>
                        <input type="text" id="contact_name" disabled="disabled" v-model="state.userName">
                    </div>
                </div>
                <div class="account-parameters-buttons buttons send-contribution-button" v-if="state.userIsConnected">
                    <a href="#" @click="sendFormData($event)">Envoyer pour validation</a>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

    import {useStore} from "vuex";
    import {computed, onMounted, reactive, watch} from "vue";
    import {getContactMessageApiURL} from "../../js/api";
    import axios from "axios";

    export default {
        components: {},
        setup() {

            const store = useStore();

            const state = reactive({
                userIsConnected: computed(() => store.state.currentUser.logged ),
                computedUserName: computed(() => store.state.currentUser.name ),
                contactNameEmailCssClass: computed(() => store.state.currentUser.logged ? "connected" : "" ),
                inputIsDisabledAttr: computed(() => store.state.currentUser.logged ? "disabled" : false ),
                filesCountMessage: computed(() => {
                    if (state && (state.base64 !== null)) {
                        return '1 fichier selectionné : ' + state.filename;
                    } else {
                        return 'Aucun fichier selectionné';
                    }
                }),

                // Valeurs du modèle du formulaire
                userName: state && state.isUserConnected ? store.state.currentUser.name : "",
                userEmail: state && state.isUserConnected ? store.state.currentUser.email : "",
                userMessage: "",
                userSubject: "",
                userAuthorCheckbox: false,
                userEnssibCheckbox: false,
                base64:null,
                filename:""
            });

            onMounted(() => {
                state.userName  = store.state.currentUser.name;
                state.userEmail = store.state.currentUser.email;
            });

            // Lorsau'une personne vien de se connecter, le nom est renvoyé par une api Omeka un peu après
            watch( () => state.computedUserName, (userName) => {
                if (state.userIsConnected) {
                    state.userName = userName;
                }
            });

            const selectFileForUpload = function($event) {
                let files = $event.target.files  || $event.dataTransfer.files;

                const file = files[0];

                state.filename = file.name;
                state.base64 = null;

                var imgReader = new FileReader();
                imgReader.onloadend = function() {
                    // console.log('Base64 Format', imgReader.result);
                    const pos = imgReader.result.indexOf(',');
                    state.base64 = imgReader.result.substring(pos + 1);
                };
                imgReader.readAsDataURL(file);

            };

            const sendFormData = function($event) {

                $event.preventDefault();

                let jsonData = {
                    'o:name': state.userName,
                    'o:email': state.userEmail,
                    'o-module-contact:body': state.userMessage,
                    'o-module-contact:subject': state.userSubject,
                    'file' : [{
                        'name': state.filename,
                        'base64': state.base64
                    }]
                };

                const apiURL = getContactMessageApiURL(store.getters.apiRoot);
                const keyIdentity = store.getters.identityAndCredentialParameters;

                axios({
                    method: 'post',
                    url: apiURL + keyIdentity,
                    data: jsonData

                }).then(function (response) {

                        // On efface le message
                        state.userMessage = "";
                        state.userSubject = "";
                        state.base64 = null;
                        state.filename = "";

                        console.log("contribution success", response);
                    })
                    .catch(function (response) {
                        //handle error
                        console.log("contribution error", response);
                    });
            };

            return {
                state,
                sendFormData,
                selectFileForUpload
            }

        }
    }

</script>