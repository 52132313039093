<template>

    <div class="popup-item-notices .with-padding">
      <div class="popup-short-notice">

        <div class="short-notice-close">
          <a href="#" @click="closeNotice($event)">{{ $t("Fermer") }}</a>
        </div>

        <div class="short-notice-content-wrapper">

          <div class="short-notice-content">

            <ul v-if="hasLanguagesSelector" class="notice-lang-selector hidden">
              <li v-for="language in languages" :key="language">
                <a  href="#"
                    @click="selectLanguage($event, language)"
                    :class="languageCssClass(language)">
                  {{ language }}
                </a>
              </li>
            </ul>

            <div>
              <h2
                      v-for="item in titles"
                      :key="item"
                      :class="contentCssClass(item.language)"
                      class="short-notice-title"
              >
                {{ item.value }}
              </h2>
            </div>

            <ul class="short-notice-description thin-scroll no-bullet">
              <li
                      v-for="item in descriptions"
                      :key="item"
                      :class="contentCssClass(item.language)"
              >
                <div v-if="item.type='html'" v-html="item.value" />
                <div v-else>
                  {{ limitContentLength(item.value) }}
                </div>
              </li>
            </ul>
            <div v-if="hasNoticeLink"
                 class="short-notice-link"
            >
              <a :href="noticeItemUrl" >{{ $t('Voir la notice') }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>

</template>


<script>

  export default {

    props: {
      titles: {
        type: Array,
        required: true
      },
      descriptions: {
        type: Array,
        required: true
      },
      languages: {
        type: Array,
        required: true
      },
      itemId: {
        type: Number,
        required: true
      },
      hasNoticeLink: {
        type: Boolean,
        required: false,
        default: true
      }
    },
    data() {
      return {
        activeLanguage: "FR",
        contentMaxLength: 220,
      }
    },
    emits: [
      "opendetailednotice"
    ],
    computed: {
      hasLanguagesSelector() {
        return this.languages.length > 1;
      },
      noticeItemUrl() {
        return this.$router.resolve({
          name   : 'Notice Item',
          params : { id: this.itemId },
          query  : { display_mode: 'notice' }
        }).href;
      },
      graphItemUrl() {
        return this.$router.resolve({
          name  : 'Graphe Item',
          params: { id: this.itemId },
          query : { display_mode: 'graph'}
        }).href;
      },
    },
    methods: {
      closeNotice($event) {
        $event.preventDefault();

        // EVENT --> PARENT
        this.$emit('close-short-notice');
      },
      openNotice($event) {
        $event.preventDefault();
        // v1 : ouverture d'une fenêtre modale :
        // EVENT --> PARENT
        this.$emit('opendetailednotice', this.itemId);
      },
      limitContentLength(text) {
        if (text === undefined) {
          return "";
        } else if (text.length > this.contentMaxLength) {
          return text.substring(0, this.contentMaxLength) + '...';
        } else {
          return text;
        }
      },

      // Gestion de la langue :

      selectLanguage($event, language) {
        $event.preventDefault();
        this.activeLanguage = language.toLowerCase().substr(0,2).toLowerCase();
      },
      languageCssClass(language) {
        return language === this.activeLanguage ? 'active' : '';
      },
      contentCssClass(itemLanguage) {
        return (itemLanguage === undefined) ||  (this.languages.length < 2) || (itemLanguage.toLowerCase().substr(0,2) === this.activeLanguage) ? '' : 'hidden';
      }

    },
    mounted() {
      this.activeLanguage = this.$store.state.currentLanguage.toLowerCase();
    },
    watch: {
      '$store.state.currentLanguage': function() {
        this.activeLanguage = this.$store.state.currentLanguage.toLowerCase();
      }
    },

  }

</script>
