<template>
    <div class="notice-footer">
      <div class="notice-footer-content">
        <p class="research-title">{{ $t( this.researchTitle ) }}</p>
        <p>{{ $t("Bâti sur Omeka S, hébergé par la TGIR-Humanum") }}</p>
        <p>{{ $t("Sauf mention contraire CC-BY-NC") }}</p>
      </div>
    </div>
</template>

<script>

  export default {
    name: 'NoticeFooter',
    computed: {
      researchTitle() {
        const settings = this.$store.state.settings;
        const lang = this.$store.state.currentLanguage;
        if (settings && settings.notices[lang]) {
          return settings.notices[lang].footer;
        }
        return '';
      }
    },

  }
</script>
