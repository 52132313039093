<template>
  <div class="view" :class="withVizualisationPopUpCssClass">
    <div class="breadcrumbs-and-title">
      <div class="breadcrumbs">
        <BreadCrumb :paths="breadcrumbs" />
      </div>
      <h1>
        {{ $t(state.collectionTitle) }}
        <a
           v-if="state.collectionDescriptions"
           @click="openCollectionDescriptions($event)"
           class="collection-description-toggle"
           href="#"></a>
      </h1>
    </div>
    <div class="contents geographie" :class="withPopUpCssClass">
      <div class="contents-wrapper with-visualization" :class="state.displayModeCssClass">

        <div class="collection-header" v-if="state.collectionDescriptions">
          <CollectionDescription
            class="with-padding"
            :class="state.collectionDescriptionVisibleCssClass"
            :descriptions="state.collectionDescriptions"
          />
        </div>

        <div class="geographie">
          <div class="contents-header with-padding">
            <ul class="contents-mode">
              <li
                  v-if="listDisplayModeAvailable"
                  class="contents-list-mode"
              >
                <router-link :to="state.listItemSetRoute">
                  {{ $t("Liste") }}
                </router-link>
              </li>
              <li
                class="contents-graph-mode"
              >
                <router-link :to="state.graphItemSetRoute">
                  {{ $t("Carte") }}
                </router-link>
              </li>
              <li class="contents-notice-mode">
                <router-link :to="state.noticeItemRoute">
                  {{ $t(state.noticeItemTabLabel) }}
                </router-link>
              </li>
            </ul>
          </div>

          <Loading class="notice-item-content with-padding no-header" v-if="state.isLoading" />

          <div v-if="! state.isLoading">

            <!-- Mode Carte -->
            <div v-if="state.isGraphDisplayMode">
              <LeafletMap
                  :items="state.items"
                  @opendetailednotice="openDetailedNotice"
              />
            </div>

            <!-- Mode Liste -->
            <div v-if="state.isListDisplayMode" class="contents-list-mode-content">
              <ListItems
                      :noticeItemType="state.noticeItemType"
                      :items="state.items"
                      :queryRouteName="state.queryRouteName"
                      :advancedQuery="state.getSearchQuery"
                      :pageNo="state.pageNo"
                      titleFieldName="title"
                      @openitem="openItem"
                      @opendetailednotice="openDetailedNotice"
                      @downloaditemnotice="openDownloadNoticePopUp"
              />
            </div>

            <!-- Mode Notice -->
            <NoticesItems
                    v-if="state.isNoticeDisplayMode"
                    :itemsIds="state.itemsIds"
                    :noticeItemId="state.noticeItemId"
                    :noticeItemType="state.noticeItemType"
                    :openGraphInPopUp="true"
                    @open-item-graph="openItemVisualization"
                    @opendetailednotice="openDetailedNotice"
                    @item-loaded="itemLoaded"
            />

            <!-- Graphe SVG d'un Item -->
            <VisualisationItem
                :class="itemVisualizationCssClass"
                :itemId="itemVisualizationItemId"
                @opendetailednotice="openDetailedNotice"
                @closeitemvisualization="closeItemVisualization"
                @notice-loaded="itemLoaded"
            />

          </div>
        </div>

      </div>
    </div>

    <NoticePopUp
            v-if="detailedNoticeItemIsOpened"
            :itemId="detailedNoticeId"
            :itemType="detailedNoticeType"
            :class="detailedNoticeCssClass"
            :itemsIds="state.itemsIds"
            :openGraphInPopUp="true"
            @open-item-graph="openItemVisualization"
            @close-detailed-item-notice="closeDetailedNotice"
    />

    <NoticeDownloadPopUp
            :itemId="state.popUpItemId"
            :itemType="state.popUpItemType"
            :class="downloadNoticePopupCssClass"
            @closedownloaditemnotice="closeDownloadNoticePopUp"
    />

  </div>

</template>

<script>

  import {computed, onMounted, reactive, watch} from 'vue'
  import NoticePopUp from "@/components/Notices/NoticePopUp";
  import NoticeDownloadPopUp from "@/components/Notices/NoticeDownloadPopUp";
  import BreadCrumb from "@/components/Header/BreadCrumb";
  import CollectionDescription from "@/components/Header/CollectionDescription";
  import ListItems from "@/components/Lists/ListItems";
  import NoticesItems from "@/components/Lists/NoticesItems";
  import VisualisationItem from "@/components/Visualization/VisualisationItem";
  import LeafletMap from "@/components/Lists/CollectionMap";
  import Loading from "@/components/Loading";

  import {useStore} from "vuex";
  import {onBeforeRouteUpdate, onBeforeRouteLeave, useRoute, useRouter} from "vue-router";

  import {getCollectionsApiURL} from "@/js/api";
  import {JsonReader} from "@/js/jsonReader";
  import {getLocalizedValue} from "@/js/functions";

  const itemsByPage = 8;

  export default {
    name: 'MapListNoticesView',
    props: {
      pageTitle: {
        type: String,
        default: '',
        required: false
      },
      category: {
        type: String,
        required: false
      },
      itemSetId: {
        type: Number,
        required: false
      },
      itemSetRouteName: {
        type: String,
        required: false,
        default:'Collection'
      },
      itemRouteName: {
        type: String,
        required: false,
        default:'Item'
      },
      noticeItemId: {
        type: Number,
        required: false,
        default:0
      },
      noticeItemType: {
        type: String,
        required: false,
        default:'item'
      },
      titleFieldName: {
        type:String,
        required: false,
        default:'o:title'
      },
      defaultDisplayMode: {
        type: String,
        required: false,
        default:"list"
      },
      listDisplayModeAvailable: {
        type: Boolean,
        required: false,
        default:true
      }    },
    components: {
      LeafletMap,
      CollectionDescription,
      ListItems,
      NoticesItems,
      NoticePopUp,
      NoticeDownloadPopUp,
      VisualisationItem,
      Loading,
      BreadCrumb
    },
    data() {
      return {

        // Données de l'Item (si onglet Notices)
        noticeItemTitle:'...',

        // Données de la popup éventuelle :
        detailedNoticeItemIsOpened:false,
        detailedNoticeId:null,
        detailedNoticeType:null,

        downloadNoticePopupIsOpened:false,
        downloadNoticeItemId:null,
        downloadNoticeItemType: this.noticeItemType,

        itemVisualizationItemId:null,
        itemVisualizationIsOpened:null,
      }
    },
    computed: {
      detailedNoticeCssClass() {
        return this.detailedNoticeItemIsOpened ? "opened" : "";
      },
      withPopUpCssClass() {
        return this.detailedNoticeItemIsOpened ? "with-popup" : "";
      },
      withVizualisationPopUpCssClass() {
        return this.itemVisualizationIsOpened ? "with-vizualisation-popup" : "";
      },
      downloadNoticePopupCssClass() {
        return this.downloadNoticePopupIsOpened === true ? "opened" : "";
      },
      itemVisualizationCssClass() {
        return this.itemVisualizationIsOpened === true ? "opened" : "closed";
      },
      breadcrumbs() {
        if ((this.state.displayMode === "notice") && this.state.noticeItemTitle) {
          // Item
          return [
            { name: 'Home', label:'Accueil' },
            { name: this.itemSetRouteName, label:this.state.collectionTitle, query: this.state.listItemQuery },
            { label: this.state.noticeItemTitle }
          ]
        } else {
          // Collection
          return [
            { name: 'Home', label:'Accueil' },
            { label: this.state.collectionTitle }
          ]
        }
      }
    },
    methods: {
      clickItem(item) {
        this.$router.push({ name: this.itemRouteName, params: { id: item.itemId } })
      },
      openItem() {
      },
      openDetailedNotice(itemId) {
        console.log("openDetailedNotice", itemId)
        this.detailedNoticeId = itemId;
        this.detailedNoticeType = this.noticeItemType;
        this.detailedNoticeItemIsOpened = true;
      },
      closeDetailedNotice() {
        this.detailedNoticeId = null;
        this.detailedNoticeType = null;
        this.detailedNoticeItemIsOpened = false;
      },
      openDownloadNoticePopUp(item) {
        this.downloadNoticePopupIsOpened = true;
        this.state.popUpItemId = item.id;
      },
      closeDownloadNoticePopUp() {
        this.downloadNoticePopupIsOpened = false;
      },
      openItemVisualization(itemId) {
        console.log("openItemVisualization", itemId);
        this.itemVisualizationIsOpened = true;
        this.itemVisualizationItemId = parseInt(itemId);
      },
      closeItemVisualization() {
        this.itemVisualizationItemId = null;
        this.itemVisualizationIsOpened = false;
      },
    },
    setup(props) {

      const route = useRoute();
      const router = useRouter();
      const store = useStore();
      const jsonReader = new JsonReader();

      let collectionId = parseInt(props.itemSetId);

      if (isNaN(collectionId)) {
        // TEMP
        const collectionsInfos = store.getters.collectionInfosByProp('name', props.category);
        if (collectionsInfos) {
          collectionId = collectionsInfos.id;
        }
      }

      const updateCollectionDescription = function() {
          state.collectionJson = store.getters.collectionJson(collectionId);
      };

      const openCollectionDescriptions = function($event) {
        $event.preventDefault();
        state.isCollectionDescriptionVisible = ! state.isCollectionDescriptionVisible;
      };

      const updateDisplayedItems = function() {

        const currentLanguage = store.state.currentLanguage;
        const items = state.items;

        let i, item, defaultTitle;
        for(i=0;i<items.length;i++)
        {
          item = items[i];
          item.id = item['o:id'];

          // o:title (RQ : il est en français quelle que soit la langue)
          defaultTitle = item[props.titleFieldName];

          if (!currentLanguage || (currentLanguage === "") || (currentLanguage === "fr")) {
            item.title = defaultTitle;
          }

          if ((typeof item.title !== "string") || (item.title.length === 0) || (currentLanguage !== "fr")) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            jsonReader.json = item;
            item.title = jsonReader.getLocalizedMetaDataValue('dcterms:alternative', currentLanguage, defaultTitle);
          }
        }
      };

      // Loads all items :
      const loadItems = function(itemId) {

        state.isLoading = true;

        const apiURL = getCollectionsApiURL(
            store.getters.apiRoot,
            [collectionId],
            "",
            state.sortByParam,
            state.sortOrderParam,
        );

        store.dispatch('getApi', apiURL).then(function (response) {

          state.isLoading = false;

          if (Array.isArray(response.data)) {

            let i, api_items = response.data, item, itemSets, firstItemSetId, collectionSettings;

            for (i = 0; i < api_items.length; i++) {
              item = api_items[i];
              item.id = item['o:id'];
              item.title = item[props.titleFieldName];

              // Récupération de l'icône et de la couleur de la collection
              itemSets = item["o:item_set"];
              if (itemSets && itemSets.length)
              {
                firstItemSetId = itemSets[0]['o:id'];
                if (firstItemSetId) {

                  collectionSettings = store.getters.collectionsSettings(firstItemSetId);
                  item.icon = collectionSettings ? collectionSettings.icon : null;
                  item.colorNo = collectionSettings ? collectionSettings.colorNo : null;

                  // Choix de la vue pour l'ouverture d'un item dans la liste :
                  // - En passant 'route', un item s'ouvrira dans son espace ( encyclopédie, corpus, ...)
                  // - En ne le passant pas, la liste déclenche une nouvelle route passée en paramètre au composant
                  // ce qui permet d'ouvrir l'item dans l'onglet Notices du composant actuel ( cf _route )
                  item._route = collectionSettings ? collectionSettings.route : '';

                  item.itemset = state.itemSetId;
                  item.type = collectionSettings ? collectionSettings.itemType : "item";
                }
              }
            }

            state.items = api_items;
            updateDisplayedItems();

          } else {
            console.log('MapListNoticesView ERROR', response);
          }

          if (!isNaN(itemId)) {
            state.noticeItemId = parseInt(itemId);
            state.displayMode = 'notice';
          } else if (state.items.length) {
            state.noticeItemId = state.displayedItemsIds[0];
          } else {
            state.noticeItemId = 0;
          }
        });
      };

      const itemLoaded = function(item) {
        state.noticeItemTitle = item.title;
      };


      //
      // Lifecycle Hooks
      //

      onMounted(() => {

        if (store.state.collections) {
          updateCollectionDescription();
        }

        if (route.query.sort_by && (route.query.sort_by.length > 0)) {
          state.sortByParam = route.query.sort_by;
        }

        if (route.query.sort_order && (route.query.sort_order.length > 0)) {
          state.sortOrderParam = route.query.sort_order;
        }

        if (route.query.display_mode && (route.query.display_mode.length > 0)) {
          state.displayMode = route.query.display_mode;
        }

        loadItems(route.params.id);
      });

      watch(() => store.state.collections, () => {
        updateCollectionDescription();
      });

      watch(() => store.state.currentLanguage, () => {
        updateCollectionDescription();
        updateDisplayedItems();
      });

      // Different route / Same component
      onBeforeRouteLeave((to, from, next) => {

        if (to.name === props.itemRouteName)
        {
          // From List/Map view to Item view
          state.noticeItemId = parseInt(to.params.id);
          state.displayMode = "notice";
          next();
        }
        else if (to.name === props.itemSetRouteName)
        {
          // From Item view to List/Map view
          state.displayMode = to.query.display_mode || props.defaultDisplayMode;
          next();
        }
        else
        {
          // Different route with different component (home, etc...)
          next();
        }
      });

      // Same route / Same component
      onBeforeRouteUpdate((to, from, next) => {

        const toDisplayMode = to.query.display_mode ? to.query.display_mode : props.defaultDisplayMode;
        const toQuerySortBy = to.query.sort_by ? to.query.sort_by : 'title';
        const toQuerySortOrder = to.query.sort_order ? to.query.sort_order : 'asc';

        if (to.name === props.itemRouteName) {

          // 1. Changement d'item (à partir d'un item)
          state.noticeItemId = parseInt(to.params.id);
          state.displayMode = 'notice';
          next();

        } else if (to.name === props.itemSetRouteName) {

          // 2. Changement de mode d'affichage de la liste des items

          if ((state.sortByParam !== toQuerySortBy) || (state.sortOrderParam !== toQuerySortOrder)) {

            // 2.1 Changement de l'ordre des items
            state.sortByParam = toQuerySortBy;
            state.sortOrderParam = toQuerySortOrder;
            state.pageNo = isNaN(to.query.p) ? 1 : parseInt(to.query.p);
            state.displayMode = toDisplayMode;

            loadItems();
            next();

          } else {

            // 2.2 Pagination
            state.pageNo = isNaN(to.query.p) ? 1 : parseInt(to.query.p);
            state.displayMode = toDisplayMode;
            next();

          }

        }
      });



      //
      // State
      //

      const state = reactive({
        collectionId,
        collectionJson: null,
        collectionTitle: computed(() => {
          if (! state.collectionJson) {
            return props.pageTitle
          } else {
            return getLocalizedValue(state.collectionJson['dcterms:title'], store.state.currentLanguage)
          }
        }),
        collectionDescriptions: computed(() => {
          if (state.collectionJson) {
            return state.collectionJson['dcterms:description']
          }
          return false;
        }),

        isCollectionDescriptionVisible:false,
        collectionDescriptionVisibleCssClass: computed( () => {
            return state.isCollectionDescriptionVisible ? "opened" : "";
        }),

        displayMode: props.noticeItemId || route.params.id ? "notice" : props.defaultDisplayMode,
        displayModeCssClass: computed(() => state.displayMode + "-mode"),

        // Item in notice view
        noticeItemId: props.noticeItemId,
        noticeItemType: props.noticeItemType,
        noticeItemTabLabel: props.noticeItemType === 'article' ? 'Articles' : 'Notices',
        noticeItemTitle: null,

        // Item in PopUp or downloaded
        popUpItemId: 0,
        popUpItemType: props.noticeItemType,

        pageNo: 1,
        pageCount: computed(() => Math.ceil( state.items.length / itemsByPage )),

        items: [],
        itemsIds: computed(() => {
          let i, itemsIds = [];
          let items = state.items;
          const n = items.length;
          for(i=0;i<n;i++) {
            itemsIds.push(parseInt(items[i]['o:id']));
          }
          return itemsIds;
        }),

        displayedItems: computed( () => {
          const startItemNo = itemsByPage * (state.pageNo - 1);
          const endItemNo = Math.min(startItemNo + itemsByPage, state.items.length);
          let i, items = [];
          for(i=startItemNo;i<endItemNo;i++) {
            items.push(state.items[i]);
          }
          return items;
        }),

        displayedItemsIds: computed(() => {
          let i, itemsIds = [];
          let items = state.displayedItems;
          const n = items.length;
          for(i=0;i<n;i++) {
            itemsIds.push(items[i]['o:id']);
          }
          return itemsIds;
        }),

        isListDisplayMode: computed(() => { return state.displayMode === "list" }),
        isGraphDisplayMode: computed(() => { return state.displayMode === "graph" }),
        isNoticeDisplayMode: computed(() => { return state.displayMode === "notice" }),

        sortByParam: 'title',
        sortOrderParam: 'asc',

        graphItemSetRoute: computed(() => {
          return router.resolve( {
              name: props.itemSetRouteName,
              query: {
                sort_param: state.sortByParam,
                sort_order: state.sortOrderParam,
                display_mode: 'graph',
                p: state.pageNo
              }
            });
          }),

        listItemSetRoute: computed(() => {
          return router.resolve( {
              name: props.itemSetRouteName,
              query: {
                sort_param: state.sortByParam,
                sort_order: state.sortOrderParam,
                display_mode: 'list',
                p: state.pageNo
              }
            });
          }),

        listItemQuery: computed(() => {
          return {
            sort_param: state.sortByParam,
            sort_order: state.sortOrderParam,
            display_mode: 'list',
            p: state.pageNo
          };
        }),

         noticeItemRoute: computed(() => {
            return router.resolve( {
              name: props.itemRouteName,
              params: { id : state.noticeItemId },
              query: {
                sort_param: state.sortByParam,
                sort_order: state.sortOrderParam,
                display_mode: 'notice'
              }
            });
        }),

        userIsConnected: computed(() => store.state.currentUser.logged )
      });


      return {
        state,
        openCollectionDescriptions,
        itemLoaded
      }
    }
  }

</script>
