<template>
  <div class="advanced-search-panel filter-by-value" :class="panelCssClass">
    <div class="advanced-search-panel-header">
      <h3><a href="#" @click="toggleSearchPanel($event)">{{ $t('Recherche par valeur') }}</a></h3>
    </div>
    <div class="filters-to-clone-parent">
      <div class="advanced-search-filters">
        <fieldset class="filter" v-for="fieldSet in state.fieldSets" v-bind:key="fieldSet.id">
          <div class="filter-inputs">

            <select :name="optionName('dctermsOption', fieldSet.id)"
                    :id="optionName('dctermsOption', fieldSet.id)"
                    :value="fieldSet.property"
                    @change="onChangeDctermsOption($event, 'dctermsOption', fieldSet.id)">
              <option value="1" data-term="dcterms:title">{{ $t("Titre de la notice") }}</option>
              <option value="2" data-term="dcterms:creator">{{ $t("Auteur de la notice") }}</option>
              <option value="23" data-term="dcterms:issued">{{ $t("Date de publication de la notice") }}</option>
              <option value="3" data-term="dcterms:subject">{{ $t("Mot-clé") }}</option>
              <option value="4" data-term="dcterms:description">{{ $t("Notice") }}</option>
              <!-- <option value="1" data-term="dcterms:title">{{ $t("Titre de l'article'") }}</option> -->
              <!-- <option value="2" data-term="dcterms:creator">{{ $t("Auteur·e de l'article'") }}</option> -->
              <!-- <option value="19" data-term="dcterms:abstract">{{ $t("Résumé") }}</option> -->
              <!-- <option value="41" data-term="dcterms:temporal">{{ $t("Période") }}</option -->
              <!-- <option value="7" data-term="dcterms:date">{{ $t("Date significative") }}</option -->
              <!-- <option value="40" data-term="dcterms:spatial">{{ $t("Lieu/ville/pays") }}</option -->
              <!-- option value="24" data-term="dcterms:modified">{{ $t("Date de modification") }}</option -->
            </select>

            <select :name="optionName('searchTypeOption', fieldSet.id)"
                    :id="optionName('searchTypeOption', fieldSet.id)"
                    :value="fieldSet.type"
                    @change="onChangeSearchTypeOption($event, 'searchTypeOption', fieldSet.id)">
              <option value="eq">{{ $t("est") }}</option>
              <option value="neq" >{{ $t("n'est pas") }}</option>
              <option value="in" selected >{{ $t("contient") }}</option>
              <option value="nin" >{{ $t("ne contient pas") }}</option>
            </select>

            <input :name="optionName('dcTermsValue', fieldSet.id)"
                   :id="optionName('dcTermsValue', fieldSet.id)"
                   :value="fieldSet.text"
                   @input="onChangeDcTermsValueOption($event, 'dcTermsValue', fieldSet.id)"
                   type="text"
                   />
            <div class="delete-button">
              <button class="delete-icon" @click="removeFieldSet($event, fieldSet.id)"></button>
            </div>
          </div>

          <div class="advanced-search-boolean">
            <div class="advanced-search-boolean-option">
              <label class="radio-label">
                <input type="radio"
                            value="and"
                            :name="optionName('joinerOption', fieldSet.id)"
                            :checked="getChecked(fieldSet.joiner, 'and')"
                            :id="optionName('joinerAndOption', fieldSet.id)"
                            @change="onChangeBooleanOption($event, 'joinerOption', fieldSet.id)">
                <span class="checkmark "></span>
                <span class="radio-label-text">{{ $t("et") }}</span>
              </label>
            </div>
            <div class="advanced-search-boolean-option">
              <label class="radio-label">
                <input type="radio"
                            value="or"
                            :name="optionName('joinerOption', fieldSet.id)"
                            :checked="getChecked(fieldSet.joiner, 'or')"
                            :id="optionName('joinerOrOption', fieldSet.id)"
                            @change="onChangeBooleanOption($event, 'joinerOption', fieldSet.id)">
                <span class="checkmark "></span>
                <span class="radio-label-text">{{ $t("ou") }}</span>
              </label>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="advanced-search-add-filter-parent">
      <a href="#" class="advanced-search-add-filter" @click="addFieldSet($event)">{{ $t("Ajouter une nouvelle valeur") }}</a>
    </div>
  </div>
</template>

<script>
  import {reactive, watch} from "vue";

  export default {
    name: 'SearchFieldSets',
    props: {
      advancedQuery: {
        type: String,
        required: false
      },
      resetQuery: {
        type: Number,
        required: false,
        default: 0
      },
      settings: {
        type: Object,
        required: false
      },
    },
    emits: [
      "change",
      "change-settings"
    ],
    computed:{
      panelCssClass() {
        return this.state.isPanelOpened ? "" : "search-panel-closed";
      }
    },
    methods:{
      toggleSearchPanel($event) {
        $event.preventDefault();
        this.state.isPanelOpened = ! this.state.isPanelOpened;
        this.$emit("change-settings", { opened: this.state.isPanelOpened })
      }
    },
    setup(props, context) {

      // console.log("initialQuery", props.advancedQuery);

      const resetFieldSet = function() {

        // On récupère dans les paramètres de l'URL les filtres et leurs options
        let fieldSetsParams = [], fieldSets;

        // RQ : Cas particulier où on a transformé la requête q=... en &property[0][type]=in&property[0][text]=...

        if (props.advancedQuery && (props.advancedQuery.indexOf('property[') !== -1)) {

          fieldSets = [];

          ('?' + props.advancedQuery ).replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            if (key.indexOf('property[') === 0) {
              fieldSetsParams.push({
                key: key,
                value: value
              });
            }
          });

          // On passe en revue chaque propriété pour extraire l'index, le nom et la valeur de la propriété
          let i, initialFieldSetParam, extractedValues, propertyIndex, propertyName, propertyValue;
          const regex = /([^[]+(?=]))/g;
          const n = fieldSetsParams.length;

          let foundIndexes = [];
          let index;

          for(i=0;i<n;i++)
          {
            // Ex : property[0][property]=1
            initialFieldSetParam = fieldSetsParams[i];

            extractedValues = initialFieldSetParam.key.match(regex);

            propertyIndex = parseInt(extractedValues[0]);
            propertyName = extractedValues[1];
            propertyValue = initialFieldSetParam.value;

            let pos = foundIndexes.indexOf(propertyIndex);
            if (pos === -1) {
              foundIndexes.push(propertyIndex);
              index = foundIndexes.length - 1;
            } else {
              index = pos;
            }

            if (index > fieldSets.length - 1) {
              fieldSets[index] = {
                id:index + 1
              };
            }

            fieldSets[index][propertyName] = propertyValue;
          }
        }
        else
        {
          fieldSets = [{
              id:1,
              property: 1,
              joiner: "and",
              type: "in",
              text: ""
            }
          ]
        }

        return fieldSets;
      };

      const initialFieldSets = resetFieldSet();
      setTimeout(function() {
        context.emit('change', initialFieldSets );
      }, 100);

      const state = reactive({
        fieldSetMaxId: 1,
        fieldSets: initialFieldSets,
        isPanelOpened: props.settings && props.settings.opened === true ? true : false
      });

      watch(state.fieldSets, (newVal) => {
        context.emit('change', newVal );
      });

      watch( props, () => {
        const initialFieldSets = resetFieldSet();
        state.fieldSets = initialFieldSets;
        context.emit('change', initialFieldSets );
      });

      const addFieldSet = function($event) {
        $event.preventDefault();
        state.fieldSets.push({
          id: ++state.fieldSetMaxId,
          property: 1,
          joiner: "and",
          type: "in",
          text: ""
        });
      };

      const removeFieldSet = function($event, fieldSetId) {
        $event.preventDefault();
        $event.stopPropagation();
        let i, fieldSet;
        const n = state.fieldSets.length;
        if (n > 1) {
          for(i=0;i<n;i++) {
            fieldSet = state.fieldSets[i];
            if (fieldSet.id === fieldSetId) {
              state.fieldSets.splice(i, 1);
              break;
            }
          }
        }
      };

      const findFieldSet = function(fieldSetId) {
        let i, fieldSet;
        const n = state.fieldSets.length;
        for(i=0;i<n;i++) {
          fieldSet = state.fieldSets[i];
          if (fieldSet.id === fieldSetId) {
            return fieldSet;
          }
        }
      };

      const resetSearch = function($event) {
        $event.preventDefault();

        const fieldSetsCount = state.fieldSets.length;
        if (fieldSetsCount > 1) {
          state.fieldSets.splice(1, fieldSetsCount - 1);
        }
      };

      const getChecked = function(fieldSetJoiner, value) {
        return fieldSetJoiner === value ? "checked" : null
      };

      const optionName = function(controlType, fieldSetId) {
        return controlType + fieldSetId;
      };

      const onChangeDctermsOption = function($event, controlType, fieldSetId) {
        $event.preventDefault();
        const fieldSet = findFieldSet(fieldSetId);
        if (fieldSet) {
          const element = $event.target;
          fieldSet.property = element.options[element.selectedIndex].value;
        }
      };

      const onChangeDcTermsValueOption = function($event, controlType, fieldSetId) {
        $event.preventDefault();
        const fieldSet = findFieldSet(fieldSetId);
        if (fieldSet) {
          const element = $event.target;
          fieldSet.text = element.value;
        }
      };

      const onChangeSearchTypeOption = function($event, controlType, fieldSetId) {
        $event.preventDefault();
        const fieldSet = findFieldSet(fieldSetId);
        if (fieldSet) {
          const element = $event.target;
          fieldSet.type = element.value;
        }
      };

      const onChangeBooleanOption = function($event, controlType, fieldSetId) {
        $event.preventDefault();
        const fieldSet = findFieldSet(fieldSetId);
        if (fieldSet) {
          const target = $event.target;
          const radioElement = document.getElementById(target.id);
          fieldSet.joiner = radioElement.value;
        }
      };

      return {
        state,
        optionName,
        onChangeDctermsOption,
        onChangeDcTermsValueOption,
        onChangeSearchTypeOption,
        onChangeBooleanOption,
        findFieldSet,
        removeFieldSet,
        addFieldSet,
        resetSearch,
        getChecked
      }

    }
  }
</script>
