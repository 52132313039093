import * as d3 from "d3";

// https://bl.ocks.org/mbostock/7555321
function wrap(text, width) {

    text.each(function () {

        let text = d3.select(this),
            words = text.text().trim().split(/[\s+-]/).reverse(),
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.1, // ems
            x = text.attr("x"),
            y = text.attr("y"),
            tspan = text.text(null).append("tspan").attr("x", x).attr("y", y).attr("dy", 0 + "em");

        word = words.pop();

        if (words.length === 0) {
            tspan.text(word);
            word = false;
        }

        while (word) {
            line.push(word);
            tspan.text(line.join(" "));
            if (tspan.node().getComputedTextLength() > width) {
                line.pop();
                tspan.text(line.join(" "));
                line = [word];
                tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dy", ++lineNumber * lineHeight + "em").text(word);
            }
            word = words.pop()
        }

    });
}


function sansAccent(text) {

    var i, n = text.length-1;
    var result = "";
    var c, lower, isLower;

    for (i=0; i<=n; i++) {

        c = text.charAt(i);
        lower = c.toLowerCase();
        isLower = c == lower;
        
        if (String("àáâãäå").indexOf(lower)>=0) {
            c = "a";
        } else if (String("èéêë").indexOf(lower)>=0) {
            c = "e";
        } else if (String("ìíïî").indexOf(lower)>=0) {
            c = "i";
        } else if (String("ðòóôõö").indexOf(lower)>=0) {
            c = "o";
        } else if (String("ùúüû").indexOf(lower)>=0) {
            c = "u";
        } else if (String("ýÿ").indexOf(lower)>=0) {
            c = "y";
        } else if (String("ñ").indexOf(lower)>=0) {
            c = "n";
        } else if (String("ç").indexOf(lower)>=0) {
            c = "c";
        } else if (String("ž").indexOf(lower)>=0) {
            c = "z";
        } else if (String("š").indexOf(lower)>=0) {
            c = "s";
        } else if (String("œ").indexOf(lower)>=0) {
            c = "oe";
        }
        result += isLower ? c : c.toUpperCase();
    }
    return result;
}

function dateJsToMySQL( dateJS ) {

    const fullYear = dateJS.getFullYear();
    const day = dateJS.getDate();

    let month = dateJS.getMonth() + 1;
    if (month < 10 ) {
        month = "0" + month;
    }

    let hours = dateJS.getHours();
    let minutes = dateJS.getMinutes();
    let seconds = dateJS.getSeconds();

    if (hours < 10 ) {
        hours = "0" + hours;
    }

    if (minutes < 10 ) {
        minutes = "0" + minutes;
    }

    if (seconds < 10 ) {
        seconds = "0" + seconds;
    }

    return [fullYear, month, day].join('-') + 'T' + [hours, minutes, seconds].join('-')
}

function mySQLToDateJs( dateMySQL ) {

    const dateMySQLArray = dateMySQL.split('T');

    const dayMySQLArray = dateMySQLArray[0].split('-');
    const timeMySQLArray = dateMySQLArray[1].split(':');

    const fullYear = parseInt(dayMySQLArray[0]);
    const month =  parseInt(dayMySQLArray[1]) - 1;
    const day =  parseInt(dayMySQLArray[2]);

    const hours =  parseInt(timeMySQLArray[0]);
    const minutes =  parseInt(timeMySQLArray[1]);
    const seconds =  parseInt(timeMySQLArray[2]);

    return new Date(fullYear, month, day, hours, minutes, seconds );
}

function dateJsToHuman( dateJS ) {

    const fullYear = dateJS.getFullYear();

    let day = dateJS.getDate();
    if (day === 1) {
        day = "1er";
    }

    const month = dateJS.getMonth();
    const monthStr = [
        "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet",
        "Août", "Septembre", "Octobre", "Novembre", "Décembre"
    ];

    let hours = dateJS.getHours();
    let minutes = dateJS.getMinutes();
    let seconds = dateJS.getSeconds();

    if (hours < 10 ) {
        hours = "0" + hours;
    }

    if (minutes < 10 ) {
        minutes = "0" + minutes;
    }

    if (seconds < 10 ) {
        seconds = "0" + seconds;
    }

    return ["le", day, monthStr[month], fullYear, "à", hours + ":" + minutes + ":" + seconds ].join(' ');
}

function dateJsToDDMMYYYY( dateJS, separator = "/" ) {


    let day = dateJS.getDate();
    if (day < 10 ) {
        day = "0" + day;
    }

    let month = dateJS.getMonth() + 1;
    if (month < 10 ) {
        month = "0" + month;
    }

    const fullYear = dateJS.getFullYear();

    return [day, month, fullYear].join(separator);
}


export {
    wrap,
    sansAccent,
    dateJsToMySQL,
    mySQLToDateJs,
    dateJsToHuman,
    dateJsToDDMMYYYY
}