<template>
  <div class="view">
    <div class="breadcrumbs-and-title">
      <div class="breadcrumbs">
        <BreadCrumb :paths="breadcrumbs" />
      </div>
      <h1>{{ $t('Mon compte') }}</h1>
    </div>
    <div class="contents">
      <div class="contents-wrapper">
        <UserAccount />
      </div>
    </div>
  </div>
</template>

<script>

  import BreadCrumb from "../components/Header/BreadCrumb";
  import UserAccount from "../components/UserAccount/UserAccount";
  import {useStore} from "vuex";
  import {onBeforeRouteUpdate} from "vue-router";

  export default {
    components: {
      BreadCrumb, UserAccount
    },
    computed: {
      breadcrumbs() {
        return [
          { name: 'Home', label:'Accueil' },
          { label:'Mon compte' }
        ]
      }
    },
    setup() {
      const store = useStore();

      onBeforeRouteUpdate((to, from, next) => {
        if (store.state.currentUser.logged === true) {
          next();
        }
      });
    }
  }

</script>