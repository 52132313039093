<template>
  <div class="account-items-panel account-parameters-panel" :class="panelOpenedCssClass">
    <div class="account-items-header">
      <a href="#" @click="toggleClose($event)">
        <h3>{{ $t("Mes paramètres") }}</h3>
        <div class="account-items-close"></div>
      </a>
    </div>
    <div class="account-items">
      <div class="account-parameters-fields">
        <div>
          <label>{{ $t("Nom") }}</label>
          <input type="text" v-model="state.inputUserName" />
        </div>
        <div>
          <label>{{ $t("Email") }} *</label>
          <input type="text" v-model="state.inputUserEmail" />
        </div>
        <div class="account-parameters-buttons">
          <a href="#" @click="submitUserChanges($event)">{{ $t("Enregistrer les changements") }}</a>
        </div>
      </div>
      <div class="account-parameters-fields">
        <div>
          <label>{{ $t("Ancien mot de passe") }}</label>
          <input type="password" v-model="state.inputUserPassword" />
        </div>
        <div>
          <label>{{ $t("Nouveau mot de passe") }}</label>
          <input type="password" v-model="state.inputUserNewPassword" />
          <p class="input-description">
            Le meilleur est une phrase complète. C’est facile à retenir et assez long pour être très sécurisé.
          </p>
        </div>
        <div class="account-parameters-buttons">
          <a href="#" @click="submitUserPasswordChange($event)">{{ $t("Changer le mot de passe") }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import {useStore} from "vuex";
  import {computed, watch, reactive} from "vue";
  import {updateUserApiURL} from "../../js/api";
  import axios from "axios";

  export default {
    name: 'UserParameters',
    components: {
    },
    props: {
      opened: {
        type: Boolean,
        required: true
      },
    },
    emits:[
      'toggle-parameters-panel'
    ],
    computed: {
      panelOpenedCssClass() {
        return this.opened ? "account-items-opened" : "";
      }
    },
    methods: {
      toggleClose($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.$emit('toggle-parameters-panel')
      }
    },
    setup() {

      const store = useStore();

      const submitUserPasswordChange = function($event) {
        $event.preventDefault();

        if ((state.inputUserPassword.length > 0) && (state.inputUserNewPassword.length > 0) && (state.inputUserPassword !== state.inputUserNewPassword)) {

          let changes = {};
          changes.password = state.inputUserPassword;
          changes.new_password = state.inputUserNewPassword;

          // Api Omeka (Guest Api Module)
          const apiURL = updateUserApiURL(store.getters.apiRoot);
          const keyIdentity = store.getters.identityAndCredentialParameters.substr(1);

          axios.patch(apiURL + keyIdentity, changes).then(function (response) {

            console.log("changes password success", response);

            if (response.data.status === "error") {
              console.log(response.data.message);
            } else if (response.data.status === "success") {
              // message: "Mot de passe changé avec succès"
              console.log(response.data.message);
            }

          }).catch(error => {
            console.error("log out error", error);
          });
        }

      };

      const submitUserChanges = function($event) {
        $event.preventDefault();

        // On regarde ce qui a changé :
        let changes = {}, changed = false;

        if ((state.inputUserName.length > 0) && (state.inputUserName !== userState.userName)) {
          changes.name = state.inputUserName;
          changed = true;
        }

        if ((state.inputUserEmail.length > 0) && checkEmail(state.inputUserEmail) && (state.inputUserEmail !== userState.userEmail)) {
          changes.email = state.inputUserEmail;
          changed = true;
        }

        if (changed)
        {
          // Api Omeka (Guest Api Module)
          const apiURL = updateUserApiURL(store.getters.apiRoot);
          const keyIdentity = store.getters.identityAndCredentialParameters.substr(1);

          let urlParams = {};

          if (changes.email) {
            urlParams.email = changes.email;
          }

          if (changes.name) {
            urlParams.name = changes.name;
          }

          if (changes.password && changes.newPassword) {
            urlParams.password = changes.password;
            urlParams.new_password = changes.newPassword;
          }

          axios.patch(apiURL + keyIdentity, urlParams).then(function (response) {

            console.log("changes success", response);

            //
            // Sauvegarde dans le store
            //

            const userChanges = {
              name: response.data["o:name"],
              email: response.data["o:email"],
            };

            store.dispatch("updateCurrentUser", userChanges);
            store.dispatch("updateUserInLocalStorage", userChanges);

          }).catch(error => {
            console.error("log out error", error);
          });
        }
      };

      const checkEmail = function (email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      };

      const userState = reactive({
        userName: computed(() => store.state.currentUser.name ),
        userEmail: computed(() => store.state.currentUser.email ),
      });


      const state = reactive({
        inputUserName: userState.userName,
        inputUserEmail: userState.userEmail,
        inputUserPassword: "****",
        inputUserNewPassword: "****"
      });

      watch( () => userState, (newValue) => {
        console.log("new", newValue);
        state.inputUserName = userState.userName;
        state.inputUserEmail = userState.userEmail;
      });

      return {
        state,
        submitUserChanges,
        submitUserPasswordChange
      }
    }
  }

</script>
