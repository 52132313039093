<template>

  <nav
      class="item-navigation item-navigation-events"
      :class="navigationCssClass"
  >
    <ul v-if="previousItemId > 0 || nextItemId > 0">
      <li>
        <a
                v-if="previousItemId > 0"
                href="#"
                @click="selectItem($event, previousItemId)"
                class="previous-item"
        >
          <span class="item-navigation-title">{{ state.previousTitle }}</span>
          <span class="item-navigation-label">{{ $t("Précédent") }}</span>
        </a>
      </li>
      <li>
        <a
                v-if="nextItemId > 0"
                href="#"
                @click="selectItem($event, nextItemId)"
                class="next-item">
          <span class="item-navigation-label">{{ $t("Suivant") }}</span>
          <span class="item-navigation-title">{{ state.nextTitle }}</span>
        </a>
      </li>
    </ul>

    <a
        href="#"
        class="close-browser-window"
        @click="closeBrowserWindow($event)">
      {{ $t("Fermer la fenêtre") }}
    </a>

  </nav>

</template>

<script>

  import {reactive, ref, onMounted, watch} from "vue";
  import {useStore} from "vuex";
  import {getItemApiURL} from "../../js/api";
  import axios from "axios";
  import {truncate} from "../../js/functions";
  import {JsonReader} from "../../js/jsonReader";
  import {useRoute} from "vue-router";

  export default {
    name: 'NavigationItemsEventDispatcher',
    components: {
    },
    props: {
      previousItemId: {
        type: Number,
        required: true,
        default: 0
      },
      nextItemId: {
        type: Number,
        required: true,
        default: 0
      }
    },
    data() {
      return {
      }
    },
    emits: [
      "change"
    ],
    methods:{
      selectItem($event, itemId) {
        $event.preventDefault();
        this.$emit("change", itemId);
      },
    },
    setup(props) {

      const route = useRoute();
      const store = useStore();
      const jsonReader = new JsonReader();

      const state = reactive({
        previousTitle:'',
        nextTitle:'',
      });

      // RQ : Le suivant/précédent est masqué dans le cas d'une notice ouverte à partir d'un autre item via un lien externe ( target _blank )
      let navigationCssClass = ref('');

      // Promise : API de l'item :
      const loadItemJson = function(itemId) {
        const apiRoot = store.getters.apiRoot;
        const keyIdentity = store.getters.identityAndCredentialParameters;
        const apiURL = getItemApiURL(apiRoot, itemId);
        return axios.get(apiURL + keyIdentity);
      };

      const parseItemTitle = function(itemJson) {

        jsonReader.json = itemJson;
        const currentLanguage = store.state.currentLanguage;

        let title = jsonReader.getLocalizedMetaDataValue('dcterms:alternative', currentLanguage);

        if ((typeof title !== "string") || (title.length === 0)) {
          title = jsonReader.getLocalizedMetaDataValue('dcterms:title', currentLanguage);
        }

        if ((typeof title !== "string") || (title.length === 0)) {
          title = itemJson['o:title'];
        }

        return truncate(title);
      };

      //
      // Lifecycle Hooks
      //

      onMounted(() => {
        navigationCssClass.value = route.query.from_item ? "hide-navigation" : "";
      });

      watch( () => props.previousItemId, (itemId) => {
        if (itemId > 0) {
          loadItemJson(itemId).then( response => {
            state.previousTitle = parseItemTitle(response.data);
          });
        } else {
          state.previousTitle = '';
        }
      });

      watch( () => props.nextItemId, (itemId) => {
        if (itemId > 0) {
          loadItemJson(itemId).then( response => {
            state.nextTitle = parseItemTitle(response.data);
          });
        } else {
          state.nextTitle = '';
        }
      });

      // First update

      if (props.previousItemId > 0) {
        loadItemJson(props.previousItemId).then( response => {
          state.previousTitle = truncate(response.data['o:title'] );
        });
      }

      if (props.nextItemId > 0) {
        loadItemJson(props.nextItemId).then( response => {
          state.nextTitle = truncate(response.data['o:title'] );
        });
      }

      // JS : Fermer la fenêtre du navigateur ( liens externes d'une notice )
      const closeBrowserWindow = function($event) {
        $event.stopPropagation();
        window.close();
      };

      return {
        state,
        navigationCssClass,
        closeBrowserWindow
      };
    }
}
</script>
