<template>
  <div class="account">
    <div class="account-title">
      <h2>
        <span class="user-first-name">{{ state.firstName }}</span> <span class="user-last-name">{{ state.lastName }}</span>
      </h2>
    </div>
    <div class="account-items-panels with-padding">
      <UserBasket
          @toggle-basket-panel="toggleBasketPanel"
          v-bind:opened ="state.basketOpened"
      />
      <UserSearches
          @toggle-searches-panel="toggleSearchesPanel"
          v-bind:opened ="state.searchesOpened"
      />
      <UserSelections
          @toggle-selections-panel="toggleSelectionsPanel"
          v-bind:opened ="state.selectionsOpened"
      />
      <UserContribution
          @toggle-contributions-panel="toggleContributionsPanel"
          v-bind:opened ="state.contributionsOpened"
      />
      <UserParameters
          @toggle-parameters-panel="toggleParametersPanel"
          v-bind:opened ="state.parametersOpened"
      />
    </div>
  </div>
</template>

<script>

  import {computed, onMounted, reactive} from "vue";
  import { useRoute, onBeforeRouteUpdate } from "vue-router";
  import UserBasket from "./UserBasket";
  import UserSearches from "./UserSearches";
  import UserSelections from "./UserSelections";
  import UserContribution from "./UserContribution";
  import UserParameters from "./UserParameters";
  import {useStore} from "vuex";

  export default {
    name: 'UserAccount',
    components: {
      UserBasket,
      UserSearches,
      UserSelections,
      UserContribution,
      UserParameters
    },
    setup() {

      const store = useStore();
      const route = useRoute();

      const updateAccordion = function(slug){

        switch(slug) {

          case 'mon-panier':
            state.basketOpened = true;
            state.searchesOpened = false;
            state.selectionsOpened = false;
            state.contributionsOpened = false;
            state.parametersOpened = false;
            break;

          case 'mes-recherches':
            state.basketOpened = false;
            state.searchesOpened = true;
            state.selectionsOpened = false;
            state.contributionsOpened = false;
            state.parametersOpened = false;
            break;

          case 'mes-selections':
            state.basketOpened = false;
            state.searchesOpened = false;
            state.selectionsOpened = true;
            state.contributionsOpened = false;
            state.parametersOpened = false;
            break;

          case 'mes-annotations':
            state.basketOpened = false;
            state.searchesOpened = false;
            state.selectionsOpened = false;
            state.contributionsOpened = true;
            state.parametersOpened = false;
            break;

          case 'mes-parametres':
            state.basketOpened = false;
            state.searchesOpened = false;
            state.selectionsOpened = false;
            state.contributionsOpened = false;
            state.parametersOpened = true;
            break;

          default:
            state.basketOpened = false;
            state.searchesOpened = false;
            state.selectionsOpened = false;
            state.contributionsOpened = false;
            state.parametersOpened = false;
            break;
        }

        // console.log(state.basketOpened, state.searchesOpened, state.contributionsOpened, state.parametersOpened);
      };

      onBeforeRouteUpdate((to, from, next) => {
        if (to.params.slug !== from.params.slug) {
          // Navigation between user panels
          updateAccordion(to.params.slug);
          next();
        } else if (to.query.lang !== from.query.lang) {
          next();
        }
      });

      onMounted(() => {
        updateAccordion(route.params.slug);
      });

      const state = reactive({
        basketOpened: false,
        searchesOpened: false,
        selectionsOpened: false,
        contributionsOpened: false,
        parametersOpened: false,
        firstName: computed(() => store.getters.userFirstName),
        lastName: computed(() =>  store.getters.userLastName)
      });

      const toggleBasketPanel = function () {
          state.basketOpened = ! state.basketOpened;
      };

      const toggleSearchesPanel = function () {
        state.searchesOpened = ! state.searchesOpened;
      };

      const toggleSelectionsPanel = function () {
        state.selectionsOpened = ! state.selectionsOpened;
      };

      const toggleContributionsPanel = function () {
        state.contributionsOpened = ! state.contributionsOpened;
      };

      const toggleParametersPanel = function () {
        state.parametersOpened = ! state.parametersOpened;
      };

      return {
        state,
        toggleBasketPanel,
        toggleSearchesPanel,
        toggleSelectionsPanel,
        toggleContributionsPanel,
        toggleParametersPanel
      }
    }

    }
</script>
