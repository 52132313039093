<template>
  <div class="view">
    <div class="breadcrumbs-and-title">
      <div class="breadcrumbs">
        <BreadCrumb :paths="breadcrumbs" />
      </div>
      <h1>Contact</h1>
    </div>
    <div class="contents">
      <div class="contents-wrapper">
        <div class="contact form-parent with-padding">
          <form>
            <div class="contact-name-email-fields form-fields" :class="state.contactNameEmailCssClass">
              <div>
                <label for="contact_name">Prénom Nom <span class="required">*</span></label>
                <input
                        type="text"
                        id="contact_name"
                        v-model="state.userName"
                        :disabled="state.inputIsDisabledAttr"
                        placeholder="username@domain.org">
              </div>
              <div>
                <label for="contact_email">Email <span class="required">*</span></label>
                <input
                        type="text"
                        name="o:email"
                        id="contact_email"
                        v-model="state.userEmail"
                        :disabled="state.inputIsDisabledAttr"
                        placeholder="paul@otlet.fr">
              </div>
            </div>
            <div class="contact-message-fields">
              <label for="contact_message">Votre message<span class="required">*</span></label>
              <textarea
                      id="contact_message"
                      name="o-module-contact:body"
                      v-model="state.userMessage"
                      placeholder="p.ex. j’ai une question sur ...">

              </textarea>
            </div>

            <p class="contact-warning">
              En envoyant ce mail vous acceptez les conditions générales et acceptez que ce site utilise votre adresse mail afin de vous répondre.
            </p>

            <div class="contact-buttons">
              <input type="submit" value="Envoyer" @click="sendFormData($event)" />
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  import {computed, onMounted, reactive, watch} from "vue";
  import {useStore} from "vuex";
  import axios from "axios";
  import {getContactMessageApiURL} from "../js/api";
  import BreadCrumb from "../components/Header/BreadCrumb";

  export default {
    components: {BreadCrumb},
    computed: {
      breadcrumbs() {
        return [
          { name: 'Home', label:'Accueil' },
          { label:'Contact' }
        ]
      }
    },
    setup() {

      const store = useStore();

      const state = reactive({
        userIsConnected: computed(() => store.state.currentUser.logged ),
        computedUserName: computed(() => store.state.currentUser.name ),
        contactNameEmailCssClass: computed(() => store.state.currentUser.logged ? "connected" : "" ),
        inputIsDisabledAttr: computed(() => store.state.currentUser.logged ? "disabled" : false ),

        // Valeurs du modèle du formulaire
        userName: state && state.isUserConnected ? store.state.currentUser.name : "",
        userEmail: state && state.isUserConnected ? store.state.currentUser.email : "",
        userMessage: "",
        userNewsletter: false,
      });

      onMounted(() => {
        state.userName  = state.userIsConnected ? store.state.currentUser.name : "";
        state.userEmail = state.userIsConnected ? store.state.currentUser.email : "";
      });

      // Lorsau'une personne se déconnecte, on vide le champ du mot de passe
      watch( () => state.userIsConnected, (userIsConnected) => {
        state.userName  = userIsConnected ? store.state.currentUser.name : "";
        state.userEmail = userIsConnected ? store.state.currentUser.email : "";
      });

      // Lorsau'une personne vient de se connecter, le nom est renvoyé par une api Omeka un peu après
      watch( () => state.computedUserName, (userName) => {
        if (state.userIsConnected) {
          state.userName = userName;
        }
      });

      console.log("connecté", state.userIsConnected, store.state.currentUser.name)

      const sendFormData = function($event) {

        $event.preventDefault();

        /*
        let bodyFormData = new FormData();
        bodyFormData.append('o:name', state.userName);
        bodyFormData.append('o:email', state.userEmail);
        bodyFormData.append('o-module-contact:body', state.userMessage);
        bodyFormData.append('o-module-contact:newsletter', state.userNewsletter);
        console.log(bodyFormData);
         */

        let jsonData = {
          'o:name': state.userName,
          'o:email': state.userEmail,
          'o-module-contact:body': state.userMessage
        };

        const apiURL = getContactMessageApiURL(store.getters.apiRoot);
        const keyIdentity = state.isUserConnected ? store.getters.identityAndCredentialParameters : "";

        console.log(apiURL + keyIdentity)

        const options = {
          headers: {'Content-Type': 'application/json'}
        };

        axios.post( apiURL + keyIdentity, jsonData, options)
          .then(function (response) {
          // On efface le message
          state.userMessage = "";
          console.log("contact success", response);
        })
        .catch(function (response) {
          //handle error
          console.log("contact error", response);
        });
      };

      return {
        state,
        sendFormData
      }

    }
  }

</script>