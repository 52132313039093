<template>

  <div
    v-if="total > 0"
    class="notice-item-field notice-item-references"
  >

    <h2 class="notice-item-field-title with-icon">
      <span class="linked-elements">{{ $t(linkedElementsLabel) }}</span>
    </h2>

    <div
      v-for="term in termsOrder" v-bind:key="term"
      :class="termCssClass(term)"
    >
      <div v-if="hasItemForTerm(term)" :data-term="term">

        <h3 class="notice-item-field-title notice-item-type">{{ $t(getLabel(term)) }}</h3>

        <ul class="notice-item-field-content" v-if="isTerm(term, 'dcterms:relation')" >
          <li v-for="item in dctermsRelation" :key="item.id" >
            <a
                :href="getNoticeURL(item)"
                :target="targetIfNotPopUp"
                @click="clickOnNotice($event, item)">
              {{ item.value }}
            </a>
          </li>
        </ul>

        <ul class="notice-item-field-content" v-else-if="isTerm(term,'dcterms:requires')">
          <li v-for="item in requiresArticles" :key="item.id" >
            <a
                :href="getArticleURL(item.id)"
                :target="targetIfNotPopUp"
                @click="clickOnArticle($event, item)">
              {{ item.value }}
            </a>
          </li>
        </ul>

        <ul class="notice-item-field-content" v-else-if="isTerm(term,'extracttext:extracted_text')">
          <li v-for="item in extractedTexts" :key="item.id" >
            <a
                :href="getNoticeURL(item)"
                :target="targetIfNotPopUp"
                @click="clickOnDocument($event, item)">
              {{ item.value }}
            </a>
          </li>
        </ul>

        <ul class="notice-item-field-content" v-else-if="isTerm(term,'bibo:uri')">
          <li v-for="item in authorityLinks" :key="item.id" >
            <a
                :href="item.id"
                target="_blank"
                class="external-link">
              {{ item.value }}
            </a>
          </li>
        </ul>

        <ul class="notice-item-field-content" v-else-if="isTerm(term,'dcterms:references')">
          <li v-for="item in referencesLinks" :key="item.id" >

            <a v-if="item.propertyName === 'o:label'"
               :href="item.id"
               target="_blank"
               class="external-link">
              {{ item.value }}
            </a>

            <a v-else-if="item.propertyName === 'display_title'"
               :href="getNoticeURL(item)"
               :target="targetIfNotPopUp"
               :class="linkCssClassIfNotPopUp"
               @click="clickOnNotice($event, item)"
            >
              {{ item.value }}
            </a>

          </li>
        </ul>

        <ul class="notice-item-field-content" v-else-if="isTerm(term,'bibo:cites')">
          <li v-for="item in cites" :key="item.id">

            <a v-if="item.propertyName === 'o:label'"
               :href="item.id"
               target="_blank"
               class="external-link">
              {{ item.value }}
            </a>

            <a v-else-if="item.propertyName === 'display_title'"
               :href="getNoticeURL(item)"
               target="_blank"
               @click="clickOnDocument($event, item)">
              {{ item.value }}
            </a>

          </li>
        </ul>

        <ul class="notice-item-field-content" v-else-if="isTerm(term,'dcterms:isPartOf')">
          <li v-for="item in isPartOf" :key="item.id">

            <a
               :href="getNoticeURL(item)"
               @click="clickOnNotice($event, item)">
              {{ item.value }}
            </a>

          </li>
        </ul>

        <ul class="notice-item-field-content" v-else-if="isTerm(term,'dcterms:hasPart')">
          <li v-for="item in hasPart" :key="item.id">

            <a
               :href="getNoticeURL(item)"
               @click="clickOnNotice($event, item)">
              {{ item.value }}
            </a>

          </li>
        </ul>

        <ul class="notice-item-field-content" v-else >
          <li v-for="item in itemsByTerm[term]" v-bind:key="item">

            <span v-if="typeof item === 'string'">
              {{ item }}
            </span>

            <a
                v-if="item.type === 'resource:item'"
                :href="getNoticeURL(item)"
                @click="clickOnNotice($event, item)">
              {{ item.value }}
            </a>
            <a
                v-else-if="item.fieldName ==='dcterms:bibliographicCitation'"
                :href="getNoticeURL(item)"
                target="_blank"
                @click="clickOnNotice($event, item)">
              {{ item.value }}
            </a>
            <a
                v-else-if="item.type.indexOf('customvocab') === 0"
                :href="getNoticeURL(item)"
                target="_blank">
              {{ item.value }}
            </a>

            <span v-else>
              {{ item }}
            </span>

          </li>
        </ul>

      </div>
    </div>
  </div>


</template>

<script>

  export default {

    name: 'NoticeMediaItemLinks',
    props: {
      total: {
        type: Number,
        required: true,
      },
      labelsByTerm: {
        type:Array,
        required: true,
      },
      itemsByTerm: {
        type:Array,
        required: true,
      },
      termsOrder: {
        type:Array,
        required: true,
      },
      linkedElementsLabel: {
        type:String,
        required: false,
        default:"Éléments associés"
      },
      linksInPopUp: {
        type:Boolean,
        required: false,
        default: false
      },
      fromItem: {
        type:Number,
        required: false
      },
    },
    computed: {
      authorityLinks() {
        return this.itemsByTerm['bibo:uri'];
      },
      extractedTexts() {
        return this.itemsByTerm['extracttext:extracted_text'];
      },
      dctermsRelation() {
        return this.itemsByTerm['dcterms:relation'];
      },
      requiresArticles() {
        return this.itemsByTerm['dcterms:requires'];
      },
      cites() {
        return this.itemsByTerm['bibo:cites'];
      },
      referencesLinks() {
        return this.itemsByTerm['dcterms:references'];
      },
      isPartOf() {
        return this.itemsByTerm['dcterms:isPartOf'];
      },
      hasPart() {
        return this.itemsByTerm['dcterms:hasPart'];
      },
      targetIfNotPopUp() {
        return !this.linksInPopUp ?'_blank' : '';
      },
      linkCssClassIfNotPopUp() {
        return !this.linksInPopUp ? 'external-link' : '';
      },
    },
    emits: [
      'clicknoticelink',
      'clickdocumentlink',
      'clickarticlelink'
    ],
    methods: {
      termCssClass(term) {
        return term.split(':').join('-');
      },
      getLabel(term) {
        return this.labelsByTerm[term];
      },
      hasItemForTerm(term) {
        return this.itemsByTerm[term] !== undefined;
      },
      isTerm(term, value) {
        return term === value;
      },
      getItemsForTerm(term) {
        return this.itemsByTerm[term] ? this.itemsByTerm[term] : [];
      },
      getQueryParamsForURLs() {
        // Item de la notice où s'affiche le lien
        const query = { lang: this.$store.state.currentLanguage };
        if (this.fromItem) {
          query.from_item = this.fromItem;
        }
        return query;
      },
      getNoticeURL(item) {
        if (item.fieldName && item.fieldName === "dcterms:subject")
        {
          // Cas particulier des mots-clés : vers la vue KeyWord
          return this.$router.resolve({
            name: "Subject",
            params : { subject: item.value },
            query: this.getQueryParamsForURLs()
          }).href;

          /*
          // V1 :
          // Cas particulier des mots-clés : on génère une URL pour la recherche avancée :
          const fieldSets = {
            property:3, // subject
            joiner: 'and',
            type: 'eq',
            text:item.value,
          };
          return this.$router.resolve("/search/" +  getAdvancedSearchQuery([fieldSets], this.$store.state.queryItemSetsIds)).href;
         */

        }
        else if ((typeof (item.id) === "string") && item.id.indexOf('http') === 0)
        {
          // Cas particulier des liens externes renseignés dans 'id'
          return item.id;
        }
        else
        {
          // Cas général : on affiche le lien vers la notice de l'item
          return this.$router.resolve({
            name: 'Item',
            params : { id: item.id },
            query: this.getQueryParamsForURLs()
          }).href;
        }
      },
      getArticleURL(itemId) {
        const itemRouteName = this.$store.getters.collectionItemRouteNameByItemType('article');
        // console.log('***itemRouteName', itemRouteName)
        return this.$router.resolve({
          name: itemRouteName,
          params : { id: itemId },
          query: this.getQueryParamsForURLs()
        }).href;
      },

      clickOnNotice($event, item) {
        if (this.linksInPopUp) {
          $event.preventDefault();
          // EVENT --> PARENT
          this.$emit('clicknoticelink', item);
        }
      },
      clickOnDocument($event, item) {
        if (this.linksInPopUp) {
          $event.preventDefault();
          // EVENT --> PARENT
          this.$emit('clickdocumentlink', item);
        }
      },
      clickOnArticle($event, item) {
        if (this.linksInPopUp) {
          $event.preventDefault();
          // EVENT --> PARENT
          this.$emit('clickarticlelink', item);
        }
      },

    }

  }

</script>
