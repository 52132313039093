<template>

    <!-- 1 Parent d'items -->
    <div v-if="hasChildren">

        <!-- Racine du tree view -->
        <h2 class="tree-group-title" v-if="node.name && state.isBaseDepth">
            <a href="#" @click="toggleNode($event)" class="toggle-parent" :class="state.nodeCssClass">{{ nodeLabel }}</a>
            <span class="item-icons" v-if="node.id">
                <a class="basket-icon" href="#"
                   v-if="userIsConnected"
                   @click="toggleItemInBasket($event, node.id)"
                   :class="itemInBasketCssClass(node.id)"
                ></a>
                <a v-if="showItemNoticeButton"
                    class="notice-icon"
                     href="#"
                     @click="openDetailedNotice($event, node.id)"
                ></a>
            </span>
        </h2>

        <h3 class="tree-group-title" v-if="node.name && ! state.isBaseDepth">

            <span v-if="node.id">
              <a href="#" @click="toggleNode($event)" class="toggle-parent toggle-parent-without-title" :class="state.nodeCssClass"></a>

              <a href="#"
                 v-if="firstLevelTitleAsToggle"
                 @click="toggleNode($event)" class="parent-item-title parent-item-title-toggle">
                {{ nodeLabel }}
              </a>
              <router-link
                  v-else
                  :to="itemRoute(node.id)"
                  class="parent-item-title">
                  {{ nodeLabel }}
              </router-link>

            </span>

            <span v-if="! node.id">
                <a href="#" @click="toggleNode($event)" class="toggle-parent" :class="state.nodeCssClass">{{ nodeLabel }}</a>
            </span>

            <span class="item-icons" v-if="node.id">
                <a class="basket-icon" href="#"
                   v-if="userIsConnected"
                   @click="toggleItemInBasket($event, node.id)"
                   :class="itemInBasketCssClass(node.id)"
                ></a>
                <a v-if="showItemNoticeButton"
                    class="notice-icon"
                   :href="itemURL(node.id)"
                   @click="openDetailedNotice($event, node.id)"
                ></a>
                <NoticePDFDownloadLink
                    v-if="userIsConnected"
                    class="download"
                    :resource-id="node.id"
                    :title="$t('Exporter en PDF')" />
            </span>

        </h3>

        <ul :class="state.nodeCssClass" class="list-items">
            <li class="list-item" v-for="(child, index) in node.children" v-bind:key="child.id">
                <TreeNode
                    :node="child"
                    :depth="childDepth"
                    :path="childPath(index)"
                    :itemRouteName="itemRouteName"
                    :show-item-graph-button="showItemGraphButton"
                    :show-item-notice-button="showItemNoticeButton"
                    :parent-item-title-as-toggle="parentItemTitleAsToggle"
                    @opendetailednotice="emitDetailedNoticeEvent"
                    @downloaditemnotice="emitDownloadNoticeEvent"
                />
            </li>
        </ul>

    </div>

    <!-- 2.1 Item avec lien -->
    <router-link class="item-title" :to="itemRoute(node.id)"  v-if="!hasChildren && node.id">
        <span>{{ nodeLabel }}</span>
    </router-link>

    <span class="item-icons"  v-if="!hasChildren && node.id">
        <a class="basket-icon" href="#"
           v-if="userIsConnected"
           @click="toggleItemInBasket($event, node.id)"
           :class="itemInBasketCssClass(node.id)"
        ></a>
        <a v-if="showItemNoticeButton"
           class="notice-icon"
           :href="itemURL(node.id)"
           @click="openDetailedNotice($event, node.id)"
        ></a>
        <NoticePDFDownloadLink
            v-if="userIsConnected"
            class="download"
            :resource-id="node.id"
            :title="$t('Exporter en PDF')" />
    </span>

    <!-- 2.2 Item sans lien -->
    <span v-if="!hasChildren && !node.id">
        <a href="#" class="item-title item-title-disabled" >
            <span>{{ nodeLabel }}</span>
        </a>
    </span>

</template>

<script>

import {computed, reactive} from "vue";
  import {useStore} from "vuex";
  import NoticePDFDownloadLink from "../Notices/NoticePDFDownloadLink";

  export default {
    name: 'TreeNode',
    components: {
      NoticePDFDownloadLink
    },
    props: {
      node: {
        type: Object,
        required: true
      },
      opened: {
        type: Boolean,
        required: false
      },
      depth: {
        type: Number,
        required: false,
        default: 0
      },
      path: {
        type: String,
        required: false,
        default: "0"
      },
      itemRouteName: {
          type: String,
          required: false,
          default:'Item'
      },
      showItemGraphButton: {
        type: Boolean,
        required: false,
        default:false
      },
      showItemNoticeButton: {
        type: Boolean,
        required: false,
        default:false
      },
      parentItemTitleAsToggle: {
        type: Boolean,
        required: false,
        default: false
      },
    },
    emits: [
      "opendetailednotice",
      "downloaditemnotice",
    ],
    setup(props, context) {

      const store = useStore();

      const state = reactive({
        nodeCssClass: computed(() => state.opened ? "tree-group-opened" : "" ),
        nodePath: props.path,
        opened: props.opened
      });

      state.opened = props.depth > 0 ? store.getters.treeViewNodeOpened(state.nodePath) : props.opened;

      const toggleNode = function ($event) {
        $event.preventDefault();
        state.opened = ! state.opened;
        store.dispatch("updateTreeViewState", { opened: state.opened, id: state.nodePath });
      };

      const itemInBasketCssClass = function(itemId) {
        return store.getters.itemInBasket(itemId, "item") ? "selected" : "";
      };

      const toggleItemInBasket = function($event, itemId) {
        $event.preventDefault();
        store.dispatch( store.getters.itemInBasket(itemId, "item") ? "removeFromBasket" : "addToBasket", { type: "item", id: parseInt(itemId) } );
      };

      const downloadItemNotice = function($event, itemId) {
        $event.preventDefault();
        context.emit("downloaditemnotice", { id: itemId });
      };

      const childPath = function(index) {
          return state.nodePath + '.' + index;
      };

      const firstLevelTitleAsToggle = computed(() => props.depth === 1 && props.parentItemTitleAsToggle );

      return {
        state,
        childPath,
        toggleNode,
        firstLevelTitleAsToggle,
        itemInBasketCssClass,
        toggleItemInBasket,
        downloadItemNotice,
      }
    },
    computed: {
      hasChildren() {
         return this.node.children && this.node.children.length;
      },
      childDepth() {
         return this.depth + 1;
      },
      isBaseDepth() {
         return this.depth === 1;
      },
      nodeTitle() {
         return this.node.title;
      },
      nodeLabel() {
         return this.node.title && this.node.title.length ? this.node.title : this.node.name;
      },
      currentRoutePath() {
         return this.$route.path;
      },
      userIsConnected() {
         return this.$store.state.currentUser.logged;
      }
    },
    methods:{
      itemRoute(itemId) {
          const hrefWithRouterBase = this.$router.resolve({
              name: this.itemRouteName,
              params: { id: itemId },
              query: { lang: this.$store.state.currentLanguage }
          }).href;
          return hrefWithRouterBase.split(this.$store.state.publicPath).join('/');
      },
      itemURL(itemId) {
          return this.$router.resolve({
              name: this.itemRouteName,
              params: { id: itemId },
              query: { lang: this.$store.state.currentLanguage }
          }).href;
      },
      openDetailedNotice($event, itemId) {
          $event.preventDefault();
          this.emitDetailedNoticeEvent(parseInt(itemId));
      },
      emitDetailedNoticeEvent(itemId) {
         // EVENT --> PARENT
          this.$emit('opendetailednotice', itemId);
      },
      emitDownloadNoticeEvent(item) {
         // EVENT --> PARENT
          this.$emit('downloaditemnotice', item);
      },
    }

  }
</script>
