<template>

  <div id="map-parent" class="map-parent with-padding">
    <div class="mapping-block">
      <div id="mapping-map" class="mapping-map" @click="clickOnMap($event)"></div>
      <div
          v-for="itemJson in displayedItems"
          :key="getMarkerId(itemJson)"
          :data-marker-id="getMarkerId(itemJson)"
          :data-item-id="getItemId(itemJson)"
          :data-item-set-id="getItemSetId(itemJson)"
          class="mapping-marker-popup-content"
          style="display:none;"
      >
        <h3>{{ getMarkerLabel(itemJson) }}</h3>
        <div>
          <a target="_blank" href="#">{{ getItemTitle(itemJson) }}</a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import {onMounted, ref, watch} from "vue";

  import $ from 'jquery';
  import L from 'leaflet';
  import 'leaflet.markercluster'

  export default {

    name: 'CollectionMap',
    components: {
    },
    props: {
      items: {
        type: Array,
        required: true
      }
    },
    emits: [
      "opendetailednotice",
      "click-item"
    ],
    setup(props, context) {

      //
      // Lifecycle Hooks
      //

      onMounted(() => {
          initMap();
      });

      watch(() => props.items, () => {
        initMap();
      });

      /* Map Leaflet */
      let map;

      /* Map items */
      const displayedItems = ref([]);


      /* Map functions */

      const initMap = function() {

        if (Array.isArray(props.items)) {

          const validItems = [];
          const n = props.items.length;
          let i, itemJson;

          for(i=0; i < n; i++) {
            itemJson = props.items[i];
            if (itemJson['o-module-mapping:marker']) {
              validItems.push(itemJson);
            }
          }

          displayedItems.value = validItems;

          // On laisse le temps de créer les Div des popups (cf class="mapping-marker-popup-content" dans le DOM)  avec un setTimeout
          setTimeout(MappingBlock)
        }
      }

      function MappingBlock() {

        const mapDiv = $('#mapping-map');
        if (! map)
        {
          var mapData = { min_zoom: 2 };

          map = new L.map(mapDiv[0], {
            minZoom: mapData.min_zoom ? mapData.min_zoom : 2,
            maxZoom: mapData.max_zoom ? mapData.max_zoom : 19
          });

          map.setView([20, 0], 2);

          // Set base map and grouped overlay layers.
          L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy;',
            minZoom: 2,
            maxZoom: 19
          }).addTo(map);

        }

        // Items => markers :
        var markerData = displayedItems.value;
        if (map && markerData.length)
        {
          var markers = new L.markerClusterGroup();
          var markersByItem = {};

          // Set the markers.
          $.each(markerData, function(index, json) {

            const mappingData = json['o-module-mapping:marker'];
            if (mappingData !== undefined)
            {
              const data = Array.isArray(mappingData) ? mappingData[0] : mappingData;
              var markerId = data['o:id'];
              var itemId = data['o:item']['o:id'];

              // Note that we must explicitly specify a new icon so a timeline's event
              // markers can be reset correctly.
              // @see https://github.com/Leaflet/Leaflet.markercluster/issues/786
              var icon = new L.Icon.Default();
              var marker = L.marker(L.latLng(
                  data['o-module-mapping:lat'],
                  data['o-module-mapping:lng']
              ), {icon: icon});

              var popupContent = $('.mapping-marker-popup-content[data-marker-id="' + markerId + '"]');
              if (popupContent.length > 0) {
                popupContent = popupContent.clone().show();
                marker.bindPopup(popupContent[0]);
              }

              if (!(itemId in markersByItem)) {
                markersByItem[itemId] = new L.markerClusterGroup();
              }

              markersByItem[itemId].addLayer(marker);
              markers.addLayer(marker);
            }
          });

          // Add the markers to the map.
          map.addLayer(markers);
        }

      }

      // Click sur un marker ou un popUp de marker
      const clickOnMap = function($event) {

        const target = $event.target;
        if ( target.className.indexOf('leaflet-marker-icon') !== -1 )
        {
          // Markers : <div class="mapping-marker-popup-content" data-marker-id="33" data-item-id="33" data-marker-lat="51.758419" data-marker-lng="-1.256637">
          $('.leaflet-popup-content').off('click');
          $('.leaflet-popup-content a').off('click');

          setTimeout(function() {
            $('.mapping-marker-popup-content:visible').on('click', function($event){
              $event.stopImmediatePropagation();
              const popupContent = $($event.target).closest('.mapping-marker-popup-content');
              openPopUpNotice( $event, popupContent.data('item-id') );
            });
          }, 100);
        }

        const markerPopup = $('.mapping-marker-popup-content:visible');
        if (markerPopup.length) {
          const clickedItemId = markerPopup.data('item-id');
          if (! isNaN(clickedItemId)) {
            $('.contents-wrapper .leaflet-popup-content a').each(function(no, item) {
              const parent = $(item).parent();
              const newParent = parent.closest('.mapping-marker-popup-content');
              parent.remove();
              $(item).appendTo(newParent);
            });
          }
        }

      };

      const openPopUpNotice = function($event, itemId) {
        $event.preventDefault();
        context.emit("opendetailednotice", itemId);
      };

      return {
        displayedItems,
        clickOnMap,
        openPopUpNotice
      }

    },
    methods: {
      getMarkerId(itemJson) {
        const mappingData = itemJson['o-module-mapping:marker'];
        const data = Array.isArray(mappingData) ? mappingData[0] : mappingData;
        return data['o:id'];
      },
      getMarkerLabel(itemJson) {
        const mappingData = itemJson['o-module-mapping:marker'];

        const data = Array.isArray(mappingData) ? mappingData[0] : mappingData;
        return data['o-module-mapping:label'];
      },
      getItemId(itemJson) {
        const mappingData = itemJson['o-module-mapping:marker'];

        const data = Array.isArray(mappingData) ? mappingData[0] : mappingData;
        return data['o:item']['o:id'];
      },
      getItemTitle(itemJson) {
        return itemJson["o:title"];
      },
      getItemSetId(itemJson) {
        const itemSetData = itemJson["o:item_set"];
        return itemSetData && itemSetData.length ? itemJson["o:item_set"][0]["o:id"] : "" ;
      }
    }
  }

</script>
