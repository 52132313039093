<template>

  <div class="popup-notice-item download-notice-item popup-parent">
    <div class="close-btn"><a href="#" @click="closeNotice($event)">{{ $t("Fermer") }}</a></div>

    <div class="popup-panel">
      <div class="popup-contents">

        <NoticeDownloadLink
                class="download-notice-button"
                linkLabel="Télécharger CSV"
                output="csv"
                :resourceId="itemId"
        />

        <NoticeDownloadLink
                class="download-notice-button"
                linkLabel="Télécharger TXT"
                output="txt"
                :resourceId="itemId"
        />

        <a
           class="download-notice-button"
           @click="downloadPDF($event)"
           href="#">Télécharger PDF</a>

      </div>
    </div>

  </div>

</template>


<script>

  import {reactive, watch} from "vue";
  import {useStore} from "vuex";
  import axios from "axios";
  import { jsPDF } from "jspdf";

  import {getItemExportApiURL} from "../../js/api";
  import NoticeDownloadLink from "./NoticeDownloadLink";

  export default {
    name: 'NoticeDownloadPopUp',
    components: {
      NoticeDownloadLink
    },
    props: {
      itemId: {
        type: Number,
        required: true,
      },
      itemType: {
        type: String,
        required: true,
      }
    },
    computed: {
    },
    setup(props) {

      const store = useStore();

      // Reactive state
      const state = reactive({
        itemId: props.itemId,
        itemType: props.itemType,
      });

      watch( props, () => {
        state.itemId = props.itemId;
        state.itemType = props.itemType;
      });

      const getNoticeURL = function(extension) {
        const siteId = store.state.settings.site.id;
        return getItemExportApiURL(store.getters.apiRoot, state.itemId, extension, siteId);
      };


      const downloadPDF = function($event) {

        $event.preventDefault();
        $event.stopPropagation();

        const siteId = store.state.settings.site.id;
        const apiURL = getItemExportApiURL(store.getters.apiRoot, state.itemId, 'txt', siteId);

        axios.get(apiURL).then(function (response) {

          // cf http://raw.githack.com/MrRio/jsPDF/master/docs/jsPDF.html
          const doc = new jsPDF();
          doc.setFontSize(10);
          doc.setLineHeightFactor(1.25);

          let lines = doc.splitTextToSize(response.data, 180);
          let top = 10;
          let lineNo = 0;
          let lineContent, previousLineContent;

          for(let i=0; i< lines.length; i++) {

            lineContent = lines[i].replace(/’/gi, "'");

            if ((lineContent === "") && (previousLineContent === "")) {
              continue;
            }

            lineNo ++;
            top += 5;

            doc.text(lineContent, 10, top);

            if (lineNo > 55) {
              doc.addPage();
              top = 0;
              lineNo = 0;
            }

            previousLineContent = lineContent;
          }

          doc.save("item" + state.itemId + ".pdf");
        });

      };

      return {
        state,
        downloadPDF,
        getNoticeURL
      }

    },
    methods: {
      closeNotice($event) {
        $event.preventDefault();

        // EVENT --> PARENT
        this.$emit('closedownloaditemnotice');
      },

    }
  }

</script>
