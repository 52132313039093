<template>
  <div class="annotations-thread-item annotations-thread-item-current-user">
    <p class="annotations-thread-author">{{ state.userName }} (moi), {{ state.annotationDate }}</p>

    <div class="annotations-thread-edit-box">

      <textarea
          v-if="state.editable"
          placeholder="Ecrivez une annotation ici …"
          v-model="state.annotationContent"
      />

      <div
          v-else
          class="annotations-thread-text-content">{{ state.annotationContent }}
      </div>

      <div class="buttons">
        <button class="edit-icon" @click="toggleEdit($event)"></button>
        <button class="delete-icon" @click="deleteAnnotation($event)"></button>
      </div>
    </div>

  </div>
</template>

<script>

  import {reactive} from "vue";

  export default {

    props: {
      annotationId: {
        type: Number,
        required: false,
      },
      annotationContent: {
        type: String,
        required: false,
      },
      annotationDate: {
        type: String,
        required: false,
      },
      userId: {
        type: Number,
        required: false,
      },
      userName: {
        type: String,
        required: false,
      },
    },
    emits: [
      "update-annotation",
      "delete-annotation"
    ],
    setup(props, context) {

      const toggleEdit = function($event) {
        $event.preventDefault();

        state.editable = ! state.editable;

        if ( ! state.editable) {

          // Fin de l'édition :
          // Quand on repasse en non-editable, on envoie le contenu du champ au serveur

          // On vérifie que le texte a changé pour l'envoyer au serveur
          if ( state.annotationContent !== state.previousAnnotationContent ) {
            context.emit("update-annotation", { id: props.annotationId, content: state.annotationContent });
          }

        } else {

          // Début de l'édition :
          // On note le texte avant le changement
          state.previousAnnotationContent = state.annotationContent;
        }
      };

      const deleteAnnotation = function($event) {
        $event.preventDefault();

        // Vers le composant parent Annotations
        context.emit("delete-annotation", { id: props.annotationId });
      };

      const state = reactive({
        editable: false,
        userName: props.userName,
        annotationDate: props.annotationDate,
        annotationContent: props.annotationContent,
        previousAnnotationContent: props.annotationContent,
      });

      return {
        state,
        toggleEdit,
        deleteAnnotation
      }

    }
  }

</script>
