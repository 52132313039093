<template>
    <div class="user-menu-parent">
      <div class="user-menu">
        <div class="user-menu-arrow"></div>
        <div class="user-menu-content">
          <div class="user-menu-header">
            <span class="user-first-name">{{ state.firstName }}</span> <span class="user-last-name">{{ state.lastName }}</span>
          </div>
          <ul class="user-menu-options">
            <li><router-link to="/mon-compte/mon-panier">{{ $t('Mon panier de notices') }}</router-link></li>
            <li><router-link to="/mon-compte/mes-recherches">{{ $t('Mon historique de recherche') }}</router-link></li>
            <li><router-link to="/mon-compte/mes-selections">{{ $t('Mes sélections') }}</router-link></li>
            <li><router-link to="/mon-compte/mes-annotations">{{ $t('Mes annotations') }}</router-link></li>
            <li><router-link to="/mon-compte/mes-parametres">{{ $t('Mes paramètres') }}</router-link></li>
            <li><router-link to="/logout">{{ $t('Se déconnecter') }}</router-link></li>
          </ul>
        </div>
      </div>
    </div>
</template>

<script>

  import {useStore} from "vuex";
  import {computed, reactive} from "vue";

  export default {
    name: 'UserMenu',
    setup() {

      const store = useStore();

      const state = reactive({
        firstName: computed(() => store.getters.userFirstName),
        lastName: computed(() =>  store.getters.userLastName)
      });

      return {
        state,
      }

    }
  }

</script>
