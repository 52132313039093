<template>
  <div class="view">
    <div class="breadcrumbs-and-title">
      <div class="breadcrumbs">
        <BreadCrumb :paths="breadcrumbs" />
      </div>
      <h1>
        {{ $t(state.collectionTitle) }}
        <a
          v-if="state.collectionDescriptions"
          @click="openCollectionDescriptions($event)"
          class="collection-description-toggle"
          href="#">
        </a>
      </h1>
    </div>
    <div class="contents" :class="withPopUpCssClass">
      <div class="contents-wrapper with-visualization" :class="displayModeCssClass">

        <div class="collection-header" v-if="state.collectionDescriptions">
          <CollectionDescription
                  class="with-padding"
                  :class="state.collectionDescriptionVisibleCssClass"
                  :descriptions="state.collectionDescriptions"
          />
        </div>

        <div class="constellation" :class="constellationCssClass">

          <!-- Graphe SVG des collections de l'encyclopédie -->
          <VisualisationEncyclopedie
            feedMode="auto"
            @itemsloadedfromserver="itemsLoadedFromServer"
            @selectitem="selectItem"
            @changecategoriesfiltermenu="changeCategoriesFilterMenu"
          />

          <!-- Onglet et panier -->
          <div class="contents-header with-padding" v-if="allItems">
            <!-- Toggle Graphe / Liste / Notices -->
            <ul class="contents-mode">
              <li class="contents-list-mode">
                <router-link :to="listItemSetRoute">
                  {{ $t("Liste") }}
                </router-link>
              </li>
              <li class="contents-notice-mode">
                <router-link :to="noticeItemRoute">
                  {{ $t("Notices") }}
                </router-link>
              </li>
              <li class="contents-graph-mode">
                <router-link :to="graphItemSetRoute">
                  {{ $t("Encyclopédie") }}
                </router-link>
              </li>
            </ul>
            <!-- Panier -->
            <div class="constellation-basket" v-if="userIsConnected">
              <router-link to="/mon-compte/mon-panier">{{ basketCount }}</router-link>
            </div>
          </div>

          <!-- Mode Liste -->
          <ListItemsWithCategorySlider
                  v-if="hasItems"
                  :items="allItems"
                  :pageNo="pageNo"
                  :item-set="itemSetForList"
                  :class="listDisplayModeCssClass"
                  @opendetailednotice="openDetailedNotice"
                  @openitemvisualization="openItemVisualization"
                  @downloaditemnotice="openDownloadNoticePopUp"
          />

          <!-- Mode Graphe SVG d'un Item -->
          <VisualisationItem
                 v-if="isGraphDisplayMode || itemVisualizationIsOpened"
                 :itemId="itemId"
                 :itemsIds="itemsIdsForVisualization"
                 :class="itemVizualisationCssClass"
                 @closeitemvisualization="closeItemVisualization"
                 @opendetailednotice="selectDetailedNotice"
                 @notice-loaded="itemLoaded"
          />

          <!-- Mode Notice -->
          <NoticesItems
                  v-if="isNoticeDisplayMode && allItems"
                  :items-ids="allItemIds"
                  :notice-item-id="itemId"
                  :openGraphInPopUp="true"
                  @open-item-graph="openItemVisualization"
                  @opendetailednotice="openDetailedNotice"
                  @item-loaded="itemLoaded"
          />

        </div>
      </div>

    </div>

    <NoticePopUp
            v-if="detailedNoticeItemIsOpened"
            :itemId="detailedNoticeId"
            :itemType="detailedNoticeType"
            :closeButtonLabel="closeButtonLabel"
            :class="detailedNoticeCssClass"
            :openGraphInPopUp="true"
            @item-loaded="detailedNoticeLoaded"
            @close-detailed-item-notice="closeDetailedNotice"
    />

    <NoticeDownloadPopUp
            v-if="downloadNoticeItemId"
            :itemId="downloadNoticeItemId"
            :itemType="downloadNoticeItemType"
            :class="downloadNoticePopupCssClass"
            @closedownloaditemnotice="closeDownloadNoticePopUp"
    />

  </div>
</template>

<script>
  import {useStore} from "vuex";
  import {computed, onMounted, reactive, watch} from "vue";

  import EventDispatcher from "../js/EventDispatcher";
  import BreadCrumb from "../components/Header/BreadCrumb";
  import VisualisationEncyclopedie from "../components/Visualization/VisualisationEncyclopedie";
  import VisualisationItem from '../components/Visualization/VisualisationItem';
  import ListItemsWithCategorySlider from '../components/Lists/ListItemsWithCategorySlider';
  import NoticePopUp from '../components/Notices/NoticePopUp';
  import NoticeDownloadPopUp from '../components/Notices/NoticeDownloadPopUp';
  import NoticesItems from "../components/Lists/NoticesItems";
  import CollectionDescription from "../components/Header/CollectionDescription";

  export default {
    name: 'Encyclopedie',
    components: {
      BreadCrumb,
      CollectionDescription,
      VisualisationEncyclopedie,
      NoticePopUp,
      NoticeDownloadPopUp,
      VisualisationItem,
      ListItemsWithCategorySlider,
      NoticesItems
    },
    props: {
      defaultDisplayMode: {
        type: String,
        required: false,
        default:"graph"
      },
    },
    data() {
      return {
        displayMode: "graph",

        allItems: null,
        allItemIds: null,

        pageNo: 1,
        itemSetForList: 0,

        itemId: null,
        noticeItemId: null,

        itemsIdsForVisualization: null,
        itemVisualizationIsOpened: false,
        filterCategoriesMenuIsOpened: false,

        annotationWindowIsOpened: false,

        detailedNoticeItemIsOpened:false,
        detailedNoticeId:null,
        detailedNoticeType:null,

        downloadNoticePopupIsOpened:false,
        downloadNoticeItemId:null,
        downloadNoticeItemType: 'item',
      }
    },
    computed: {
      breadcrumbs() {
        if ((this.displayMode === "notice" || this.displayMode === "graph") && this.state.noticeItemTitle) {
          // Item
          return [
            { name: 'Home', label:'Accueil' },
            { name: "Encyclopedie", label:this.state.collectionTitle, query: this.listItemQuery },
            { label: this.state.noticeItemTitle }
          ]
        } else {
          return [
            { name: 'Home', label:'Accueil' },
            { label:this.state.collectionTitle }
          ]
        }
      },
      constellationCssClass() {
        return this.filterCategoriesMenuIsOpened === true ? "with-filters" : "";
      },
      itemVizualisationCssClass() {
        return this.itemVisualizationIsOpened === true ? "opened" : "";
      },
      isItemVisualizationOpened() {
        return this.itemVisualizationIsOpened === true;
      },
      isListDisplayMode() {
        return this.displayMode === "list"
      },
      isGraphDisplayMode() {
        return this.displayMode === "graph"
      },
      isNoticeDisplayMode() {
        return this.displayMode === "notice"
      },
      displayModeCssClass() {
        return this.displayMode + "-mode";
      },
      itemVisualizationCssClass() {
        return this.itemVisualizationIsOpened === true ? "opened" : "closed";
      },
      listDisplayModeCssClass() {
        return this.itemVisualizationIsOpened === true ? "visible" : "hidden";
      },
      detailedNoticeCssClass() {
        return this.detailedNoticeItemIsOpened ? "opened" : "";
      },
      withPopUpCssClass() {
        return this.detailedNoticeItemIsOpened || this.itemVisualizationIsOpened ? "with-popup" : "";
      },
      hasItems() {
        return this.allItems !== null;
      },
      userIsConnected() {
        return this.$store.state.currentUser.logged;
      },
      basketCount() {
        return this.$store.state.basket.length;
      },
      closeButtonLabel() {
        return this.$t( this.itemVisualizationIsOpened ? "Retour au graphe" : "Fermer" );
      },
      downloadNoticePopupCssClass() {
        return this.downloadNoticePopupIsOpened === true ? "opened" : "";
      },
      changeRouteWithPreviousAndNext() {
        return true;
      },
      graphItemSetRoute() {
        return this.$router.resolve( {
          name: "Encyclopedie",
          query: {
            display_mode: 'graph'
          }
        });
      },
      listItemSetRoute() {
        return this.$router.resolve( {
          name: "Encyclopedie",
          query: {
            display_mode: 'list',
            p: 1
          }
        });
      },
      noticeItemRoute() {
        return this.$router.resolve( {
          name: 'Notice Item',
          params: { id : ! this.noticeItemId ? 0 : this.noticeItemId },
          query: {
            display_mode: 'notice'
          }
        });
      },

      listItemQuery: computed(() => {
        return {
          display_mode: 'list',
        };
      }),


    },
    methods : {
      openItemNotice(itemId, mode) {
        if (mode === 'graph')
        {
          // Mode
          this.displayMode = "graph";

          // On ouvre la visualisation d'un item de l'encyclopédie
          this.openItemVisualization(itemId);

          // On n'ouvre pas la notice en popup modal
          this.detailedNoticeId = null;
          this.detailedNoticeType = null;
          this.detailedNoticeItemIsOpened = false;
        }
        else if (mode === 'modal')
        {
          // Mode
          this.displayMode = "graph";

          // On ouvre la visualisation des items de l'encyclopédie
          this.openItemVisualization(itemId);

          // On ouvre la notice de l'item en popup modal
          this.openDetailedNotice(itemId);
        }
        else
        {
          // Par défaut :

          // On ouvre la notice pleine page d'un item de l'encyclopédie
          this.displayMode = "notice";
          this.itemId = itemId;

          // On n'ouvre pas la notice en popup modal...
          this.detailedNoticeId = null;
          this.detailedNoticeType = null;
          this.detailedNoticeItemIsOpened = false;

          // ... ni celle en graphe :
          this.itemVisualizationIsOpened = false;
          this.itemsIdsForVisualization = null;
        }
      },
      itemsLoadedFromServer(items, itemsIds) {
        this.allItems = items;
        this.allItemIds = itemsIds;
        if ( !this.noticeItemId && this.allItemIds.length) {
          this.noticeItemId = this.allItemIds[0];
        }
      },
      selectItem(itemId) {
        this.openItemVisualization(itemId);
        /* ROUTER */
        this.$router.push({
          name: "Graphe Item",
          params: { id: itemId },
          query: { display_mode: 'graph' }
        });
      },
      changeCategoriesFilterMenu(menuIsOpened) {
        this.filterCategoriesMenuIsOpened = menuIsOpened;
      },
      openItemVisualization(itemId) {
        this.itemId = parseInt(itemId);
        this.itemsIdsForVisualization = null;
        this.itemVisualizationIsOpened = true;
      },
      openItemsVisualization(itemsIds) {
        this.itemId = 0;
        this.itemsIdsForVisualization = itemsIds;
        this.itemVisualizationIsOpened = true;
      },
      closeItemVisualization() {
        // this.itemId = null;
        this.itemsIdsForVisualization = null;
        this.itemVisualizationIsOpened = false;
        if ((this.displayMode === "notice" || this.displayMode === "graph")) {
          this.$router.push({ name: "Encyclopedie" });
        }
      },
      openDetailedNotice(itemId) {
        this.detailedNoticeId = itemId;
        this.detailedNoticeType = "item";
        this.detailedNoticeItemIsOpened = true;
      },
      openArticle(itemId) {
        this.detailedNoticeId = itemId;
        this.detailedNoticeType = "article";
        this.detailedNoticeItemIsOpened = true;
      },
      selectDetailedNotice(itemId) {
        this.openDetailedNotice(itemId);
        /* ROUTER */
        this.$router.push({ name: "Notice Item Modal", params: { id: itemId }, query: { display_mode: 'modal'} });
      },
      detailedNoticeLoaded(itemInfos) {
        EventDispatcher.emits("document-title", "Encyclopédie" + " - " + itemInfos.title);
      },
      closeDetailedNotice() {

        if (this.itemId) {
          /* ROUTER */
          this.$router.push({
            name: "Graphe Item",
            params: { id: this.itemId },
            query: { display_mode: 'graph' }
          });
        }

        this.detailedNoticeId = null;
        this.detailedNoticeType = null;
        this.detailedNoticeItemIsOpened = false;
      },
      openDownloadNoticePopUp(item) {
        this.downloadNoticePopupIsOpened = true;
        this.downloadNoticeItemId = item.id;
      },
      closeDownloadNoticePopUp() {
        this.downloadNoticePopupIsOpened = false;
        this.downloadNoticeItemId = null;
      },
    },
    beforeRouteLeave(to, from, next) {

      //
      // Different route / Same component
      //

      // console.log("beforeRouteLeave", to, from);

      this.state.noticeItemTitle = null;

      if (to.name === 'Notice Item')
      {
        this.itemId = parseInt(to.params.id);
        this.displayMode = to.query.display_mode ? to.query.display_mode : 'notice';
        this.openItemNotice(this.itemId, to.query.display_mode);
      }
      else if (to.name === 'Graphe Item')
      {
        this.itemId = parseInt(to.params.id);
        this.itemsIdsForVisualization = null;
        this.itemVisualizationIsOpened = true;
        this.displayMode = 'graph';
      }
      else if (to.name === 'Encyclopedie')
      {
        this.itemId = null;
        this.itemsIdsForVisualization = null;
        this.itemVisualizationIsOpened = false;
        this.displayMode = to.query.display_mode ? to.query.display_mode : 'categories-graph';
        this.pageNo = !isNaN(to.query.p) ? parseInt(to.query.p) : 1;
        this.itemSetForList = !isNaN(to.query.itemset) ? parseInt(to.query.itemset) : 0;
      }

      next();
    },
    beforeRouteUpdate(to, from, next) {

      //
      // Same route / Same component
      //

      // console.log("beforeRouteUpdate", to, from);

      this.state.noticeItemTitle = null;

      if (to.name === 'Notice Item Modal')
      {
        const intIdFromRoute = parseInt(to.params.id);
        this.openItemVisualization(intIdFromRoute);
        if (to.params.notice !== false) {
          this.openDetailedNotice(intIdFromRoute);
        }
      }
      else if (to.name === 'Notice Item')
      {
        this.itemId = parseInt(to.params.id);
        this.displayMode = to.query.display_mode ? to.query.display_mode : 'notice';
        this.openItemNotice(this.itemId, to.query.display_mode);
      }
      else if (to.name === 'Graphe Item')
      {
        this.itemId = parseInt(to.params.id);
        this.itemsIdsForVisualization = null;
        this.itemVisualizationIsOpened = true;
        this.displayMode = 'graph';
      }
      else if (to.name === 'Encyclopedie')
      {
        this.itemId = null;
        this.itemsIdsForVisualization = null;
        this.itemVisualizationIsOpened = false;
        this.displayMode = to.query.display_mode ? to.query.display_mode : 'categories-graph';
        this.pageNo = !isNaN(to.query.p) ? parseInt(to.query.p) : 1;
        this.itemSetForList = !isNaN(to.query.itemset) ? parseInt(to.query.itemset) : 0;
      }
      next();
    },
    mounted() {

      /* Paramètres de la route --> ouverture d'un item, d'un article */

      // Mode d'affichage (list, graphe, notice)
      if (this.$route.query.display_mode && (this.$route.query.display_mode.length > 0)) {
        this.displayMode = this.$route.query.display_mode;
      }

      const idFromRoute = this.$route.params.id;
      if (idFromRoute !== undefined)
      {

        // Ouverture d'un item
        const intIdFromRoute = parseInt(idFromRoute);

        // Mode d'affichage
        const mode = this.$route.query.display_mode;

        switch(this.$route.name) {

          case "Graphe Item":
            // On ouvre la visualisation d'un item de l'encyclopédie
            this.openItemVisualization(intIdFromRoute);
            break;

          case "Graphe Items":
            // On ouvre la visualisation des items de l'encyclopédie
            this.openItemVisualization(intIdFromRoute);
            break;

          case "Notice Item Modal":
            // On ouvre la visualisation des items de l'encyclopédie
            this.openItemVisualization(intIdFromRoute);
            // On ouvre la notice de l'item
            this.openDetailedNotice(intIdFromRoute);
            break;

          case "Notice Item":
            this.openItemNotice(intIdFromRoute, mode);
            break;
        }
      }
      else if (this.$route.params.s)
      {
        const ids = this.$route.params.s.split('id[]=').join('').split('&');
        let i;
        const n = ids.length;
        for(i=0;i<n;i++) {
          ids[i] = parseInt(ids[i]);
        }
        this.openItemsVisualization(ids);
      }

    },
    setup() {

      const store = useStore();

      // Description de la collection
      const updateCollectionDescription = function() {
        const collectionsInfos = store.getters.collectionInfosByProp('views', "encyclopedie");
        if (collectionsInfos && collectionsInfos.id) {
          const collectionJson = store.getters.collectionJson(collectionsInfos.id);
          if (collectionJson)
          {
            // Titre de l'encyclopédie
            const collectionJsonValues = collectionJson['dcterms:title'];
            if (Array.isArray(collectionJsonValues) && collectionJsonValues.length) {
              const collectionTitle = collectionJsonValues[0]['@value'];
              if (collectionTitle) {
                state.collectionTitle = collectionTitle;
              }
            }

            // Description de l'encyclopédie
            state.collectionDescriptions = collectionJson['dcterms:description'];
          }
        }
      };

      const openCollectionDescriptions = function($event) {
        $event.preventDefault();
        state.isCollectionDescriptionVisible = ! state.isCollectionDescriptionVisible;
      };

      const itemLoaded = function(item) {
        state.noticeItemTitle = item.title;
      };


      //
      // Lifecycle Hooks
      //

      onMounted(() => {
        if (store.state.collections) {
          updateCollectionDescription();
        }
      });

      watch(() => store.state.collections, () => {
        updateCollectionDescription();
      });



      //
      // State
      //

      const state = reactive({
        collectionTitle: "",
        collectionDescriptions: null,
        isCollectionDescriptionVisible: false,
        collectionDescriptionVisibleCssClass: computed(() => {
          return state.isCollectionDescriptionVisible ? "opened" : "";
        }),
        noticeItemTitle: null
      });

      return {
        state,
        updateCollectionDescription,
        openCollectionDescriptions,
        itemLoaded
      }
    }
  }

</script>
