<template>
  <div class="view">
    <div class="breadcrumbs-and-title">
      <div class="breadcrumbs">
        <BreadCrumb :paths="breadcrumbs" />
      </div>
      <h1>{{ state.subject }}</h1>
    </div>
    <div class="contents" :class="withPopUpCssClass">
      <div class="contents-wrapper list-mode">
        <div class="mots-cles">

          <Loading class="notice-item-content with-padding" v-if="state.isLoading" />

          <!-- Mode Liste -->
          <div
              v-if="! state.isLoading"
              class="contents-list-mode-content subject-list-mode full-width"
              :class="navigationCssClass"
          >
            <ListItems
                    :items="state.items"
                    :itemsByPage="20"
                    :pageNo="state.pageNo"
                    @change-pagination-page="displayPage"
                    @openitem="openItem"
                    @opendetailednotice="openDetailedNotice"
                    @openitemvisualization="openItemVisualization"
                    @downloaditemnotice="openDownloadNoticePopUp"
            />
            <a
                href="#"
                class="close-browser-window"
                @click="closeBrowserWindow($event)">
              {{ $t("Fermer la fenêtre") }}
            </a>
          </div>

          <!-- Graphe SVG d'un Item -->
          <VisualisationItem
                    :class="itemVisualizationCssClass"
                    :itemId="itemVisualizationItemId"
                    @opendetailednotice="openDetailedNotice"
                    @closeitemvisualization="closeItemVisualization"
          />

        </div>
      </div>
    </div>

    <NoticePopUp
            v-if="detailedNoticeItemIsOpened"
            :itemId="detailedNoticeId"
            :itemType="detailedNoticeType"
            :class="detailedNoticeCssClass"
            @close-detailed-item-notice="closeDetailedNotice"
    />

    <NoticeDownloadPopUp
            v-if="downloadNoticeItemId"
            :itemId="downloadNoticeItemId"
            :itemType="downloadNoticeItemType"
            :class="downloadNoticePopupCssClass"
            @closedownloaditemnotice="closeDownloadNoticePopUp"
    />

  </div>
</template>
<script>

  import {onMounted, ref, reactive} from "vue";
  import {onBeforeRouteUpdate, useRoute} from "vue-router";
  import {useStore} from "vuex";
  import {getAdvancedSearchQuery, getItemsApiURL} from "../js/api";

  import "vue-timelinejs3/css/timeline.css";
  import "vue-timelinejs3/css/fonts/font.knightlab.css";

  import BreadCrumb from "../components/Header/BreadCrumb";
  import Loading from "../components/Loading";
  import ListItems from "../components/Lists/ListItems";
  import NoticePopUp from "../components/Notices/NoticePopUp";
  import NoticeDownloadPopUp from "../components/Notices/NoticeDownloadPopUp";
  import VisualisationItem from "../components/Visualization/VisualisationItem";

  export default {
    name: 'Subject',
    components: {
      BreadCrumb,
      Loading,
      ListItems,
      VisualisationItem,
      NoticePopUp,
      NoticeDownloadPopUp
    },
    data() {
      return {
        detailedNoticeItemIsOpened:false,
        detailedNoticeId:null,
        detailedNoticeType:null,

        itemId: null,
        itemVisualizationIsOpened: false,
        itemVisualizationItemId:null,

        downloadNoticePopupIsOpened:false,
        downloadNoticeItemId:null,
        downloadNoticeItemType: 'item'
      }
    },
    computed: {
      breadcrumbs() {
        return [
          { name: 'Home', label:'Accueil' },
          { label:'Mot-clé : ' + this.state.subject }
        ]
      },
      constellationCssClass() {
        return this.filterCategoriesMenuIsOpened === true ? "with-filters" : "";
      },
      itemVizualisationCssClass() {
        return this.itemVisualizationIsOpened === true ? "opened" : "";
      },
      isItemVisualizationOpened() {
        return this.itemVisualizationIsOpened === true;
      },
      listDisplayModeCssClass() {
        return this.itemVisualizationIsOpened === true ? "visible" : "hidden";
      },
      detailedNoticeCssClass() {
        return this.detailedNoticeItemIsOpened ? "opened" : "";
      },
      itemVisualizationCssClass() {
        return this.itemVisualizationIsOpened === true ? "opened" : "closed";
      },
      withPopUpCssClass() {
        return this.detailedNoticeItemIsOpened || this.itemVisualizationIsOpened ? "with-popup" : "";
      },
      annotationCssClass() {
        return this.annotationWindowIsOpened ? "popup-parent-opened" : "";
      },
      userIsConnected() {
        return this.$store.state.currentUser.logged;
      },
      basketCount() {
        return this.$store.state.basket.length;
      },
      downloadNoticePopupCssClass() {
        return this.downloadNoticePopupIsOpened === true ? "opened" : "";
      },
    },
    methods: {
      openItem() {
      },
      openDetailedNotice(itemId) {
        this.detailedNoticeId = itemId;
        this.detailedNoticeType = "item";
        this.detailedNoticeItemIsOpened = true;
      },
      closeDetailedNotice() {
        this.detailedNoticeId = null;
        this.detailedNoticeType = null;
        this.detailedNoticeItemIsOpened = false;
      },
      openItemVisualization(itemId) {
        this.itemVisualizationIsOpened = true;
        this.itemVisualizationItemId = itemId;
      },
      closeItemVisualization() {
        this.itemVisualizationItemId = null;
        this.itemVisualizationIsOpened = false;
      },
      clickOnTimelinePopUp($event) {
        this.openDetailedNotice($event.detail.id);
      },
      openDownloadNoticePopUp(item) {
        this.downloadNoticePopupIsOpened = true;
        this.downloadNoticeItemId = item.id;
      },
      closeDownloadNoticePopUp() {
        this.downloadNoticePopupIsOpened = false;
        this.downloadNoticeItemId = null;
      }
    },
    setup() {

      const route = useRoute();
      const store = useStore();

      // RQ : On affiche un bouton Fermer la fanêtre dans le cas d'une notice ouverte à partir d'un autre item via un lien externe ( target _blank )
      let navigationCssClass = ref('');

      // Chargement des items pour le mode Liste
      const loadItems = function() {

        if (route.query.sort_by && (route.query.sort_by.length > 0)) {
          state.sortByParam = route.query.sort_by;
        }

        if (route.query.sort_order && (route.query.sort_order.length > 0)) {
          state.sortOrderParam = route.query.sort_order;
        }

        state.subject = route.params.subject;

        const fieldSets = {
          property:3, // subject
          joiner: 'and',
          type: 'eq',
          text: state.subject,
        };

        const options = getAdvancedSearchQuery([fieldSets], store.state.queryItemSetsIds);

        const apiURL = getItemsApiURL(
                store.getters.apiRoot,
                [],
                options,
                state.sortByParam,
                state.sortOrderParam
        );

        store.dispatch('getApi', apiURL).then(function(response){

          state.isLoading = false;

          const items = response.data;

          if (Array.isArray(items)) {

            let i, n = items.length, item, itemsIds = [], itemId, itemSets, firstItemSetId, collectionSettings;
            for (i = 0; i < n; i++) {
              item = items[i];
              itemId = parseInt(item['o:id']);
              itemSets = item["o:item_set"];

              itemsIds.push(itemId);

              if (itemSets && itemSets.length) {
                firstItemSetId = itemSets[0]['o:id'];
                if (firstItemSetId)
                {
                  collectionSettings = store.getters.collectionsSettings(firstItemSetId);
                  if (collectionSettings) {
                    item.icon = collectionSettings.icon;
                    item.colorNo = collectionSettings.colorNo;
                    item.route = collectionSettings.route ? collectionSettings.route : '';
                  } else {
                    item.icon = null;
                    item.colorNo = 0;
                    item.route = '';
                  }

                  item.id = itemId;
                  item.type = collectionSettings.itemType;
                }
              }

              // console.log(i, itemId, firstItemSetId, collectionSettings, item.route, item);
            }


            state.items = items;
            state.itemsIds = itemsIds;
          }
        });


      };

      // JS : Fermer la fenêtre du navigateur ( liens externes d'une notice )
      const closeBrowserWindow = function($event) {
        $event.stopPropagation();
        console.log('close')
        window.close();
      };


      //
      // Lifecycle Hooks
      //

      // Same route / Same component
      onBeforeRouteUpdate((to, from, next) => {

        const toQuerySortBy = to.query.sort_by ? to.query.sort_by : 'title';
        const toQuerySortOrder = to.query.sort_order ? to.query.sort_order : 'asc';

        state.subject = to.params.subject;
        state.displayMode = 'items';

        if ((state.sortByParam !== toQuerySortBy) || (state.sortOrderParam !== toQuerySortOrder)) {

          // 1.1 Changement de l'ordre des items
          state.sortByParam = toQuerySortBy;
          state.sortOrderParam = toQuerySortOrder;
          state.pageNo = isNaN(to.query.p) ? 1 : parseInt(to.query.p);

          loadItems();
          next();

        } else {

          // 1.2 Pagination
          state.pageNo = isNaN(to.query.p) ? 1 : parseInt(to.query.p);
          next();

        }
      });

      onMounted(() => {
        navigationCssClass.value = route.query.from_item ? "show-close-browser-button" : "";
        loadItems();
      });

      const state = reactive({
        title:'',
        subject: null,
        isLoading: true,
        items: [],
        itemsIds: [],
        queryRouteName: '',
        sortByParam: 'title',
        sortOrderParam: 'asc',
        pageNo: 1,
      });

      return {
        state,
        loadItems,
        navigationCssClass,
        closeBrowserWindow
      }
    }
  }

</script>