<template>

  <div
          class="notice-item"
          :class="state.noticeCssClass"

  >
    <div class="notice-item-header" v-if="! state.isLoading">
      <div class="notice-tools" v-if="tools">
        <div :class="state.isUserConnected ? 'user-connected' : ''" >

          <!-- Cas 1 : Ouverture du graphe dans l'Encyclopédie -->
          <router-link
              v-if="isCategoryInGraph && ! state.openGraphInPopUp"
              :to="graphItemRoute"
              class="notice-graphe"
          ></router-link>

          <!-- Cas 2 : Ouverture du graphe dans le contexte de la notice (dans une popup) -->
          <a
              v-if="isCategoryInGraph && state.openGraphInPopUp"
              href="#"
              :title="$t('Afficher le graphe')"
              class="notice-graphe"
              @click="openItemGraphInPopUp($event)"
          ></a>

          <a
             v-if="state.isUserConnected && state.userCanEdit"
             :href="editNoticeUrl"
             target="_blank"
             :title="$t('Editer la notice')"
             class="edit-notice"
          >
          </a>

          <a
             v-if="state.isUserConnected"
             href="#"
             :title="$t('Ajouter au panier')"
             class="add-to-basket"
             :class="itemInBasketCssClass"
             @click="toggleItemInBasket($event)"
          >
          </a>

          <router-link
              v-if="inPopUp"
              :to="noticeItemRoute"
              class="notice-plein-ecran"
          ></router-link>

          <NoticePDFDownloadLink
              v-if="state.isUserConnected"
              class="download"
              :resource-id="state.itemId"
              :title="$t('Exporter en PDF')"
          />

        </div>
      </div>
    </div>

    <!-- Suivant/Précédent -->
    <NavigationItemsEventDispatcher
            v-if="! state.isLoading && state.itemsIds && state.itemsIds.length"
            :previousItemId="state.previousNoticeItemId"
            :nextItemId="state.nextNoticeItemId"
            @change="navigationChangeNoticeItem"
    />

    <div class="notice-item-content" :class="isCategoryInGraphCssClass">

      <Loading v-if="state.isLoading" />

      <div :class="displayModeCssClass" v-if="! state.isLoading && state.isJsonAndResourceTemplateLoaded">

        <a v-if="collectionTitle.length"
            :href="getCollectionURL(collectionId)"
            class="notice-item-collection-name"
        >
          <span class="collection-icon" :class="categoryIconCssClass"  />
          <span class="notice-item-collection-name-code" v-if="!isCategoryInGraph">{{ collectionTitleCode }}</span>
          <span>{{ collectionTitle }}</span>
        </a>

        <div class="notice-media-title">
          <h1>{{ capitalizeText(dctermsTitle) }}</h1>
        </div>

        <div class="notice-display-mode-contents">

          <!-- CAS 1. NOTICES du GRAPHE ou DOCUMENTS SANS MEDIA -->
          <div v-if="isCategoryInGraph || !hasMedia">
            <div class="notice-item-columns">

              <!-- Colonne 1 : Meta-données -->
              <div class="notice-item-column">

                <NoticeMediaItemMetadata
                        v-for="term in getTermsAtPosition(1)" v-bind:key="term"
                        :term="term"
                        :label='getMetaDataLabel(term)'
                        :value='getMetaDataValue(term)'
                        :from-item="state.itemId"
                        class="notice-part-1"
                />

                <!-- Affichage du media : image, pdf, html -->
                <div
                    v-if="state.hasMedia"
                    id="lightgallery"
                >

                  <Media
                      v-for="mediaId in state.medias"
                      v-bind:key="'media'+mediaId"
                      :mediaId = mediaId
                      class="media"
                      @loaded="loadLightGallery"
                  />

                </div>


                <NoticeMediaItemMetadata
                        v-for="term in getTermsAtPosition(2)" v-bind:key="term"
                        :term="term"
                        :label='getMetaDataLabel(term)'
                        :value='getMetaDataValue(term)'
                        :from-item="state.itemId"
                        class="notice-part-2"
                />

                <NoticeMediaItemMetadata
                        v-for="term in getTermsAtPosition(3)" v-bind:key="term"
                        :term="term"
                        :label='getMetaDataLabel(term)'
                        :value='getMetaDataValue(term)'
                        :from-item="state.itemId"
                        class="notice-part-3"
                />

              </div>

              <!-- Colonne 2 : Références -->
              <div class="notice-item-column">

                <NoticeMediaItemMetadata
                        v-for="(term, no) in getTermsAtPosition(4)" v-bind:key="getMetaDataBindKey(no, term, 4)"
                        :term="term"
                        :label='getMetaDataLabel(term)'
                        :value='getMetaDataValue(term)'
                        :linksInPopUp="inPopUp"
                        :from-item="state.itemId"
                        class="notice-part-4"
                        @clicknoticelink="emitClickOnNotice"
                />

                <NoticeMediaItemMetadata
                        v-for="(term, no) in getTermsAtPosition(5)" v-bind:key="getMetaDataBindKey(no, term, 5)"
                        :term="term"
                        :label='getMetaDataLabel(term)'
                        :value='getMetaDataValue(term)'
                        :linksInPopUp="inPopUp"
                        :from-item="state.itemId"
                        class="notice-part-5"
                        @clicknoticelink="emitClickOnNotice"
                />

                <div class="notice-item-field linked-items" v-if="hasLinkedItems">
                  <h2 class="notice-item-field-title with-icon">
                    <span>{{ $t("Notices liées") }}</span>
                  </h2>
                  <ul class="notice-item-field-content">
                    <li
                            v-for="item in linkedItems"
                            :key="item.id"
                            class="notice-part-6"
                    >
                      <a
                          v-if="inPopUp"
                          :href="getNoticeURL(item.id)"
                          target="_blank"
                          class="external-link"
                      >
                        {{ item.value }}
                        <span class="link-type">
                            [ {{ getLinkTypeLabel(item.fieldName ) }} ]
                          </span>
                      </a>
                      <router-link
                          v-else
                          :to="getNoticeRoute(item.id)">
                        {{ item.value }}
                        <span class="link-type">
                            [ {{ getLinkTypeLabel(item.fieldName ) }} ]
                          </span>
                      </router-link>
                    </li>
                  </ul>
                </div>

                <NoticeMediaItemLinks
                        v-if="linksOfItems.total > 0"
                        :total="linksOfItems.total"
                        :itemsByTerm="linksOfItems.itemsByTerm"
                        :labelsByTerm="linksOfItems.labelsByTerm"
                        :termsOrder="linksOfItems.termsOrder"
                        :linkedElementsLabel="collectionLinkedElementsLabel"
                        :linksInPopUp="inPopUp"
                        :from-item="state.itemId"
                        class="notice-part-6"
                        @clicknoticelink="emitClickOnNotice"
                        @clickdocumentlink="emitClickOnDocument"
                        @clickarticlelink="emitClickOnArticle"
                />

              </div>

            </div>
          </div>

          <!-- CAS 2. DOCUMENTS AVEC MEDIA -->
          <div  v-else
                class="notice-item-columns document-with-media">

            <!-- PARTIE 2 MEDIA -->
            <div class="notice-item-column">

              <NoticeMediaItemMetadata
                      v-for="(term, no) in getTermsAtPosition(0)" v-bind:key="getMetaDataBindKey(no, term, 0)"
                      :term="term"
                      :label='getMetaDataLabel(term)'
                      :value='getMetaDataValue(term)'
                      :data-key="getMetaDataBindKey(no, term, 5)"
                      :from-item="state.itemId"
                      class="notice-part-2"
              />

              <div :class="state.mediaParentCssClass">

                <!-- Média -->
                <div class="notice-media">

                  <!-- Absence de media : affichage de la description -->
                  <div v-if="!state.hasMedia && hasDescriptionOrAbstract">

                    <div class="notice-item-field notice-description">
                      <h3 class="notice-item-field-title">Descriptif</h3>
                      <div class="notice-lang-selector">
                        <a href="#" class="active">FR</a>
                        <a href="#">EN</a>
                      </div>
                      <p class="notice-item-field-content">
                        {{ dctermsAbstract }}
                      </p>
                      <p class="notice-item-field-content" v-for="item in dctermsDescriptions" :key="item" >
                        {{ item }}
                      </p>
                    </div>

                  </div>

                  <!-- Affichage du media : image, pdf, html -->
                  <div id="lightgallery" v-if="state.hasMedia">

                    <Media
                            v-for="mediaId in state.medias"
                            v-bind:key="'media'+mediaId"
                            :mediaId = mediaId
                            class="media"
                            @loaded="loadLightGallery"
                    />

                  </div>
                </div>

                <NoticeMediaItemMetadata
                        v-for="(term, no) in getTermsAtPosition(2)" v-bind:key="getMetaDataBindKey(no, term, 2)"
                        :term="term"
                        :label='getMetaDataLabel(term)'
                        :value='getMetaDataValue(term)'
                        :from-item="state.itemId"
                        class="notice-part-2"
                />

              </div>
            </div>

            <!-- PARTIE 1 à PARTIE 5 -->
            <div class="notice-item-column">

              <NoticeMediaItemMetadata
                      v-for="(term, no) in getTermsAtPosition(1)" v-bind:key="getMetaDataBindKey(no, term, 1)"
                      :term="term"
                      :label='getMetaDataLabel(term)'
                      :value='getMetaDataValue(term)'
                      :from-item="state.itemId"
                      class="notice-part-1"
              />

              <NoticeMediaItemMetadata
                      v-for="(term, no) in getTermsAtPosition(3)" v-bind:key="getMetaDataBindKey(no, term, 3)"
                      :term="term"
                      :label='getMetaDataLabel(term)'
                      :value='getMetaDataValue(term)'
                      :from-item="state.itemId"
                      class="notice-part-3"
              />

              <NoticeMediaItemMetadata
                      v-for="(term, no) in getTermsAtPosition(4)" v-bind:key="getMetaDataBindKey(no, term, 4)"
                      :term="term"
                      :label='getMetaDataLabel(term)'
                      :value='getMetaDataValue(term)'
                      :linksInPopUp="inPopUp"
                      :from-item="state.itemId"
                      class="notice-part-4"
                      @clicknoticelink="emitClickOnNotice"
              />

              <NoticeMediaItemMetadata
                      v-for="(term, no) in getTermsAtPosition(5)" v-bind:key="getMetaDataBindKey(no, term, 5)"
                      :term="term"
                      :label='getMetaDataLabel(term)'
                      :value='getMetaDataValue(term)'
                      :linksInPopUp="inPopUp"
                      :data-key="getMetaDataBindKey(no, term, 5)"
                      :from-item="state.itemId"
                      class="notice-part-5"
                      @clicknoticelink="emitClickOnNotice"
              />

              <NoticeMediaItemLinks
                      v-if="linksOfItems.total > 0"
                      :total="linksOfItems.total"
                      :itemsByTerm="linksOfItems.itemsByTerm"
                      :labelsByTerm="linksOfItems.labelsByTerm"
                      :termsOrder="linksOfItems.termsOrder"
                      :linkedElementsLabel="collectionLinkedElementsLabel"
                      :linksInPopUp="inPopUp"
                      :from-item="state.itemId"
                      class="notice-part-6"
                      @clicknoticelink="emitClickOnNotice"
                      @clickdocumentlink="emitClickOnDocument"
                      @clickarticlelink="emitClickOnArticle"
              />

            </div>
          </div>

        </div>

        <!-- Citation-->
        <Citation
                v-if="canHaveCitation"
                :creator = "dctermsCreator"
                :title = "dctermsTitle"
                :itemId = "state.itemId"
                :itemSetId = "collectionId"
        />

        <!-- Annotations -->
        <Annotations
                v-if="canHaveAnnotations"
                :itemId = "state.itemId"
        />

      </div>
    </div>

    <Coins v-if="state.itemId" :query="state.coinQuery" />

    <NoticeFooter />

  </div>

</template>

<script>

  import {reactive, watch, computed, onMounted} from "vue";
  import {useRoute} from "vue-router";
  import { useStore } from "vuex";
  import { JsonReader }  from '../../js/jsonReader.js';
  import { getItemApiURL, getItemAdminURL, getLinkedResourcesApiURL} from "../../js/api";
  import axios from 'axios'

  import $ from 'jquery'
  import "lightgallery/dist/css/lightgallery.css";
  import "lightgallery/dist/js/lightgallery.js";
  import "lg-zoom/dist/lg-zoom.js";

  import Media from "./Media";
  import Loading from "../Loading";
  import Coins from "../Coins";
  import Annotations from "../Annotations/Annotations";
  import Citation from "./Citation";

  import NoticeMediaItemMetadata from "./NoticeMediaItemMetadata";
  import NoticeMediaItemLinks from "./NoticeMediaItemLinks";
  import NoticeFooter from "./NoticeFooter";
  import NavigationItemsEventDispatcher from "../Lists/NavigationItemEventDispatcher";
  import NoticePDFDownloadLink from "./NoticePDFDownloadLink";


  export default {

    name: 'NoticeMedia',
    components: {
      NoticePDFDownloadLink,
      NoticeMediaItemMetadata,
      NoticeMediaItemLinks,
      NoticeFooter,
      NavigationItemsEventDispatcher,
      Media,
      Annotations,
      Citation,
      Loading,
      Coins
    },
    props: {
      itemId: {
        type: Number,
        required: false,
        default:0
      },
      itemJson: {
        type: Object,
        required: false,
        default:null
      },
      entityType: {
        type: String,
        required: false,
        default: "item"
      },
      displayMode: {
        type: String,
        required: false,
        default: "reference"
      },
      displayMedia: {
        type: Boolean,
        required: false,
        default: false
      },
      tools: {
        type: Boolean,
        required: false,
        default:true
      },
      footer: {
        type: Boolean,
        required: false,
        default:true
      },
      inPopUp: {
        type:Boolean,
        required: false,
        default: false
      },
      itemsIds: {
        type: Array,
        required: false
      },
      hasPartMetadataSorted: {
        type:Boolean,
        required: false,
        default: false
      },
      openGraphInPopUp: {
        type:Boolean,
        required: false,
        default: false
      },
    },
    emits: [
      'item-loaded',
      'change-item-notice',
      'open-item-graph',
      'downloaditemnotice',
      'previous-next-item-notice'
    ],
    setup(props, context) {

      const store = useStore();
      const route = useRoute();

      // Collections du graphe
      const encyclopedieCollectionsIds = store.getters.encyclopedieCollectionIds();

      // Api infos
      const apiRoot = store.getters.apiRoot;
      const keyIdentity = store.getters.identityAndCredentialParameters;


      const loadItem = function(itemId) {

        state.isLoading = true;
        state.isJsonAndResourceTemplateLoaded = false;

        // console.log("loadItem", itemId);

        const apiURL = getItemApiURL(apiRoot, itemId);

        // API de l'item :
        return axios.get(apiURL + keyIdentity ).then(function(response){

          if (route.query.debug) {
            console.log('loadItem', itemId, response);
          }

          const medias = parseItemJson(response.data);
          return new Promise((resolve) => {
            resolve(medias)
          });
        });
      };

      const parseItemJson = function(itemJson) {

        if (route.query.debug) {
          console.log('parseItemJson', itemJson)
        }

        state.jsonReader = new JsonReader(itemJson);
        state.isJsonAndResourceTemplateLoaded = false;

        if (!state.itemId) {
          state.itemId = state.jsonReader.getAsNumber("o:id");
        }

        // Vignettes par défaut (si le media ne se charge pas)
        state.mediaThumbnails = state.jsonReader.getAsNumber("thumbnail_display_urls");
        if (state.mediaThumbnails) {
          state.mediaLargeImage = state.mediaThumbnails.large;
        }

        // Collections de la recherche ( encyclopédie, oeuvres, iconographie, ...)
        const referenceItemSetIds = store.getters.searchCollectionsIds();

        // Collections de l'item :
        state.jsonReader.get('o:item_set').sort(function(a, b) {
          const posA = referenceItemSetIds.indexOf(a['o:id']);
          const posB = referenceItemSetIds.indexOf(b['o:id']);
          if (posA === -1) {
            return 1;
          } else if (posB === -1) {
            return -1;
          } else {
            return posA < posB ? -1: 1;
          }
        });

        // console.log(state.jsonReader.get('o:item_set'))
        // console.log('first', state.jsonReader.getLiteralFirstValue('o:item_set', "o:id"))

        // On renvoie le json au parent :
        // Sert notamment à la vue parente pour mettre à jour le fil d'Ariane
        context.emit('item-loaded', {
          id: state.itemId,
          title: state.jsonReader.get('o:title'),
          itemSetId: state.jsonReader.getLiteralFirstValue('o:item_set', "o:id"),
          jsonReader: state.jsonReader
        });

        // Média de l'items
        let mediaJson = state.jsonReader.get('o:media'), medias = false;
        if (Array.isArray(mediaJson) && mediaJson.length) {
          let i, mediaCount = mediaJson.length, mediaIds = [], mediaId;
          for(i=0; i< mediaCount; i++) {
            mediaId = mediaJson[i]['o:id'];
            if (! isNaN(mediaId)) {
              mediaIds.push(mediaId);
            }
            medias = mediaIds;
          }
        }

        // Modèle de ressource de l'item :
        const resourceTemplateId = state.jsonReader.getFieldValue('o:resource_template', 'o:id');

        // Récupération du modèle ( stocké ou téléchargé )
        return store.dispatch("getResourceTemplate", resourceTemplateId).then(function (response) {

          // console.log('response', response);

          // On stocke les données du modèle de ressource
          state.jsonReader.labelsByTerm = response.labels;
          state.resourceTemplateTitle = response.titleTerm;
          state.resourceTemplateTermByPosition = response.by_position;
          state.isJsonAndResourceTemplateLoaded = true;

          /*
          // DEBUG TITLE
          console.log('title term', state.resourceTemplateTitle);
          console.log('o:title', state.jsonReader.getMetaDataValue(state.resourceTemplateTitle));
          console.log('dcterms:title', state.jsonReader.getMetaDataFirstValue('dcterms:title'));
          console.log('dcterms:alternative', state.jsonReader.getMetaDataFirstValue('dcterms:alternative'));
           */

          // On retourne l'id du media s'il y en a :
          if (medias) {
            return medias;
          }
          else
          {
            state.hasMedia = false;
            state.isMediaLoadingFailed = true;
            state.isLoading = false;
          }

        });

      };

      let loadLightGalleryTimeout;
      const loadLightGallery = function() {
        // console.log("loadLightGallery...", $('#lightgallery'))
        // Initialisation du plugin JQuery Light Gallery
        if (loadLightGalleryTimeout) clearTimeout(loadLightGalleryTimeout);
        loadLightGalleryTimeout = setTimeout(function() {
          $('#lightgallery').lightGallery({
            thumbnail:true,
            animateThumb: false,
            showThumbByDefault: false,
            selector:'.notice-item-image-for-gallery'
          });

          if (route.query.debug) {
            // console.log('loadLightGallery is initialized !')
          }

        }, 500);
      };

      const loadContent = function(itemId) {

        if (state.itemId !== itemId) {

          state.itemId = itemId;
          state.jsonReader = null;

          if (itemId > 0) {
            if (props.entityType === "media") {

              state.medias = [state.itemId];

            } else {

              loadItem(state.itemId).then(function(medias){

                state.isLoading = false;
                state.hasMedia = Array.isArray(medias) && medias.length;

                if (medias) {
                  state.medias = medias;
                }

                checkIfItemHasLinkedResources();
              });

            }
          }
        }
      };

      const parseContent = function(itemJson) {
        parseItemJson(itemJson).then(function(medias){

          state.isLoading = false;
          state.hasMedia = medias;

          if (medias) {
            state.medias = medias;
          }

          checkIfItemHasLinkedResources();
        });
      };

      const checkIfItemHasLinkedResources = function() {
        // Cas particulier des rédacteurs : chargement des ressources liées au dcterms:creator de l'item
        const collectionInfos = store.getters.collectionInfosByProp("name", "redacteurs");
        if (collectionInfos) {
          const collectionId = collectionInfos.id;
          const itemCollectionId = state.jsonReader.getLiteralFirstValue('o:item_set', "o:id");

          if (itemCollectionId === collectionId) {
            state.noticeCssClass += ' notice-item-author';
            loadLinkedResources();
          }
        }
      };

      const loadLinkedResources = function() {

        // 1. Api Notices de l'encyclopédie liées (à afficher dans la zone 3 ):
        const encyclopedieCollectionsIds = store.getters.encyclopedieCollectionIds();
        const apiURL = getLinkedResourcesApiURL(apiRoot, 'dcterms:creator', state.itemId, encyclopedieCollectionsIds);
        axios.get(apiURL + keyIdentity ).then(function(response){
          state.jsonReader.set('dcterms:references', response.data);
          state.renderKeyVersion ++;
        });

        // 2. Api Articles liées (à afficher dans la zone 4 ) :
        const collectionInfos = store.getters.collectionInfosByProp("name", "articles");
        if (collectionInfos) {
          const collectionId = collectionInfos.id;
          const articlesApiURL = getLinkedResourcesApiURL(apiRoot, 'dcterms:creator', state.itemId, [collectionId]);
          axios.get(articlesApiURL + keyIdentity ).then(function(response){
            state.jsonReader.set('dcterms:requires', response.data);
            state.renderKeyVersion ++;
          });
        }
      };

      const getTermsAtPosition = function(position) {
        return state.resourceTemplateTermByPosition ? state.resourceTemplateTermByPosition[position] : [];
      };

      const getMetaDataBindKey = computed(  () => (no, term, position) => {
        return [term, position + '.' + no, 'v' + state.renderKeyVersion].join('-');
      });

      const hasTermsAtPosition = function(position) {
        return state.resourceTemplateTermByPosition[position].length > 0;
      };

      const toggleItemInBasket = function($event) {
        $event.preventDefault();
        store.dispatch( store.getters.itemInBasket(parseInt(state.itemId), "item") ? "removeFromBasket" : "addToBasket", { type: "item", id: state.itemId } );
      };

      const openItemGraphInPopUp = function($event) {
        $event.preventDefault();

        context.emit('open-item-graph', {
          id: state.itemId
        });
      };

      const navigationChangeNoticeItem = function(itemId){
        context.emit('previous-next-item-notice', itemId);
        const pos = state.itemsIds.indexOf(itemId);
        state.previousNoticeItemId = pos > 0 ? state.itemsIds[pos - 1] : 0;
        state.nextNoticeItemId = pos < state.itemsIds.length - 1 ? state.itemsIds[pos + 1] : 0;
      };


      //
      // State
      //

      const state = reactive({

        userId: computed(() => parseInt(store.getters.userId) ),
        isUserConnected: computed(() => store.getters.isUserConnected ),
        userCanEdit: computed(() => store.getters.userCanEdit ),

        itemId:null,
        hasMedia:null,
        medias:[],
        mediaType:null,
        mediaTitle:null,
        mediaPath:null,
        mediaHTML:null,
        mediaThumbnails:[],
        mediaLargeImage: null,

        isMediaLoadingFailed:false,
        isMediaImage: computed(() => state.mediaType && state.mediaType.indexOf("image/") === 0),
        isMediaPDF: computed(() => state.mediaType && state.mediaType === "application/pdf"),
        isMediaHTML: computed(() => state.mediaHTML && state.mediaHTML.length > 0),

        mediaParentCssClass: computed(() => state.isMediaPDF ? "notice-pdf-viewer" : "" ),
        coinQuery: computed(() => 'id=' + state.itemId ),

        hasPartMetadataSorted: props.hasPartMetadataSorted,
        noticeCssClass: '',
        isLoading:true,
        isJsonAndResourceTemplateLoaded: false,
        renderKeyVersion:0,

        basketButtonLabel: computed(() => store.getters.itemInBasket(state.itemId, "item") ? "Retirer du panier" : "Ajouter au panier" ),

        encyclopedieCollectionsIds,

        // Pour la navigation (optionnelle) Suivant/Précédent
        itemsIds:props.itemsIds,
        previousNoticeItemId: 0,
        nextNoticeItemId: 0,

        openGraphInPopUp: props.openGraphInPopUp,

        // Types de liens
        linkTypeLabels: store.getters.linkTypeLabels()
      });


      //
      // Lifecycle Hooks
      //

      watch( () => props.itemId, (itemId) => {
        loadContent(itemId);
      });

      watch( () => props.itemJson, (itemJson) => {
        parseItemJson(itemJson);
      });

      watch( () => store.state.settings, () => {
        state.linkTypeLabels = store.getters.linkTypeLabels();
      });

      onMounted(() => {

        // jQuery sert à la visionneuse de média : LightGallery
        window.$ = $;

        if (props.itemId) {
          loadContent(props.itemId);
        } else if (props.itemJson) {
          // console.log("parseItemJson itemJson", props.itemJson);
          parseContent(props.itemJson);
        }

        if (state.itemsIds) {
          // Résultats courant, suivant et précédent
          state.itemId = parseInt(props.itemId);
          const pos = state.itemsIds.indexOf(state.itemId);
          state.previousNoticeItemId = pos > 0 ? state.itemsIds[pos - 1] : 0;
          state.nextNoticeItemId = pos < state.itemsIds.length - 1 ? state.itemsIds[pos + 1] : 0;
        }

      });

      return {
        state,
        getTermsAtPosition,
        hasTermsAtPosition,
        getMetaDataBindKey,
        toggleItemInBasket,
        openItemGraphInPopUp,
        navigationChangeNoticeItem,
        loadLightGallery
      };

    },
    computed: {

      collection() {
        return this.state.jsonReader.getLiteralFirstValue('o:item_set', 'o:id');
      },
      collectionTitle() {
        if (this.state.jsonReader) {
          const itemSetId  = this.state.jsonReader.getLiteralFirstValue('o:item_set', "o:id");
          if (itemSetId) {
            const store = useStore();
            const itemSetTitle = store.getters.collectionTitle(itemSetId);
            if (itemSetTitle) return itemSetTitle;
          }
          const collectionRdfType = this.state.jsonReader.getLiteralFirstValue('rdf:type', "display_title");
          if (collectionRdfType) {
            return collectionRdfType;
          }
        }
        return "";
      },
      collectionTitleCode() {
        const store = useStore();
        return store.getters.collectionCode(this.collectionId);
      },
      categoryNameInSettings() {
        const itemSetId  = this.state.jsonReader.getLiteralFirstValue('o:item_set', "o:id");
        const store = useStore();
        return store.getters.collectionsSettings(itemSetId);
      },
      collectionId() {
        if (this.state.jsonReader) {
          const itemSet = this.state.jsonReader.getLiteralFirstValue('o:item_set', "o:id");
          if (! isNaN(itemSet)) {
            return itemSet;
          } else {
            return this.state.jsonReader.getLiteralFirstValue('rdf:type', "value_resource_id");
          }
        }
        return 0;
      },
      isCategoryInGraph() {
        return this.state.encyclopedieCollectionsIds.indexOf(this.collectionId) !== -1;
      },
      isCategoryInGraphCssClass() {
        return this.isCategoryInGraph ? "notice-encyclopedie" : "";
      },
      hasMedia() {
        return this.state.hasMedia; // OU ARTICLE OU CONTENU TD ?
      },
      categoryIcon() {
        return this.categoryNameInSettings ? 'cercle_' + this.categoryNameInSettings.icon + '.svg' : '';
      },
      categoryIconCssClass() {
        return this.isCategoryInGraph && this.categoryNameInSettings ? ('category-' + this.categoryNameInSettings.colorNo) : '';
      },
      resourceTemplateTitle() {
        return this.state.resourceTemplateTitle;
      },

      hasDescriptionOrAbstract() {
        return this.hasAbstract || this.hasDcTermsDescriptions;
      },

      hasAbstract() {
        return this.dctermsAbstract.length;
      },

      dctermsAbstract() {
        return this.state.jsonReader.getLiteralFirstValue('dcterms:abstract');
      },

      hasDcTermsDescriptions() {
        return this.dctermsDescriptions.length > 0;
      },

      dctermsDescriptions() {
        return this.state.jsonReader.getMetaDataValue('dcterms:description' );
      },

      mediaHTML() {
        return this.state.mediaHTML;
      },

      dctermsCreator() {
        return this.state.jsonReader.getLiteralFieldStringValues('dcterms:creator', 'display_title').join(', ');
      },

      dctermsTitle() {
        const currentLanguage = this.$store.state.currentLanguage;
        return this.state.jsonReader.getLocalizedMetaDataValue('dcterms:title', currentLanguage, this.dctermsAlternativeTitle);
      },

      dctermsAlternativeTitle() {
        const currentLanguage = this.$store.state.currentLanguage;
        return this.state.jsonReader.getLocalizedMetaDataValue('dcterms:alternative', currentLanguage, this.title);
      },

      title() {
        // resourceTemplateTitle = Metadata fieldname for title, set in the item-type resource template (modèle de ressources)
        if (this.$route.query.debug) {
          console.log('title term', this.state.resourceTemplateTitle);
          console.log('title term values', this.state.jsonReader.getMetaDataValue(this.state.resourceTemplateTitle));
          console.log('dctermsTitle', this.state.jsonReader.getLocalizedMetaDataValue('dcterms:title', currentLanguage));
          console.log('dctermsAlternativeTitle', this.state.jsonReader.getLocalizedMetaDataValue('dcterms:alternative', currentLanguage));
        }

        const currentLanguage = this.$store.state.currentLanguage;
        return this.state.jsonReader.getLocalizedMetaDataValue(this.state.resourceTemplateTitle, currentLanguage, '');
      },

      thumbnail() {
        return this.getThumbnailURL('large');
      },

      imageFilename() {
        return this.json["o:filename"];
      },

      displayModeCssClass() {
        return "notice-" + this.displayMode + "-display-mode notice-type-" + this.entityType;
      },

      linksOfItems() {
        // Metadonnées de la partie 5 de l'interface : on retire les terms du vocabulaire "skos"
        let items, itemsByTerm = [], termsOrder = [], labelsByTerm = [], total = 0;
        let i, term, terms = this.getTermsAtPosition(6);
        if (! terms) {
          return { total: 0 };
        }
        const n = terms.length;
        for(i=0;i<n;i++)
        {
          term = terms[i];
          if (term.indexOf('skos') === -1) {
            termsOrder.push(term);
            items = this.getMetaDataValue(term);
            if (items.length > 0) {
              total += items.length;
              itemsByTerm[term] = items;
              labelsByTerm[term] = this.getMetaDataLabel(term);
            }
          }
        }
        return {
          termsOrder,
          itemsByTerm,
          labelsByTerm,
          total
        }
      },

      linkedItems() {
        // Metadonnées de la partie 5 de l'interface : uniquement celles du vocabulaire "skos"
        let items = [];
        let i, j, term, terms = this.getTermsAtPosition(6);
        if (! terms) {
          return [];
        }
        const n = terms.length;
        for(i=0;i<n;i++) {
          term = terms[i];
          if (term.indexOf('skos') === 0) {
            const itemsForTerm = this.state.jsonReader.getObjectFieldValues(term, "display_title", "value_resource_id");
            for(j=0;j<itemsForTerm.length;j++) {
              items.push(itemsForTerm[j]);
            }
          }
        }
        return items
      },

      hasLinkedItems() {
        return this.linkedItems.length > 0;
      },

      descriptionById: function() {
        return (id) => this.itemById(id).description;
      },

      collectionLinkedElementsLabel() {
        return this.$store.getters.collectionsSettings(this.collectionId, "linkedElementsLabel");
      },
      collectionMetaDataSettings() {
        return this.$store.getters.collectionMetaDataSettings(this.collectionId);
      },
      canHaveAnnotations: function (){
        return this.state.jsonReader.resourceTemplateHas("bibo:annotates");
      },
      canHaveCitation: function (){
        return this.state.jsonReader.resourceTemplateHas("dcterms:bibliographicCitation");
      },
      noticeItemRoute() {
        return {
          name   : 'Notice Item',
          params : { id: this.state.itemId },
          query  : { display_mode: 'notice' }
        }
      },
      noticeItemUrl() {
        return this.$router.resolve(this.noticeItemRoute).href;
      },
      graphItemRoute() {
        return {
          name  : 'Graphe Item',
          params: { id: this.state.itemId },
          query : { display_mode: 'graph'}
        }
      },
      graphItemUrl() {
        return this.$router.resolve(this.graphItemRoute).href;
      },
      editNoticeUrl() {
        return getItemAdminURL(this.$store.getters.adminRoot, this.state.itemId);
      },
      itemInBasketCssClass() {
        return this.$store.getters.itemInBasket(this.state.itemId, "item") ? "selected" : "";
      }
  },
    methods: {
      clickOnNotice($event, item) {
        $event.preventDefault();
        this.emitClickOnNotice(item);
      },
      emitClickOnNotice(item) {
        this.$emit('change-item-notice', {
          "itemId"    : item.id,
          "itemType"  : item.fieldName ==='dcterms:bibliographicCitation' ? "extrait" : "item",
          "previousItemTitle" : this.dctermsTitle,
        });
      },
      clickOnDocument($event, item) {
        $event.preventDefault();
        this.emitClickOnDocument(item);
      },
      emitClickOnDocument(item) {
        this.$emit('change-item-notice', {
          "itemId"   : item.id,
          "itemType" : this.isCategoryInGraph ? "media" : "item",
          "previousItemTitle" : this.dctermsTitle,
        });
      },
      clickOnArticle($event, item) {
        $event.preventDefault();
        this.emitClickOnArticle(item);
      },
      emitClickOnArticle(item) {
        // EVENT --> PARENT
        this.$emit('change-item-notice', {
          "itemId"   : item.id,
          "itemType" : "article",
          "previousItemTitle" : this.dctermsTitle,
        });
      },
      downloadNotice($event) {
        $event.preventDefault();

        // EVENT --> PARENT
        this.$emit('downloaditemnotice');
      },
      blockLink($event) {
        $event.preventDefault();
      },
      getCollectionURL(collectionId) {
        if (collectionId) {
          return this.$router.resolve({
            name: 'Collection',
            params : { id: collectionId },
            query : { lang: this.$store.state.currentLanguage }
          }).href;
        } else {
          return "#";
        }
      },
      getNoticeURL(itemId) {
        return this.$router.resolve({
          name: 'Notice Item Modal',
          params : { id: itemId },
          query : { lang: this.$store.state.currentLanguage, display_mode: 'modal' }
        }).href;
      },
      getNoticeRoute(itemId) {
        return this.$router.resolve({
          name: 'Notice Item',
          params : { id: itemId },
          query : { lang: this.$store.state.currentLanguage, display_mode: 'notice' }
        });
      },
      getArticleURL(itemId) {
        const itemRouteName = this.$store.getters.collectionItemRouteNameByItemType('article');
        // console.log('***itemRouteName', itemRouteName)
        return this.$router.resolve({
          name: itemRouteName,
          params : { id: itemId },
          query : { lang: this.$store.state.currentLanguage }
        }).href;
      },
      formatDate(dateMySQL) {
        return dateMySQL.split('-').reverse().join('/');
      },
      getLinkTypeLabel: function(fieldName) {
        if (this.state.linkTypeLabels[fieldName]) {
          return this.state.linkTypeLabels[fieldName];
        }
        return fieldName;
      },
      getMetaDataLabel: function (metadata, defaultValue = '') {
        return this.state.jsonReader.getMetaDataLabel(metadata, defaultValue);
      },
      getMetaDataValue: function (metadata) {
        const metadataValues = this.state.jsonReader.getMetaDataValue(metadata);

        // Cas particulier : on trie les metadonnées dcterms:hasPart par ordre alphabétique
        if (Array.isArray(metadataValues)) {
          if ((metadata === "dcterms:hasPart") && this.state.hasPartMetadataSorted) {
            metadataValues.sort(this.getMetaDataValueSortOrder);
          }
        }

        return metadataValues;
      },
      getThumbnailURL: function (size){
        return this.state.jsonReader.getThumbnailURL(size);
      },
      getMetaDataValueSortOrder: function(valueA, valueB) {
        return valueA.value < valueB.value ? -1 : 1;
      },
      capitalizeText: function (title = '') {
        return title.charAt(0).toUpperCase() + title.slice(1);
      },
    }
  }

</script>
