<template>

  <div id="timeline">
  </div>

</template>

<script>

  import {onMounted, watch} from "vue";
  import TL from "vue-timelinejs3";
  import $ from "jquery";
  import {useRouter} from "vue-router";
  import {useStore} from "vuex";

  export default {
    name: 'CollectionTimeline',
    components: {
    },
    props: {
      items: {
        type: Array,
        required: true
      },
      itemRouteName: {
        type: String,
        required: false,
        default:'Item'
      },
    },
    emits: [
      "opendetailednotice",
      "click-item"
    ],
    setup(props, context) {

      const router = useRouter();
      const store = useStore();

      //
      // Lifecycle Hooks
      //

      onMounted(() => {
          initTimeline();
      });

      watch(() => props.items, () => {
        initTimeline();
      });

      watch(() => store.state.collections, () => {
        initTimeline();
      });


      /* Timeline functions */

      const getItemURL = function(itemId) {

        let itemURL = router.resolve( { name: props.itemRouteName, params: { id: itemId }, }).href;

        const lang = store.state.currentLanguage;
        if ( lang !== "fr") {
          itemURL  += "&lang=" + lang;
        }

        return itemURL;
      }

      const parseDate = function(entryDateString) {
        var entryDate = entryDateString;
        var parsedDate = entryDate.split('-');

        // Année
        if (parsedDate.length === 1) return [entryDate, null, null, entryDate];

        // Date du jour
        var entryYear = parseInt(parsedDate[0]);
        var entryMonth = parseInt(parsedDate[1]);
        const entryMonthStr = [
          "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet",
          "Août", "Septembre", "Octobre", "Novemvre", "Décembre"
        ];

        if (parsedDate.length === 2) return [entryYear, entryMonth, null, entryMonthStr[entryMonth - 1] + " " + entryYear];

        var entryDay = parseInt(parsedDate[2].slice(0, 2));
        return [entryYear, entryMonth, entryDay, entryDay + " " + entryMonthStr[entryMonth - 1] + " " + entryYear];
      };

      const initTimeline = function() {

        if (! store.state.collections) return;

        if (Array.isArray(props.items))
        {
          let i, items = props.items, n = items.length;
          let itemJson, itemId, itemTitle, itemURL, itemDescription, itemImageData, itemImage;
          let itemDates, itemStartDateData, itemStartDate, itemEndDateData, itemEndDate, itemCollections, itemCollectionTitle;
          let startDateArray, endDateArray;

          const timelineId = "timeline";
          const timelineEvents = [];
          const languageIsNotFrench = store.state.currentLanguage !== "fr";

          for (i = 0; i < n; i++) {

            itemJson = items[i];

            itemDates = itemJson["dcterms:date"];
            // console.log(i, itemDates, itemJson);

            if (Array.isArray(itemDates) && itemDates.length) {

              itemStartDateData = itemDates[0];
              if (itemStartDateData["@value"]) {

                itemId = itemJson['o:id'];
                itemTitle = itemJson['o:title'];
                itemDescription = "";
                itemURL = getItemURL(itemId);

                // Date de début
                itemStartDate = itemStartDateData["@value"];

                // Parse the date string into Y, M, D.
                // Assumes YYYY-MM-DD.
                startDateArray = parseDate(itemStartDate);

                // console.log(i, startDateArray)

                // Collection
                itemCollectionTitle = "";
                itemCollections = itemJson['o:item_set'];
                if (itemCollections && itemCollections.length) {
                  itemCollectionTitle = store.getters.collectionTitle(itemCollections[0]['o:id'])
                }

                // Create the slide object for the record.
                var timelineEntry = {
                  "text": {
                    "headline": "<a href=" + itemURL + ">" + itemTitle + "</a>"
                  },
                  "start_date": {
                    "year": startDateArray[0],
                    "month": startDateArray[1],
                    "day": startDateArray[2],
                    "display_date": languageIsNotFrench ? null : startDateArray[3]
                  },
                  "group" : itemCollectionTitle
                };

                // If the item has a description, include it.
                if (itemDescription) {
                  timelineEntry.text["text"] = itemDescription;
                }

                // If the record has an end date, include it.
                itemEndDateData = itemDates.length > 1 ? itemDates[1] : false;
                if (itemEndDateData && itemEndDateData["@value"]) {

                  // Date de fin
                  itemEndDate = itemEndDateData["@value"];
                  endDateArray = parseDate(itemEndDate);

                  timelineEntry["end_date"] = {
                    "year": endDateArray[0],
                    "month": endDateArray[1],
                    "day": endDateArray[2],
                    "display_date": languageIsNotFrench ? null : endDateArray[3]
                  };
                }

                // If the record has a file attachment, include that.
                // Limits based on returned JSON:
                // If multiple images are attached to the record, it only shows the first.
                // If a pdf is attached, it does not show it or indicate it.
                // If a mp3 is attached in Files, it does not appear.
                itemImageData = itemJson["thumbnail_display_urls"];
                if (itemImageData) {
                  itemImage = itemImageData.medium;
                  if (itemImage) {
                    timelineEntry["media"] = { "url": itemImage };
                  }
                }

                // Add the slide to the events.
                timelineEvents.push(timelineEntry);
              }

            }
          }

          // Create the collection of slides.
          var slides = {
            "title": {
              "text": {
                "headline": '',
                "text": ''
              }
            },
            "events": timelineEvents
          };

          var timelineOptions = {};

          // Mode Graphe : Timeline
          new TL.TL.Timeline(timelineId, slides, timelineOptions);

          $('.tl-slide-content').on('click', function($event) {
            $event.stopPropagation();

            const linkElement = $(this).find('.tl-headline a');
            if (linkElement.length)
            {
              const itemId = linkElement.attr('href').split('/').pop();
              openPopUpNotice($event, itemId);
            }
          });

        }
      }

      const openPopUpNotice = function($event, itemId) {
        $event.preventDefault();
        context.emit("opendetailednotice", itemId);
      };

      return {
        openPopUpNotice
      }

    }
  }

</script>
