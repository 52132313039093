<template>

    <div class="gallery">

      <div class="list-pagination top-list-pagination" >
        <div class="pagination-sort-options">

          <ListSortMenu
              :notice-item-type="state.defaultNoticeItemType"
              :sortByParam="state.sortByParam"
              :sortOrderParam="state.sortOrderParam"
              @changesortquery="changeSortQuery"
          />

        </div>
        <ListCount
                :element-count="state.items.length"
                class="cards-count"
        />
        <ListPagination
              v-if="state.pageCount > 1"
              :pageCount="state.pageCount"
              :pageNo="state.pageNo"
              @change-pagination-page="displayPage"
        />
      </div>

      <div class="cards with-padding">
        <div class="card"
             v-for="item in state.displayedItems"
             :key="item.id">
          <div class="card-tools">
            <a class="add-to-basket"
               href="#"
               v-if="state.userIsConnected"
               @click="toggleItemInBasket($event, item['o:id'])"
               :class="itemInBasketCssClass(item['o:id'])"
            ></a>
            <a v-if="showItemNoticeButton"
                class="notice-icon"
               :href="itemURL(item['o:id'])"
               @click="openPopUpNotice($event, item['o:id'])"
            >
            </a>
            <NoticePDFDownloadLink
                v-if="state.userIsConnected"
                class="download"
                :resource-id="item['o:id']"
                :title="$t('Exporter en PDF')" />
          </div>

          <router-link class="item-title" :to="itemRoute(item['o:id'])">
            <div class="image" :class="thumbnailCssClass(item)">
              <img :src="mediumThumbnail(item)" alt="" v-if="mediumThumbnail(item).length" />
            </div>
            <div class="card-title">
              {{ item[state.titleFieldName] }}
            </div>
          </router-link>
        </div>
      </div>

      <div class="list-pagination bottom-list-pagination with-padding" v-if="state.items.length > 0">
        <div class="pagination-sort-options">
        </div>
        <ListPagination
                v-if="state.pageCount > 1"
                :pageCount="state.pageCount"
                :pageNo="state.pageNo"
                @change-pagination-page="displayPage"
        />
      </div>
      <Coins v-if="state.coinQuery" :query="state.coinQuery" />
    </div>

</template>

<script>

  import {reactive, computed} from "vue";
  import ListPagination from "../../components/Lists/ListPagination";
  import ListCount from "../../components/Lists/ListCount";
  import ListSortMenu from "../../components/Lists/ListSortMenu";
  import Coins from "../Coins";
  import NoticePDFDownloadLink from "../Notices/NoticePDFDownloadLink";

  import {useStore} from "vuex";
  import {getQueryParameterForIds, getSortQueryParameters} from "../../js/api";
  import {useRoute, useRouter} from "vue-router";

  const itemsByPage = 15;

  export default {

    name: 'Gallery',
    components: {
      ListPagination,
      ListSortMenu,
      ListCount,
      Coins,
      NoticePDFDownloadLink
    },
    props: {
      items: {
        type: Array,
        required: true
      },
      noticeItemType: {
        type: String,
        required: true,
        default:"item"
      },
      titleFieldName: {
        type:String,
        required: false,
        default:'o:title'
      },
      showItemNoticeButton: {
        type: Boolean,
        required: false,
        default:false
      },
    },
    emits: [
      "opendetailednotice",
      "downloaditemnotice",
      "click-item"
    ],
    setup(props, context) {

      const route = useRoute();
      const router = useRouter();
      const store = useStore();

      const changeSortQuery = function( changes ) {
       router.push({
          name: route.name,
          query : {
            sort_by : changes.sortBy,
            sort_order : changes.sortOrder,
            display_mode: route.query.display_mode
          }
        });
      };


      /* Page State */

      const state = reactive({
        items: props.items,
        pageNo: computed(() => isNaN(route.query.p) ? 1 : parseInt(route.query.p) ),
        pageCount: computed(() => Math.ceil( state.items.length / itemsByPage )),
        displayedItems: computed( () => {
          const startItemNo = itemsByPage * (state.pageNo - 1);
          const endItemNo = Math.min(startItemNo + itemsByPage, state.items.length);
          let i, items = [];
          for(i=startItemNo;i<endItemNo;i++) {
            items.push(state.items[i]);
          }
          return items;
        }),
        displayedItemsIds: computed(() => {
          let i, itemsIds = [];
          let items = state.displayedItems;
          const n = items.length;
          for(i=0;i<n;i++) {
            itemsIds.push(items[i]['o:id']);
          }
          return itemsIds;
        }),
        defaultNoticeItemType: props.noticeItemType,
        titleFieldName: props.titleFieldName,
        sortByParam: 'title',
        sortOrderParam: 'asc',
        userIsConnected: computed(() => store.state.currentUser.logged ),
        coinQuery: computed(() => getQueryParameterForIds(state.displayedItemsIds))
      });

      const itemInBasketCssClass = function(itemId) {
        return store.getters.itemInBasket(itemId, "item") ? "selected" : "";
      };

      const toggleItemInBasket = function($event, itemId) {
        $event.preventDefault();
        store.dispatch( store.getters.itemInBasket(itemId, "item") ? "removeFromBasket" : "addToBasket", { type: "item", id: parseInt(itemId) } );
      };

      const openPopUpNotice = function($event, itemId) {
        $event.preventDefault();
        context.emit("opendetailednotice", itemId);
      };

      const downloadItemNotice = function($event, itemId) {
        $event.preventDefault();
        context.emit("downloaditemnotice", { id: itemId });
      };

      return {
        state,
        itemInBasketCssClass,
        toggleItemInBasket,
        openPopUpNotice,
        downloadItemNotice,
        changeSortQuery
      }

    },
    computed: {
      currentRoutePath() {
        return this.$route.path;
      }
    },
    methods: {
      thumbnailCssClass(item) {
        return this.getThumbnailURL(item, 'medium') ? "with-media" : "without-media";
      },
      mediumThumbnail(item) {
        return this.getThumbnailURL(item, 'medium');
      },
      getThumbnailURL(item, size){
        const thumbnails = item['o:thumbnail_urls'];
        if (thumbnails && thumbnails[size]) {
          return thumbnails[size];
        }
        const thumbnails_display_urls = item['thumbnail_display_urls'];
        if (thumbnails_display_urls && thumbnails_display_urls[size]) {
          return thumbnails_display_urls[size];
        }
        return "";
      },
      clickItem($event, itemId) {
        $event.preventDefault();
        this.$emit('click-item', {
          "itemId"   : itemId
        });
      },
      displayPage(pageNo) {
        // On modifie la route en cours avec le paramètre p=...
        // Ce changement déclenchera, via onBeforeUpdate
        // de ce composant ListeItems, l'affichage de la nouvelle page

        let routeCopy = {
          name : this.$route.name,
          query : { ...this.$route.query },
          params : { ...this.$route.params }
        };

        // Nouvelle page
        routeCopy.query.p = pageNo;

        this.$router.push(routeCopy);
      },
      itemRoute(itemId) {

        let itemURL = this.currentRoutePath + "/item/" + itemId + getSortQueryParameters(this.state.sortByParam, this.state.sortOrderParam, true);

        const lang = this.$store.state.currentLanguage;
        if ( lang !== "fr") {
          itemURL  += "&lang=" + lang;
        }

        return itemURL;
      },
      itemURL(itemId) {
        return this.$router.resolve(this.itemRoute(itemId)).href;
      }
    }
  }

</script>
