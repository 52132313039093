import { version } from 'vue'
import { createApp } from 'vue'
import {createStore} from 'vuex'
import { createI18n } from 'vue-i18n'
import axios from 'axios'
import App from '@/App.vue'
import {createRouterInstance} from './router'
import {mySQLToDateJs} from "@/js/viz-utils";

import {
    getInfosUserApiURL, getResourceTemplateApiURL, getItemsApiURL, getSelectionResourcesItemApiURL,
    getPagesApiURL, getSelectionResourcesApiURL, getBasketApiURL, getSelectionsApiURL, getSelectionsItemApiURL } from "@/js/api";
import {JsonReader} from "@/js/jsonReader";
import {capitalizeFirstChar} from "@/js/functions";
import CollectionFromSettings from "@/views/CollectionFromSettings";
import {getSitesApiURL} from "./js/api";

console.log("Vue.version", version );

// Web storage :
const WEB_STORAGE_KEY_FOR_USER_INFOS = 'omeka-vue-user';
const DEFAULT_KEY_IDENTITY = 'O6D0zXuZsSQX5LZ8VwGFIIIhtp3jIDzC';
const DEFAULT_KEY_CREDENTIAL = 'GGHbfuLG70mQ7BCa5PfJYYKgtdfv5MDV';

// Préfixe de clé de tableaux associatifs
const COLLECTION_ARRAY_KEY = "c";
const TEMPLATE_ARRAY_KEY = "rt";

const jsonReader = new JsonReader();

// Détermination des chemins possibles du fichier de settings : dynamique, ou près du fichier index.html

let jsURL = new URL(document.currentScript.src);
const getCurrentJsPath = () => jsURL.pathname.split('js/').shift();
const getCurrentJsOriginPath = () => jsURL.origin;

const getSupposedOmekaFrontPath = () => {

    const documentLocation = document.location;
    const documentOrigin = documentLocation.origin;
    const documentHref = documentLocation.href;

    // console.log("documentHref", documentHref);
    // console.log("documentOrigin", documentOrigin);

    if (documentHref === documentOrigin + "/") {
        // Racine du domaine
        console.log("cas 1 : Racine du domaine");
        return documentOrigin + "/";
    } else if (documentHref.indexOf("/front/") !== -1 ) {
        // Humanum hors du thème
        console.log("cas 2 : Front hors du thème");
        return documentHref.substr(0, documentHref.indexOf("/front/") + 7);
    } else {
        // Thème
        const themePrefix = "/s/";
        const pos = documentHref.indexOf(themePrefix);
        if (pos !== -1 ) {
            const nextPos = documentHref.indexOf("/", pos + themePrefix.length + 1);
            console.log("cas 3 : Thème", pos, nextPos);
            if (nextPos === -1) {
                return documentHref + "/";
            }
            return documentHref.substr(0, nextPos + 1);
        }
    }
    return "/";
};

// Chemin supposé de l'api data/settings :
const dataPath = getSupposedOmekaFrontPath();
// console.log("[SETTINGS] dataPath", dataPath);

// Chemin des fichiers de l'application :
const serverPath = getCurrentJsPath();
const settingsPath = 'files/encyclopedia/';

// console.log("[SETTINGS] serverPath", serverPath);

let queryParams = new URLSearchParams(document.location.search);
let defaultLanguage = queryParams.get("lang") || "fr";
console.log("defaultLanguage", defaultLanguage);


//
// Store Vuex
//




const store = createStore({
    state() {
        return {
            currentUser : {
                id:null,
                email: null,
                name:null,
                keyIdentity: DEFAULT_KEY_IDENTITY,
                keyCredential: DEFAULT_KEY_CREDENTIAL,
                logged: false
            },
            currentLanguage: defaultLanguage,
            loadedLanguages: [],
            languages: ['fr', 'en'],

            // Sous-dossier où se trouvent les fichiers
            serverPath: serverPath,
            dataPath: dataPath,

            // Sous-dossier base des URLs et base du routeur ( Récupéré du fichier des settings )
            publicPath: "/",

            pdfViewer : "libs/pdfjs-2.5.207-dist/web/viewer.html",
            tdApiURL : "td.json",
            settings : null,
            settingsByCollectionId: null,
            settingsByCollectionName: null,

            site: 1,
            siteName: "Maurice Halbwachs",
            collections: null,
            sitePages: null,

            queryItemSets: "", // Liste des collections à inclure pour la requête de recherche basique
            queryItemSetsIds: [],

            pages: null,
            resourceTemplates: [],
            basket: [],              // Panier standard (ids)
            visualizationBasket: [], // Panier de la visualisation d'item en cours (ids)
            selectionBaskets: [],    // Sauvegarde des paniers de visualisation d'item (nom + ids)
            searches: [],
            searchSettings: { textfield: { opened:false }, fieldsets: { opened:false }, collections: { opened:false }, global: { opened:true }},
            treeViewState:[],
            apiCache: [],
            langCache: [],
        }
    },
    mutations: {

        //
        // Données des setttings
        //

        storeSettings (state, payload) {

            state.settings = payload ;
            state.publicPath = payload.paths.public;

            state.settingsByCollectionId = [];
            state.settingsByCollectionName = [];
            state.encyclopedieCollections = [];
            state.facetsCollections = [];

            const htmlElement =  document.querySelector("html");

            // Tableau linéaire des backgrounds définis dans les settings
            // On met à jour les variables CSS :
            const backgroundsSettings = state.settings.backgrounds;
            let backgroundVarName;
            for (backgroundVarName in backgroundsSettings) {
                htmlElement.style.setProperty("--" + backgroundVarName, "url("+ backgroundsSettings[backgroundVarName] + ")");
            }

            // Tableau lineaire des collections (et de leurs infos)
            const collectionsSettings = state.settings.collections;
            let i, collectionSettings, collectionColorNo = 0, queryItemSets = "", queryItemSetsIds = [];

            const svgFilter = function(hexaColor) {
                const r = parseInt(hexaColor.substr(1, 2), 16);
                const g = parseInt(hexaColor.substr(3, 2), 16);
                const b = parseInt(hexaColor.substr(5, 2), 16);

                let matrixValues = '';
                matrixValues += '0 0 0 0 ' + (r/255)
                matrixValues += ' 0 0 0 0 ' + (g/255)
                matrixValues += ' 0 0 0 0 ' + (b/255)
                matrixValues += ' 0 0 0 1 0';

                return 'url(\'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"><defs><filter id="'+ hexaColor.substr(1, 6) +'" color-interpolation-filters="sRGB"><feColorMatrix type="matrix" values="'+ matrixValues + '"/></filter></defs></svg>'+ hexaColor +'\')';
            };

            const n= collectionsSettings.length;
            for (i=0; i<n;i++) {

                collectionSettings = collectionsSettings[i];

                // Par défaut :
                collectionSettings.itemType = collectionSettings.itemType === undefined ? "item" : collectionSettings.itemType;

                state.settingsByCollectionId[COLLECTION_ARRAY_KEY + collectionSettings.id] = collectionSettings;
                state.settingsByCollectionName[COLLECTION_ARRAY_KEY + collectionSettings.icon] = collectionSettings;

                if (collectionSettings.encyclopedie) {
                    state.encyclopedieCollections.push(collectionSettings);
                    collectionSettings.colorNo = ++collectionColorNo;
                } else if (collectionSettings.facet) {
                    state.facetsCollections.push(collectionSettings);
                }

                if (collectionSettings.color) {
                    // CSS vars :
                    htmlElement.style.setProperty("--category-"+ collectionSettings.colorNo +"-color", collectionSettings.color);
                    htmlElement.style.setProperty("--category-"+ collectionSettings.colorNo +"-color-with-opacity", collectionSettings.color + 'AA');
                    htmlElement.style.setProperty("--category-"+ collectionSettings.colorNo +"-svg-filter", svgFilter(collectionSettings.color));
                }

                if (collectionSettings.icon) {
                    htmlElement.style.setProperty("--category-"+ collectionSettings.colorNo +"-icon", "url(" + collectionSettings.icon + ")");
                }

                if (collectionSettings.facet) {
                    queryItemSets += '&item_set_id[]=' + collectionSettings.id;
                    queryItemSetsIds.push(collectionSettings.id);
                }
            }

            // Query : toutes les collections à inclure par défaut dans les recherches (basiques, par mot-clés...)
            state.queryItemSets = queryItemSets;
            state.queryItemSetsIds = queryItemSetsIds;

            // Link types ( relations entre les items dans les Graphes )
            state.linkTypes = payload.linkTypes;
        },

        //
        // Données de l'api
        //

        storeSitesParams (state, payload) {
            state.sitesParams = payload;
            store.dispatch('updateSiteParams');
        },
        storeSiteParams (state, payload) {
            state.siteParams = payload;
        },

        storeCollections (state, payload) {
            state.collections = payload ;
            store.commit("addCollectionsTitleToLanguageDictionary", state.currentLanguage);
        },

        storePages (state, payload) {
            state.sitePages = payload ;
        },

        addCollectionsTitleToLanguageDictionary (state, lang = "en") {
            // On ajoute le titre des collections comme termes du dictionnaire de la langue courante
            const collections = state.collections;
            if (collections && state.langCache && (lang !== "fr")) {
                const langCachedResults = state.langCache[lang];
                if (langCachedResults) {
                    let i, collectionJson, titlesObj, titleLanguage, j, n, titleObj, titleLang, titleFra;
                    for(i=0;i<collections.length;i++)
                    {
                        collectionJson = collections[i];
                        titleLang = null;
                        titleFra = null;
                        titlesObj = collectionJson["dcterms:title"];
                        if (Array.isArray(titlesObj)) {
                            n = titlesObj.length;
                            for (j = 0; j < n; j++) {
                                titleObj = titlesObj[j];
                                if (titleObj['@language']) {
                                    titleLanguage = titleObj['@language'].substr(0,2).toLowerCase();
                                    if (titleLanguage === "fr") {
                                        titleFra = titleObj['@value'];
                                    } else if (titleLanguage && (titleLanguage === lang)) {
                                        titleLang = titleObj['@value'];
                                    }
                                }
                            }
                            if (titleLang && titleFra) {
                                langCachedResults.terms[titleFra] = titleLang;
                                // console.log(titleFra, titleLang);
                            }
                        }
                    }
                }
            }
        },


        //
        // Données de l'utilisateur
        //

        storeCurrentUser (state, payload) {

            // console.log("storeCurrentUser", payload);

            state.currentUser.id = payload.id;
            state.currentUser.keyCredential = payload.keyCredential;
            state.currentUser.keyIdentity = payload.keyIdentity;
            state.currentUser.email = payload.email;
            state.currentUser.name = payload.name;
            state.currentUser.logged = payload.logged;
            state.currentUser.loggedMethod = payload.loggedMethod;

            state.currentUser.firstName = null;
            state.currentUser.lastName = null;

            if (payload.name && payload.name.length) {
                const userNameArray = payload.name.split(' ');
                const lastName = userNameArray.pop();
                state.currentUser.firstName = userNameArray.join(' ');
                state.currentUser.lastName = lastName;
            }
        },
        updateCurrentUser (state, payload) {
            if (state.currentUser) {
                if (payload.name)
                {
                    state.currentUser.name = payload.name;

                    const userNameArray = state.currentUser.name.split(' ');
                    const lastName = userNameArray.pop();
                    state.currentUser.firstName = userNameArray.join(' ');
                    state.currentUser.lastName = lastName;
                }
                if (payload.role) {
                    state.currentUser.role = payload.role;
                }
                if (payload.email) {
                    state.currentUser.email = payload.email;
                }
            }
        },
        resetCurrentUser (state) {
            if(state.currentUser) {
            state.currentUser.id = null ;
            state.currentUser.name = null ;
            state.currentUser.firstName = null;
            state.currentUser.lastName = null;
            state.currentUser.role = null;
            state.currentUser.keyIdentity = DEFAULT_KEY_IDENTITY;
            state.currentUser.keyCredential = DEFAULT_KEY_CREDENTIAL;
            state.currentUser.logged = false ;
            }
        },

        setCurrentLanguage (state, lang) {
            state.currentLanguage = lang;
        },


        // Panier d'items classique
        initBasket (state, basketItems) {
            state.basket = basketItems;
        },
        addToBasket (state, payload) {
            const n = state.basket.length;
            let i, basketItem;
            for(i=0;i<n;i++) {
                basketItem = state.basket[i];
                if ((basketItem.id === payload.id) && (basketItem.type === payload.type)) {
                    return;
                }
            }
            state.basket.push(payload) ;
        },
        removeFromBasket (state, payload) {
            const n = state.basket.length;
            let i, basketItem;
            for(i=0;i<n;i++) {
                basketItem = state.basket[i];
                if ((basketItem.id === payload.id)) {
                    state.basket.splice(i, 1);
                    break;
                }
            }
        },
        clearBasket (state) {
            state.basket.length = 0;
        },

        // Panier de la Visualisation d'items en cours (ids)
        initToVisualisationBasket (state, itemsIds) {
            state.visualizationBasket = itemsIds;
        },
        addToVisualisationBasket (state, itemId) {
            if (state.visualizationBasket.indexOf(itemId) === -1) {
                state.visualizationBasket.push(itemId) ;
            }
        },
        removeFromVisualisationBasket (state, itemId) {
            const pos = state.visualizationBasket.indexOf(itemId);
            if ( pos !== -1) {
                state.visualizationBasket.splice(pos, 1);
            }
        },
        clearVisualisationBasket (state) {
            if (state.visualizationBasket && state.visualizationBasket.length) {
                state.visualizationBasket.splice(0, state.visualizationBasket.length);
            }
        },

        // Sauvegarde des paniers de la Visualisation d'items en cours (nom, ids)
        initSelectionBaskets (state, selectionBaskets) {
            state.selectionBaskets = selectionBaskets;
        },
        saveSelectionBasket (state, selection) {
            state.selectionBaskets.push({
                id: selection.id,
                name: selection.name,
                items: selection.items,
                created: selection.created,
            });
            // console.log("saveSelectionBasket", state.selectionBaskets);
        },
        updateSelectionBasketAtPosition (state, selection) {
            // console.log("updateSelectionBasketAtPosition", selection.name, selection.items, selection.position);
            state.selectionBaskets[selection.position] = {
                id: selection.id,
                name: selection.name,
                items: selection.items,
                created: selection.created,
            };
            // console.log("--> saveSelectionBasket", state.selectionBaskets);
        },
        deleteSelectionBasketAtPosition (state, position) {
            state.selectionBaskets.splice(position, 1);
        },

        // Recherches
        initSearches (state, searches) {
            state.searches = searches;
        },
        addSearch (state, payload) {
            state.searches.push({
                id: payload.id,
                created: payload.created,
                name: payload.name,
                query: payload.query
            });
        },
        updateSearchAtPosition (state, selection) {
            // console.log("updateSelectionBasketAtPosition", selection.name, selection.query, selection.position);
            state.searches[selection.position] = {
                id: selection.id,
                name: selection.name,
                query: selection.query,
                created: selection.created,
            };
        },
        deleteSearch (state, payload) {
            const n = state.searches.length;
            let i, searchItem;
            for(i=0;i<n;i++) {
                searchItem = state.searches[i];
                if (searchItem.id === payload.id) {
                    state.searches.splice(i, 1);
                    break;
                }
            }
        },
        deleteSearchAtPosition (state, position) {
            state.searches.splice(position, 1);
        },


        //
        // Modèles de Ressources Omeka
        //

        saveResourceTemplate (state, templateParams) {
            state.resourceTemplates[TEMPLATE_ARRAY_KEY + templateParams.id] = templateParams.data;
        },
        updateTreeViewState (state, treeNodeParams ) {
            const treeNodeId = treeNodeParams.id;
            if (treeNodeParams.opened) {
                if (state.treeViewState.indexOf(treeNodeId) === -1) {
                    state.treeViewState.push(treeNodeId) ;
                }
            } else {
                const pos = state.treeViewState.indexOf(treeNodeId);
                if ( pos !== -1) {
                    state.treeViewState.splice(pos, 1);
                }
            }
        },
        updateSearchSettingsState (state, searchSettingsChanges ) {
            if (searchSettingsChanges.panel === "textfield") {
                state.searchSettings.textfield.opened = searchSettingsChanges.opened;
            } else if (searchSettingsChanges.panel === "fieldsets") {
                state.searchSettings.fieldsets.opened = searchSettingsChanges.opened;
            } else if (searchSettingsChanges.panel === "collections") {
                state.searchSettings.collections.opened = searchSettingsChanges.opened;
            } else if (searchSettingsChanges.panel === "global") {
                state.searchSettings.global.opened = searchSettingsChanges.opened;
            }
        },

    },
    actions: {
        storeCurrentUser(context, userParams) {
            context.commit('storeCurrentUser', userParams);
            context.dispatch('getCurrentUserName', userParams);
            context.dispatch('getBasket', userParams);
            context.dispatch('getSelections', userParams);
        },
        updateCurrentUser(context, userParams) {
            context.commit('updateCurrentUser', userParams);
        },
        saveUserInLocalStorage(context, userParams) {
            localStorage.setItem(WEB_STORAGE_KEY_FOR_USER_INFOS, JSON.stringify(userParams));
        },
        updateUserInLocalStorage(context, emailAndNameParams) {
            const userStr = localStorage.getItem(WEB_STORAGE_KEY_FOR_USER_INFOS);
            if (userStr) {
                const userParams = JSON.parse(userStr);
                userParams.name = emailAndNameParams.name;
                userParams.email = emailAndNameParams.email;
                localStorage.setItem(WEB_STORAGE_KEY_FOR_USER_INFOS, JSON.stringify(userParams));
            }
        },
        logout(context) {
            context.commit('resetCurrentUser');
            localStorage.removeItem(WEB_STORAGE_KEY_FOR_USER_INFOS);
        },
        updateCurrentLanguage(context, lang) {
            if (context.state.currentLanguage !== lang) {
                context.commit('setCurrentLanguage', lang);
                context.commit("addCollectionsTitleToLanguageDictionary", lang);
                context.dispatch('updateSiteParams');
            }
        },

        updateSiteParams (context) {
            const siteId = context.getters.getSitePropFromLang(context.state.currentLanguage);
            const sites = context.state.sitesParams;
            if (sites && siteId) {
                const n = sites.length;
                let i, siteObj;
                for(i=0;i<n;i++) {
                    siteObj = sites[i];
                    if (siteObj["o:id"] === siteId) {
                        context.commit('storeSiteParams', siteObj);
                        break;
                    }
                }
            }
        },

        getDictionary(context, lang) {
            const langCache = context.state.langCache;
            const langCachedResults = langCache[lang];
            if (langCachedResults === undefined) {
                const dictionaryURL = context.state.serverPath + 'languages/'+ lang + '.json';
                return axios.get(dictionaryURL).then(function (response) {
                    langCache[lang] = response.data;
                    context.dispatch('updateCurrentLanguage', lang);
                    return response;
                });
            } else {
                context.commit('setCurrentLanguage', lang);
                return new Promise(() => langCachedResults);
            }
        },
        getApi(context, apiURL) {
            const apiCache = context.state.apiCache;
            const apiCachedResults = apiCache[apiURL];
            // console.log('api', apiURL);
            if (apiCachedResults === undefined) {
                const keyIdentity = context.getters.identityAndCredentialParameters;
                if (apiURL.indexOf('?') === -1) {
                    apiURL += "?" + keyIdentity.substr(1);
                }
                return axios.get(apiURL  + keyIdentity).then(function (response) {
                    apiCache[apiURL] = response.data;
                    return response;
                });
            } else {
                return new Promise((resolve) =>
                    setTimeout(() => resolve({ data: apiCachedResults }), 50)
                );
            }
        },
        getCurrentUserName(context, userParams) {
            if (userParams) {
               // Api Omeka (users)

                // PRevious version :
                // const apiURL = getUserApiURL(context.getters.apiRoot, context.state.currentUser.email);

                const apiURL = getInfosUserApiURL(context.getters.apiRoot);
                const keyIdentity = context.getters.identityAndCredentialParameters.substr(1);
                axios
                    .get(apiURL  + keyIdentity)
                    .then(function (response) {
                        const userInfos = response.data;
                        const userName = userInfos["o:name"];
                        const userRole = userInfos["o:role"];

                        store.dispatch('updateCurrentUser', {
                            name : userName,
                            role : userRole
                        });

                    }).catch(error => {

                    // Cf https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/
                    if (error.response) {
                        // 1. Client received an error response (5xx, 4xx)
                        console.error("user name api error1", error.response);
                    } else if (error.request) {
                        // 2. Client never received a response, or request never left ( network error, cross-domain ...)
                        console.error("user name api error2", error.request);
                    } else {
                        // 3. Anything else
                        console.error("user name api error3", error);
                    }
                });
            } else {
                // console.log("logout");
                context.dispatch('logout');
            }
        },
        getSelections(context, userParams) {
            if (userParams) {
               // Api Omeka (selections)
                const apiURL = getSelectionsApiURL(context.getters.apiRoot, context.state.currentUser.id);
                const keyIdentity = context.getters.identityAndCredentialParameters;
                axios
                    .get(apiURL  + keyIdentity)
                    .then(function (response) {

                        const selections = response.data;
                        const searches = [];
                        const selectionBaskets = [];

                        // On doit distinguer les recherches et les sélections de visualisations
                        const n = selections.length;
                        let i, selection, selectionQuery, selectionResources, selectionLabel, selectionCreated, selectionId;
                        for(i=0;i<n;i++) {

                            selection = selections[i];
                            selectionId = selection['o:id'];
                            selectionLabel = selection['o:label'];
                            selectionCreated = mySQLToDateJs ( selection['o:created']['@value'] );

                            selectionQuery = selection['o:search_query'];
                            if (selectionQuery) {
                                searches.push( {
                                    id: selectionId,
                                    name : selectionLabel,
                                    query: selectionQuery,
                                    created: selectionCreated
                                });
                            } else {
                                selectionResources = selection['o:resources'];
                                if (selectionResources) {
                                    const m = selectionResources.length;
                                    let j, resource, items = [];
                                    for(j=0;j<m;j++){
                                        resource = selectionResources[j];
                                        items.push( parseInt( resource['o:id'] ));
                                    }
                                    selectionBaskets.push({
                                        id: selectionId,
                                        name : selectionLabel,
                                        items,
                                        created: selectionCreated
                                    });
                                }
                            }
                        }

                        // console.log("initSearches", searches);
                        // console.log("initSelectionBaskets", selectionBaskets);

                        context.commit('initSearches', searches);
                        context.commit('initSelectionBaskets', selectionBaskets);

                    }).catch(error => {

                    // Cf https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/
                    if (error.response) {
                        // 1. Client received an error response (5xx, 4xx)
                        console.error("user name api error1", error.response);
                    } else if (error.request) {
                        // 2. Client never received a response, or request never left ( network error, cross-domain ...)
                        console.error("user name api error2", error.request);
                    } else {
                        // 3. Anything else
                        console.error("user name api error3", error);
                    }
                });
            }
        },

        // Panier d'items classique (ids)
        getBasket (context, userParams) {
            if (userParams) {
                // Api Omeka (users)
                const apiURL = getBasketApiURL(context.getters.apiRoot, context.state.currentUser.id);
                const keyIdentity = context.getters.identityAndCredentialParameters;
                axios
                    .get(apiURL  + keyIdentity)
                    .then(function (response) {

                        const basketItems = response.data;
                        const n = basketItems.length;
                        let i, item, itemId, selectionId, selectionCreated, items = [], ids = [];

                        for(i=0;i<n;i++) {
                            item = basketItems[i];
                            itemId = item['o:resource']['o:id'];
                            selectionId = item['o:id'];
                            selectionCreated = mySQLToDateJs ( item['o:created']['@value'] );

                            items.push({
                                id: itemId,
                                selectionId: selectionId,
                                created: selectionCreated
                            });

                            ids.push(itemId);
                        }

                        context.commit('initBasket', items);
                        context.dispatch('getBasketItemsInfos', ids);

                    }).catch(error => {

                    // Cf https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/
                    if (error.response) {
                        // 1. Client received an error response (5xx, 4xx)
                        console.error("user name api error1", error.response);
                    } else if (error.request) {
                        // 2. Client never received a response, or request never left ( network error, cross-domain ...)
                        console.error("user name api error2", error.request);
                    } else {
                        // 3. Anything else
                        console.error("user name api error3", error);
                    }
                });
            }
        },
        getBasketItemsInfos (context, itemsIds) {
            if (itemsIds && itemsIds.length) {
                // Api Omeka (items)

                const apiURL = getItemsApiURL(context.getters.apiRoot, itemsIds);
                const keyIdentity = context.getters.identityAndCredentialParameters;
                const currentLanguage = context.state.currentLanguage;

                axios
                    .get(apiURL  + keyIdentity)
                    .then(function (response) {

                        const items = response.data;
                        const n = items.length;
                        let i, item, itemId, firstItemSetId, itemSets, collectionSettings, collectionCode, defaultTitle;
                        let itemsByKey = [], key = "I";

                        for(i=0;i<n;i++) {

                            item = items[i];
                            itemId = parseInt( item['o:id']);

                            defaultTitle = item['o:title'];

                            if (! currentLanguage || (currentLanguage === "") || (currentLanguage === "fr")) {
                                item.title = defaultTitle;
                            }

                            if ((typeof item.title !== "string") || (item.title.length === 0)) {
                                jsonReader.json = item;
                                item.title = jsonReader.getLocalizedMetaDataValue('dcterms:alternative', currentLanguage, defaultTitle);
                            }

                            itemSets = item["o:item_set"];
                            itemsByKey[key + itemId] = item;

                            if (itemSets && itemSets.length)
                            {
                                firstItemSetId = itemSets[0]['o:id'];
                                if (firstItemSetId)
                                {
                                    // La plupart des collections ont des paramètres spécifiques dans "settings.json"
                                    collectionSettings = store.getters.collectionsSettings(firstItemSetId);
                                    collectionCode = store.getters.collectionCode(firstItemSetId);


                                    if (collectionSettings)
                                    {
                                        item.route = collectionSettings.route ? collectionSettings.route : '';
                                        item.type = collectionSettings.itemType;
                                        item.itemset = collectionSettings.id;
                                        item.icon = collectionSettings.icon;
                                        item.colorNo = collectionSettings.colorNo;

                                        if (collectionSettings.encyclopedie) {
                                            item.route = "/encyclopedie" + item.route;
                                        }

                                        // console.log("itemId", itemId, "firstItemSetId", firstItemSetId, collectionSettings, item);
                                    }
                                    else
                                    {
                                        item.route = '';
                                        item.type = "item";
                                        item.itemset = null;
                                        item.icon = null;
                                        item.colorNo = null;
                                    }

                                    item.id = itemId;
                                    item.collectionCode = item.icon ? '' : collectionCode;

                                }
                            }
                        }

                        // On repasse les items du panier en revue
                        const basketItems = context.state.basket;
                        const m = basketItems.length;
                        let basketItem, basketItemId;

                        for(i=0;i<m;i++) {
                            basketItem = basketItems[i];
                            basketItemId = basketItem.id;
                            item = itemsByKey[key + basketItemId];
                            if (item) {
                                basketItem.icon = item.icon;
                                basketItem.itemset = item.itemset;
                                basketItem.colorNo = item.colorNo;
                                basketItem.route = item.route;
                                basketItem.type  = item.type;
                                basketItem.title = item.title;
                            }
                        }

                }).catch(error => {

                    // Cf https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/
                    if (error.response) {
                        // 1. Client received an error response (5xx, 4xx)
                        console.error("user name api error1", error.response);
                    } else if (error.request) {
                        // 2. Client never received a response, or request never left ( network error, cross-domain ...)
                        console.error("user name api error2", error.request);
                    } else {
                        // 3. Anything else
                        console.error("user name api error3", error);
                    }
                });
            }
        },

        addToBasket (context, itemParams) {
            // Api Omeka (selection, sans id, ni label)
            const apiURL = getSelectionResourcesApiURL(context.getters.apiRoot, context.state.currentUser.id);
            const keyIdentity = context.getters.identityAndCredentialParameters;
            const resource = { "o:resource": {"o:id": itemParams.id }};
            axios
                .post(apiURL  + keyIdentity, resource )
                .then(function (response) {

                    const selection = response.data;
                    itemParams.selectionId = selection['o:id'];
                    itemParams.created = mySQLToDateJs ( selection['o:created']['@value'] );

                    context.commit('addToBasket', itemParams);
                    context.dispatch('getBasketItemsInfos', [itemParams.id]);
                });
        },
        removeFromBasket (context, itemParams) {
            // Api Omeka (selection, sans id, ni label)
            const basketItem = context.getters.getBasketFromItemId(itemParams.id, itemParams.type);

            if (basketItem) {
                const apiURL = getSelectionResourcesItemApiURL(context.getters.apiRoot, context.state.currentUser.id, basketItem.selectionId);
                const keyIdentity = context.getters.identityAndCredentialParameters;
                axios
                    .delete(apiURL  + keyIdentity)
                    .then(function (response) {

                        const selection = response.data;
                        itemParams.selectionId = selection['o:id'];

                        context.commit('removeFromBasket', itemParams);
                    });
            }
        },
        clearBasket (context) {
            context.commit('clearBasket');
        },

        // Panier de la Visualisation d'items en cours (ids)
        initToVisualisationBasket (context, itemParams) {
             context.commit('initToVisualisationBasket', itemParams.ids);
        },
        addToVisualisationBasket (context, itemParams) {
            context.commit('addToVisualisationBasket', itemParams.id);
        },
        removeFromVisualisationBasket (context, itemParams) {
            context.commit('removeFromVisualisationBasket', itemParams.id);
        },
        clearVisualisationBasket (context) {
            context.commit('clearVisualisationBasket');
        },

        // Sauvegarde des Paniers de la Visualisation d'items (nom, ids)
        saveSelectionBasket (context, selectionParams) {

            const pos = context.getters.findSelectionBasketPosition(selectionParams.name);
            let success = false, message;
            if ((pos === - 1) || selectionParams.force ) {

                if (pos === - 1) {
                    // Création : POST

                    const postApiURL = getSelectionsApiURL(context.getters.apiRoot, context.state.currentUser.id);
                    const keyIdentity = context.getters.identityAndCredentialParameters;

                    const resources = {
                        "o:resources": selectionParams.resources,
                        "o:label": selectionParams.name
                    };

                    return axios
                        .post(postApiURL  + keyIdentity, resources )
                        .then(function (response) {

                            const selection = response.data;
                            const selectionId = selection['o:id'];
                            const selectionCreated = mySQLToDateJs ( selection['o:created']['@value'] );

                            selectionParams.id = selectionId;
                            selectionParams.created = selectionCreated;

                            context.commit('saveSelectionBasket', selectionParams);

                            success = true;

                            return {success};
                        });

                } else {

                    // Mise à jour : PATCH

                    const basketToUpdate = context.state.selectionBaskets[pos];
                    if (basketToUpdate && basketToUpdate.id) {

                        const basketId = basketToUpdate.id;
                        const patchApiURL = getSelectionsItemApiURL(context.getters.apiRoot, context.state.currentUser.id, basketId);
                        const keyIdentity = context.getters.identityAndCredentialParameters;

                        const resources = { "resources": selectionParams.items };

                        return axios
                            .patch(patchApiURL  + keyIdentity, resources )
                            .then(function (response) {

                                const selection = response.data;
                                const selectionId = selection['o:id'];
                                const selectionModified = mySQLToDateJs ( selection['o:modified']['@value'] );

                                const position = context.getters.findSelectionBasketPosition(selectionParams.name);

                                selectionParams.id = selectionId;
                                selectionParams.position = position;
                                selectionParams.created = selectionModified;

                                context.commit('updateSelectionBasketAtPosition', selectionParams);

                                success = true;

                                return {success};
                            });
                    }
                }

            } else {
                success = false;
                message = "Une sélection du même nom existe déjà... voulez-vous la remplacer ?";
            }
            return new Promise((resolve) => resolve({success, message }));
        },

        deleteSelectionBasket (context, selectionParams) {
            const pos = context.getters.findSelectionBasketPosition(selectionParams.name);
            if (pos !== - 1) {
                const apiURL = getSelectionsItemApiURL(context.getters.apiRoot, context.state.currentUser.id, selectionParams.id);
                const keyIdentity = context.getters.identityAndCredentialParameters;
                return axios
                    .delete (apiURL  + keyIdentity )
                    .then(function (response) {
                        if (response) {
                            context.commit('deleteSelectionBasketAtPosition', pos);
                        }
                    });
            }
        },

        // Recherches
        addSearch (context, searchParams) {

            const pos = context.getters.findSearchNamePosition(searchParams.name);
            let success, message;
            if ((pos === - 1) || searchParams.force ) {

                if (pos === - 1) {
                    // Création

                    const postApiURL = getSelectionsApiURL(context.getters.apiRoot, context.state.currentUser.id);
                    const keyIdentity = context.getters.identityAndCredentialParameters;

                    const resources = {
                        "o:search_query": searchParams.query,
                        "o:label": searchParams.name
                    };

                    return axios
                        .post(postApiURL  + keyIdentity, resources )
                        .then(function (response) {

                            const selection = response.data;
                            const selectionId = selection['o:id'];
                            const selectionCreated = mySQLToDateJs ( selection['o:created']['@value'] );

                            searchParams.id = selectionId;
                            searchParams.created = selectionCreated;

                            context.commit('addSearch', searchParams);

                            success = true;

                            return {success};
                        });

                } else {

                    // Mise à jour : PATCH

                    const searchToUpdate = context.state.searches[pos];
                    if (searchToUpdate && searchToUpdate.id) {

                        const searchId = searchToUpdate.id;
                        const patchApiURL = getSelectionsItemApiURL(context.getters.apiRoot, context.state.currentUser.id, searchId);
                        const keyIdentity = context.getters.identityAndCredentialParameters;

                        const resources = { "o:search_query": searchParams.query };

                        return axios
                            .patch(patchApiURL  + keyIdentity, resources )
                            .then(function (response) {

                                const selection = response.data;
                                const selectionId = selection['o:id'];

                                let selectionModified;
                                if (selection['o:modified']) {
                                    selectionModified = mySQLToDateJs ( selection['o:modified']['@value'] );
                                } else {
                                    selectionModified = mySQLToDateJs ( selection['o:created']['@value'] );
                                }

                                const position = context.getters.findSearchNamePosition(searchParams.name);

                                searchParams.id = selectionId;
                                searchParams.created = selectionModified;
                                searchParams.position = position;

                                context.commit('updateSearchAtPosition', searchParams);

                                success = true;

                                return {success};
                            });
                    }
                }

            } else {
                success = false;
                message = "Attention, une recherche du même nom existe déjà ! voulez-vous la mettre à jour ?";
            }
            return new Promise((resolve) => resolve({success, message }));
        },

        deleteSearch (context, searchParams) {
            const pos = context.getters.findSearchNamePosition(searchParams.name);
            if (pos !== - 1) {
                const apiURL = getSelectionsItemApiURL(context.getters.apiRoot, context.state.currentUser.id, searchParams.id);
                const keyIdentity = context.getters.identityAndCredentialParameters;
                return axios
                    .delete (apiURL  + keyIdentity )
                    .then(function (response) {
                        if (response) {
                            context.commit('deleteSearchAtPosition', pos);
                        }
                });
            }
        },


        // Mémorisation de l'état du Panneau de recherche (facettes)
        updateSearchSettings (context, searchSettingsChanges) {
            context.commit('updateSearchSettingsState', searchSettingsChanges);
        },

        // Mémorisation de l'état du TreeView
        updateTreeViewState (context, treeNodeParams) {
            context.commit('updateTreeViewState', treeNodeParams);
        },

        // Modèles de ressources
        getResourceTemplate(context, templateId) {

            // S'il n'y a pas de modèle de ressources, on renvoie un modèle par défaut :
            if (! templateId ) {
                return new Promise((resolve) => resolve({
                    titleTerm : "dcterms:title",
                    labels : { "dcterms:title": "Titre", "dcterms:description": "Description" },
                    by_position : [["dcterms:title", "dcterms:description"]]
                }));
            }

            // On vérifie que si la template a déjà été téléchargée :
            const storedTemplate = context.state.resourceTemplates[ TEMPLATE_ARRAY_KEY + templateId ];
            if (storedTemplate) {
                return new Promise((resolve) => resolve(storedTemplate) );
            } else {

                // Api Omeka (resource templates)
                const apiURL = getResourceTemplateApiURL(context.getters.apiRoot, templateId);
                const keyIdentity = context.getters.identityAndCredentialParameters;

                return axios
                    .get(apiURL  + keyIdentity)
                    .then(function (response) {

                        const resourceTemplate = response.data;

                        // Champ servant au titre
                        const titleTermInfos = resourceTemplate["o:title_property"];
                        const titleTerm = titleTermInfos ? titleTermInfos["o:term"] : "dcterms:title";

                        // On construit le tableau associatif ( term --> label ) du modèle de ressource de l'item :
                        let resourceTemplateTermLabels = [];

                        // et le tableau de l'ordre des champs par zone
                        let resourceTemplateTermsByPosition = new Array(6);

                        const terms = resourceTemplate['o:resource_template_property'];

                        // console.log("terms", resourceTemplate);

                        if (terms) {
                            let i, term, termInfos, termFieldName, termLabel, termPositionData, termPosition, termPositionAsIndex;
                            for(i=0;i<terms.length;i++)
                            {
                                term = terms[i];
                                termLabel = term['o:alternate_label'];
                                termInfos = term['o:property'];
                                termFieldName = termInfos['o:term'];

                                // On range dans un tableau les différents champs selon la zone d'affichage
                                // [1] => zone 1
                                // [2] => zone 2 ... etc
                                termPositionData = term['o:data'];
                                if (termPositionData && termPositionData.length)
                                {
                                    // console.log("termPositionData", termPositionData);

                                    termPosition = termPositionData[0]['position_theme'];
                                    if (termPosition && termPosition.length) {
                                        termPositionAsIndex = parseInt(termPosition);
                                        if (! resourceTemplateTermsByPosition[termPositionAsIndex]) {
                                            resourceTemplateTermsByPosition[termPositionAsIndex] =  [];
                                        }
                                        resourceTemplateTermsByPosition[termPositionAsIndex].push(termFieldName);
                                    }
                                }

                                if (! termLabel) {
                                    termLabel = termInfos['o:label'];
                                }

                                if (termFieldName) {
                                    resourceTemplateTermLabels[termFieldName] = termLabel;
                                }
                            }
                        }

                        // console.log('resourceTemplateTermsByPosition', resourceTemplateTermsByPosition);

                        const templateData = {
                            titleTerm,
                            labels: resourceTemplateTermLabels,
                            by_position: resourceTemplateTermsByPosition
                        };

                        context.commit('saveResourceTemplate', { id:templateId, data: templateData });

                        return templateData;
                    });
            }
        },

        // Pages du site
        getPagesInfos(context) {

            const sitePages = context.state.sitePages;
            if (sitePages) {
                // Si la liste des pages a déjà été téléchargée, on peut récupérer ses infos (title, id)
                return new Promise((resolve) => resolve(sitePages) );
            }
            else
            {
                // Sinon on récupère la liste des pages via l'api Omeka
                const apiPagesURL = getPagesApiURL(context.getters.apiRoot);
                const keyIdentity = "?" + context.getters.identityAndCredentialParameters.substr(1);

                return axios.get(apiPagesURL + keyIdentity ).then(function (response) {

                    const pagesData = response.data;
                    const n = pagesData.length;
                    let i, pageData, pages = [];

                    for (i = 0; i < n; i++) {
                        pageData = pagesData[i];
                        if (pageData["o:is_public"]) {
                            pages.push({
                                id: pageData["o:id"],
                                title: pageData["o:title"],
                                slug: pageData["o:slug"],
                                route: '/pages/' + pageData["o:slug"],
                                site: pageData["o:site"]["o:id"]
                            })
                        }
                    }

                    context.commit('storePages', pages);

                    return pages;
                });
            }
        },

        // Page du site : slug
        getSitePageInfosFromSlug(context, pageInfos) {
            const pageSlug = pageInfos.slug;
            const siteId = pageInfos.site;
            console.log("store getSitePageInfosFromSlug", pageSlug, siteId, "...");
            const storedPage = context.getters.getPageInfosFromSlug( pageSlug, siteId);
            if (storedPage) {
                // Si la liste des pages a déjà été téléchargée, on peut récupérer ses infos (title, id)
                return new Promise((resolve) => resolve(storedPage) );
            }
            else
            {
                // Sinon on récupère la liste des pages via l'api Omeka
                return context.dispatch("getPagesInfos").then(function () {
                    return context.getters.getPageInfosFromSlug(pageSlug, siteId);
                });
            }
        },
        // Pages du site : id
        getSitePageInfosFromId(context, pageId) {

            // Si la liste des pages a déjà été téléchargée, on peut récupérer ses infos (title, id)
            const storedPage = context.getters.getPageInfosFromId(pageId);
            if (storedPage) {
                return new Promise((resolve) => resolve(storedPage) );
            }
            else
            {
                // Sinon on récupère la liste des pages via l'api Omeka
                return context.dispatch("getPagesInfos").then(function () {
                    return context.getters.getPageInfosFromId(pageId);
                });
            }
        },
    },
    getters : {
        apiRoot(state) {
            return state.settings.paths.api;
        },
        siteId(state) {
            return state.siteParams ? state.siteParams["o:id"] : state.settings.site.id;
        },
        siteNavigation(state) {
            return state.siteParams ? state.siteParams["o:navigation"] : null;
        },
        siteHomepage(state) {
            return state.siteParams ? state.siteParams["o:homepage"]["o:id"] : null;
        },
        downloadRoot(state, getters) {
            return '/s/' + getters.getSitePropFromLang(state.currentLanguage, "slug");
        },
        adminRoot(state) {
            return state.settings.paths.admin;
        },
        isUserConnected(state) {
            return state.currentUser.id !== null;
        },
        userCanEdit(state) {
            return state.currentUser && state.currentUser.role && ["", "guest", "researcher"].indexOf(state.currentUser.role) === -1;
        },
        userId(state) {
            return state.currentUser.id;
        },
        userName(state) {
            return state.currentUser.name;
        },
        userFirstName(state) {
            return state.currentUser.firstName;
        },
        userLastName(state) {
            return state.currentUser.lastName;
        },
        identityAndCredentialParameters(state) {
            return '&key_identity=' + state.currentUser.keyIdentity + '&key_credential=' + state.currentUser.keyCredential;
        },
        getLanguagesFromSettings: (state) => () => {
            const settingsSites = state.settings.sites;
            const languages = [];
            for (let siteLang in settingsSites) {
                languages.push(siteLang);
            }
            return languages;
        },
        getSitePropFromLang: (state) => (lang, prop = "id") => {
            // On connaît la langue, on cherche le site associé
            let sitePropValue;
            const settingsSites = state.settings.sites;
            for (let siteLang in settingsSites) {
                if (! sitePropValue) {
                    // Première valeur : valeur par défaut si la langue passée en paramètre n'est pas trouvée
                    sitePropValue = settingsSites[siteLang][prop] ? settingsSites[siteLang][prop] : settingsSites[siteLang];
                }
                if (siteLang === lang) {
                    sitePropValue = settingsSites[siteLang][prop] ? settingsSites[siteLang][prop] : settingsSites[siteLang];
                    break;
                }
            }

            return sitePropValue;
        },
        translation: (state) => (term) => {
            const dictionary = state.langCache[state.currentLanguage];
            if (dictionary) {
                const termTranslation = dictionary.terms[term];
                // console.log('dictionary', state.currentLanguage, dictionary.terms, term, termTranslation);
                if (termTranslation !== undefined) {
                    return termTranslation;
                }
            }
            return term; // French term by default
        },

        //
        // Les routes déclarées dans settings.json sont ajoutées au routes du Router Vue :
        //

        routesFromSettings: (state) => () => {
            const collections = state.settings.collections;
            const routes = [];
            const itemsTypes = ["item", "article", "extrait"];

            if (collections) {
                let i, collection, collectionRouteName, collectionItemRouteName, collectionMetas;
                for(i=0;i<collections.length;i++)
                {
                    collection = collections[i];
                    // console.log(collection.route, collection.name);
                    if (collection.route !== undefined)
                    {
                        if (collection.route === "encyclopedie") continue;
                        if (!collection.route || collection.route.length === 0) continue;

                        // On vérifie que la route commence par un slash
                        if (collection.route.substr(0, 1) !== "/") {
                            collection.route = "/" + collection.route
                        }

                        // On vérifie que la route est associée à un type de vue
                        if (! collection.views) {
                            collection.views = "list-item";
                        }

                        if (! collection.defaultView) {
                            collection.defaultView = "list";
                        }

                        if ((! collection.itemType) || itemsTypes.indexOf(collection.itemType) === - 1) {
                            collection.itemType = "item";
                        }

                        // Nom de la route qui liste les items de la collection
                        collectionRouteName = capitalizeFirstChar(collection.route.split('/').join(''));
                        collectionMetas = { collectionId: collection.id, collectionRouteName }

                        // Nom de la route qui affiche la notice d'un item de la collection
                        collectionItemRouteName = collectionRouteName + ' Item';

                        // On mémorise le nom des routes dans les settings
                        collection.routeName = collectionRouteName;
                        collection.itemRouteName = collectionItemRouteName;

                        // Route de la collection
                        routes.push({
                            path: collection.route,
                            name: collectionRouteName,
                            component: CollectionFromSettings,
                            meta: collectionMetas
                        });

                        // Route de l'item
                        routes.push({
                            path: collection.route + '/item/:id',
                            name: collectionItemRouteName,
                            component: CollectionFromSettings,
                            meta: collectionMetas
                        });
                    }
                }
            }
            return routes;
        },
        encyclopedieCollectionInfos: (state) => () => {
            return state.encyclopedieCollections;
        },
        encyclopedieCollectionIds: (state) => () => {
            const collections = state.encyclopedieCollections;
            const collectionIds = [];
            if (collections) {
                let i, collection;
                for(i=0;i<collections.length;i++)
                {
                    collection = collections[i];
                    if (collection.id) {
                        collectionIds.push(parseInt(collection.id));
                    }
                }
            }
            return collectionIds;
        },
        facetsCollectionInfos: (state) => () => {
            return state.facetsCollections;
        },
        facetsCollectionIds: (state) => () => {
            const collections = state.facetsCollections;
            const collectionIds = [];
            if (collections) {
                let i, collection;
                for(i=0;i<collections.length;i++)
                {
                    collection = collections[i];
                    if (collection.id) {
                        collectionIds.push(parseInt(collection.id));
                    }
                }
            }
            return collectionIds;
        },
        searchCollectionsIds: (state, getters) => () => {
            // Ids des collections apparaissant dans le filtres de recherche
            return getters.encyclopedieCollectionIds().concat( getters.facetsCollectionIds() );
        },
        searchCollectionsQueryParameters: (state, getters) => () => {
            let query = "";
            const collectionsIds = getters.searchCollectionsIds();
            if (collectionsIds) {
                const n = collectionsIds.length;
                let i;
                for(i=0;i<n;i++) {
                    query += '&item_set_id[]=' + collectionsIds[i];
                }
            }
            return query;
        },

        collectionInfosByProp: (state) => (property = "route", value) => {
            const collections = state.settings.collections
            if (collections) {
                let i, collection;
                for(i=0;i<collections.length;i++)
                {
                    collection = collections[i];
                    if (collection[property] === value) {
                        return collection
                    }
                }
            }
        },
        collectionIdsByProp: (state) => (property = "itemType", value) => {
            const collections = state.settings.collections;
            const collectionIds = [];
            if (collections) {
                let i, collection;
                for(i=0;i<collections.length;i++)
                {
                    collection = collections[i];
                    // console.log("collectionIdsByProp", collection.id, collection[property]);
                    if (collection[property] === value) {
                        collectionIds.push(parseInt(collection.id));
                    }
                }
            }
            return collectionIds;
        },
        linkTypeTerms: (state) => () => {
            const terms = []; // Tableau linéaire des termes
            if (Array.isArray(state.linkTypes)) {
                const n = state.linkTypes.length;
                let i, linkType;
                for(i=0; i<n; i++) {
                    linkType = state.linkTypes[i];
                    if (linkType.term) {
                        terms.push(linkType.term);
                    }
                }
            }
            return terms;
        },
        linkTypeLabels: (state) => () => {
            const labels = []; // Tableau associatif terme => libellé
            if (Array.isArray(state.linkTypes)) {
                const n = state.linkTypes.length;
                let i, linkType, linkTypeTerm;
                for(i=0; i<n; i++) {
                    linkType = state.linkTypes[i];
                    if (linkType.term) {
                        linkTypeTerm = linkType.term;
                        labels[linkTypeTerm] = linkType.title ? linkType.title : "Libellé du type de lien non défini"
                    }
                }
            }
            return labels;
        },

        // Données des collections issues des settings
        collectionsSettings: (state) => (collectionId, property = null) => {
            const collectionSettings = state.settingsByCollectionId[COLLECTION_ARRAY_KEY + collectionId];
            if (collectionSettings) {
                return property ? collectionSettings[property] : collectionSettings;
            }
        },
        collectionItemRouteNameByItemType: (state) => (itemType = 'item') => {
            const collectionsSettings = state.settings.collections;
            const n= collectionsSettings.length;
            let i, collectionSettings;
            for (i=0; i<n;i++) {
                collectionSettings = collectionsSettings[i];
                if (collectionSettings.itemType === itemType) {
                    return collectionSettings.itemRouteName;
                }
            }
            return "Item";
        },

        // Issus des Json des collections (api Omeka)
        collectionsInfos: (state) => (collectionFieldName, keyPrefix = "c") => {
            let infos = [];
            const collections = state.collections;
            if (collections) {
                let category;
                for (category in collections) {
                    if (Object.prototype.hasOwnProperty.call(collections, category)) {
                        const collectionsOfCategory = collections[category].collections;
                        let i, n = collectionsOfCategory.length, collection;
                        for(i=0;i<n;i++) {
                            collection = collectionsOfCategory[i];
                            infos[keyPrefix + collection.id] = collection[collectionFieldName];
                        }
                    }
                }
            }
            return infos;
        },
        collectionCode: (state, getters) => (collectionId) => {
            let code = getters.collectionsSettings(collectionId, 'code');
            if (code === undefined) {
                code = getters.collectionTitle(collectionId).substring(0, 2)
            }
            return '[' + code + ']';
       },
       collectionTitle: (state) => (collectionId, term = "dcterms:title") => {
           let titlesObj, titleObj, title, titleLanguage, n;
           const currentLanguage = state.currentLanguage;
           const collections = state.collections;
           if (collections) {
               let i, collectionJson;
               for(i=0;i<collections.length;i++)
               {
                   collectionJson = collections[i];
                   if (collectionJson['o:id'] === collectionId) {
                       titlesObj = collectionJson[term];
                       if (Array.isArray(titlesObj)) {
                           n = titlesObj.length;
                           for (let j = 0; j < n; j++) {
                               titleObj = titlesObj[j];
                               titleLanguage = titleObj['@language'];
                               if (titleLanguage && (titleLanguage.substr(0,2).toLowerCase() === currentLanguage)) {
                                   title = titleObj['@value'];
                               }
                           }
                           if (! title && (n > 0)) {
                               title = titlesObj[0]['@value']
                           }
                       }

                       if (typeof title !== "string") {
                           title = collectionJson['o:title'];

                       }

                       return title;
                   }
                }
            }
            return "";
        },
        collectionsTitles: (state) => (keyPrefix = "c") => {
           let infos = [];
            const collections = state.collections;
            if (collections) {
                let i, collectionJson;
                for(i=0;i<collections.length;i++) {
                    collectionJson = collections[i];
                    infos[keyPrefix + collectionJson['o:id']] = collectionJson['o:title'];
                }
            }
            return infos;
        },
        localizedCollectionsTitles: (state) => (term = "dcterms:title", keyPrefix = "c") => {
           let infos = [], titlesObj, titleObj, title, titleLanguage, n;
           const currentLanguage = state.currentLanguage;
            const collections = state.collections;
            if (collections) {
                let i, collectionJson;
                for(i=0;i<collections.length;i++)
                {
                    title = null;
                    collectionJson = collections[i];
                    titlesObj = collectionJson[term];
                    if (Array.isArray(titlesObj)) {
                        n = titlesObj.length;
                        for (let j = 0; j < n; j++) {
                            titleObj = titlesObj[j];
                            titleLanguage = titleObj['@language'];
                            if (titleLanguage && (titleLanguage.substr(0,2).toLowerCase() === currentLanguage)) {
                                title = titleObj['@value'];
                            }
                        }
                        if (! title && (n > 0)) {
                            title = titlesObj[0]['@value']
                        }
                    }

                    if (typeof title !== "string") {
                        title = collectionJson['o:title'];
                    }

                    // Tableau associative
                    infos[keyPrefix + collectionJson['o:id']] = title;
                }
            }
            return infos;
        },

        collectionJson: (state) => (collectionId) => {
            const collections = state.collections;
            const collectionIdInt = parseInt(collectionId);
            if (collections) {
                let i, collectionJson;
                for(i=0;i<collections.length;i++) {
                    collectionJson = collections[i];
                    if (collectionJson['o:id'] === collectionIdInt) {
                        return collectionJson;
                    }
                }
            }
        },
        collectionMetaDataSettings: (state) => (collectionId) => {
            const collections = state.collections;
            if (collections) {
                let category;
                for (category in collections) {
                    if (Object.prototype.hasOwnProperty.call(collections, category)) {
                        const collectionsOfCategory = collections[category].collections;
                        let i, n = collectionsOfCategory.length, collection;
                        for(i=0;i<n;i++) {
                            collection = collectionsOfCategory[i];
                            if (collectionId === parseInt(collection.id)) {
                                return collection.notice;
                            }
                        }
                    }
                }
            }
        },
        curatedCollections: (state) => () => {
            const collections = state.collections;
            let curatedCollections = [];
            if (collections)
            {
                let i, collectionJson;
                for(i=0;i<collections.length;i++)
                {
                    collectionJson = collections[i];
                    if (Array.isArray(collectionJson['curation:selected']))
                    {

                        curatedCollections.push(collectionJson);
                    }
                }
            }
            return curatedCollections;
        },

        getPageInfosFromSlug: (state) => (slug = 'accueil', siteId = 1) => {
            const pages = state.sitePages;
            let i, pageInfos;
            console.log("getPageInfosFromSlug", slug, siteId);
            if (pages) {
                for(i=0;i<pages.length;i++) {
                    pageInfos = pages[i];
                    if ((pageInfos.slug === slug) && (pageInfos.site === siteId)) {
                        return pageInfos;
                    }
                }
            }
        },
        getPageInfosFromId: (state) => (id) => {
            const pages = state.sitePages;
            let i, pageInfos;
            if (pages) {
                for(i=0;i<pages.length;i++) {
                    pageInfos = pages[i];
                    if (pageInfos.id === id) {
                        return pageInfos;
                    }
                }
            }
        },

        // https://vuex.vuejs.org/guide/actions.html#dispatching-actions
        getVisualisationBasketCount: (state) => () => {
            return state.visualizationBasket.length;
        },
        getVisualisationBasketForApi: (state) => () => {
            const n = state.visualizationBasket.length;
            let i, items = [];
            for(i=0;i<n;i++) {
                items.push({
                    "o:id" : state.visualizationBasket[i]
                });
            }
            return items;
        },

        getBasketItemsIds: (state) => () => {
            const n = state.basket.length;
            let i, basketItem, ids = [];
            for(i=0;i<n;i++) {
                basketItem = state.basket[i];
                ids.push( parseInt(basketItem.id));
            }
            return ids;
        },
        getBasketFromItemId: (state) => (itemId, itemType = "item") => {
            const n = state.basket.length;
            let i, basketItem, basketItemId, basketItemType;
            for(i=0;i<n;i++) {
                basketItem = state.basket[i];
                basketItemId = basketItem.id;
                basketItemType = basketItem.type
                // Un article est aussi un item du point de vue du panier
                if (basketItemType === "article") {
                    basketItemType = "item";
                }
                if ((basketItemId === itemId) && (basketItemType === itemType)) {
                    return basketItem;
                }
            }
            return null;
        },
        itemInBasket: (state) => (itemId, entityType = "item") => {

            const n = state.basket.length;
            let i, basketItem, basketItemId, basketItemType;
            for(i=0;i<n;i++) {
                basketItem = state.basket[i];
                basketItemId = basketItem.id;
                basketItemType = basketItem.type
                // Un article est aussi un item du point de vue du panier
                if (basketItemType === "article") {
                    basketItemType = "item";
                }
                if ((basketItemId === itemId) && (basketItemType === entityType)) {
                    return true;
                }
            }
            return false;
        },
        findSelectionBasketPosition: (state) => (selectionName) => {
            const n = state.selectionBaskets.length;
            let i, selection;
            for(i=0;i<n;i++) {
                selection = state.selectionBaskets[i];
                if (selection.name === selectionName) {
                    return i;
                }
            }
            return -1;
        },
        findSearchNamePosition: (state) => (name) => {
            const n = state.searches.length;
            let i, searchItem;
            for(i=0;i<n;i++) {
                searchItem = state.searches[i];
                if (searchItem.name === name) {
                    return i;
                }
            }
            return -1;
        },
        treeViewNodeOpened: (state) => (treeNodeId) => {
            return state.treeViewState.indexOf(treeNodeId) !== -1;
        },
    }
});



//
// Language Localization - i18n - https://vue-i18n.intlify.dev/guide/
//

const messages = {
    fr: {
    }
};

export const i18n = createI18n({
    locale: 'fr', // set locale
    fallbackLocale: 'fr',
    messages
});

function setI18nLanguage (store, i18n, locale) {
    i18n.global.locale = locale;
    store.dispatch('updateCurrentLanguage', locale);
}

export function loadLanguageAsync (store, i18n, locale) {
    if (store.state.loadedLanguages.includes(locale)) {
        if (i18n.locale !== locale) setI18nLanguage(store, i18n, locale);
        return Promise.resolve();
    }
    return store.dispatch('getDictionary', locale).then(function(response){
        store.state.loadedLanguages.push(locale);
        i18n.global.setLocaleMessage(locale, response.data.terms);
        setI18nLanguage (store, i18n, locale);
    });
}


//
// Vue App
//

const app = createApp(App);
app.use(store);
app.use(i18n);

app.config.globalProperties.axios = axios;

function getSettings( settingsURL, firstTry = true ) {

    axios.get(settingsURL)
        .then(function (response) {

            // console.log("settings are loaded", settingsURL, response.data);

            //
            // Enregistrement des settings dans le store
            //

            store.commit('storeSettings', response.data);

            let publicPath;

            if (process.env.NODE_ENV === 'production') {
                publicPath = store.state.publicPath;
                console.log("[SETTINGS] publicPath PROD (JSON)", publicPath);
            } else {
                publicPath = serverPath;
                console.log("[SETTINGS] publicPath DEV (from JS location)", publicPath);
            }

            __webpack_public_path__ = getCurrentJsOriginPath() + serverPath;
            // console.log("[SETTINGS] __webpack_public_path__", __webpack_public_path__);


            //
            // Récupération des paramètres des sites
            //

            const siteApiURL = getSitesApiURL(store.getters.apiRoot);
            store.dispatch('getApi', siteApiURL).then(function(response){
                store.commit('storeSitesParams', response.data );
            });

            //
            // Récupération des langues
            //




            //
            // Instantiation du router
            //

            const router = createRouterInstance(publicPath, store.getters.routesFromSettings());
            app.use(router);

            router.beforeEach((to, from, next) => {

                if ((to.name.indexOf('UserAccount') === 0) && (! store.state.currentUser.logged === true)) {
                    next({name: 'Home Login'});
                }
                else if (to.path === '/item')
                {
                    // Les requêtes de types : /item?property[0][property]=dcterms:subject&property[0][type]=eq&property[0][text]=architecture
                    // sont renvoyées vers le moteur de recherche :

                    let s_parts = [];

                    const queryProperty = to.query['property[0][property]'];
                    if (queryProperty) {
                        s_parts.push('property[0][property]=' + queryProperty);
                    }

                    const queryType = to.query['property[0][type]'];
                    if (queryType) {
                        s_parts.push('property[0][type]=' + queryType);
                    }

                    const queryText = to.query['property[0][text]'];
                    if (queryText) {
                        s_parts.push('property[0][text]=' + queryText);
                    }

                    const s = s_parts.join("&");

                    next({ name: 'Recherche Avancée', params: { s }, replace: true });

                }
                else if (to.name === 'Omeka Curated ItemSet')
                {
                    next({ name: 'Curated ItemSet', params: to.params });
                }
                else
                {

                    // Prise en compte des ancres HTML
                    if (to.hash && (to.name === from.name) ) {
                        next(false);

                    } else if (!to.query.lang && (store.state.currentLanguage !== 'fr')) {

                        // Ajout de la langue courante (si elle manque et si ce n'est pas le français)
                        // Cf https://github.com/vuejs/vue-router/issues/934
                        to.query.lang = store.state.currentLanguage;
                        next({ path: to.path, query: to.query, params: to.params });

                    } else {
                        next();
                    }
                }

            });

            // Prise en compte de la langue dans l'URL
            router.beforeResolve(async to => {
                // Chargement du fichier json de la langue
                let langInQuery = to.query.lang;
                if (!langInQuery || langInQuery.length === 0) {
                    langInQuery = "fr";
                }
                if (langInQuery !== store.state.currentLanguage || ! store.state.langCache[langInQuery]) {
                    loadLanguageAsync(store, i18n, langInQuery);
                }
            });



            //
            // Chargement de l'application
            //

            app.mount('#app');


            //
            // Utilisateur
            //

            const userStr = localStorage.getItem(WEB_STORAGE_KEY_FOR_USER_INFOS);
            if (userStr) {
                const userInfos = JSON.parse(userStr);
                const now = new Date();
                if (now.getTime() > userInfos.expiry) {
                    localStorage.removeItem(WEB_STORAGE_KEY_FOR_USER_INFOS);
                    // console.log("Connexion expirée");
                } else {
                    // Récupération des données stockées dans le Web storage :
                    userInfos.loggedMethod = 'localStorage';
                    store.dispatch("storeCurrentUser", userInfos);
                    // console.log("Connexion restaurée", userInfos);
                }
            } else {
                // console.log("Pas connexion mémorisée");
            }

        })
        .catch(() => {
            if (! store.state.settings && firstTry) {
                getSettings( serverPath + settingsPath + 'settings.json', false );
            }
        });
}

getSettings( dataPath + settingsPath + 'settings.json' );
// getSettings( 'https://halbwachs.sempiternelia.com/files/encyclopedia/settings.json' );


