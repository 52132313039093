<template>
  <div class="collection-description"
       v-html="descriptionsContents"
  />
</template>

<script>

  import {getLocalizedValue} from "../../js/functions";
  import {useStore} from "vuex";
  import {onMounted, ref, watch} from "vue";

  export default {
    name: 'CollectionDescription',
    props: {
      descriptions: {
        type: Array,
        required: true
      },
      maxChars: {
        type:Number,
        required: false,
        default:200
      }
    },
    data() {
      return {
        contentFollowUp:null,
        opened:false,
      }
    },
    setup(props) {

      const store = useStore();

      var descriptionsContents = ref('');

      const updateContent = function() {
        const description = getLocalizedValue(props.descriptions, store.state.currentLanguage);
        descriptionsContents.value = '<ul><li>' + description + '</li></ul>';
      };

      onMounted(() => {
        updateContent();
      });

      watch(() => store.state.currentLanguage, () => {
        updateContent();
      });

      return {
        descriptionsContents
      }
    }
  }
</script>
