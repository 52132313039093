<template>
  <div class="inscription-menu-parent popup-parent">
    <div class="inscription-menu-bg"></div>
    <div class="connexion-menu inscription-menu">
      <div class="connexion-menu-close">
        <a href="#" @click="closeWindow($event)">{{ $t("Fermer") }}</a>
      </div>
      <div class="form-parent">
        <form @submit="checkForm">

          <h2>Créer un compte pour contribuer</h2>

          <div class="inscription-menu-intro">
            <span>En créant un compte vous pouvez :</span>
            <ul>
              <li>- ajouter des items à votre panier</li>
              <li>- organiser les items dans votre panier</li>
              <li>- annoter les items dans votre panier</li>
              <li>- contribuer à la recherche en créant des annotations aux notices</li>
            </ul>
          </div>

          <div class="inscription-form-fields">
            <div class="form-fields">
              <label for="username">{{ $t("Email") }} <span class="required">*</span></label>
              <input type="text" id="username" placeholder="Mon identifiant" v-model="state.email">
              <ul class="inscription-form-errors">
                <li v-for="item in state.errorMessages" v-bind:key="item">{{ item }}</li>
              </ul>
            </div>
            <div>
              <div class="form-fields">
                <label for="password">{{ $t("Mot de passe") }} <span class="required">*</span></label>
                <input type="password" id="password" placeholder="password" v-model="state.userpassword">
              </div>
              <p>Le meilleur est une phrase complète. C’est facile à retenir et assez long pour être très sécurisé.</p>
            </div>
          </div>

          <p class="contact-warning">
            En envoyant ce mail vous acceptez les conditions générales et acceptez que ce site utilise votre adresse mail afin de vous répondre.
          </p>

          <div class="form-buttons">
            <input type="submit" :value="$t('Créer un compte')" />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

  import axios from "axios";
  import {useStore} from "vuex";
  import {reactive} from "vue";
  import {getRegisterApiURL} from "../../js/api";

  export default {
    name: 'RegistrationWindow',
    emits:[
      'close-register-window',
      'open-register-window',
      'register-success'
    ],
    methods: {
      closeWindow($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.$emit('close-register-window');
      },
      openRegisterWindow($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.$emit('open-register-window');
      },
    },
    setup(props, context) {

      const store = useStore();

      const register = function() {

        // Api Omeka (Guest Api Module)
        const apiURL = getRegisterApiURL(store.getters.apiRoot);

        axios.post(apiURL, {
          "email": state.email,
          "password": state.userpassword
        }).then(function (response) {

          const responseData = response.data;

          if (responseData.status === "success") {

            /* SUCCES */

            // Un message est renvoyé par l'api, ainsi que le status="success"
            // message: "Thank you for registering. Please check your email for a confirmation message. Once you have confirmed your request, you will be able to log in."

              state.errorMessages = [];

              // On vide les données du l'utilisateur connecté éventuel
              store.dispatch('logout');

              context.emit("register-success");

          } else if (response.status === "error") {

            /* ERROR */
            state.errorMessages = [ responseData.message ];

          }

        }).catch(error => {

          // Cf https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/

          if (error.response) {

            // 1. Client received an error response (5xx, 4xx)
            // console.error("register error1", error.response);

            const errorsObject = error.response.data.errors;
            let i, errorType, errorsArray, errorsCount;
            let errorMessages = [];

            for (errorType in errorsObject) {
              if (Object.prototype.hasOwnProperty.call(errorsObject, errorType)) {
                errorsArray = errorsObject[errorType];
                errorsCount = errorsArray.length;
                for(i=0;i<errorsCount;i++) {
                  errorMessages.push(errorsArray[i]);
                }
              }
            }

            state.errorMessages = errorMessages;

          } else if (error.request) {
            // 2. Client never received a response, or request never left ( network error, cross-domain ...)
            console.error("register error2", error.request);
          } else {
            // 3. Anything else
            console.error("register error3", error);
          }

        });

      };

      const checkEmail = function (email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      };

      const checkForm = function ($event) {
          $event.preventDefault();
          if (state.email && (state.email.length > 5) && checkEmail(state.email)  && state.userpassword ) {
            register();
          }
      };

      const state = reactive({
        email: '',
        userpassword: '',
        errorMessages:[]
      });

      return {
        state,
        checkForm
      }
    }
  }

</script>
