<template>

  <div>

    <!-- Pagination Top -->
    <div class="list-pagination top-list-pagination with-padding">
      <div class="results-count"></div>
      <div class="pagination-sort-options">
      </div>
      <ListPagination
              v-if="state.pageCount > 1"
              :pageCount="state.pageCount"
              :pageNo="state.pageNo"
              @change-pagination-page="displayPage"
      />
    </div>

    <div class="list-and-filter-panel with-padding">

      <!-- Liste des collections -->
      <ul
              v-if="filterPanel"
              class="filter-panel"
      >
        <li class="selector-previous-button">
          <a href="#" @click="selectPreviousItemInMobileMenu($event)"></a>
        </li>

        <li
          v-for="item in filterPanelOptions"
          v-bind:key="item"
          :class="filterCssClass(item)"
        >
          <a href="#"
             @click="selectFilter($event, item)"
          >{{ item.title }}</a>
        </li>

        <li class="selector-next-button">
          <a href="#" @click="selectNextItemInMobileMenu($event)"></a>
        </li>
      </ul>

      <!-- References -->
      <ul class="list-items">
        <li>
          <ListReferenceItems
                  v-for="reference in state.displayedReferences"
                  v-bind:key="reference.id"
                  :title="reference.val"
                  :items="reference.resources"
                  :titleFieldName="titleFieldName"
                  :queryRouteName="queryRouteName"
                  @opendetailednotice="openDetailedNotice"
                  @downloaditemnotice="openDownloadNoticePopUp"
                  @openitemvisualization="openItemVisualization"
          />
        </li>
      </ul>
    </div>

    <!-- Pagination Bottom -->
    <div class="list-pagination bottom-list-pagination with-padding" v-if="state.pageCount > 0">
      <div class="pagination-sort-options">
      </div>
      <ListPagination
              v-if="state.pageCount > 1"
              v-bind:pageCount="state.pageCount"
              v-bind:pageNo="state.pageNo"
              @change-pagination-page="displayPage"
      />
    </div>
  </div>

</template>

<script>

  import { reactive, computed } from 'vue'
  import ListPagination from './ListPagination';
  import ListReferenceItems  from './ListReferenceItems';

  const itemsByPage = 15;

  export default {
    name: 'ListReferences',
    components: {
      ListPagination,
      ListReferenceItems
    },
    props: {
      items: {
        type: Array,
        required: true
      },
      titleFieldName: {
        type:String,
        required: true,
        default:'o:title'
      },
      filterPanel: {
        type: Boolean,
        required: true,
        default: false
      },
      filterPanelOptions: {
        type: Array,
        required: false
      },
      activeFilterId: {
        type: Number,
        required: false,
        default:0
      },
      queryRouteName: {
        type: String,
        required: false
      },
    },
    data() {
      return {
        isSortMenuOpened:false
      }
    },
    emits: [
      "openitem",
      "openitemvisualization",
      "opendetailednotice",
      "downloaditemnotice",
      "selectfilter",
      "nextfilter",
      "previousfilter",
    ],
    setup(props) {

      //
      // State
      //

      const state = reactive({
        pageNo: 1,
        pageCount: computed(() => props.items ? Math.ceil( props.items.length / itemsByPage ) : 0),
        displayedReferences: computed( () => {
          if (! props.items) {
            return [];
          } else {
            const startItemNo = itemsByPage * (state.pageNo - 1);
            const endItemNo = Math.min(startItemNo + itemsByPage, props.items.length);
            let i, items = [], item;
            for(i=startItemNo;i<endItemNo;i++) {
              item = props.items[i];
              item.id = i + 1;
              items.push(item);
            }
            return items;
          }
        }),
        activeFilterId: props.activeFilterId
      });


      return {
        state
      }
    },
    computed:{
      currentRoutePath() {
        return this.$route.path;
      }
    },
    methods:{
      listItemCssClass(item) {
        return item.colorNo ? "list-item-category-" + item.colorNo : "";
      },
      clickItem($event, itemId) {
        $event.preventDefault();
        this.$emit('openitem', itemId);
      },
      displayPage(pageNo) {
        this.state.pageNo = pageNo
      },
      openDetailedNotice(itemId, itemType) {
        // EVENT --> PARENT
        this.$emit('opendetailednotice', itemId, itemType);
      },
      openItemVisualization(itemId) {
        // EVENT --> PARENT
        this.$emit('openitemvisualization', itemId);
      },
      openDownloadNoticePopUp(item) {
        // EVENT --> PARENT
        this.$emit('downloaditemnotice', item);
      },
      selectFilter($event, item) {
        $event.preventDefault();
        this.state.activeFilterId = item.id;
        // EVENT --> PARENT
        this.$emit('selectfilter', item.id);
      },
      filterCssClass(item) {
        return this.state.activeFilterId === item.id ? 'active' : '';
      },
      selectPreviousItemInMobileMenu($event) {
        $event.preventDefault();
        this.$emit('previousfilter', this.state.activeFilterId);
      },
      selectNextItemInMobileMenu($event) {
        $event.preventDefault();
        this.$emit('nextfilter', this.state.activeFilterId);
      }
  }
}
</script>
