<template>
  <div>

    <!-- Pagination Top -->
    <div v-if="state.pageCount > 0" class="list-pagination top-list-pagination">
      <div class="pagination-sort-options">
        <ListSortMenu
            v-if="showSortOptions"
                :notice-item-type="state.defaultNoticeItemType"
                :sortByParam="state.sortByParam"
                :sortOrderParam="state.sortOrderParam"
                :resetQuery="state.resetQuery"
                @changesortquery="changeSortQuery"
        />
      </div>
      <ListCount
              :element-count="state.itemsCount"
              class="cards-count"
      />
      <ListPagination
              v-if="state.pageCount > 1"
              :pageCount="state.pageCount"
              :pageNo="state.pageNo"
              @change-pagination-page="displayPage"
      />
    </div>

    <Loading v-if="state.isLoading"
      class=" with-padding"
    />

    <div class="treeview with-padding">
      <TreeNode
          v-if="!state.isLoading && state.nodes && state.nodes.name"
          :node="state.nodes"
          :itemRouteName="itemRouteName"
          :opened=true
          :path="mainNodePath()"
          :show-item-graph-button="showItemGraphButton"
          :show-item-notice-button="showItemNoticeButton"
          :parent-item-title-as-toggle="true"
          @opendetailednotice="openDetailedNotice"
          @downloaditemnotice="openDownloadNoticePopUp"
      />
    </div>

    <!-- Pagination Bottom -->
    <div class="list-pagination bottom-list-pagination" v-if="!state.isLoading && (state.pageCount > 0)">
      <div class="pagination-sort-options">
      </div>
      <ListPagination
              v-if="state.pageCount > 1"
              :pageCount="state.pageCount"
              :pageNo="state.pageNo"
              @change-pagination-page="displayPage"
      />
    </div>

  </div>
</template>

<script>

  import {computed, onMounted, reactive, watch} from "vue";
  import { useStore } from "vuex";
  import {getTreeApiURL, getTreeRootNodesCountApiURL, getItemsApiURL} from "../../js/api";

  import TreeNode from "./TreeNode";
  import ListPagination from '../Lists/ListPagination';
  import ListCount from "../Lists/ListCount";
  import ListSortMenu from "../Lists/ListSortMenu";
  import Loading from "../../components/Loading";
  import {JsonReader} from "../../js/jsonReader";

  export default {
    name: 'TreeView',
    components: {
      TreeNode,
      Loading,
      ListPagination,
      ListCount,
      ListSortMenu
    },
    props: {
      itemSetId: {
        type: Number,
        required: true,
        default:0
      },
      pageNo: {
        type: Number,
        required: false,
        default:1
      },
      itemsByPage: {
        type: Number,
        required: false,
        default:10
      },
      itemsCount: {
        type: Number,
        required: false,
        default:0
      },
      itemRouteName: {
        type: String,
        required: false,
        default:'Item'
      },
      showItemGraphButton: {
        type: Boolean,
        required: false,
        default:false
      },
      showItemNoticeButton: {
        type: Boolean,
        required: false,
        default:false
      },
      showSortOptions: {
        type: Boolean,
        required: false,
        default:true
      },
    },
    methods : {
      openDetailedNotice(itemId) {
        // EVENT --> PARENT
        this.$emit('opendetailednotice', itemId);
      },
      openDownloadNoticePopUp(item) {
        // EVENT --> PARENT
        console.log('downloadItemNotice 2', item);

        this.$emit('downloaditemnotice', item);
      },
      displayPage(pageNo) {

        let routeCopy = {
          name : this.$route.name,
          query : { ...this.$route.query },
          params : { ...this.$route.params }
        };

        // Nouvelle page
        routeCopy.query.p = pageNo;

        this.$router.push(routeCopy);
      },

    },
    emits: [
      "opendetailednotice",
      "downloaditemnotice",
      "data-loaded"
    ],
    setup(props, context) {

      const store = useStore();
      const jsonReader = new JsonReader();

      const emit = function(json) {
        context.emit("data-loaded", json);
      };

      // Loads root items (without their children) to compute pageCount
      const loadRootNodesCount = function() {

        // Appel à l'api
        const apiURL = getTreeRootNodesCountApiURL(store.getters.apiRoot, props.itemSetId);

        store.dispatch('getApi', apiURL).then(function(response){
          state.rootNodesCount = response.data.total;
        });

      };

      // Tree Api : load items of current page with children
      const loadTreeItems = function() {

        state.isLoading = true;

        // Appel à l'api
        const apiURL = getTreeApiURL(store.getters.apiRoot, props.itemSetId, props.pageNo, props.itemsByPage);

        store.dispatch('getApi', apiURL).then(function(response){
          state.nodes = response.data;
          state.isLoading = false;

          // Chargement des items pour pouvoir récupérer les traductions
          loadItems();
        });

      };

      // Retrieves nodes id recursively
      const getItemIdsFromNodes = function(nodes, ids) {
        let i, node;

        for(i=0; i< nodes.length; i++) {
          node = nodes[i];
          saveNodeReference(node);

          ids.push(node.id);

          // Récursivité :
          getItemIdsFromNodes(node.children, ids);
        }
        return ids;
      }

      const saveNodeReference = function(node) {
        state.nodesReferences['node' + node.id] = node;
      }

      const updateNodeTitle = function(language = 'fr') {
        let prop, node;
        for(prop in state.nodesReferences) {
          node = state.nodesReferences[prop];
          jsonReader.json = node.details;
          node.title = jsonReader.getLocalizedMetaDataValue('dcterms:alternative', language, node.defaultTitle);
        }
      }

      const getNodeReference = function(nodeId) {
        return state.nodesReferences['node' + nodeId];
      }

      // Items Api : load items of current page (for translations)
      const loadItems = function() {

        state.isLoading = true;

        // On doit lister tous les noeuds :
        // - pour récupérer les ids des items à traduire
        // - pour pouvoir ajouter les traductions à chaque noeud une fois l'api des items appelée

        // On stocke donc : les ids, mais aussi les références aux noeuds (du json du TreeView) pour pouvoir les mettre à jour.
        // On les stocke dans un tableau associatif :
        state.nodesReferences = [];

        const rootNode = state.nodes;

        // On stocke la référence au noeud principal
        saveNodeReference(rootNode);

        // On récupère les ids de l'ensemble des noeuds de la page actuelle
        const itemsIds = getItemIdsFromNodes(state.nodes.children, [rootNode.id]);

        // Appel à l'api
        const apiURL = getItemsApiURL(store.getters.apiRoot, itemsIds);

        store.dispatch('getApi', apiURL).then(function(response){
          state.isLoading = false;

          // On met à jour l'ensemble des noeuds du treeView
          const itemsDetails = response.data;
          let i, n = itemsDetails.length, itemObj, itemId, node;


          for(i=0; i<n; i++) {
            itemObj = itemsDetails[i];
            itemId = itemObj['o:id'];

            node = getNodeReference(itemId);

            if (node) {
              node.details = itemObj;
              node.defaultTitle = node.title;

              // Langue courante au moment du chargement des données
              jsonReader.json = itemObj;
              node.title = jsonReader.getLocalizedMetaDataValue('dcterms:alternative', store.state.currentLanguage, node.defaultTitle);
            }
          }

          emit(response.data);
        });

      };

      const changeSortQuery = function( changes ) {
        state.sortByParam = changes.sortBy;
        state.sortOrderParam = changes.sortOrder;
      };

      const mainNodePath = function() {
        return props.itemSetId + ':0';
      };

      //
      // Lifecycle Hooks
      //

      onMounted(() => {

        if (props.itemSetId !== 0) {
          loadTreeItems();
          loadRootNodesCount();
        }

      });

      watch(() => props.itemSetId, () => {
        loadTreeItems();
      });

      watch(() => props.pageNo, () => {
        state.pageNo = props.pageNo;
        loadTreeItems();
      });

      watch(() => store.state.currentLanguage, () => {
        updateNodeTitle(store.state.currentLanguage);
      });

      const state = reactive({
        isLoading: true,
        nodes : null,
        itemsCount: props.itemsCount,
        rootNodesCount: 0,

        pageNo: props.pageNo,
        pageCount: computed(() => state.rootNodesCount > 0 ? Math.ceil( state.rootNodesCount / props.itemsByPage ) : 0),
      });

      return {
        state,
        changeSortQuery,
        mainNodePath,
        emit
      }

    },
}
</script>
