<template>
  <!-- Connexion : Confirmation Inscription -->
  <div class="inscription-menu-parent inscription-confirmation-parent popup-parent">
    <div class="inscription-menu-bg"></div>
    <div class="connexion-menu">
      <div class="connexion-menu-close">
        <a href="#" @click="closeWindow($event)">{{ $t("Fermer") }}</a>
      </div>
      <div class="inscription-confirmation-content form-parent">
        <p>
          {{ $t("Merci ! Nous avons envoyé un mail de confirmation pour finaliser l’inscription.") }}
        </p>
        <p>
          Merci de cliquer sur le lien dans le mail afin de vérifier que votre adresse email soit correcte.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegistrationWindow',
  props: {
  },
  emits:[
    'close-register-confirmation-window'
  ],
  methods: {
    closeWindow($event) {
      $event.preventDefault();
      $event.stopPropagation();
      this.$emit('close-register-confirmation-window');
    }
  },
}
</script>
