<template>
  <div class="view">
    <div class="breadcrumbs-and-title">
      <div class="breadcrumbs">
        <BreadCrumb :paths="breadcrumbs" />
      </div>
      <h1>
        {{ $t(pageTitle) }}
        <a
          v-if="state.collectionDescriptions"
          @click="openCollectionDescriptions($event)"
          class="collection-description-toggle"
          href="#">
        </a>
      </h1>
    </div>
    <div class="contents">
      <div class="contents-wrapper" :class="state.displayModeCssClass">

        <div class="collection-header" v-if="state.collectionDescriptions">
          <CollectionDescription
            class="with-padding"
            :class="state.collectionDescriptionVisibleCssClass"
            :descriptions="state.collectionDescriptions"
          />
        </div>

        <!-- Onglets et panier -->
        <div class="contents-header with-padding">
          <!-- Toggle Graphe / Liste / Notices -->
          <ul class="contents-mode">
            <li class="contents-list-mode">
              <router-link :to="state.listItemSetRoute">
                {{ $t("Liste") }}
              </router-link>
            </li>
            <li class="contents-notice-mode">
              <router-link :to="state.noticeItemRoute">
                {{ $t('Notices') }}
              </router-link>
            </li>
          </ul>
          <!-- Panier -->
          <div class="constellation-basket" v-if="state.userIsConnected">
            <router-link to="/mon-compte/mon-panier">{{ basketCount }}</router-link>
          </div>
        </div>

        <Loading class="notice-item-content with-padding no-header" v-if="state.isLoading" />

        <div v-if="! state.isLoading">

          <!-- Mode Liste -->
          <div v-if="state.isListDisplayMode" class="contents-items-mode-content">
            <ListItems
              :items="state.items"
              :pageNo="state.pageNo"
              :list-title="state.collectionTitle"
              :list-descriptions="state.collectionDescriptions"
              :queryRouteName=itemRouteName
              :backRoute="state.backItemSetRoute"
              backRouteLabel="Retour au sommaire"
              @opendetailednotice="openDetailedNotice"
              @openitemvisualization="openItemVisualization"
            />
          </div>

          <!-- Mode Notice -->
          <NoticesItems
            v-if="state.isNoticeDisplayMode"
            :items-ids="state.itemsIds"
            :notice-item-id="state.noticeItemId"
            :changeRouteWithPreviousAndNext="state.changeRouteWithPreviousAndNext"
            :openGraphInPopUp="true"
            @open-item-graph="openItemVisualization"
            @opendetailednotice="openDetailedNotice"
            @item-loaded="itemLoaded"
          />

          <!-- Graphe SVG d'un Item -->
          <VisualisationItem
              :class="itemVisualizationCssClass"
              :itemId="itemVisualizationItemId"
              @opendetailednotice="openDetailedNotice"
              @closeitemvisualization="closeItemVisualization"
              @notice-loaded="itemLoaded"
          />

        </div>

      </div>
    </div>

    <NoticePopUp
            v-if="detailedNoticeItemIsOpened"
            v-bind:itemId="detailedNoticeId"
            v-bind:itemType="detailedNoticeType"
            @close-detailed-item-notice="closeDetailedNotice"
            :class="detailedNoticeCssClass"
    />


  </div>

</template>

<script>

  import {useStore} from "vuex";
  import {computed, onMounted, reactive, watch} from 'vue'

  import BreadCrumb from "@/components/Header/BreadCrumb";
  import CollectionDescription from "@/components/Header/CollectionDescription";
  import ListItems from "@/components/Lists/ListItems";
  import NoticesItems  from "@/components/Lists/NoticesItems";
  import Loading from "@/components/Loading";
  import NoticePopUp from "@/components/Notices/NoticePopUp";
  import VisualisationItem from "@/components/Visualization/VisualisationItem.vue";

  import {onBeforeRouteLeave, onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";
  import {getCollectionsApiURL} from "@/js/api";
  import {getLocalizedValue} from "@/js/functions";

  const itemsByPage = 8;

  export default {
    name: 'ListNoticesCuratedCollectionView',
    components: {
      VisualisationItem,
      BreadCrumb,
      CollectionDescription,
      ListItems,
      Loading,
      NoticePopUp,
      NoticesItems
    },
    props: {
      pageTitle: {
        type: String,
        required: true
      },
      itemSetsRouteName: {
        type: String,
        required: false,
        default:'Curated ItemSets'
      },
      itemSetRouteName: {
        type: String,
        required: false,
        default:'Curated ItemSet'
      },
      itemRouteName: {
        type: String,
        required: false,
        default:'Curated ItemSet Item'
      },
      defaultDisplayMode: {
        type: String,
        required: false,
        default:"list"
      },
    },
    data() {
      return {
        detailedNoticeItemIsOpened:false,
        detailedNoticeId:null,
        detailedNoticeType:null,
        itemVisualizationItemId:null,
        itemVisualizationIsOpened:null,
      }
    },
    computed: {
      detailedNoticeCssClass() {
        return this.detailedNoticeItemIsOpened ? "opened" : "";
      },
      itemVisualizationCssClass() {
        return this.itemVisualizationIsOpened === true ? "opened" : "closed";
      },
      basketCount() {
        return this.$store.state.basket.length;
      },
      breadcrumbs() {
        if ((this.state.displayMode === "notice") && this.state.noticeItemTitle) {
          // Item
          return [
            { name: 'Home', label:'Accueil' },
            { name: 'Curated ItemSets', label:'Sélections' },
            { name: this.itemSetRouteName, label:this.state.collectionTitle, query: this.state.listItemQuery },
            { label: this.state.noticeItemTitle }
          ]
        } else {
          return [
            { name: 'Home', label:'Accueil' },
            { name: 'Curated ItemSets', label:'Sélections' },
            { label:this.state.collectionTitle }
          ]
        }
      }
    },
    methods: {
      openDetailedNotice(itemId) {
        this.detailedNoticeId = itemId;
        this.detailedNoticeType = "item";
        this.detailedNoticeItemIsOpened = true;
      },
      closeDetailedNotice() {
        this.detailedNoticeId = null;
        this.detailedNoticeType = null;
        this.detailedNoticeItemIsOpened = false;
      },
      openItemVisualization(itemId) {
        this.itemVisualizationIsOpened = true;
        this.itemVisualizationItemId = parseInt(itemId);
      },
      closeItemVisualization() {
        this.itemVisualizationItemId = null;
        this.itemVisualizationIsOpened = false;
      },
      openDownloadNoticePopUp(item) {
        this.downloadNoticePopupIsOpened = true;
        this.state.popUpItemId = item.id;
      },
      closeDownloadNoticePopUp() {
        this.downloadNoticePopupIsOpened = false;
      }

    },

    setup(props) {

      const route = useRoute();
      const router = useRouter();
      const store = useStore();


      //
      // Mode Items
      //

      const selectListDisplayMode = function($event){
        $event.preventDefault();
        state.displayMode = "list";
      };

      const selectNoticeDisplayMode = function($event){
        $event.preventDefault();
        state.displayMode = "notice";
      };

      // Loads all itemsets :
      const loadItems = function(noticeItemId) {

        state.isLoading = true;

        const apiURL = getCollectionsApiURL(
          store.getters.apiRoot,
          [ state.itemSetId ],
          "",
          state.sortByParam,
          state.sortOrderParam,
        );

        store.dispatch('getApi', apiURL).then(function(response){

          state.isLoading = false;

          const items = response.data;

          if (Array.isArray(items)) {

            let i, n = items.length, item, itemsIds = [], itemId, itemSets, firstItemSetId, collectionSettings;
            for(i=0;i<n;i++)
            {
              item = items[i];
              itemId = parseInt(item['o:id']);
              itemSets = item["o:item_set"];

              itemsIds.push(itemId);

              // Récupération de l'icône et de la couleur de la collection
              if (itemSets && itemSets.length)
              {
                firstItemSetId = itemSets[0]['o:id'];
                if (firstItemSetId) {

                  collectionSettings = store.getters.collectionsSettings(firstItemSetId);
                  item.icon = collectionSettings ? collectionSettings.icon : null;
                  item.colorNo = collectionSettings ? collectionSettings.colorNo : null;

                  // Choix de la vue pour l'ouverture d'un item dans la liste :
                  // - En passant 'route', un item s'ouvrira dans son espace ( encyclopédie, corpus, ...)
                  // - En ne le passant pas, la liste déclenche une nouvelle route passée en paramètre au composant
                  // ce qui permet d'ouvrir l'item dans l'onglet Notices du composant actuel ( cf _route )
                  item._route = collectionSettings ? collectionSettings.route : '';

                  item.id = itemId;
                  item.itemset = state.itemSetId;
                  item.type = collectionSettings ? collectionSettings.itemType : "item";
                }
              }
            }

            state.items = items;
            state.itemsIds = itemsIds;

          }

          // Item éventuel passé dans l'URL :
          if (! isNaN(noticeItemId)) {
            state.noticeItemId = parseInt(noticeItemId);
            state.displayMode = 'notice';
          } else if (state.items.length) {
            state.noticeItemId = state.itemsIds[0];
          } else {
            state.noticeItemId = 0;
          }

        });
      };

      const itemLoaded = function(item) {
        state.noticeItemTitle = item.title;
      };

      const updateCollectionDescription = function() {
        const collectionJson = store.getters.collectionJson(state.itemSetId);
        if (collectionJson) {
          state.collectionTitle = getLocalizedValue(collectionJson['dcterms:title'], store.state.currentLanguage, collectionJson['o:title']);
          state.collectionDescriptions = collectionJson['dcterms:description'];
        }
      };

      const openCollectionDescriptions = function($event) {
        $event.preventDefault();
        state.isCollectionDescriptionVisible = ! state.isCollectionDescriptionVisible;
      };


      //
      // Lifecycle Hooks
      //

      onMounted(() => {

        if (store.state.collections) {
          updateCollectionDescription();
        }

        if (route.query.sort_by && (route.query.sort_by.length > 0)) {
          state.sortByParam = route.query.sort_by;
        }

        if (route.query.sort_order && (route.query.sort_order.length > 0)) {
          state.sortOrderParam = route.query.sort_order;
        }

        if (route.query.display_mode && (route.query.display_mode.length > 0)) {
          state.displayMode = route.query.display_mode;
        }

        // Sélection d'items
        state.itemSetId = parseInt(route.params.itemset);
        state.displayMode = "list";
        state.displayModeForItemSets = route.query.from_mode ? route.query.from_mode : 'gallery';

        updateCollectionDescription();

        loadItems(route.params.id);

      });

      watch(() => store.state.collections, () => {
        if (route.name === props.itemSetRouteName) {
          updateCollectionDescription();
        }
      });

      watch(() => store.state.currentLanguage, () => {
        updateCollectionDescription();
        loadItems();
      });


      // Different route / Same component
      onBeforeRouteLeave((to, from, next) => {

        if (to.name === props.itemRouteName)
        {
          // From List view to Item view
          state.noticeItemId = parseInt(to.params.id);
          state.displayMode = "notice";
          next();
        }
        else if (to.name === props.itemSetRouteName)
        {
          // From Item view to List view
          state.noticeItemId = 0;
          state.displayMode = 'list';
          next();
        }
        else
        {
          // Different route with different component (home, etc...)
          next();
        }
      });

      // Same route / Same component
      onBeforeRouteUpdate((to, from, next) => {

        const toQuerySortBy = to.query.sort_by ? to.query.sort_by : 'title';
        const toQuerySortOrder = to.query.sort_order ? to.query.sort_order : 'asc';

        if (to.name === props.itemRouteName)
        {
          // Item view
          state.noticeItemId = parseInt(to.params.id);
          state.displayMode = "notice";
          next();

        }
        else if (to.name === props.itemSetRouteName)
        {
          // List view
          state.noticeItemId = 0;
          state.itemSetId = parseInt(to.params.itemset);
          state.displayMode = 'list';

          if ((state.sortByParam !== toQuerySortBy) || (state.sortOrderParam !== toQuerySortOrder)) {

            // 1.1 Changement de l'ordre des items
            state.sortByParam = toQuerySortBy;
            state.sortOrderParam = toQuerySortOrder;
            state.pageNo = isNaN(to.query.p) ? 1 : parseInt(to.query.p);

            updateCollectionDescription();
            loadItems();
            next();

          } else {

            // 1.2 Pagination
            state.pageNo = isNaN(to.query.p) ? 1 : parseInt(to.query.p);
            next();

          }

        }
        else
        {
          // Different route with different component (home, etc...)
          next();
        }

      });


      //
      // State
      //

      const state = reactive({

        collectionDescriptions: null,
        isCollectionDescriptionVisible:false,
        collectionDescriptionVisibleCssClass: computed( () => {
          return state.isCollectionDescriptionVisible ? "opened" : "";
        }),

        displayMode: props.defaultDisplayMode,
        displayModeCssClass: computed(() => state.displayMode + "-mode"),

        sortByParam: 'title',
        sortOrderParam: 'asc',

        displayModeForItemSets: props.defaultDisplayMode,
        sortByParamForItemSets: 'title',
        sortOrderParamForItemSets: 'asc',
        changeRouteWithPreviousAndNext:true,

        // Mode items (items d'une sélection)
        pageItemNo: 1,
        pageItemCount: computed(() => Math.ceil( state.items.length / itemsByPage )),
        itemSetId: 0,

        noticeItemId: 0,
        noticeItemTitle: null,

        items: [],
        collectionTitle: "",

        displayedItems: computed( () => {
          const startItemNo = itemsByPage * (state.pageItemNo - 1);
          const endItemNo = Math.min(startItemNo + itemsByPage, state.items.length);
          let i, items = [];
          for(i=startItemNo;i<endItemNo;i++) {
            items.push(state.items[i]);
          }
          return items;
        }),

        displayedItemsIds: computed(() => {
          let i, itemsIds = [];
          let items = state.displayedItems;
          const n = items.length;
          for(i=0;i<n;i++) {
            itemsIds.push(items[i]['o:id']);
          }
          return itemsIds;
        }),

        isListDisplayMode: computed(() => { return state.displayMode === "list" }),
        isNoticeDisplayMode: computed(() => { return state.displayMode === "notice" }),

        backItemSetRoute: computed(() => {
          return {
            name: props.itemSetsRouteName,
            query: {
              sort_param: state.sortByParamForItemSets,
              sort_order: state.sortOrderParamForItemSets,
              display_mode: state.displayModeForItemSets,
              p: state.pageNo
            }
          }
        }),

        listItemSetRoute: computed(() => {
          return router.resolve( {
              name: props.itemSetRouteName,
              params: { itemset: state.itemSetId },
              query: {
                sort_param: state.sortByParamForItemSets,
                sort_order: state.sortOrderParamForItemSets,
                display_mode: 'list',
                p: state.pageNo
              }
            });
          }),

        listItemQuery: computed(() => {
          return {
            sort_param: state.sortByParam,
            sort_order: state.sortOrderParam,
            display_mode: 'list',
            p: state.pageNo
          };
        }),

        noticeItemRoute: computed(() => {
          return router.resolve( {
            name: props.itemRouteName,
            params: { itemset: state.itemSetId, id: state.noticeItemId },
            query: {
              sort_param: state.sortByParam,
              sort_order: state.sortOrderParam,
              display_mode: 'notice'
            }
          });
        }),

        userIsConnected: computed(() => store.state.currentUser.logged )
      });

      return {
        state,
        selectListDisplayMode,
        selectNoticeDisplayMode,
        openCollectionDescriptions,
        itemLoaded
      }
    }
  }

</script>
