<template>
  <header class="with-side-menu" :class="menusOpenedClass">

    <!-- Menus -->
    <UserMenu />
    <NavigationMenu />

    <Hamburger v-on:click-on-navigation-menu="toggleNavigationMenu()" />

    <Logo :logo-url="state.headerLogoUrl" />

    <div class="search-options-panel-header"></div>
    <div class="search-options-panel" @click="blockEvent($event)" :class="overflowClass">

      <div class="search-input">
        <input type="text"
               @keyup="onKeyUpSearchInput($event)"
               @click="blockEvent($event)"
               @input="onInputSearchInput($event)"
               :placeholder="$t('Rechercher')"
               :value="textInput"
        />
        <a href="#"
           class="search-submit-btn"
           :title="$t('Rechercher')"
           @click="execSearchInput($event)"
        />
      </div>

      <div class="advanced-search-link">
        <a href="#" @click="toggleAdvancedSearchMenu($event)">{{ $t("Recherche avancée") }}</a>
      </div>

      <div class="browse-link" >
        <a href="#" @click="toggleBrowseLink($event)">{{ $t("Parcourir") }}</a>
        <ul
                class="browse-link-menu"
                :class="browseLinkMenuOpenedClass"
                @click="toggleBrowseLink($event)"
        >
          <li>
            <router-link :to="{ name:'Parcourir', query: { metadata: 'o:title' }}">{{ $t("Par titre") }}</router-link>
          </li>
          <li>
            <router-link :to="{ name:'Parcourir', query: { metadata: 'dcterms:subject' }}">{{ $t("Par mot-clé") }}</router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="header-right">
      <div class="language-selector">
        <a href="#" @click="toggleLanguageMenu($event)">{{ state.language }}</a>
        <ul>
          <li v-for="language in state.languages" :key="language">
            <a href="#" @click="selectLanguage($event, language)">{{ language }}</a>
          </li>
        </ul>
      </div>
      <div class="search-mobile-link">
        <a href="#" @click="toggleSearchMenu($event)"></a>
      </div>
      <div class="user login">
        <a href="#" @click="toggleUserMenu($event)"></a>
      </div>
    </div>
  </header>
</template>

<script>

import {computed, watch, onMounted, onUnmounted, reactive, ref} from "vue";
  import {useRouter, useRoute} from 'vue-router';
  import {useStore} from "vuex";

  import UserMenu from './UserMenu.vue'
  import NavigationMenu from './NavigationMenu.vue'
  import Hamburger from './Hamburger.vue'
  import Logo from './Logo.vue'

  export default {
    name: 'Header',
    components: {
      UserMenu,
      NavigationMenu,
      Hamburger,
      Logo
    },
    emits: [
      'close-advanced-search-menu',
      'toggle-login-menu',
      'click-on-advanced-search-menu'
    ],
    setup() {

      const store = useStore();
      const router = useRouter();
      const route = useRoute();

      const clickOutside = function() {

        state.navigationMenu = false;
        state.browseLinkMenu = false;
        state.searchMenu = false;
        state.languageMenu = false;

        // Avant la première ouverture, on maintient state.userMenu à null
        // pour ne pas déclencher le fondu d'apparition ou disparition
        if (state.userMenu === true) {
          state.userMenu = false;
        }
      };

      const updateSearchField = function() {
        if (route.query.q && route.query.q.length) {
          textInput.value = route.query.q;
        } else {
          var searchParams = new URLSearchParams(route.params.s);
          textInput.value = searchParams.get('property[0][text]') || searchParams.get('q');
        }
      };

      onMounted(() => {
        document.body.addEventListener("click", clickOutside);
      });

      onUnmounted(() => {
        document.body.removeEventListener("click", clickOutside);
      });

      watch( route, () => {
        updateSearchField();
      });



      // Reactive state ( props and computed )

      const textInput = ref('');

      const state = reactive({
        navigationMenu:false,
        browseLinkMenu: false,
        userIsConnected: computed(() => store.state.currentUser.logged ),
        userMenu: null,
        searchMenu: false,
        languageMenu: false,
        language: computed(() => store.state.currentLanguage ),
        languages: store.getters.getLanguagesFromSettings(),
        headerLogoUrl: store.state.settings.header.logo
      });


      const userState = reactive({
        userId: computed(() => store.state.currentUser.id ),
        userName: computed(() => store.state.currentUser.name ),
        userLogged: computed(() => store.state.currentUser.logged && (store.state.currentUser.loggedMethod === 'login'))
      });

      watch(userState, () => {
        if (userState.userLogged) {
          // Ouvre le menu utilisateur après le login
          state.userMenu = true;
        } else if (state.userMenu === true) {
          // Ferme le menu utilisateur après le logout
          state.userMenu = false;
        }
      });

      const blockEvent = function($event) {
        $event.stopPropagation();
      };

      const applySearchInput = function(textValue) {
        router.push({
          name: "Recherche",
          query: {
            q: textValue
          }
        });
      }

      const execSearchInput = function($event) {
        $event.preventDefault();
        applySearchInput(textInput.value)
      }

      const onInputSearchInput = function($event) {
        textInput.value = $event.target.value
      }

      const onKeyUpSearchInput = function($event) {
        $event.preventDefault();
        if ($event.keyCode === 13 ) {
          textInput.value = $event.target.value
          applySearchInput(textInput.value);
        }
      };

      return {
        state,
        userState,
        textInput,
        onKeyUpSearchInput,
        onInputSearchInput,
        applySearchInput,
        execSearchInput,
        blockEvent
      }

    },
    computed: {
      menusOpenedClass() {
        return [
          this.state.navigationMenu ? "menu-opened": "",
          this.state.userMenu === null ? "" : (this.state.userMenu ? "menu-user-opened": "menu-user-closed"),
          this.state.searchMenu ? "search-menu-opened" : "",
          this.state.languageMenu ? "language-menu-opened": ""
        ].join(' ')
      },
      browseLinkMenuOpenedClass() {
        return this.state.browseLinkMenu ? "browse-link-menu-opened": "browse-link-menu-closed";
      },
      overflowClass() {
        return this.state.browseLinkMenu ? "overflow-unset": "";
      },

    },
    methods : {
      toggleNavigationMenu() {
        this.state.navigationMenu = ! this.state.navigationMenu;
        this.state.searchMenu = false;
        this.$emit('close-advanced-search-menu');
      },
      closeBrowseLink($event) {
        $event.preventDefault();
        this.state.browseLinkMenu = false;
        this.$emit('close-advanced-search-menu');
      },
      toggleBrowseLink($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.state.browseLinkMenu = ! this.state.browseLinkMenu;
        this.$emit('close-advanced-search-menu');
      },
      toggleUserMenu($event) {
        $event.preventDefault();
        $event.stopPropagation();
        if (! this.state.userIsConnected)
        {
          // PopUp de login
          this.state.searchMenu = false;
          this.state.browseLinkMenu = false;
          this.$emit('toggle-login-menu');
        }
        else
        {
          // Menu de l'utilisateur
          this.state.browseLinkMenu = false;
          this.state.userMenu = ! this.state.userMenu;
          this.$emit('close-advanced-search-menu');
        }
      },
      toggleSearchMenu($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.state.searchMenu = ! this.state.searchMenu;
        this.state.navigationMenu = false;
      },
      toggleLanguageMenu($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.state.languageMenu = ! this.state.languageMenu;
      },
      selectLanguage($event, newLanguage) {
        $event.preventDefault();
        $event.stopPropagation();

        this.state.languageMenu = false;

        let routeCopy = {
          name : this.$route.name,
          query : { ...this.$route.query },
          params : { ...this.$route.params }
        };

        routeCopy.query.lang = newLanguage;

        this.$router.push(routeCopy);
      },
      toggleAdvancedSearchMenu($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.state.userMenu = false;
        this.state.browseLinkMenu = false;
        this.state.navigationMenu = false;
        this.$emit('click-on-advanced-search-menu');
      }
    }
}
</script>
