<template>
    <div class="view">
        <div class="breadcrumbs-and-title">
            <div class="breadcrumbs">
                <BreadCrumb :paths="breadcrumbs" />
            </div>
            <h1>
              {{ $t(pageTitle) }}
              <a
                  v-if="state.collectionDescriptions"
                  @click="openCollectionDescriptions($event)"
                  class="collection-description-toggle"
                  href="#"></a>
            </h1>
        </div>
        <div class="contents" :class="withPopUpCssClass">
        <div class="contents-wrapper with-visualization" :class="state.displayModeCssClass">

          <div class="collection-header" v-if="state.collectionDescriptions">
            <CollectionDescription
                class="with-padding"
                :class="state.collectionDescriptionVisibleCssClass"
                :descriptions="state.collectionDescriptions"
            />
          </div>

          <div class="traite">

            <div class="contents-header with-padding">
              <ul class="contents-mode">
                <li class="contents-list-mode">
                    <router-link :to="state.listItemSetRoute">
                        {{ $t("Liste") }}
                    </router-link>
                </li>
                <li class="contents-graph-mode">
                    <router-link :to="state.graphItemSetRoute">
                        {{ $t("Graphe") }}
                    </router-link>
                </li>
                <li class="contents-notice-mode">
                  <router-link :to="state.noticeItemRoute">
                      {{ $t("Notices") }}
                  </router-link>
                </li>
                <li class="contents-link-mode"><a href="https://books.openedition.org/emsha/482" target="_blank">Open Edition</a></li>
                <li class="contents-download-mode"><a href="https://books.openedition.org/emsha/epub/482" download="download">E-Book</a></li>
                <li class="contents-download-mode"><a href="https://books.openedition.org/emsha/pdf/482" download="download">PDF</a></li>
              </ul>
            </div>

              <!-- Menu mobile -->
            <ul class="list-items-menu-mobile-selector">
              <li class="selector-previous-button">
                  <a href="#" @click="selectPreviousItemInMobileMenu($event)"></a>
              </li>
              <ul class="list-items-menu-mobile">
                  <li class="contents-link-mode" :class="menuMobileItemCssClass(0)">
                      <a href="https://books.openedition.org/emsha/482" target="_blank">Open Edition</a>
                  </li>
                  <li class="contents-download-mode" :class="menuMobileItemCssClass(1)">
                      <a href="https://books.openedition.org/emsha/epub/482" download="download">E-Book</a>
                  </li>
                  <li class="contents-download-mode" :class="menuMobileItemCssClass(2)">
                      <a href="https://books.openedition.org/emsha/pdf/482" download="download">PDF</a>
                  </li>
              </ul>
              <li class="selector-next-button">
                  <a href="#" @click="selectNextItemInMobileMenu($event)"></a>
              </li>
            </ul>


            <!-- Mode Graphe TD -->
            <div class="td-graph-parent with-padding">
              <div id="svg-parent" class="td-graph"></div>
            </div>

            <!-- Mode Liste-arborescente -->
            <div class="contents-list-mode-content">
                <TreeView
                        :item-set-id="state.collectionId"
                        :item-route-name="itemRouteName"
                        @data-loaded="dataLoaded"
                        @opendetailednotice="openDetailedNotice"
                        @downloaditemnotice="openDownloadNoticePopUp"
                />
            </div>

             <!-- Mode Notice -->
            <NoticesItems
                      v-if="state.isNoticeDisplayMode && state.noticeItemId !== 0"
                      :itemsIds="state.itemsIds"
                      :noticeItemId="state.noticeItemId"
                      :noticeItemType="state.noticeItemType"
                      :has-part-metadata-sorted="true"
                      @opendetailednotice="openDetailedNotice"
                      @item-loaded="itemLoaded"
            />

          </div>
        </div>
      </div>

    <NoticePopUp
            v-if="detailedNoticeItemIsOpened"
            :itemId="detailedNoticeId"
            :itemType="detailedNoticeType"
            :itemJson="detailedNoticeJson"
            :class="detailedNoticeCssClass"
            @close-detailed-item-notice="closeDetailedNotice"
    />

    <NoticeDownloadPopUp
            v-if="downloadNoticeItemId"
            :itemId="downloadNoticeItemId"
            :itemType="downloadNoticeItemType"
            :class="downloadNoticePopupCssClass"
            @closedownloaditemnotice="closeDownloadNoticePopUp"
    />

    </div>
</template>

<script>

    import TreeView from "../components/Trees/TreeView";
    import BreadCrumb from "../components/Header/BreadCrumb";
    import CollectionDescription from "../components/Header/CollectionDescription";
    import NoticesItems from "../components/Lists/NoticesItems";
    import NoticePopUp from '../components/Notices/NoticePopUp';
    import NoticeDownloadPopUp from '../components/Notices/NoticeDownloadPopUp';
    import { VizTD } from '../js/viz-td.js';
    import {onBeforeRouteLeave, onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";
    import {computed, onMounted, reactive, watch} from "vue";
    import {useStore} from "vuex";
    import {getCollectionsApiURL, getCollectionsItemIdsApiURL} from "../js/api";

    const graphParentId = "svg-parent";
    const vizTD = new VizTD();

    export default {
        name: 'TraiteDocumentation',
        components: {
            TreeView,
            NoticesItems,
            NoticePopUp,
            BreadCrumb,
            CollectionDescription,
            NoticeDownloadPopUp
        },
        props: {
            pageTitle: {
                type: String,
                required: false,
                default: "Le traité de documentation"
            },
            category: {
                type: String,
                required: false,
                default:'traite_documentation'
            },
            itemSetId: {
              type: Number,
              required: false
            },
            sortBy: {
                type: String,
                required: false,
                default:'bibo:section'
            },
            sortOrder: {
                type: String,
                required: false,
                default:'asc'
            },
            itemSetRouteName: {
                type: String,
                required: false,
                default:'TraiteDocumentation'
            },
            itemRouteName: {
                type: String,
                required: false,
                default:'TraiteDocumentation Item'
            },
            noticeItemId: {
                type: Number,
                required: false,
                default:0
            },
            noticeItemType: {
                type: String,
                required: false,
                default:'item'
            },
            defaultDisplayMode: {
                type: String,
                required: false,
                default:"list"
            },
        },
        data() {
            return {
                isGraphDisplayMode: false,
                isGraphRendered: false,
                graphData: null,

                // Données de l'Item (si onglet Notices)
                noticeItemTitle:'...',

                // Données de la popup éventuelle :
                detailedNoticeItemIsOpened:false,
                detailedNoticeId:null,
                detailedNoticeType:null,
                detailedNoticeJson:null,

                downloadNoticePopupIsOpened:false,
                downloadNoticeItemId:null,
                downloadNoticeItemType: 'item'
            }
        },
        computed: {
            detailedNoticeCssClass() {
                return this.detailedNoticeItemIsOpened ? "opened" : "";
            },
            withPopUpCssClass() {
                return this.detailedNoticeItemIsOpened ? "with-popup" : "";
            },
            downloadNoticePopupCssClass() {
                return this.downloadNoticePopupIsOpened === true ? "opened" : "";
            },
            breadcrumbs() {
                if (this.state.displayMode === "notice") {
                    // Item
                    return [
                        { name: 'Home', label: 'Accueil' },
                        { name: this.itemSetRouteName, label: this.pageTitle },
                        { label: this.noticeItemTitle }
                    ]
                } else {
                    // Collection
                    return [
                        { name: 'Home', label: 'Accueil' },
                        { label: this.pageTitle }
                    ]
                }
            }
        },
        beforeUnmount() {
            document.getElementById(graphParentId).removeEventListener("notice", null);
        },
        mounted() {
            const t = this;
            const vizParent = document.getElementById(graphParentId);
            vizParent.addEventListener("notice", function ($event) {
                t.openTDNodeNotice($event.detail.id, $event.detail.json)
            });
        },
        methods : {
            itemLoaded(itemInfos) {
                this.noticeItemTitle = itemInfos.title;
            },
            openDetailedNotice(itemId, noticeType = "item") {
                this.detailedNoticeId = itemId;
                this.detailedNoticeType = noticeType;
                this.detailedNoticeItemIsOpened = true;
                this.detailedNoticeJson = null;
            },

            openTDNodeNotice(itemId, itemJson, noticeType = "td-branch") {
                this.detailedNoticeId = itemId;
                this.detailedNoticeType = noticeType;
                this.detailedNoticeItemIsOpened = true;
                this.detailedNoticeJson = itemJson;
            },
            closeDetailedNotice() {
                this.detailedNoticeId = null;
                this.detailedNoticeType = null;
                this.detailedNoticeJson = null;
                this.detailedNoticeItemIsOpened = false;
            },
            openDownloadNoticePopUp(item) {
                this.downloadNoticePopupIsOpened = true;
                this.downloadNoticeItemId = item.id;
            },
            closeDownloadNoticePopUp() {
                this.downloadNoticePopupIsOpened = false;
                this.downloadNoticeItemId = null;
            }
        },
        setup(props) {

          const route = useRoute();
          const router = useRouter();
          const store = useStore();

          const apiRoot = store.getters.apiRoot;
          let collectionId = parseInt(props.itemSetId);

          const updateCollectionDescription = function() {
            state.collectionJson = store.getters.collectionJson(collectionId);
          };

          const openCollectionDescriptions = function($event) {
              $event.preventDefault();
              state.isCollectionDescriptionVisible = ! state.isCollectionDescriptionVisible;
          };

          const tryToRenderGraph = function() {
              if (state.graphData && ! state.isGraphRendered) {
                  setTimeout(function() {
                      renderGraph();
                  }, 10);
              }
          };

          const renderGraph = function() {
              const vizParent = document.getElementById(graphParentId);
              vizTD.width = vizParent.offsetWidth;
              vizTD.height = vizParent.offsetHeight || window.innerHeight;
              // console.log(graphParentId, vizTD.width, vizTD.height);

              vizTD.init(graphParentId);
              vizTD.initData(state.graphData);
              vizTD.renderSVG();

              state.isGraphRendered = true;
          };

          const dataLoaded = function(json) {
              state.graphData = json;
          };

          // Loads all items :
          const loadItems = function(itemId) {

              state.isLoading = true;

              const apiURL = getCollectionsApiURL(
                  apiRoot,
                  [collectionId],
                  "",
                  state.sortByParam,
                  state.sortOrderParam,
              );

              store.dispatch('getApi', apiURL).then(function(response){
                  state.items = response.data;
                  state.isLoading = false;

                  if (! isNaN(itemId)) {
                      state.noticeItemId = parseInt(itemId);
                      state.displayMode = 'notice';
                  } else if (state.items.length) {
                      state.noticeItemId = state.items[0]['o:id'];
                  } else {
                      state.noticeItemId = 0;
                  }
              });

          };

          const loadItemsIds = function() {

              state.sortByParam = props.sortBy;
              state.sortOrderParam = props.sortOrder;

              const options = "&hasMedia=1";
              const apiURL = getCollectionsItemIdsApiURL(
                  apiRoot,
                  [collectionId],
                  options,
                  state.sortByParam,
                  state.sortOrderParam
              );

              store.dispatch('getApi', apiURL).then(function(response){
                  state.itemsIds = response.data.items;
                  // console.log('state.itemsIds', state.itemsIds);
              });
          };


          //
          // Navigation mobile (liens)
          //

          const selectPreviousItemInMobileMenu = ($event) => {
              $event.preventDefault();
              state.menuMobileIndex = state.menuMobileIndex > 0 ? state.menuMobileIndex - 1 : state.menuMobileIndexMax;
          };

          const selectNextItemInMobileMenu = ($event) => {
              $event.preventDefault();
              state.menuMobileIndex = state.menuMobileIndex < state.menuMobileIndexMax ? state.menuMobileIndex + 1 : 0;
          };

          const menuMobileItemCssClass = (no) => {
              return state.menuMobileIndex === no ? "selected" : "";
          };


          //
          // Lifecycle Hooks
          //

          onMounted(() => {

              if (store.state.collections) {
                  updateCollectionDescription();
              }

              if (route.query.display_mode && (route.query.display_mode.length > 0)) {
                  state.displayMode = route.query.display_mode;
              }

              // ID de la collection du TD
              state.collectionId = collectionId;

              loadItems(route.params.id);
              loadItemsIds();
          });

          watch(() => store.state.collections, () => {
              updateCollectionDescription();
          });


          // Different route / Same component
          onBeforeRouteLeave((to, from, next) => {

              if (to.name === props.itemRouteName)
              {
                  // From List/Graph view to Item view
                  state.noticeItemId = parseInt(to.params.id);
                  state.displayMode = "notice";
                  next();
              }
              else if (to.name === props.itemSetRouteName)
              {
                  // From Item view to List/Graph view
                  state.displayMode = to.query.display_mode || props.defaultDisplayMode;

                  if (state.displayMode === "graph") {
                      tryToRenderGraph();
                  }

                  next();
              }
              else
              {
                  // Different route with different component (home, etc...)
                  next();
              }
          });

          // Same route / Same component
          onBeforeRouteUpdate((to, from, next) => {

              const toDisplayMode = to.query.display_mode ? to.query.display_mode : props.defaultDisplayMode;

              if (to.name === props.itemRouteName) {

                  // 1. Changement d'item (à partir d'un item)
                  state.displayMode = 'notice';
                  state.noticeItemId = parseInt(to.params.id);
                  next();

              } else if (to.name === props.itemSetRouteName) {

                  // 2. Changement de mode d'affichage List/Graph
                  state.displayMode = toDisplayMode;

                  if (state.displayMode === "graph") {
                      tryToRenderGraph();
                  }

                  next();
              }
          });


          //
          // State
          //

          const state = reactive({

              isCollectionDescriptionVisible:false,
              collectionId:0,
              collectionDescriptions: null,
              collectionDescriptionVisibleCssClass: computed( () => {
                  return state.isCollectionDescriptionVisible ? "opened" : "";
              }),

              displayMode: props.noticeItemId || route.params.id ? "notice" : props.defaultDisplayMode,
              displayModeCssClass: computed(() => state.displayMode + "-mode"),

              isNoticeDisplayMode: computed(() => { return state.displayMode === "notice" }),

              menuMobileIndex: 0,
              menuMobileIndexMax: 2,

              isGraphRendered: false,
              graphData: null,

              sortByParam: props.sortBy,
              sortOrderParam: props.sortOrder,

              // Item in notice view
              noticeItemId: props.noticeItemId,
              noticeItemType: props.noticeItemType,

              items: [],
              itemsIds: [],

              graphItemSetRoute: computed(() => {
                  return router.resolve( {
                      name: props.itemSetRouteName,
                      query: {
                          display_mode: 'graph',
                      }
                  });
              }),

              listItemSetRoute: computed(() => {
                  return router.resolve( {
                      name: props.itemSetRouteName,
                      query: {
                          display_mode: 'list',
                      }
                  });
              }),

              noticeItemRoute: computed(() => {
                  return router.resolve( {
                      name: props.itemRouteName,
                      params: { id : state.noticeItemId },
                      query: {
                          display_mode: 'notice'
                      }
                  });
              }),
          });

          return {
              state,
              selectPreviousItemInMobileMenu,
              selectNextItemInMobileMenu,
              menuMobileItemCssClass,
              openCollectionDescriptions,
              dataLoaded
          }

        }
    }

</script>