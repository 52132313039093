<template>

  <div class="notice-item notice-td">

    <Loading class="notice-item-content" v-if="state.isLoading" />

    <div class="notice-tools"></div>

    <div class="notice-item-columns">

        <div class="notice-item-column">
            <div class="notice-item-content" v-if="!state.isLoading">
              <div
                v-for="item in state.displayedItems"
                :class="'td-content td-depth-'+item.depth"
                :key="item.id"
                :id="getAnchorId(item.id)"
              >
                <div class="notice-media-title">
                  <h1 v-if="item.depth === 0">{{ item.title }}</h1>
                  <h2 v-if="item.depth > 0">{{ item.title }}</h2>
                </div>
                <div class="notice-media">
                  <div
                    v-if="item.mediaHTML"
                    v-html="item.mediaHTML"
                    class="notice-media article-content notice-media-html"
                  />
                </div>
              </div>
            </div>
        </div>
        <div class="notice-item-column">
            <div class="notice-td-context-menu notice-item-content">
                <div
                        v-for="item in state.displayedItems"
                        :class="'td-content td-depth-'+item.depth"
                        :key="item.id"
                >
                    <div class="notice-media-title">
                        <a :href="getAnchor(item.id)">{{ item.title }}</a>
                    </div>
                </div>
            </div>
        </div>
      </div>
  </div>

</template>

<script>

  import {onMounted, reactive} from "vue";
  import Loading from "../Loading";
  import {getMediasApiURL} from "../../js/api";
  import axios from "axios";
  import {useStore} from "vuex";

  export default {

    name: 'NoticeTreeTD',
    components: {
      Loading,
    },
    props: {
      itemId: {
        type: Number,
        required: false,
        default:0
      },
      itemJson: {
        type: Object,
        required: false,
        default:null
      },
      inPopUp: {
        type:Boolean,
        required: false,
        default: false
      },
    },
    methods: {
        getAnchorId(itemId) {
            return 'td-part-' + itemId;
        },
        getAnchor(itemId) {
            return '#td-part-' + itemId;
        }
    },
    emits: [
      'item-loaded'
    ],
    setup(props) {

      const store = useStore();

      // Recursive function :
      const getChildren = function(node, depth = 0) {
        const n = node.children.length;
        let i, child, items = [], j, m, childrenIds;
        for(i=0;i<n;i++) {
          child = node.children[i];
          items.push({
            id: child.id,
            title: child.title,
            medias: child.medias,
            depth: depth + 1
          });
          if (child.children && child.children.length) {
            childrenIds = getChildren(child, depth + 1);
            m = childrenIds.length;
            for(j=0;j<m;j++) {
              items.push(childrenIds[j]);
            }
          }
        }
        return items;
      };

      const getMediaIds = function(items) {
        const n = items.length;
        let i, item, medias, ids = [], j, m;
        for(i=0;i<n;i++) {
          item = items[i];
          medias = item.medias;
          if (Array.isArray(medias)) {
              m = medias.length;
              for(j=0;j<m;j++) {
                  ids.push(medias[j]);
              }
          }
        }
        return ids;
      };

      const findMedia = function(mediaId) {
        const n = state.medias.length;
        let i, media;
        for(i=0;i<n;i++) {
          media = state.medias[i];
          if (parseInt(media['o:id']) === mediaId ) {
            return media;
          }
        }
      };

      onMounted(() => {

        state.isLoading = true;

        // List of objects, respecting the tree order
        let itemsData = [{
          id: props.itemId,
          medias: props.itemJson.medias,
          title: props.itemJson.title,
          depth:0,
        }].concat(getChildren(props.itemJson));

        state.displayedItems = itemsData;

        // console.log('state.displayedItems', state.displayedItems);

        // Get list of json of medias of items
        const mediaIds = getMediaIds(itemsData);
        if (mediaIds.length === 0)
        {
          state.isLoading = false;
        }
        else
        {
          const mediaApiURL = getMediasApiURL(store.getters.apiRoot, mediaIds);
          const keyIdentity = store.getters.identityAndCredentialParameters;
          axios
            .get(mediaApiURL  + keyIdentity)
            .then(function (response) {

              // Array of Media json
              state.medias = response.data;

              let i, itemMedias, itemData, m, j, mediaJson, mediaHtml;
              const n = itemsData.length;
              for(i=0;i<n;i++)
              {
                itemData = itemsData[i];
                itemMedias = itemData.medias;

                if (itemMedias) {
                  m = itemMedias.length;
                  mediaHtml = '';
                  for(j=0;j<m;j++) {
                    mediaJson = findMedia(itemMedias[j]);
                    mediaHtml += mediaJson.data.html;
                  }
                  itemData.mediaHTML = mediaHtml;
                }
              }

              state.isLoading = false;
            });
        }
      });

      const state = reactive({
        isLoading: false,
        itemId: props.itemId,
        items: [],
        medias: [],
        displayedItems: [],
      });

      return {
        state
      };

    },
  }

</script>
