<template>
  <div :class="topCssClass" @click="closeMenus()" >
    <Header
            @click-on-advanced-search-menu="toggleAdvancedSearchMenu()"
            @close-advanced-search-menu="closeAdvancedSearchMenu()"
            @toggle-login-menu="toggleUserLoginWindow()"
    />

    <router-view />

    <BackToTopButton />

    <AdvancedSearchWindow
            :class="advancedSearchMenuOpenedCssClass"
            @close-advanced-search-menu="closeAdvancedSearchMenu()"
    />

    <LoginWindow
            :class="loginOpenedCssClass"
            @close-login-window="closeLoginWindow()"
            @open-register-window="openRegisterWindow()"
            @open-forgotten-password-window="openForgottenPasswordWindow()"
    />

    <ForgottenPasswordWindow
            :class="forgottenPasswordOpenedCssClass"
            @close-forgotten-password-window="closeForgottenPasswordWindow()"
            @open-login-window="closeForgottenPasswordAndopenLoginWindow()"
    />

    <RegistrationWindow
            v-if="state && state.isRegisterOpened"
            :class="registerOpenedCssClass"
            @register-success="openRegisterConfirmationWindow()"
            @close-register-window="closeRegisterWindow()"
    />

    <RegistrationConfirmationWindow
            v-if="state && state.isRegisterConfirmationOpened"
            :class="registerOpenedWindowCssClass"
            @close-register-confirmation-window="closeRegisterConfirmationWindow()"
    />

  </div>
</template>

<script>

  import './assets/style.css'
  import EventDispatcher from "./js/EventDispatcher";

  import {computed, onMounted, reactive} from "vue";
  import {useStore} from "vuex";
  import {useRoute, useRouter} from "vue-router";
  import { getCollectionsListApiURL } from './js/api.js';
  import { i18n, loadLanguageAsync } from './main.js';

  import Header from './components/Header/Header.vue'
  import AdvancedSearchWindow from './components/Search/AdvancedSearchWindow.vue'
  import LoginWindow from './components/UserForms/LoginWindow.vue'
  import RegistrationWindow from './components/UserForms/RegistrationWindow.vue'
  import RegistrationConfirmationWindow from './components/UserForms/RegistrationConfirmationWindow.vue'
  import BackToTopButton from './components/BackToTopButton.vue'
  import ForgottenPasswordWindow from "./components/UserForms/ForgottenPasswordWindow.vue";

  export default {
    name: 'App',
    components: {
      Header,
      AdvancedSearchWindow,
      LoginWindow,
      RegistrationWindow,
      RegistrationConfirmationWindow,
      ForgottenPasswordWindow,
      BackToTopButton
    },
    data() {
      return {
        advancedSearchMenu: false,
      }
    },
    computed: {
      currentRouteName() {
        return this.$route && this.$route.name ? this.$route.name.toLowerCase() : "";
      },
      topCssClass() {
        return [  this.currentRouteNameCssClass, this.withPopUpCssClass ].join(' ');
      },
      currentRouteNameCssClass() {
        const routeArray = this.currentRouteName.split(' ');
        if (routeArray.length === 1) {
          return this.currentRouteName;
        } else {
          return routeArray[0] + ' ' + routeArray.join('_');
        }
      },
      withPopUpCssClass() {
        return this.state.isRegisterOpened || this.state.isLoginOpened || this.state.isRegisterConfirmationOpened ? "with-user-popup" : "";
      },
      advancedSearchMenuOpenedCssClass() {
        return this.advancedSearchMenu ? "advanced-search-menu-opened": "advanced-search-menu-closed";
      },
      loginOpenedCssClass() {
        return this.state.isLoginOpened ? "connexion-menu-opened" : "connexion-menu-closed";
      },
      forgottenPasswordOpenedCssClass() {
        return this.state.isForgottenPasswordOpened ? "connexion-menu-opened" : "connexion-menu-closed";
      },
      registerOpenedCssClass() {
        return this.state.isRegisterOpened ? "inscription-menu-opened" : "inscription-menu-closed";
      },
      registerOpenedWindowCssClass() {
        return this.state.isRegisterConfirmationOpened ? "inscription-confirmation-opened" : "inscription-confirmation-closed";
      }
    },
    methods: {
      toggleAdvancedSearchMenu() {
        this.advancedSearchMenu = ! this.advancedSearchMenu;
      },
      closeAdvancedSearchMenu() {
        this.advancedSearchMenu = false;
      },
      closeLoginWindow() {
        this.state.isLoginOpened = false;
      },
      openRegisterWindow() {
        this.state.isLoginOpened = false;
        this.state.isRegisterOpened = true;
        this.state.isRegisterConfirmationOpened = false;
      },
      openForgottenPasswordWindow() {
        this.state.isForgottenPasswordOpened = true;
        this.state.isLoginOpened = false;
        this.state.isRegisterOpened = false;
        this.state.isRegisterConfirmationOpened = false;
      },
      closeForgottenPasswordWindow() {
        this.state.isForgottenPasswordOpened = false;
      },
      closeForgottenPasswordAndopenLoginWindow() {
        this.state.isForgottenPasswordOpened = false;
        this.state.isLoginOpened = true;
      },
      closeRegisterWindow() {
        this.state.isRegisterOpened = false;
      },
      openRegisterConfirmationWindow() {
        this.state.isLoginOpened = false;
        this.state.isRegisterOpened = false;
        this.state.isRegisterConfirmationOpened = true;
      },
      closeRegisterConfirmationWindow() {
        this.state.isRegisterConfirmationOpened = false;
      },
      closeMenus() {
      }
    },
    setup() {

      const route = useRoute();
      const router = useRouter();
      const store = useStore();
      const apiURL = getCollectionsListApiURL(store.getters.apiRoot);

      store.dispatch('getApi', apiURL).then(function(response){
        store.commit('storeCollections', response.data );
      });

      const state = reactive({
        isLoginOpened: false,
        isRegisterOpened: false,
        isRegisterConfirmationOpened: false,
        userIsConnected: computed(() => store.state.currentUser.id !== null ),
        currentUserId: computed(() => store.state.currentUser.id )
      });

      const toggleUserLoginWindow = function() {
        state.isLoginOpened = !state.isLoginOpened;
        state.isRegisterOpened = false;
      };

      const updateDocumentTitle = function(title) {
        document.title = store.state.siteName + " : " + title;
      };

      onMounted(() => {

        if (route.name === "Home Login") {
          state.isLoginOpened = true;
          updateDocumentTitle("Inscription");
        }

        if (route.name === "Home Register") {
          state.isRegisterOpened = true;
        }

        if (route.name === "Home") {
          state.isLoginOpened = false;
          state.isRegisterOpened = false;
        }

        // Language
        if (! new Date()) {
          loadLanguageAsync(store, i18n, 'en');
        }

        // Cf BreadCrumb
        EventDispatcher.on("document-title", updateDocumentTitle);

        router.afterEach((to) => {
            if (to.meta.title) {
              updateDocumentTitle(to.meta.title);
            }
        });

        // Process relatives < a > links from dynamic content (i.e. omeka HTML blocks) through router
        window.addEventListener('click', event => {
          // ensure we use the link, in case the click has been received by a subelement
          let { target } = event
          while (target && target.tagName !== 'A') target = target.parentNode
          // handle only links that do not reference external resources
          if (target && target.matches("a:not([href*='://'])") && target.href) {
            // some sanity checks taken from vue-router:
            // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
            const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event
            // don't handle with control keys
            if (metaKey || altKey || ctrlKey || shiftKey) return
            // don't handle when preventDefault called
            if (defaultPrevented) return
            // don't handle right clicks
            if (button !== undefined && button !== 0) return
            // don't handle if `target="_blank"`
            if (target && target.getAttribute) {
              const linkTarget = target.getAttribute('target')
              if (/\b_blank\b/i.test(linkTarget)) return
            }
            // don't handle same page links/anchors
            const url = new URL(target.href)
            const to = url.pathname.split(store.state.publicPath).join('/');
            if (window.location.pathname !== to && event.preventDefault) {
              event.preventDefault()
              router.push(to)
            }
          }
        })

      });

      return {
        state,
        toggleUserLoginWindow
      }
    }
  }

</script>

<style>
</style>
