<template>
  <label class="checkbox-label">
    <input type="checkbox"
           name="category-filter"
           @click="toggleCategoryCheckBox($event)"
           v-model="state.categoryChecked"
    />
    <span class="checkmark"></span>
    <span class="collection-title">{{ $t(categoryTitle) }}</span>
    <span class="collection-code">{{ categoryCode }}</span>
  </label>
  <ul class="list-items-collections">
    <li
          v-for="collection in state.visibleCollections" v-bind:key="collection.id"
          :class="collectionCssClass(collection)">
      <label class="checkbox-label">
        <input type="checkbox"
               :name="categoryCssClass"
               v-model="collection.checked"
               @click="toggleCheckBox($event, collection)"
        />
        <span class="checkmark"></span>
        <span class="collection-title">{{ collection.title }}</span>
      </label>
    </li>
  </ul>
</template>

<script>
  import {reactive, watch} from "vue";

  export default {
    name: 'CollectionsCheckboxGroup',
    props: {
      categoryTitle: {
        type: String,
        required: true
      },
      categoryName: {
        type: String,
        required: false
      },
      categoryCode: {
        type: String,
        required: false,
        default: ''
      },
      collections: {
        type: Array,
        required: true
      },
      multiple: {
        type: Boolean,
        default: true,
        required: false
      }
    },
    emits: [
      "changefiltercollection"
    ],
    computed:{
      categoryCssClass() {
        return this.categoryName + "-collection-filter";
      },
    },
    methods:{
      collectionCssClass(collection) {
        return "list-items-category-" + (collection.colorNo ? collection.colorNo : 0);
      }
    },
    setup(props, context) {

      const isCategoryChecked = function() {
        let i;
        const n = props.collections.length;
        for(i=0;i<n;i++) {
          if (props.collections[i].checked) {
            return true;
          }
        }
        return false;
      };

      const getVisibleCollections = function() {
        let i, visibleCollections = [], collection;
        if (props.multiple) {
          const n = props.collections.length;
          for(i=0;i<n;i++) {
            collection = props.collections[i];
              visibleCollections.push(collection);
          }
        }
        return visibleCollections;
      };

      const state = reactive({
        categoryChecked: isCategoryChecked(),
        collections: props.collections,
        visibleCollections: getVisibleCollections()
      });

      const getCheckedCheckboxes = function() {
        let i, collection;
        const n = state.collections.length;
        let checkedCollectionsIds = [];
        for(i=0;i<n;i++) {
          collection = state.collections[i];
          if (collection.checked) {
            checkedCollectionsIds.push(collection.id);
          }
        }
        return checkedCollectionsIds;
      };

      const toggleCategoryCheckBox = function($event) {
        const beforeChange = getCheckedCheckboxes();
        let i, collection;
        const n = state.collections.length;
        for(i=0;i<n;i++) {
          collection = state.collections[i];
          collection.checked = $event.target.checked;
        }
        const afterChange = getCheckedCheckboxes();
        onChangeCheckbox(afterChange, beforeChange);
      };

      const toggleCheckBox = function($event, collection) {
        const beforeChange = getCheckedCheckboxes();
        collection.checked = $event.target.checked;
        state.categoryChecked = isCategoryChecked();
        const afterChange = getCheckedCheckboxes();
        onChangeCheckbox(afterChange, beforeChange);
      };

      const onChangeCheckbox = function(afterCheckedCollectionsIds, beforeCheckedCollectionsIds) {
        context.emit("changefiltercollection", afterCheckedCollectionsIds, beforeCheckedCollectionsIds);
      };

      watch(props, () => {
        state.categoryChecked = isCategoryChecked();
      });

      return {
        state,
        toggleCheckBox,
        toggleCategoryCheckBox
      }
    }
  }
</script>
