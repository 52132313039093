<template>

  <GalleryListNoticesView
      v-if="state.collectionViews === 'list-gallery-item' || state.collectionViews === 'list-item'"
      :item-set-id="state.collectionId"
      :itemSetRouteName="state.collectionRouteName"
      :itemRouteName="state.collectionItemRouteName"
      :noticeItemType="state.collectionItemType"
      :default-display-mode="state.collectionDefaultView"
      :gallery-display-mode-available="state.collectionViews === 'list-gallery-item'"
      :key="state.collectionId"
  />

  <GalleryTreeNoticesView
      v-else-if="state.collectionViews === 'tree-gallery-item' || state.collectionViews === 'tree-item'"
      :item-set-id="state.collectionId"
      :itemSetRouteName="state.collectionRouteName"
      :itemRouteName="state.collectionItemRouteName"
      :noticeItemType="state.collectionItemType"
      :default-display-mode="state.collectionDefaultView"
      :gallery-display-mode-available="state.collectionViews === 'tree-gallery-item'"
      :key="state.collectionId"
  />

  <MapListNoticesView
      v-else-if="state.collectionViews === 'list-map-item'"
      :item-set-id="state.collectionId"
      :itemSetRouteName="state.collectionRouteName"
      :itemRouteName="state.collectionItemRouteName"
      :noticeItemType="state.collectionItemType"
      :default-display-mode="state.collectionDefaultView"
      :key="state.collectionId"
  />

  <TimelineListNoticesView
      v-else-if="state.collectionViews === 'list-timeline-item'"
      :item-set-id="state.collectionId"
      :itemSetRouteName="state.collectionRouteName"
      :itemRouteName="state.collectionItemRouteName"
      :noticeItemType="state.collectionItemType"
      :default-display-mode="state.collectionDefaultView"
      :key="state.collectionId"
  />

  <TraiteDocumentation
      v-else-if="state.collectionViews === 'list-td-item'"
      :item-set-id="state.collectionId"
      :itemSetRouteName="state.collectionRouteName"
      :itemRouteName="state.collectionItemRouteName"
      :default-display-mode="state.collectionDefaultView"
      :key="state.collectionId"
  />

  <CollectionView
      v-else
      :collectionId="state.collectionId"
      :key="state.collectionId"
  />


</template>

<script>

  import {onBeforeRouteLeave, onBeforeRouteUpdate, useRoute} from "vue-router";
  import {onMounted, reactive} from "vue";
  import {useStore} from "vuex";

  import GalleryListNoticesView from "@/components/CollectionViews/GalleryListNoticesView";
  import GalleryTreeNoticesView from "@/components/CollectionViews/GalleryTreeNoticesView";
  import MapListNoticesView from "@/components/CollectionViews/MapListNoticesView";
  import CollectionView from "@/components/Views/CollectionView";
  import TimelineListNoticesView from "@/components/CollectionViews/TimelineListNoticesView";
  import TraiteDocumentation from "@/views/TraiteDocumentation.vue";


  export default {
    components: {
      TraiteDocumentation,
      TimelineListNoticesView,
      CollectionView,
      GalleryTreeNoticesView,
      GalleryListNoticesView,
      MapListNoticesView
    },
    setup() {

      const route = useRoute();
      const store = useStore();

      const updateState = function(to) {

        // Route de la collection (cf les meta ajoutées dans routesFromSettings in main.js)
        const collectionRouteName = to.meta.collectionRouteName;
        const collectionId = to.meta.collectionId;

        if (collectionId) {

          // On récupère les settings de la collection
          const collectionInfos = store.getters.collectionInfosByProp('id', collectionId);

          const collectionItemRouteName = collectionRouteName + " Item";
          const collectionItemType = collectionInfos.itemType ? collectionInfos.itemType : "item";
          const collectionViews = collectionInfos.views ? collectionInfos.views : "list-item";

          let collectionDefaultView = collectionInfos.defaultView ? collectionInfos.defaultView : "list";

          // RQ : Si la vue Galerie n'est pas proposée, on impose la vue "Liste" comme vue par défaut :
          if (( collectionDefaultView ==='gallery') && ( collectionViews === 'list-item' || collectionViews === 'tree-item')) {
            collectionDefaultView = "list";
          }

          state.collectionId = collectionId;
          state.collectionRouteName = collectionRouteName;
          state.collectionItemRouteName = collectionItemRouteName;
          state.collectionItemType = collectionItemType;
          state.collectionViews = collectionViews;
          state.collectionDefaultView = collectionDefaultView;

        }
      }


      //
      // Lifecycle Hooks
      //

      onMounted(() => {
        updateState(route);
      });

      // Different route / Same component
      onBeforeRouteLeave((to) => {
        updateState(to);
      });

      // Same route / Same component
      onBeforeRouteUpdate((to) => {
        updateState(to);
      });


      //
      // State
      //

      const state = reactive({
        collectionId: null,
        collectionRouteName: null,
        collectionItemRouteName: null,
        collectionItemType: null,
        collectionViews: null,
        collectionDefaultView: null
      });

      return {
        state
      }
    }
  }

</script>