<template>
  <span v-if="name"><router-link :to="{ name:name, params, query }">{{ $t(label) }}</router-link> &gt; </span>
  <span v-if="!name">{{ shortenedLabel }}</span>
</template>

<script>

const MAX_LENGTH = 30;

export default {
  name: 'Logo',
  props: {
    name: {
      type: String,
      required: false
    },
    params: {
      type: Object,
      required: false
    },
    query: {
      type: Object,
      required: false
    },
    label: {
      type: String,
      required: true
    },
  },
  computed: {
    shortenedLabel() {
      if (this.label.length <= MAX_LENGTH)
      {
        return this.$t(this.label);
      }
      else
      {
        // Titres longs des notices (ne sont pas dans les dictionnaires i18n)
        const words = this.label.split(' ');
        let i, shortLabel = '';
        for(i=0;i<words.length;i++)
        {
          shortLabel += words[i];

          if (shortLabel.length > MAX_LENGTH)
          {
            if (shortLabel.length < this.label.length)
            {
              shortLabel += "...";
            }

            break;

          } else {
            shortLabel += " ";
          }
        }

        return shortLabel;
      }
    }
  }
}

</script>
