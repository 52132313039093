<template>
  <form>
    <textarea
      v-model="content"
      :placeholder="$t('Écrivez une Annotation ici...')"
    />
    <button @click="sendAnnotation($event)">{{ $t("Publier") }}</button>
  </form>
</template>

<script>

  export default {

    data() {
      return {
        content:''
      }
    },
    emits: [
      "add-new-annotation"
    ],
    methods: {
      sendAnnotation($event) {
        $event.preventDefault();
        if (this.content.length > 0) {

          // Vers le composant parent Annotations
          this.$emit("add-new-annotation", { content: this.content });

          // On vide le champ
          this.content = '';
        }
      }
    }
  }

</script>
