<template>
  <div class="view">
    <div class="breadcrumbs-and-title">
      <div class="breadcrumbs">
        <BreadCrumb :paths="breadcrumbs" />
      </div>
      <h1>
        {{ $t(state.itemCollectionTitle) }}
        <a
          v-if="state.collectionDescriptions"
          @click="openCollectionDescriptions($event)"
          class="collection-description-toggle"
          href="#">
        </a>
      </h1>
    </div>
    <div class="contents" :class="withPopUpCssClass">

      <div class="collection-header" v-if="state.collectionDescriptions">
        <CollectionDescription
                class="with-padding"
                :class="state.collectionDescriptionVisibleCssClass"
                :descriptions="state.collectionDescriptions"
        />
      </div>

      <Loading class="notice-item-content with-padding no-header" v-if="state.isLoading" />

      <NavigationItem
              v-if="!state.isLoading"
              class="with-padding"
              :previousItemId="state.previousItemId"
              :nextItemId="state.nextItemId"
              :sortByParam="state.sortByParam"
              :sortOrderParam="state.sortOrderParam"
              :collectionPath="collectionPath"
      />

      <NoticeMedia
              v-if="state.isNotice && !state.isLoading"
              class="with-padding"
              :itemJson="state.itemJson"
              :tools="hasTools"
              displayMode="reference"
              @item-loaded="itemLoaded"
              @change-item-notice="openDetailedNotice"
      />

      <NoticeArticle
              v-if="state.isNoticeArticle && !state.isLoading"
              class="with-padding"
              :itemJson="state.itemJson"
              :tools="hasTools"
              @item-loaded="itemLoaded"
              @change-item-notice="openDetailedNotice"
      />

      <NoticeExtrait
              v-if="state.isNoticeExtrait && !state.isLoading"
              class="with-padding"
              :itemJson="state.itemJson"
              :tools="hasTools"
              @item-loaded="itemLoaded"
              @change-item-notice="openDetailedNotice"
      />

    </div>

    <!-- Pour les items liés -->
    <NoticePopUp
            v-if="detailedNoticeItemIsOpened"
            v-bind:itemId="detailedNoticeId"
            v-bind:itemType="detailedNoticeType"
            @close-detailed-item-notice="closeDetailedNotice"
            :class="detailedNoticeCssClass"
    />

  </div>
</template>

<script>

  import {computed, onMounted, reactive, watch} from "vue";
  import {onBeforeRouteUpdate, useRoute} from "vue-router";
  import {useStore} from "vuex";

  import {getItemApiURL, getCollectionsItemIdsApiURL, getCollectionApiURL} from "../../js/api.js";
  import {getLocalizedValue} from "../../js/functions";

  import BreadCrumb from "../Header/BreadCrumb";
  import CollectionDescription from "../Header/CollectionDescription";
  import NavigationItem from "../Lists/NavigationItem";
  import NoticeMedia from "../Notices/NoticeMedia";
  import NoticeArticle from "../Notices/NoticeArticle";
  import NoticeExtrait from "../Notices/NoticeExtrait";
  import NoticePopUp from "../Notices/NoticePopUp";
  import Loading from "../Loading";

  export default {
    name: 'ItemView',
    components: {
      BreadCrumb,
      CollectionDescription,
      NavigationItem,
      NoticeMedia,
      NoticeArticle,
      NoticeExtrait,
      NoticePopUp,
      Loading
    },
    data() {
      return {
        detailedNoticeItemIsOpened:false,
        detailedNoticeId:null,
        detailedNoticeType:null,
        itemTitle:""
      }
    },
    computed: {
      itemId() { return parseInt(this.$route.params.id) },
      hasTools() { return true },
      detailedNoticeCssClass() {
        return this.detailedNoticeItemIsOpened ? "opened" : "";
      },
      withPopUpCssClass() {
        return this.detailedNoticeItemIsOpened ? "with-popup" : "";
      },
      collectionPath() {
        return this.$route.meta.parentRouteName ? this.$router.resolve({ name:this.$route.meta.parentRouteName }).href : '';
      },
      breadcrumbs() {
        if (this.state.itemCollectionTitle) {
          if (this.$route.meta.parentRouteName) {
            // Cf router
            return [
              { name: 'Home', label:'Accueil' },
              { name: this.$route.meta.parentRouteName, label: this.state.itemCollectionTitle },
              { label: this.itemTitle }
            ]
          } else {
            return [
              { name: 'Home', label:'Accueil' },
              { name: "Collection", params: { id: this.state.itemCollectionId }, label: this.state.itemCollectionTitle },
              { label: this.itemTitle }
            ]
          }
        } else {
          return [
            { name: 'Home', label:'Accueil' }
          ]
        }
      }
    },
    methods: {
      openDetailedNotice(item) {
        this.detailedNoticeId = item.itemId;
        this.detailedNoticeType = "item";
        this.detailedNoticeItemIsOpened = true;
      },
      openArticle(itemId) {
        this.detailedNoticeId = itemId;
        this.detailedNoticeType = "article";
        this.detailedNoticeItemIsOpened = true;
      },
      closeDetailedNotice() {
        this.detailedNoticeId = null;
        this.detailedNoticeType = null;
        this.detailedNoticeItemIsOpened = false;
      },
      itemLoaded(itemInfos) {
        this.itemTitle = itemInfos.title;
      }
    },
    setup() {

      const route = useRoute();
      const store = useStore();

      const updateNavigation = function() {
        const currentItemId = state.currentItemId;
        const itemsIds = state.itemsIds;
        if (itemsIds && itemsIds.length) {
          const currentItemPosition = itemsIds.indexOf(currentItemId);
          state.previousItemId = currentItemPosition > 0 ? itemsIds[currentItemPosition - 1] : -1;
          state.nextItemId = currentItemPosition < itemsIds.length - 1 ? itemsIds[currentItemPosition + 1] : -1;
        }
      };

      const loadItem = function() {

        state.isLoading = true;
        state.itemType = null;

        const apiRoot = store.getters.apiRoot;
        const apiURL = getItemApiURL(apiRoot, state.currentItemId);

        // API de l'item :
        return store.dispatch('getApi', apiURL).then(function(response){

          // Json
          state.itemJson = response.data;

          // Item ou Article ?
          state.itemType = "item";
          state.isLoading = false;

          // Collection de l'item
          const itemSets = state.itemJson['o:item_set'];
          if (itemSets && itemSets.length)
          {
            // Id de la collection
            const itemCollectionId = parseInt(itemSets[0]['o:id']);

            if( state.itemCollectionId !== itemCollectionId)
            {
              state.itemCollectionId = itemCollectionId;

              // Type d'item : Item, Article, ou Extrait ?
              const articlesCollectionsIds = store.getters.collectionIdsByProp("itemType", "article");
              const extraitsCollectionsIds = store.getters.collectionIdsByProp("itemType", "extrait");

              if (articlesCollectionsIds.indexOf(itemCollectionId) !== - 1) {
                state.itemType = "article";
              } else if (extraitsCollectionsIds.indexOf(itemCollectionId) !== - 1) {
                state.itemType = "extrait";
              } else {
                state.itemType = "item";
              }

              // Récupération du titre et de la description de la collection
              updateCollectionDescription();

              // Chargement des autres items pour la navigation Suivant/Précédent
              loadCollectionIds();

            } else {

              // La collection n'a pas changé
              updateNavigation();
            }

          }

          console.log(state.itemType)
        });
      };

      const updateCollectionDescription = function() {
        if(! isNaN(state.itemCollectionId)) {
          const apiURL = getCollectionApiURL( store.getters.apiRoot, state.itemCollectionId );
          store.dispatch('getApi', apiURL).then(function(response){
            const collectionJson = response.data;
            state.itemCollectionTitle = getLocalizedValue(collectionJson['dcterms:title'], store.state.currentLanguage, collectionJson['o:title']);
            state.collectionDescriptions = collectionJson['dcterms:description'];
          });
        }
      };

      const openCollectionDescriptions = function($event) {
        $event.preventDefault();
        state.isCollectionDescriptionVisible = ! state.isCollectionDescriptionVisible;
      };

      const loadCollectionIds = function() {

        if (route.query.sort_by && (route.query.sort_by.length > 0)) {
          state.sortByParam = route.query.sort_by;
        }

        if (route.query.sort_order && (route.query.sort_order.length > 0)) {
          state.sortOrderParam = route.query.sort_order;
        }

        const options = "";
        let collectionsIds = [ state.itemCollectionId ];

        const apiURL = getCollectionsItemIdsApiURL(
                store.getters.apiRoot,
                collectionsIds,
                options,
                state.sortByParam,
                state.sortOrderParam
        );

        store.dispatch('getApi', apiURL).then(function(response){
          state.itemsIds = response.data.items;
          updateNavigation();
        });
      };



      //
      // Lifecycle Hooks
      //

      onMounted(() => {
        state.currentItemId = parseInt(route.params.id);
        loadItem();
      });

      /*
      onBeforeRouteLeave((to, from) => {
        // When a new route uses the same component (nb : onBeforeRouteUpdate is not called in this case) :
        if (from.name !== to.name) {
          state.currentItemId = parseInt(to.params.id);
          state.sortByParam = to.query.sort_by;
          state.sortOrderParam = to.query.sort_order;
          loadItem();
        }
      });
       */

      onBeforeRouteUpdate((to, from, next) => {
        if (state.currentItemId !== to.params.id)
        {
          state.currentItemId = parseInt(to.params.id);
          state.sortByParam = to.query.sort_by;
          state.sortOrderParam = to.query.sort_order;

          loadItem();
          next();

        } else if ((state.sortByParam !== to.query.sort_by) || (state.sortOrderParam !== to.query.sort_order)) {

          state.sortByParam = to.query.sort_by;
          state.sortOrderParam = to.query.sort_order;

          loadCollectionIds();
          next();
        }
      });

      watch(() => store.state.currentLanguage, () => {
        updateCollectionDescription();
        loadCollectionIds();
      });


      //
      // State
      //

      const state = reactive({

        collectionDescriptions: null,
        isCollectionDescriptionVisible:false,
        collectionDescriptionVisibleCssClass: computed( () => {
          return state.isCollectionDescriptionVisible ? "opened" : "";
        }),

        sortByParam: '',
        sortOrderParam: '',
        itemsIds: [],
        currentItemId: -1,
        previousItemId:-1,
        nextItemId:-1,
        itemType: "",
        itemCollectionTitle: "",
        itemCollectionId: 0,
        isNoticeItem: computed(() => state.itemType === "item"),
        isNoticeMedia: computed(() => state.itemType === "media"),
        isNotice: computed(() => state.isNoticeItem || state.isNoticeMedia ),
        isNoticeArticle: computed(() => state.itemType === "article"),
        isNoticeExtrait: computed(() => state.itemType === "extrait"),
      });

      return {
        state,
        updateCollectionDescription,
        openCollectionDescriptions
      }
    }
  }

</script>
