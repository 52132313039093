<template>
  <SitePage />
</template>

<script>

  import {onMounted} from "vue";
  import {useStore} from "vuex";
  import {useRouter} from "vue-router";
  import {getLogoutApiURL} from "../js/api";
  import SitePage from "@/views/SitePage";
  import axios from "axios";

  export default {
    name: 'Logout',
    components: {
      SitePage
    },
    setup() {

      const router = useRouter();
      const store = useStore();

      const localLogout = function() {

        // Effacement des données utilisateur
        store.dispatch("logout");

        // Retour à l'accueil
        router.push({ name:'Home' });

      };

      onMounted(() => {

        // Api Omeka (Guest Api Module)
        const apiURL = getLogoutApiURL(store.getters.apiRoot);
        const keyIdentity = "?" + store.getters.identityAndCredentialParameters.substr(1);

        axios.get(apiURL + keyIdentity).then(function () {
          localLogout();
        }).catch(error => {
          console.error("log out error", error);
          localLogout();
        });

      });
    }
  }

</script>
