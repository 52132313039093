<template>

  <div class="notice-item-quote">
    <div class="notice-item-field-content">
      <h3 class="notice-item-field-title">{{ $t("Pour citer cette notice") }} :</h3>
      <p><span v-if="creator.length > 0">{{ creator }}, </span>« {{ title }} » {{ projectName }}, {{ today }}</p>
      <p><a href="#" @click="blockLink($event)" style="cursor:default;">{{ noticeUrl }}</a></p>
    </div>
    <div
      class="notice-item-quote-buttons"
      :class="copiedCssClass"
    >
      <a href="#" class="copy-button" @click="copyToClipboard($event)">{{ $t("Copier la citation") }}</a>

      <a
        class="download-notice-button"
        style="display:none !important;"
        @click="downloadPDF($event)"
        href="#">{{ $t("Exporter en PDF") }}</a>

      <NoticeDownloadLink
        style="display:none !important;"
        :linkLabel="$t('Exporter en CSV')"
        output="csv"
        :resourceId="itemId"
      />
    </div>
  </div>

  </template>

<script>

  // <a href="#">Partager la citation</a>

  import axios from "axios";
  import { jsPDF } from "jspdf";
  import {dateJsToHuman} from "../../js/viz-utils";
  import NoticeDownloadLink from "./NoticeDownloadLink";
  import {getItemExportApiURL} from "../../js/api";

  export default {
    components: {
      NoticeDownloadLink
    },
    props: {
      creator: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      itemId: {
        type: Number,
        required: true,
      },
      itemSetId: {
        type: Number,
        required: false,
        default: 0
      },
    },
    data() {
      return {
        isCopied: false
      }
    },
    computed: {
      noticeUrl() {

        const url = window.location.href;
        const url_parts = url.split("/");
        const urlBase = url_parts[0] + "//" + url_parts[2];
        const lang = this.$store.state.currentLanguage;

        let itemURL = urlBase + this.$router.resolve({
          name: "Item",
          params: { id: this.itemId }
        }).href;

        if ( lang !== "fr") {
          itemURL  += "?lang=" + lang;
        }

        return itemURL;
      },
      today() {
        return dateJsToHuman(new Date());
      },
      copiedCssClass() {
        return this.isCopied ? 'copied' : '';
      },
      projectName() {
        const settings = this.$store.state.settings;
        const lang = this.$store.state.currentLanguage;
        if (settings && settings.notices[lang]) {
          return settings.notices[lang].project;
        }
        return '';
      }
    },
    methods: {
      async copyToClipboard($event) {
        $event.preventDefault();
        if (!navigator.clipboard) {
          return;
        }
        try {
          var text = [
            this.creator,
            [ this.title, this.projectName, this.today ].join(', '),
            this.noticeUrl
          ].join('\n');

          await navigator.clipboard.writeText(text);

          // Tooltip:
          this.isCopied = true;
          setTimeout( this.resetCopied, 1100 );

        } catch (error) {
          console.error("copy failed", error);
        }
      },
      resetCopied() {
        this.isCopied = false;
      },
      downloadPDF($event) {

        $event.preventDefault();
        $event.stopPropagation();

        const store = this.$store;
        const siteId = store.state.settings.site.id;
        const itemId = this.itemId;
        const apiURL = getItemExportApiURL(store.getters.apiRoot, itemId, 'txt', siteId);

        axios.get(apiURL).then(function (response) {

          // cf http://raw.githack.com/MrRio/jsPDF/master/docs/jsPDF.html
          const doc = new jsPDF();
          doc.setFontSize(10);
          doc.setLineHeightFactor(1.25);

          let lines = doc.splitTextToSize(response.data, 180);
          let top = 10;
          let lineNo = 0;
          let lineContent, previousLineContent;

          for(let i=0; i< lines.length; i++) {

            lineContent = lines[i].replace(/’/gi, "'");

            if ((lineContent === "") && (previousLineContent === "")) {
              continue;
            }

            lineNo ++;
            top += 5;

            doc.text(lineContent, 10, top);

            if (lineNo > 55) {
              doc.addPage();
              top = 0;
              lineNo = 0;
            }

            previousLineContent = lineContent;
          }

          doc.save("item" + itemId + ".pdf");
        });

      }
    }
  }

</script>
