<template>

    <div class="gallery">

      <div class="list-pagination top-list-pagination" >
        <div class="pagination-sort-options">
          <ListSortMenu
              @changesortquery="changeSortQuery"
              :sortByParam="state.sortByParam"
              :sortOrderParam="state.sortOrderParam"
          />
        </div>
        <ListCount
                :element-count="state.items.length"
                class="cards-count"
        />
        <ListPagination
              v-if="state.pageCount > 1"
              :pageCount="state.pageCount"
              :pageNo="state.pageNo"
              @change-pagination-page="displayPage"
        />
      </div>

      <ul class="cards curated-item-cards with-padding">
        <li
                v-for="collectionSelection in state.items" v-bind:key="collectionSelection"
                class="card"
        >
          <CuratedCollectionsCard
                  :collectionJson="collectionSelection"
                  :from-display-mode="fromDisplayMode"
          />
        </li>
      </ul>

      <div class="list-pagination bottom-list-pagination with-padding" v-if="state.items.length > 0">
        <div class="pagination-sort-options">
        </div>
        <ListPagination
                v-if="state.pageCount > 1"
                :pageCount="state.pageCount"
                :pageNo="state.pageNo"
                @change-pagination-page="displayPage"
        />
      </div>
    </div>

</template>

<script>

  import {useRoute, useRouter} from "vue-router";
  import {reactive, computed} from "vue";
  import ListPagination from "../../components/Lists/ListPagination";
  import ListCount from "../../components/Lists/ListCount";
  import ListSortMenu from "../../components/Lists/ListSortMenu";
  import CuratedCollectionsCard from "../../components/Curation/CuratedCollectionsCard";

  const itemsByPage = 15;

  export default {

    name: 'CuratedCollectionsGallery',
    components: {
      ListPagination,
      ListSortMenu,
      ListCount,
      CuratedCollectionsCard
    },
    props: {
      items: {
        type: Array,
        required: true
      },
      entityType: {
        type: String,
        required: true,
        default:"item"
      },
      fromDisplayMode: {
        type:String,
        required: false,
        default:'gallery'
      },
    },
    setup(props) {

      const route = useRoute();
      const router = useRouter();

      const changeSortQuery = function( changes ) {
       router.push({
          name: route.name,
          query : {
            sort_by : changes.sortBy,
            sort_order : changes.sortOrder,
            display_mode: route.query.display_mode
          }
        });
      };


      /* Page State */

      const state = reactive({
        items: props.items,
        pageNo: computed(() => isNaN(route.query.p) ? 1 : parseInt(route.query.p) ),
        pageCount: computed(() => Math.ceil( state.items.length / itemsByPage )),
        displayedItems: computed( () => {
          const startItemNo = itemsByPage * (state.pageNo - 1);
          const endItemNo = Math.min(startItemNo + itemsByPage, state.items.length);
          let i, items = [];
          for(i=startItemNo;i<endItemNo;i++) {
            items.push(state.items[i]);
          }
          return items;
        }),
        displayedItemsIds: computed(() => {
          let i, itemsIds = [];
          let items = state.displayedItems;
          const n = items.length;
          for(i=0;i<n;i++) {
            itemsIds.push(items[i]['o:id']);
          }
          return itemsIds;
        }),
        sortByParam: 'title',
        sortOrderParam: 'asc'
      });

      return {
        state,
        changeSortQuery
      }

    },
    computed: {
      currentRoutePath() {
        return this.$route.path;
      }
    },
    methods: {
      displayPage(pageNo) {
        // On modifie la route en cours avec le paramètre p=...
        // Ce changement déclenchera, via onBeforeUpdate
        // de ce composant ListeItems, l'affichage de la nouvelle page

        let routeCopy = {
          name : this.$route.name,
          query : { ...this.$route.query },
          params : { ...this.$route.params }
        };

        // Nouvelle page
        routeCopy.query.p = pageNo;

        this.$router.push(routeCopy);
      }
    }
  }

</script>
