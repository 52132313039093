<template>
  <div class="view">
    <div class="breadcrumbs-and-title">
      <div class="breadcrumbs">
        <BreadCrumb :paths="breadcrumbs" />
      </div>
      <h1>{{ $t(state.pageTitle) }}</h1>
    </div>
    <div class="contents" :class="withPopUpCssClass">
      <div class="contents-wrapper with-visualization" :class="displayModeCssClass">

        <div class="constellation" :class="constellationCssClass">

          <!-- Graphe SVG des collections de l'encyclopédie -->
          <VisualisationEncyclopedie
              feedMode="manual"
              :itemsByCollection="state.itemsForEncyclopedia"
              @selectitem="openItemVisualization"
              @changecategoriesfiltermenu="changeCategoriesFilterMenu"
          />

            <!-- Onglet et panier -->
            <div class="contents-header with-padding">
              <!-- Toggle Graphe / Liste / Notices -->
              <ul class="contents-mode">
                <li class="contents-list-mode">
                  <router-link :to="state.listItemSetRoute">
                    {{ $t("Liste") }}
                  </router-link>
                </li>
                <li class="contents-notice-mode">
                  <router-link :to="state.noticeItemRoute">
                    {{ $t("Notices") }}
                  </router-link>
                </li>
                <!--
                <li class="contents-graph-mode">
                  <router-link :to="state.graphItemSetRoute">
                    {{ $t("Notices de l'encyclopédie documentaire") }}
                  </router-link>
                </li>
                -->
              </ul>
              <!-- Panier -->
              <div class="constellation-basket" v-if="state.userIsConnected">
                <router-link to="/mon-compte/mon-panier">{{ basketCount }}</router-link>
              </div>
            </div>

            <div class="contents-list-mode-content with-sort-options" v-if="! state.isLoading" >

              <!-- Mode Liste : alphabet -->
              <ul
                  v-if="state.isListDisplayMode && state.items"
                  class="references-abc with-padding"
              >
                <li v-for="letter in state.letters" v-bind:key="letter">
                  <router-link :to="getLetterRoute(letter)" :class="getLetterCssClass(letter)">
                    {{ letter }}
                  </router-link>
                </li>
              </ul>

              <Loading class="no-header with-padding" v-if="state.isLoading" />

              <!-- Liste des items -->
              <ListReferences
                  v-if="state.isListDisplayMode && state.items"
                  :facets=false
                  :class="listReferencesCssClass"
                  :items="state.items"
                  :titleFieldName="state.titleFieldName"
                  :filter-panel="state.filterPanel"
                  :filter-panel-options="state.filterOptions"
                  :active-filter-id="state.filterId"
                  :queryRouteName="itemRouteName"
                  @opendetailednotice="openDetailedNotice"
                  @openitemvisualization="openItemVisualization"
                  @downloaditemnotice="openDownloadNoticePopUp"
                  @selectfilter="selectFilter"
                  @previousfilter="selectPreviousFilter"
                  @nextfilter="selectNextFilter"
              />

            </div>

            <!-- Mode Notice -->
            <NoticesItems
                v-if="state.isNoticeDisplayMode && state.items"
                :items-ids="state.itemsIds"
                :notice-item-id="state.noticeItemId"
                :openGraphInPopUp="true"
                @open-item-graph="openItemVisualization"
                @opendetailednotice="openDetailedNotice"
                @item-loaded="itemLoaded"
            />

          <!-- Graphe SVG d'un Item -->
          <VisualisationItem
              :class="itemVisualizationCssClass"
              :itemId="itemVisualizationItemId"
              @opendetailednotice="openDetailedNotice"
              @closeitemvisualization="closeItemVisualization"
              @notice-loaded="itemLoaded"
          />

        </div>
      </div>
    </div>

    <NoticePopUp
            v-if="detailedNoticeItemIsOpened"
            v-bind:itemId="detailedNoticeId"
            v-bind:itemType="detailedNoticeType"
            @close-detailed-item-notice="closeDetailedNotice"
            :class="detailedNoticeCssClass"
    />

    <NoticeDownloadPopUp
          v-if="downloadNoticeItemId"
          :itemId="downloadNoticeItemId"
          :itemType="downloadNoticeItemType"
          :class="downloadNoticePopupCssClass"
          @closedownloaditemnotice="closeDownloadNoticePopUp"
    />

  </div>
</template>
<script>

  import {computed, onMounted, reactive, watch} from "vue";
  import { useStore } from "vuex";
  import {onBeforeRouteLeave, onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";
  import {getReferencesURL} from "../js/api";
  import {getLocalizedValue} from "../js/functions";
  import axios from "axios";

  import ListReferences from "../components/Lists/ListReferences";
  import Loading from "../components/Loading";
  import NoticePopUp from "../components/Notices/NoticePopUp";
  import NoticeDownloadPopUp from "../components/Notices/NoticeDownloadPopUp";
  import BreadCrumb from "../components/Header/BreadCrumb";
  import VisualisationItem from '../components/Visualization/VisualisationItem';
  import VisualisationEncyclopedie from "../components/Visualization/VisualisationEncyclopedie";
  import NoticesItems from "../components/Lists/NoticesItems";

  export default {
    components: {
      NoticesItems,
      VisualisationEncyclopedie,
        ListReferences,
        Loading,
        NoticePopUp,
        NoticeDownloadPopUp,
        BreadCrumb,
        VisualisationItem
    },
    props: {
      itemSetRouteName: {
        type: String,
        required: false,
        default:'Parcourir'
      },
      itemRouteName: {
        type: String,
        required: false,
        default:'Parcourir Item'
      },
      defaultDisplayMode: {
        type: String,
        required: false,
        default:"list"
      },
    },
    data() {
      return {
        detailedNoticeItemIsOpened:false,
        detailedNoticeId:null,
        detailedNoticeType:null,

        itemVisualizationItemId:null,
        itemVisualizationIsOpened:null,

        downloadNoticePopupIsOpened:false,
        downloadNoticeItemId:null,
        downloadNoticeItemType: 'item',

        filterCategoriesMenuIsOpened:false
      }
    },
    computed: {
        constellationCssClass() {
            return this.filterCategoriesMenuIsOpened === true ? "with-filters" : "";
        },
        detailedNoticeCssClass() {
            return this.detailedNoticeItemIsOpened ? "opened" : "";
        },
        itemVisualizationCssClass() {
            return this.itemVisualizationIsOpened === true ? "opened" : "closed";
        },
        displayModeCssClass() {
            return this.state.displayMode + "-mode";
        },
        withPopUpCssClass() {
            return this.detailedNoticeItemIsOpened || this.itemVisualizationIsOpened ? "with-popup" : "";
        },
        downloadNoticePopupCssClass() {
            return this.downloadNoticePopupIsOpened === true ? "opened" : "";
        },
        listReferencesCssClass() {
            return 'references-metadata references-metadata-' + this.state.metadata.split(":").join('-');
        },
        currentRoutePath() {
            return this.$route.path;
        },
        basketCount() {
            return this.$store.state.basket.length;
        },
        breadcrumbs() {
          if ((this.state.displayMode === "notice") && this.state.noticeItemTitle) {
            return [
              { name: 'Home', label:'Accueil' },
              { name: 'Parcourir', label:'Parcourir', query: this.state.listItemQuery },
              { label: this.state.noticeItemTitle }
            ]
          } else {
            return [
              { name: 'Home', label:'Accueil' },
              { label: 'Parcourir' }
            ]
          }
        }
    },
    methods: {
        openDetailedNotice(itemId, itemType = "item") {
            this.detailedNoticeId = itemId;
            this.detailedNoticeType = itemType;
            this.detailedNoticeItemIsOpened = true;
        },
        closeDetailedNotice() {
            this.detailedNoticeId = null;
            this.detailedNoticeType = null;
            this.detailedNoticeItemIsOpened = false;
        },
        openItemVisualization(itemId) {
            this.itemVisualizationIsOpened = true;
            this.itemVisualizationItemId = itemId;
        },
        closeItemVisualization() {
            this.itemVisualizationItemId = null;
            this.itemVisualizationIsOpened = false;
        },
        changeCategoriesFilterMenu(menuIsOpened) {
            this.filterCategoriesMenuIsOpened = menuIsOpened;
        },
        openDownloadNoticePopUp(item) {
            this.downloadNoticePopupIsOpened = true;
            this.downloadNoticeItemId = item.id;
        },
        closeDownloadNoticePopUp() {
            this.downloadNoticePopupIsOpened = false;
            this.downloadNoticeItemId = null;
        },
    },
    setup(props) {

      const router = useRouter();
      const route = useRoute();
      const store = useStore();

      // Letters
      let i, letters = [];
      for(i=0;i<26;i++) {
        letters.push(String.fromCharCode(65+i));
      }

      //
      // Appel à l'api de recherche
      //

      const loadResults = function() {

        state.isLoading = true;

        state.activeFilterParams = getActiveFilterParams(state.filterId);

        let metadata = state.metadata;
        const letter = state.letter;

        let customvocabs;

        switch(metadata){
            case "o:title" :
                state.pageTitle = "Parcourir par titre";
                metadata = "dcterms:title";
                state.titleFieldName = metadata;
                break;

            case "dcterms:title" :
            case "dcterms:alternative" :
                state.pageTitle = "Parcourir par titre";
                state.titleFieldName = metadata;
                break;

            case "dcterms:subject" :
                state.pageTitle = "Parcourir par mot-clé";
                state.titleFieldName = "o:title";
                customvocabs = state.activeFilterParams.customvocabs;

                break;

                default:
                state.pageTitle = "Parcourir";
                state.titleFieldName = "o:title";
        }

        // console.log("metadata", metadata, " lettre:", letter);

        let lang = "" ;

        switch(state.lang) {
            case undefined:
            case "":
            case "fr":
                lang =  ["fra", null];
                break;
            case "en":
                lang = ["eng"];
                break;
            default:
                lang = state.lang;
        }

        const keyIdentity = store.getters.identityAndCredentialParameters;
        const apiURL = getReferencesURL( store.getters.apiRoot,  metadata, letter, store.state.queryItemSetsIds, customvocabs, lang);

        // Chargement des résultats :

        axios.get(apiURL + keyIdentity).then(function(response){

          const items = response.data.refs["o:references"];
          const n = items.length;
          let i, item, itemSets, itemSetId, firstItemId, collectionSettings;
          let j, resource, resources;

          let filteredItems = [], itemsIds = [];
          let collectionRef, encyclopedieCollectionForViz, encyclopedieByCollection = [];

          for(i=0;i<n;i++)
          {
            item = items[i];

            resources = item.resources;
            let filteredResources = [];

            for(j=0;j<resources.length;j++)
            {
              resource = resources[j];
              itemSets = resource["o:item_set"];
              if (itemSets && itemSets.length)
              {
                itemSetId = itemSets[0]['o:id'];
                if (itemSetId)
                {
                  // Si l'item fait partie des collections (encyclopédie, corpus, ...)

                  // On récupère les infos spécifiques au site :
                  collectionSettings = store.getters.collectionsSettings(itemSetId);

                  // On note la route de chaque item (vers le corpus, l'encyclopédie, etc...)
                  resource.encyclopedie = collectionSettings ? collectionSettings.encyclopedie : false;

                  // On prépare les données pour le graphe des items de l'encyclopedie
                  if (resource.encyclopedie)
                  {
                    collectionRef = "c" + itemSetId;
                    encyclopedieCollectionForViz = encyclopedieByCollection[collectionRef];

                    // Si on n'a pas encore rencontré la collection de l'item :
                    if (! encyclopedieCollectionForViz) {
                      encyclopedieCollectionForViz = encyclopedieByCollection[collectionRef] = {
                        id: collectionSettings.id,
                        title: collectionSettings.title,
                        total:0,
                        items:[]
                      };
                    }

                    encyclopedieCollectionForViz.items.push(resource);
                    encyclopedieCollectionForViz.total = encyclopedieCollectionForViz.items.length;
                  }


                  // Choix de la vue pour l'ouverture d'un item dans la liste :
                  // - En passant 'route', un item s'ouvrira dans son espace ( encyclopédie, corpus, ...)
                  // - En ne le passant pas, la liste déclenche une nouvelle route passée en paramètre au composant
                  // ce qui permet d'ouvrir l'item dans l'onglet Notices du composant actuel ( cf _route )
                  resource.route = collectionSettings ? collectionSettings.route : '';

                  resource.id = resource['o:id'];

                  if (collectionSettings) {
                    resource.type = collectionSettings.itemType;
                    resource.icon = collectionSettings.icon;
                    resource.colorNo = collectionSettings.colorNo;
                  } else {
                    resource.type = "item";
                    resource.icon = null;
                    resource.colorNo = 0;
                  }

                  if (firstItemId === undefined) {
                    firstItemId = resource.id;
                  }

                  if (itemsIds.indexOf(resource.id) === - 1) {
                    itemsIds.push(resource.id);
                  }

                  filteredResources.push(resource);
                }
              }
            }

            if (filteredResources.length > 0) {
              filteredItems.push({
                val: item.val,
                resources:filteredResources
              });
            }
          }

          state.items = filteredItems;
          state.itemsIds = itemsIds;

          state.noticeItemId = firstItemId === undefined ? 0 : firstItemId;
          state.isLoading = false;
          // console.log(state.items)

          // Graphe :
          let itemsForEncyclopedia = [];
          for (collectionRef in encyclopedieByCollection) {
            if (Object.prototype.hasOwnProperty.call(encyclopedieByCollection, collectionRef)) {
              itemsForEncyclopedia.push(encyclopedieByCollection[collectionRef]);
            }
          }
          state.itemsForEncyclopedia = itemsForEncyclopedia;

          // console.log('itemsForEncyclopedia', state.itemsForEncyclopedia.length);
        });
      };

      const itemLoaded = function(item) {
        state.noticeItemTitle = item.title;
      };

      const getLetterRoute = function(letter) {
          return { name:'Parcourir', query: { metadata: state.metadata, letter: letter, filter: state.filterId }}
      };

      const getLetterCssClass = function(letter) {
          return letter.toLowerCase() === state.letter.toLowerCase() ? "active-letter" : "letter";
      };

      let filterOptions = [];

      const getActiveFilterParams = function(activeFilterId) {
          let i, n = filterOptions.length, filterParams;
          for(i=0;i<n;i++) {
              filterParams = filterOptions[i];
              if (filterParams.id === activeFilterId) {
                  return filterParams;
              }
          }
      };

      const initIndexPanel = function() {

          // Reset :
          filterOptions.length = 0;

          const currentLanguage = store.state.currentLanguage;
          const browseSettings = store.state.settings.browse;
          let browseVocabs = [];
          let n = browseSettings.length, browseCollection, browseCollectionClone, browseTitle, collectionJson;

          for(i=0;i<n;i++) {
              browseCollection = browseSettings[i];
              browseCollectionClone = {...browseCollection};
              collectionJson = store.getters.collectionJson(browseCollection.id);
              if (collectionJson) {
                  browseTitle = getLocalizedValue(collectionJson['dcterms:title'], currentLanguage, collectionJson['o:title']);
                  browseCollectionClone.title = browseTitle;
              }
              filterOptions.push(browseCollectionClone);
              browseVocabs = browseVocabs.concat(browseCollection.customvocabs);
          }

          // Index general : compilation of all vocabs
          filterOptions.unshift({
              id:0,
              title: currentLanguage === "en" ? 'General Index' : 'Index général',
              customvocabs: browseVocabs
          });

          state.filterOptions = filterOptions;
          state.activeFilterParams = getActiveFilterParams(0);
      };

      const updateRouteWithFilter = function(filterId) {

            let routeCopy = {
                name : route.name,
                query : { ...route.query },
                params : { ...route.params }
            };

            routeCopy.query.filter = filterId;

            router.push(routeCopy);
      };

      const selectFilter = function(filterId) {
          updateRouteWithFilter(filterId);
      };

      const selectPreviousFilter = function(currentFilterId) {
          let i, n = filterOptions.length, filterNo = 0;
          for (i=0; i<n; i++) {
              if (filterOptions[i].id === currentFilterId) {
                  filterNo = ( i === 0 ? n - 1 : i - 1);
                  break;
              }
          }
          selectFilter(filterOptions[filterNo].id);
      };

      const selectNextFilter = function(currentFilterId) {
          let i, n = filterOptions.length, filterNo = 0;
          for (i=0; i<n; i++) {
              if (filterOptions[i].id === currentFilterId) {
                  filterNo = ( i === n - 1 ? 0 : i + 1);
                  break;
              }
          }
          selectFilter(filterOptions[filterNo].id);
      };


      //
      // Lifecycle Hooks
      //

      onMounted(() => {

        // Chargement des résultats

        // Paramètres de l'URL : display mode, sort, ...
        if (route.query.sort_by && (route.query.sort_by.length > 0)) {
          state.sortByParam = route.query.sort_by;
        }

        if (route.query.sort_order && (route.query.sort_order.length > 0)) {
          state.sortOrderParam = route.query.sort_order;
        }

        if (route.name === props.itemRouteName) {
          state.displayMode = "notice";
        } else {
          if (route.query.display_mode && (route.query.display_mode.length > 0)) {
            state.displayMode = route.query.display_mode ? route.query.display_mode : props.defaultDisplayMode;
          }
        }

        // console.log('state.displayMode', state.displayMode);

        // Paramètres de l'URL : metadata,  v, filter, ...
        const metadata =  route.query.metadata ? route.query.metadata : "dcterms:title";
        const letter   = route.query.letter ? route.query.letter : "a";
        const filterId = route.query.filter ? parseInt(route.query.filter) : 0;

        state.metadata = metadata;
        state.letter = letter;
        state.filterId = filterId;

        if (Array.isArray(store.state.collections)) {
            initIndexPanel();
            loadResults();
        }
      });

      // Different route / Same component
      onBeforeRouteLeave((to, from, next) => {

        if (to.name === props.itemRouteName)
        {
          // From List view to Item view
          state.noticeItemId = parseInt(to.params.id);
          state.displayMode = "notice";
          next();
        }
        else if (to.name === props.itemSetRouteName)
        {
          // From Item view to List view
          state.noticeItemId = 0;
          state.displayMode = to.query.display_mode ? to.query.display_mode : props.defaultDisplayMode;
          next();
        }
        else
        {
          // Different route with different component (home, etc...)
          next();
        }
      });

      // Same route / Same component
      onBeforeRouteUpdate((to, from, next) => {

        // console.log('onBeforeRouteLeave', to.name)

        const toQuerySortBy = to.query.sort_by ? to.query.sort_by : 'title';
        const toQuerySortOrder = to.query.sort_order ? to.query.sort_order : 'asc';

        // const toQueryQ = to.query.q;
        // const toParamS = to.params.s;

        if (to.name === props.itemRouteName)
        {
          // Item view
          state.noticeItemId = parseInt(to.params.id);
          state.displayMode = "notice";
          next();

        }
        else if (to.name === props.itemSetRouteName)
        {
          // List view
          state.noticeItemId = 0;
          state.displayMode = to.query.display_mode ? to.query.display_mode : props.defaultDisplayMode;

          // console.log('onBeforeRouteLeave', state.s, toParamS, "q", state.q, toQueryQ)

          if ((state.metadata !== to.query.metadata) || (state.letter !== to.query.letter) || (state.filterId !== to.query.filter)) {

            state.metadata = to.query.metadata ? to.query.metadata : "dcterms:title";
            state.letter   = to.query.letter ? to.query.letter : "a";
            state.filterId = to.query.filter ? parseInt(to.query.filter) : 0;
            state.lang     = to.query.lang;

            loadResults();

            next();

          } else if ((state.sortByParam !== toQuerySortBy) || (state.sortOrderParam !== toQuerySortOrder)) {

            // 1.1 Changement de l'ordre des items
            state.sortByParam = toQuerySortBy;
            state.sortOrderParam = toQuerySortOrder;
            state.pageNo = isNaN(to.query.p) ? 1 : parseInt(to.query.p);

            loadResults();
            next();

          } else {

            // 1.2 Pagination
            state.pageNo = isNaN(to.query.p) ? 1 : parseInt(to.query.p);
            next();

          }

        }
        else
        {
          // Different route with different component (home, etc...)
          next();
        }

      });

      watch( () => store.state.collections, () => {
          initIndexPanel();
          loadResults();
      });

      watch( () => store.state.currentLanguage, () => {
          initIndexPanel();
          state.lang = store.state.currentLanguage;
      });


      //
      // State
      //

      const state = reactive({
        pageTitle: "Parcourir",
        letters: letters,
        items:null,

        noticeItemId: 0,
        noticeItemTitle: null,

        isLoading: true,

        filterOptions:[],
        filterPanel: computed(() => state.metadata === "dcterms:subject" ),
        activeFilterId: 0,
        activeFilterParams: null,
        lang: store.state.currentLanguage,

        userIsConnected: computed(() => store.state.currentUser.logged ),

        displayMode: props.defaultDisplayMode,
        displayModeCssClass: computed(() => state.displayMode + "-mode"),

        isListDisplayMode: computed(() => { return state.displayMode === "list" }),
        isNoticeDisplayMode: computed(() => { return state.displayMode === "notice" }),
        isGraphDisplayMode: computed(() => { return state.displayMode === "graph" }),

        changeRouteWithPreviousAndNext:true,

        graphItemSetRoute: computed(() => {
          return router.resolve( {
            name: props.itemSetRouteName,
            query: {
              display_mode: 'graph',
            }
          });
        }),

        listItemSetRoute: computed(() => {
          return router.resolve( {
            name: props.itemSetRouteName,
            query: {
              sort_param: state.sortByParamForItemSets,
              sort_order: state.sortOrderParamForItemSets,
              display_mode: 'list',
              p: state.pageNo,
              metadata: state.metadata,
              filter: state.filterId,
              letter: state.letter
            }
          });
        }),

        listItemQuery: computed(() => {
          return {
            sort_param: state.sortByParamForItemSets,
            sort_order: state.sortOrderParamForItemSets,
            display_mode: 'list',
            p: state.pageNo,
            metadata: state.metadata,
            filter: state.filterId,
            letter: state.letter
          };
        }),

        noticeItemRoute: computed(() => {
          return router.resolve( {
            name: props.itemRouteName,
            params: { id: state.noticeItemId },
            query: {
              sort_param: state.sortByParam,
              sort_order: state.sortOrderParam,
              display_mode: 'notice'
            }
          });
        }),
      });

      return {
        state,
        getLetterRoute,
        getLetterCssClass,
        selectFilter,
        selectPreviousFilter,
        selectNextFilter,
        itemLoaded
      }
    }
  }
</script>