<template>
  <div id="item-svg-parent" class="item-svg-parent">

    <div class="item-svg-tools">
      <div class="item-svg-parent-back-button">
        <a href="#" @click="close($event)">{{ $t('Retour') }}</a>
      </div>
      <ul class="item-svg-filters">
        <li
            v-for="linkType in state.linkTypesSettings"
            :key = "linkType.term"
            :class="getStyleForLinkType(linkType.term, 'visible', 'visible-link-type' )"
        >
          <label class="checkbox-label">
            <input
                type="checkbox"
                checked="checked"
                v-model="linkType.visibleOnGraph"
                :class="linkType.term"
                @change="selectLinkType($event)"
            />
            <span class="checkmark"></span>
            <svg width="55" height="18">
              <line
                  stroke-width="1"
                  :stroke="getStyleForLinkType(linkType.term, 'strokeStyle', '#FFFFFF' )"
                  :stroke-dasharray="getStyleForLinkType(linkType.term, 'dashStyle', '' )"
                  x1="0"
                  x2="55"
                  y1="8"
                  y2="8">
              </line>
            </svg>
            <span>{{ $t(linkType.title) }}</span>
          </label>
        </li>
      </ul>
    </div>

    <div class="item-svg-basket" v-if="state.userIsConnected">
      <a href="#" @click="openBasket($event)">{{ visualisationBasketCount }}</a>
    </div>

    <div class="item-svg-basket-info" v-if="state.userIsConnected">
      {{ $t('Créer ma documentation avec cette sélection') }}
    </div>

    <ul class="item-svg-zoom-buttons zoom-buttons">
      <li class="constellation-zoom-plus" :class="zoomInCssClass">
        <a href="#" @click="zoomIn($event)"></a>
      </li>
      <li class="constellation-zoom-moins" :class="zoomOutCssClass">
        <a href="#" @click="zoomOut($event)"></a>
      </li>
      <li class="constellation-zoom-reload">
        <a href="#" @click="zoomReset($event)"></a>
      </li>
    </ul>

    <Loading v-if="state.isLoading" />

    <VisualisationTitle
        :class="selectionInputTitleCssClass"
        :message="state.inputTitleMessage"
        @save-with-visualization-title="saveSelectionWithTitle"
        @change-visualization-title="changeSelectionTitleInput"
        @validate-message="validateSelectionTitleMessage"
        @hide-message="hideSelectionTitleMessage"
    />

    <VisualisationAnnotation :class="annotationCssClass" />

    <ShortNoticeItem
        :titles="state.noticeTitles"
        :descriptions="state.noticeDescriptions"
        :languages="state.noticeLanguages"
        :itemId="state.noticeId"
        @close-short-notice="closeShortNotice"
        @opendetailednotice="openDetailedNotice"
        @mouseover="mouseOverShortNotice($event)"
        @mouseout="mouseOutShortNotice($event)"
        :class="noticeCssClass"
        :style = "{ left: state.noticePosition.left + 'px', top: state.noticePosition.top + 'px' }"
    />

  </div>

</template>

<script>

import {computed, onMounted, onUnmounted, reactive, watch} from 'vue';
import {useStore} from "vuex";
import {useRouter} from "vue-router";

import { VizItem } from '../../js/viz-item.js';
import {getCollectionsByItemSetApiURL} from "../../js/api";
import ShortNoticeItem from "../Notices/ShortNoticeItem";
import Loading from "../Loading";
import VisualisationAnnotation from '../Annotations/VisualisationAnnotation';
import VisualisationTitle from './VisualisationItemTitle';
import {JsonReader} from "../../js/jsonReader";

const vizItem = new VizItem();

export default {
  name: 'VisualisationItem',
  components: {
    ShortNoticeItem,
    Loading,
    VisualisationAnnotation,
    VisualisationTitle,
  },
  props: {
    itemId: {
      type: Number,
      required: false,
    },
    itemsIds: {
      type: Array,
      required: false,
    },
  },
  emits: [
    "notice-loaded",
    "opendetailednotice",
    "closeitemvisualization"
  ],
  data() {
    return {
      zoomLevelIsMax: false,
      zoomLevelIsMin: true,
      annotationOpened:false
    }
  },
  setup(props, context) {

    const store = useStore();
    const jsonReader = new JsonReader();

    // Données des settings
    const encyclopedieCollectionInfos = store.getters.encyclopedieCollectionInfos();

    let i, collectionInfos;
    const n = encyclopedieCollectionInfos.length;

    const collectionsIds = [];
    const collectionIconsAssocArray = [];
    const collectionColorsAssocArray = [];
    const collectionColorNosAssocArray = [];
    const COLLECTION_ARRAY_KEY = "c";

    for(i=0; i<n; i++) {
      collectionInfos = encyclopedieCollectionInfos[i];
      collectionsIds.push(collectionInfos.id);
      collectionColorsAssocArray[COLLECTION_ARRAY_KEY + collectionInfos.id] = collectionInfos.color;
      collectionColorNosAssocArray[COLLECTION_ARRAY_KEY + collectionInfos.id] = i + 1;
      collectionIconsAssocArray[COLLECTION_ARRAY_KEY + collectionInfos.id] = collectionInfos.icon;
    }

    vizItem.collections = encyclopedieCollectionInfos;
    vizItem.iconsByCategory = collectionIconsAssocArray;
    vizItem.colorsByCategory = collectionColorsAssocArray;
    vizItem.colorNosByCategory = collectionColorNosAssocArray;

    // Settings des liens entre les items (terme, libellé, styles de trait et de couleur)
    vizItem.setLinkTypesSettings(store.state.linkTypes);

    // Permettra de retrouver la couleur à partir du nom de la collection
    vizItem.settingsByCollectionName = store.state.settingsByCollectionName;

    const loadItem = function(itemId) {
      if (itemId !== null)
      {
        vizItem.keyIdentity = store.getters.identityAndCredentialParameters;
        vizItem.collectionsApiURL = getCollectionsByItemSetApiURL(store.getters.apiRoot, collectionsIds);
        vizItem.apiRoot = store.getters.apiRoot;
        vizItem.currentLanguage = store.state.currentLanguage;

        // Chargement de l'API du JSON de l'item :
        state.isLoading = true;

        // console.log(`itemId : ` + itemId);

        vizItem.clear();
        vizItem.resetLinkTypes();
        vizItem.loadCollectionsAndItem( itemId );
      }
    };

    const loadItems = function(itemsIds) {
      if (itemsIds !== null)
      {

        vizItem.keyIdentity = store.getters.identityAndCredentialParameters;
        vizItem.collectionsApiURL = getCollectionsByItemSetApiURL(store.getters.apiRoot, collectionsIds);
        vizItem.apiRoot = store.getters.apiRoot;
        vizItem.currentLanguage = store.state.currentLanguage;

        // Chargement de l'API du JSON de l'item :
        state.isLoading = true;

        // console.log(`itemId : ` + itemId);

        vizItem.clear();
        vizItem.resetLinkTypes();
        vizItem.loadCollectionsAndItems( itemsIds );
      }
    };

    const close = function($event) {

      if ($event) $event.preventDefault();

      state.noticeOpened = false;
      state.selectionInputTitleOpened = false;

      vizItem.clear();

      // EVENT --> PARENT
      context.emit('closeitemvisualization');
    };


    let openShortNoticeTimeOut;

    const clearTimeoutToCloseShortNotice = function() {
      if (openShortNoticeTimeOut) clearTimeout(openShortNoticeTimeOut);
    };

    const restartTimeoutToCloseShortNotice = function() {
      clearTimeoutToCloseShortNotice();
      openShortNoticeTimeOut = setTimeout(closeShortNotice, 350);
    };

    const closeShortNotice = function() {
      clearTimeoutToCloseShortNotice();
      state.noticeOpened = false;
    };

    const mouseOverShortNotice = function($event) {
      $event.preventDefault();
      clearTimeoutToCloseShortNotice();
    };

    const mouseOutShortNotice = function($event) {
      $event.preventDefault();
      restartTimeoutToCloseShortNotice();
    };

    const getStyleForLinkType = function(linkTypeTerm, linkTypeStyle = "dashStyle", defaultValue = "") {
      let linkTypeSetting = defaultValue;
      if (Array.isArray(state.linkTypesSettings)) {
        const n = state.linkTypesSettings.length;
        let i, linkTypeSettings;
        for(i=0; i<n; i++) {
          linkTypeSettings = state.linkTypesSettings[i];
          if (linkTypeSettings.term === linkTypeTerm) {
            linkTypeSetting = linkTypeSettings[linkTypeStyle] ? linkTypeSettings[linkTypeStyle] : defaultValue;
            break;
          }
        }
      }
      return linkTypeSetting;
    };


    // Lifecycle Hooks
    onMounted(() => {

      const store = useStore();
      const router = useRouter();

      /* Visualisation d'un item */

      const itemParentId = "item-svg-parent";
      const itemParent = document.getElementById(itemParentId);

      vizItem.width = itemParent.offsetWidth;
      vizItem.height = itemParent.offsetHeight;
      vizItem.init(itemParentId);

      itemParent.addEventListener('notice-loaded', function (event) {
        context.emit('notice-loaded', event.detail);
      });

      itemParent.addEventListener('open-graph', function (event)
      {
        const eventDetail = event.detail;
        const itemId = eventDetail.id;
        console.log(itemId, eventDetail);

        state.noticeOpened = false;

        router.push({
          name   : 'Notice Item',
          params : { id: itemId },
          query  : { display_mode: 'graph' }
        })
      })

      itemParent.addEventListener('open-notice', function (event)
      {
        const eventDetail = event.detail;
        const json = eventDetail.json;
        const itemId = json["o:id"];

        state.noticeOpened = false;

        router.push({
          name   : 'Notice Item',
          params : { id: itemId },
          query  : { display_mode: 'notice' }
        })

        // v1 : ouverture d'une fenêtre modale :
        // EVENT --> PARENT
        // context.emit('opendetailednotice', itemId);
      });

      itemParent.addEventListener('open-short-notice', function (event)
      {
        const eventDetail = event.detail;
        const json = eventDetail.json;
        const itemId = json["o:id"];

        jsonReader.json = json;


        clearTimeoutToCloseShortNotice();

        // Languages
        const languages = [];

        let i, n, language;

        // Titre
        const titles = [];

        const alternativeTitleObjects = jsonReader.getMetaDataValue('dcterms:alternative');
        if (Array.isArray(alternativeTitleObjects)) {

          n = alternativeTitleObjects.length;
          let alternativeTitleObject;

          for (i = 0; i < n; i++) {
            alternativeTitleObject = alternativeTitleObjects[i];
            language = alternativeTitleObject.language;

            titles.push({
              value: alternativeTitleObject.value,
              language: language,
            });

            if ((language !== undefined) && language.length && (languages.indexOf(language) === -1)) {
              languages.push(language);
            }
          }
        }

        if (titles.length === 0) {
          titles.push({
            value: json['o:title']
          })
        }

        // Textes (selon langue)
        const descriptions = [];

        const descriptionObjects = json['dcterms:description'];
        if (Array.isArray(descriptionObjects)) {

          const n = descriptionObjects.length;
          let i, descriptionObject;

          for(i=0;i<n;i++) {
            descriptionObject = descriptionObjects[i];
            language = descriptionObject['@language'];

            descriptions.push({
              value: descriptionObject['@value'],
              language: language,
            });

            if ((language !== undefined) && language.length && (languages.indexOf(language) === -1)) {
              languages.push(language);
            }
          }
        }

        // Position
        const mouseEvent = event.detail.mouseEvent;
        const mouseEventX = mouseEvent.clientX; //- mouseEvent.layerX;
        const mouseEventY = mouseEvent.clientY - 10; //- mouseEvent.layerY;

        const documentX = document.body.clientWidth;
        const shortNoticeWidth = 360;

        // const documentY = document.body.clientHeight;
        // console.log(mouseEventX, documentX);

        if (mouseEventX + shortNoticeWidth + 80 < documentX ) {
          state.noticePosition.left = mouseEventX + 50;
          state.noticePosition.top = mouseEventY - 228;
          state.noticePositionCssClass = "on-left";
        } else {
          state.noticePosition.left = mouseEventX - shortNoticeWidth - 30;
          state.noticePosition.top = mouseEventY - 228;
          state.noticePositionCssClass = "on-right";
        }

        state.noticeId = itemId;
        state.noticeTitles = titles;
        state.noticeDescriptions = descriptions;
        state.noticeLanguages = languages;
        state.noticeOpened = true;

      });

      itemParent.addEventListener('close-notice', function ($event) {
        const eventDetail = $event.detail;
        if (eventDetail.withDelay) {
          // Timeout
          restartTimeoutToCloseShortNotice();
        } else {
          closeShortNotice();
        }
      });

      itemParent.addEventListener('close', function () {
        close();
      });

      itemParent.addEventListener('init-basket', function (event) {
        const eventDetail = event.detail;
        store.dispatch('initToVisualisationBasket', eventDetail);
        state.isLoading = false;
      });

      itemParent.addEventListener('add-to-basket', function (event) {
        const eventDetail = event.detail;
        store.dispatch('addToVisualisationBasket', eventDetail);
      });

      itemParent.addEventListener('remove-from-basket', function (event) {
        const eventDetail = event.detail;
        store.dispatch('removeFromVisualisationBasket', eventDetail);
      });

      itemParent.addEventListener('link-types', function (event) {
        const eventDetail = event.detail;
        const linkTypesSettings = state.linkTypesSettings;
        if (Array.isArray(linkTypesSettings)) {
          const n = linkTypesSettings.length;
          let i, linkTypeSettings;
          for(i=0; i<n; i++) {
            linkTypeSettings = linkTypesSettings[i];
            linkTypeSettings.visible = eventDetail.linkTypes.indexOf(linkTypeSettings.term) === -1 ? "hidden-link-type" : "visible-link-type";
            linkTypeSettings.visibleOnGraph = true;
          }
        }
      });


      window.addEventListener('resize', function () {
        const w = itemParent.offsetWidth;
        const h = itemParent.offsetHeight;
        vizItem.resize(w, h);
      });

      loadItem(props.itemId);

    });

    // Chargement de la visualisation

    watch( () => props.itemId, (itemId) => {
      if (itemId) loadItem(itemId);
    });

    watch( () => props.itemsIds, (itemsIds) => {
      if (itemsIds && itemsIds.length) loadItems(itemsIds);
    });

    watch( () => store.state.currentLanguage, () => {
      vizItem.currentLanguage = store.state.currentLanguage;
      if (! vizItem.isLoading) {
        if (props.itemsIds && props.itemsIds.length) {
          loadItems(props.itemsIds);
        } else if (props.itemId) {
          loadItem(props.itemId);
        }
      }
    });

    onUnmounted(() => {
      vizItem.clear();
    });

    const state = reactive({
      isLoading:false,
      noticeOpened: false,
      noticeTitles: [],
      noticeDescriptions: [],
      noticeLanguages: [],
      noticeId: 0,
      noticePosition: { left: 0, top: 0},
      noticePositionCssClass: "on-left",
      selectionInputTitleOpened:false,
      userIsConnected: computed(() => store.state.currentUser.logged ),
      linkTypesSettings: store.state.linkTypes.concat().sort(function (a, b) { return a.menuOrder < b.menuOrder ? -1 : 1 }),
      visibleLinkTypes: []
    });

    return {
      state,
      close,
      restartTimeoutToCloseShortNotice,
      mouseOverShortNotice,
      mouseOutShortNotice,
      getStyleForLinkType
    }

  },
  computed: {
    noticeCssClass() {
      return this.state.noticePositionCssClass + " " + (this.state.noticeOpened === true ? "opened" : "");
    },
    selectionInputTitleCssClass() {
      return this.state.userIsConnected && this.state.selectionInputTitleOpened ? "opened" : "";
    },
    annotationCssClass() {
      return this.annotationOpened === true ? "popup-parent-opened" : "";
    },
    zoomInCssClass() {
      return this.zoomLevelIsMax === true ? "" : "active";
    },
    zoomOutCssClass() {
      return this.zoomLevelIsMin === true ? "" : "active";
    },
    visualisationBasketCount() {
      const store = useStore();
      return store.getters.getVisualisationBasketCount();
    }
  },
  methods : {
    selectLinkType($event) {
      $event.preventDefault();
      $event.stopPropagation();

      let activeLinkTypes = [];

      const target = $event.target;
      const linkTypesSettings = this.state.linkTypesSettings;
      if (Array.isArray(linkTypesSettings)) {
        const n = linkTypesSettings.length;
        let i, linkTypeSettings;
        for(i=0; i<n; i++) {
          linkTypeSettings = linkTypesSettings[i];
          if (linkTypeSettings.term === target.className) {
            linkTypeSettings.visibleOnGraph = target.checked;
          }
          if (linkTypeSettings.visibleOnGraph === undefined || linkTypeSettings.visibleOnGraph) {
            activeLinkTypes.push(linkTypeSettings.term);
          }
        }
      }
      vizItem.filterByLinkType( activeLinkTypes );
    },
    closeShortNotice() {
      this.state.noticeOpened = false;
    },
    openDetailedNotice(itemId) {
      this.state.noticeOpened = false;

      this.$router.push({
        name   : 'Notice Item',
        params : { id: itemId },
        query  : { display_mode: 'notice' }
      })

      // v1 : ouverture d'une fenêtre modale :
      // EVENT --> PARENT
      // this.$emit('opendetailednotice', itemId);
    },
    openBasket($event) {
      $event.preventDefault();
      $event.stopPropagation();
      this.state.selectionInputTitleOpened = ! this.state.selectionInputTitleOpened;
    },
    zoomIn($event) {
      $event.preventDefault();
      $event.stopPropagation();
      vizItem.zoomIn();
      this.updateZoomButtons();
    },
    zoomOut($event) {
      $event.preventDefault();
      $event.stopPropagation();
      vizItem.zoomOut();
      this.updateZoomButtons();
    },
    zoomReset($event) {
      $event.preventDefault();
      $event.stopPropagation();
      vizItem.zoomReset();
      this.updateZoomButtons();
    },
    updateZoomButtons() {
      this.zoomLevelIsMin = vizItem.zoomLevelIsMin();
      this.zoomLevelIsMax = vizItem.zoomLevelIsMax();
    },
    changeSelectionTitleInput() {
      this.state.inputTitleMessage = "";
    },
    saveSelectionWithTitle(selectionTitle) {
      var t = this;
      this.$store.dispatch('saveSelectionBasket', {
        name : selectionTitle,
        items : this.$store.state.visualizationBasket.concat(),
        resources: this.$store.getters.getVisualisationBasketForApi(),
        force: false
      }).then(function (response) {
        if (response.success) {
          t.state.selectionInputTitleOpened = false;
        } else {
          t.state.inputTitleMessage = response.message;
        }
      });

    },
    validateSelectionTitleMessage(selectionTitle) {
      this.$store.dispatch('saveSelectionBasket', {
        name : selectionTitle,
        items : this.$store.state.visualizationBasket.concat(),
        resources: this.$store.getters.getVisualisationBasketForApi(),
        force: true
      });
      this.state.selectionInputTitleOpened = false;
    },
    hideSelectionTitleMessage() {
      this.state.selectionInputTitleOpened = false;
    }
  }
}

</script>
