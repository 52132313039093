<template>
  <div class="view">
    <div class="breadcrumbs-and-title">
      <div class="breadcrumbs">
        <BreadCrumb :paths="breadcrumbs" />
      </div>
      <h1>{{ state.title }}</h1>
    </div>
    <div class="contents">
      <div class="contents-wrapper list-mode">
        <div class="page no-header with-padding">
          <Loading class="notice-item-content with-padding" v-if="state.isLoading" />
          <div
              v-if="state.isHome"
              class="article-background"
              :class="state.isLoading ? '' : 'is-loaded'"
          />
          <HTMLSitePage
              v-if="! state.isLoading"
              class="article-content"
              :key="state.pageId"
              :htmlContent="state.htmlContent"
              :is-home="state.isHome"
          />
        </div>
      </div>
    </div>

  </div>
</template>
<script>

import {computed, onMounted, reactive, watch} from "vue";
  import { useStore } from "vuex";
import {useRoute, onBeforeRouteUpdate, onBeforeRouteLeave} from "vue-router";
  import {getSitePageApiURL} from "../js/api";
  import axios from "axios";

  import Loading from "../components/Loading";
  import BreadCrumb from "../components/Header/BreadCrumb";
  import HTMLSitePage from "../components/SitePages/HTMLSitePage";

  export default {
    components: {HTMLSitePage, Loading, BreadCrumb},
    computed: {
      breadcrumbs() {
        return [
          { name: 'Home', label:'Accueil' },
          { label: this.state.title }
        ]
      }
    },
    methods: {

    },
    setup() {

      const route = useRoute();
      const store = useStore();

      // Chargement du contenu de la page

      const loadPageContent = function(pageId) {

        state.isLoading = true;

        const siteId = store.getters.siteId;
        const apiPageURL = getSitePageApiURL(store.getters.apiRoot, pageId, siteId);
        const keyIdentity = store.getters.identityAndCredentialParameters;

        axios.get(apiPageURL + keyIdentity).then(function(response){

          const pageData = response.data;
          const pageHtmlContent = pageData['o-module-api-info:append'];

          if (pageHtmlContent) {
            state.htmlContent = pageHtmlContent.html;
          }

          state.isLoading = false;
        });
      };

      const getPageIdAndLoadContent = function(slug, lang) {
        if (slug) {
          // Récupération de l'id de la page à partir du slug
          const site = store.getters.getSitePropFromLang(lang);
          store.dispatch("getSitePageInfosFromSlug", { slug, site }).then(function (pageInfos) {
            if (pageInfos && pageInfos.id) {
              state.title = pageInfos.title;
              state.pageId = pageInfos.id;
              loadPageContent(pageInfos.id);
            }
          });
        }
        else
        {
          // Récupération des infos de la homepage à partir de l'id (issu des infos du site via l'api)
          const homePageId = store.getters.siteHomepage;
          if (homePageId) {
            store.dispatch("getSitePageInfosFromId", homePageId).then(function (pageInfos) {
              if (pageInfos && pageInfos.id) {
                state.title = pageInfos.title;
                state.pageId = pageInfos.id;
                loadPageContent(pageInfos.id);
              }
            });
          }
        }
      };

      // Different route / Same component
      onBeforeRouteLeave((to, from, next) => {
        if (to.params.slug !== from.params.slug) {
          getPageIdAndLoadContent(to.params.slug, to.query.lang);
        }
        next();
      });

      // Same route / Same component
      onBeforeRouteUpdate((to, from, next) => {
        if (to.params.slug !== from.params.slug) {
          getPageIdAndLoadContent(to.params.slug, to.query.lang);
        }
        next();
      });

      onMounted(() => {
        getPageIdAndLoadContent(route.params.slug, route.query.lang);
      });

      watch(() => store.state.siteParams, () => {
        if (state.isHome) {
          getPageIdAndLoadContent(route.params.slug, store.state.currentLanguage);
        }
      });

      const state = reactive({
        title:'',
        pageId: null,
        isLoading: true,
        isHome: computed(() => route.name === "Home" ),
        language: store.state.currentLanguage,
        htmlContent:''
      });

      return {
        state
      }
    }
  }
</script>