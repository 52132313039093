<template>

  <div>

    <!-- Navigation Item suivant / Item précédent -->
    <NavigationItems
            v-if="state.changeRouteWithPreviousAndNext"
            class="with-padding"
            :previousItemId="state.previousNoticeItemId"
            :nextItemId="state.nextNoticeItemId"
    />

    <!-- Notice pleine page des Items -->
    <NavigationItemsEventDispatcher
            v-if="! state.changeRouteWithPreviousAndNext"
            class="with-padding"
            :previousItemId="state.previousNoticeItemId"
            :nextItemId="state.nextNoticeItemId"
            @change="navigationChangeNoticeItem"
    />

    <NoticeMedia
            v-if="state.isNotice"
            class="with-padding"
            :itemId="state.noticeItemId"
            :tools=true
            :openGraphInPopUp="state.openGraphInPopUp"
            :hasPartMetadataSorted="state.hasPartMetadataSorted"
            displayMode="reference"
            @change-item-notice="openDetailedNotice"
            @open-item-graph="openItemGraph"
            @item-loaded="itemLoaded"
    />

    <NoticeArticle
            v-if="state.isNoticeArticle"
            class="with-padding"
            :itemId="state.noticeItemId"
            :tools=true
            displayMode="reference"
            @change-item-notice="openDetailedNotice"
            @item-loaded="itemLoaded"
    />

  </div>


</template>

<script>

import {computed, onMounted, reactive, watch} from 'vue'
  import NavigationItems from "../Lists/NavigationItem";
  import NavigationItemsEventDispatcher from "../Lists/NavigationItemEventDispatcher";
  import NoticeMedia from "../Notices/NoticeMedia";
  import NoticeArticle from "../Notices/NoticeArticle";

  export default {
    name: 'NoticesItems',
    components: {
      NavigationItems,
      NavigationItemsEventDispatcher,
      NoticeMedia,
      NoticeArticle
    },
    props: {
      itemsIds: {
        type: Array,
        required: true
      },
      noticeItemId: {
        type: Number,
        required: true
      },
      noticeItemType: {
        type: String,
        required: false,
        default:'item'
      },
      hasPartMetadataSorted: {
        type:Boolean,
        required: false,
        default: false
      },
      changeRouteWithPreviousAndNext: {
        type: Boolean,
        required: false,
        default:true
      },
      openGraphInPopUp: {
        type:Boolean,
        required: false,
        default: false
      },
    },
    data() {
      return {
      }
    },
    emits: [
      "item-loaded",
      "opendetailednotice",
      "open-item-graph"
    ],
    setup(props, context) {

      const navigationChangeNoticeItem = function(itemId){
        if (isNaN(itemId))
        {
          // Pas de résultats
          state.noticeItemId = 0;
          state.previousNoticeItemId = 0;
          state.nextNoticeItemId = 0;
        }
        else if (state.itemsIds)
        {
          // Résultats courant, suivant et précédent
          state.noticeItemId = parseInt(itemId);

          if (state.noticeItemId === 0) {
            state.noticeItemId = state.itemsIds[0];
          }

          const pos = state.itemsIds.indexOf(state.noticeItemId);

          state.previousNoticeItemId = pos > 0 ? state.itemsIds[pos - 1] : 0;
          state.nextNoticeItemId = pos < state.itemsIds.length - 1 ? state.itemsIds[pos + 1] : 0;
        }
      };

      const itemLoaded = function(itemInfos) {
        context.emit('item-loaded',  itemInfos);
      };

      const checkIfNavigationChanges = function() {
        if ((props.itemsIds && props.itemsIds.length) || props.noticeItemId) {
          navigationChangeNoticeItem(props.noticeItemId);
        }
      };

      const state = reactive({
        itemsIds: props.itemsIds,
        noticeItemId: props.noticeItemId || (props.itemsIds && props.itemsIds.length ? props.itemsIds[0] : 0),
        noticeItemType: props.noticeItemType,
        hasPartMetadataSorted: props.hasPartMetadataSorted,
        changeRouteWithPreviousAndNext: props.changeRouteWithPreviousAndNext,
        previousNoticeItemId: 0,
        nextNoticeItemId: 0,
        isNotice: computed(() => props.noticeItemType === "item" ),
        isNoticeArticle: computed(() => props.noticeItemType === "article" ),
        openGraphInPopUp: props.openGraphInPopUp
      });

      onMounted(() => {
        checkIfNavigationChanges();
      });

      watch( () => props.itemsIds, (itemsIds) => {
        // console.log('watch itemsIds', itemsIds);
        state.itemsIds = itemsIds;
        checkIfNavigationChanges();
      });

      watch( () => props.noticeItemType, (itemType) => {
        state.noticeItemType = itemType;
      });

      watch( () => props.noticeItemId, (itemId) => {
        // console.log('watch noticeItemId', itemId);
        state.noticeItemId = itemId;
        checkIfNavigationChanges();
      });

      return {
        state,
        navigationChangeNoticeItem,
        itemLoaded
      }
    },
    computed:{
    },
    methods:{
      openDetailedNotice($event, itemId) {
        $event.preventDefault();

        // EVENT --> PARENT
        this.$emit('opendetailednotice', itemId);
      },
      openItemGraph(item) {
        // EVENT --> PARENT
        this.$emit('open-item-graph', item.id);
      },
    }
}
</script>
