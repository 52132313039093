<!-- Connexion : login -->
<template>
  <div class="connexion-menu-parent popup-parent">
    <div class="connexion-menu-bg"></div>
    <div class="connexion-menu">
      <div class="connexion-menu-close">
        <a href="#" @click="closeWindow($event)">{{ $t("Fermer") }}</a>
      </div>
      <div class="form-parent">
        <form @submit="checkForm">
          <h2>{{ $t("Se connecter") }}</h2>

          <div class="connexion-form-fields">
            <div class="form-fields">
              <label for="email">{{ $t("Email") }} <span class="required">*</span></label>
              <input type="text" id="email" placeholder="username@domain.org" v-model="state.email" />
            </div>
            <div class="form-fields">
              <label for="password">{{ $t("Mot de passe") }} <span class="required">*</span></label>
              <input type="password" id="password" placeholder="password" v-model="state.userpassword" />
            </div>
          </div>

          <div class="forgotten-password">
            <a href="#" @click="openForgottenPasswordWindow($event)">{{ $t("Mot de passe oublié") }}</a>
          </div>

          <ul class="connexion-form-errors">
            <li v-for="item in state.errorMessages" v-bind:key="item">{{ item }}</li>
          </ul>

          <div class="form-buttons">
            <input type="submit" :value="$t('Se connecter')" />
            <a href="#" class="button" @click="openRegisterWindow($event)">{{ $t("Créer un compte") }}</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

  import axios from "axios";
  import {useStore} from "vuex";
  import {getLoginApiURL} from "../../js/api";
  import {computed, reactive, watch} from "vue";

  export default {
  name: 'LoginWindow',
  props: {
  },
  emits:[
    'close-login-window',
    'open-register-window',
    'open-forgotten-password-window'
  ],
  data() {
    return {
    }
  },
  methods: {
    closeWindow($event) {
      $event.preventDefault();
      $event.stopPropagation();
      this.$emit('close-login-window');
    },
    openRegisterWindow($event) {
      $event.preventDefault();
      $event.stopPropagation();
      this.$emit('open-register-window');
    },
    openForgottenPasswordWindow($event) {
      $event.preventDefault();
      $event.stopPropagation();
      this.$emit('open-forgotten-password-window');
    },
  },
  setup(props, context) {

    const store = useStore();

    const loadCredentials = function () {

      // Api Omeka (Guest Api Module)
      const apiURL = getLoginApiURL(store.getters.apiRoot);

      axios.post(apiURL, {
        "email": state.email,
        "password": state.userpassword
      }).then(function (response) {

        const responseData = response.data;

        if (responseData.status === "error") {

          /* ERROR */
          state.errorMessages = [ responseData.message ];

        } else {

          /* SUCCES */

          state.errorMessages = [];

          const session_token = responseData.data.session_token;

          // En attendant de récupérer le nom via l'api
          const emailUsername = state.email.split('@').shift();

          // Sauvegarde dans le store
          const user = {
            id: session_token["o:user"]["o:id"],
            keyCredential: session_token.key_credential,
            keyIdentity: session_token.key_identity,
            email: state.email,
            name: emailUsername.split('.').join(' '),
            logged: true,
            loggedMethod: 'login',
          };

          store.dispatch("storeCurrentUser", user);

          // Sauvegarde dans le web storage
          const localStorageInfos = {...user };
          localStorageInfos.expiry = (new Date()).getTime() + 1000 * 60 * 60 * 24 * 5;

          store.dispatch("saveUserInLocalStorage", localStorageInfos);

          context.emit('close-login-window');
        }

      }).catch(error => {

        /* ERRORS */

        // Cf https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/

        if (error.response) {

          // 1. Client received an error response (5xx, 4xx)

          const errorsObject = error.response.data.errors;
          let i, errorType, errorsArray, errorsCount;
          let errorMessages = [];

          for (errorType in errorsObject) {
            if (Object.prototype.hasOwnProperty.call(errorsObject, errorType)) {
              errorsArray = errorsObject[errorType];
              if (Array.isArray(errorsArray)) {
                errorsCount = errorsArray.length;
                for(i=0;i<errorsCount;i++) {
                  errorMessages.push(errorsArray[i]);
                }
              } else {
                errorMessages.push(errorsArray);
              }
            }
          }

          state.errorMessages = errorMessages;

        } else if (error.request) {
          // 2. Client never received a response, or request never left ( network error, cross-domain ...)
          console.error("login error2", error.request);
        } else {
          // 3. Anything else
          console.error("login error3", error);
        }

      });
    };

    const checkForm = function ($event) {

      $event.preventDefault();

      if (state.email && state.userpassword) {
        loadCredentials();
      }
    };

    const state = reactive({
      email: '',
      userpassword: '****',
      errorMessages:[]
    });

    const userState = reactive({
      userId: computed(() => store.state.currentUser.id ),
    });

    // Lorsuq'une personne se déconnecte, on vide le champ du mot de passe
    watch(userState, () => {
      if (! userState.userId) {
        state.userpassword = '****';
      }
    });

    return {
      state,
      checkForm
    }
  }
}
</script>
