<template>
  <div class="view">
    <div class="breadcrumbs-and-title">
      <div class="breadcrumbs">
        <BreadCrumb :paths="breadcrumbs" />
      </div>
      <h1>
        {{ $t(pageTitle) }}
        <a
          v-if="state.collectionDescriptions"
          @click="openCollectionDescriptions($event)"
          class="collection-description-toggle"
          href="#">
        </a>
      </h1>
    </div>
    <div class="contents">
      <div class="contents-wrapper" :class="state.displayModeCssClass">

        <div class="collection-header" v-if="state.collectionDescriptions">
          <CollectionDescription
                  class="with-padding"
                  :class="state.collectionDescriptionVisibleCssClass"
                  :descriptions="state.collectionDescriptions"
          />
        </div>

        <div class="contents-header with-padding">
          <ul class="contents-mode">
            <li class="contents-list-mode">
              <router-link :to="state.listItemSetRoute">
                {{ $t('Liste') }}
              </router-link>
            </li>
            <li class="contents-gallery-mode">
              <router-link :to="state.galleryItemSetRoute">
                {{ $t('Vignettes') }}
              </router-link>
            </li>
          </ul>
        </div>

        <Loading class="notice-item-content with-padding no-header" v-if="state.isLoading" />

        <div v-if="! state.isLoading">

          <!-- Mode Vignette -->
          <div v-if="state.isGalleryDisplayMode">
            <CuratedCollectionsGallery
                    :items="state.itemSets"
                    from-display-mode="gallery"
                    entityType="itemSet"
                    class="contents" />
          </div>

          <!-- Mode Liste -->
          <div v-if="state.isListDisplayMode" class="contents-list-mode-content">
            <CuratedCollectionsList
                    :items="state.itemSets"
                    from-display-mode="list"
                    queryRouteName="Curated ItemSet"
                    :pageNo="state.pageNo"
            />
          </div>

        </div>

      </div>
    </div>

  </div>

</template>

<script>

  import {computed, onMounted, reactive, watch} from 'vue'
  import {useStore} from "vuex";
  import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";
  import {getCuratedCollectionsApiURL} from "@/js/api";

  import CuratedCollectionsGallery from "../Curation/CuratedCollectionsGallery";
  import CuratedCollectionsList from "./CuratedCollectionsList";
  import BreadCrumb from "../Header/BreadCrumb";
  import CollectionDescription from "../Header/CollectionDescription";
  import Loading from "../Loading";


  const itemsByPage = 8;

  export default {
    name: 'GalleryListCuratedCollectionsView',
    components: {
      BreadCrumb,
      CollectionDescription,
      Loading,
      CuratedCollectionsGallery,
      CuratedCollectionsList
    },
    props: {
      pageTitle: {
        type: String,
        required: true
      },
      itemSetsRouteName: {
        type: String,
        required: false,
        default:'Curated ItemSets'
      },
      itemSetRouteName: {
        type: String,
        required: false,
        default:'Curated ItemSet'
      },
      defaultDisplayMode: {
        type: String,
        required: false,
        default:"list"
      },
    },
    data() {
      return {
      }
    },
    computed: {
      breadcrumbs() {
        return [
          { name: 'Home', label:'Accueil' },
          { label:this.pageTitle }
        ]
      }
    },
    setup(props) {

      const route = useRoute();
      const router = useRouter();
      const store = useStore();

      // Description de la collection
      const updateCollectionDescription = function() {
        const collectionsInfos = store.getters.collectionInfosByProp('views', 'curated_collections');
        if (collectionsInfos && collectionsInfos.id) {
            const collectionJson = store.getters.collectionJson(collectionsInfos.id);
            if (collectionJson) {
              state.collectionDescriptions = collectionJson['dcterms:description'];
            }
        }
      };

      const openCollectionDescriptions = function($event) {
        $event.preventDefault();
        state.isCollectionDescriptionVisible = ! state.isCollectionDescriptionVisible;
      };


      //
      // Mode Galerie / Mode list of selections :
      //

      // Loads all itemsets :
      const loadItemsets = function() {

        state.isLoading = true;

        // Appel à l'api :
        const apiURL = getCuratedCollectionsApiURL(
                store.getters.apiRoot,
                "",
                state.sortByParam,
                state.sortOrderParam,
        );

        store.dispatch('getApi', apiURL).then(function(response){

          state.isLoading = false;

          if (Array.isArray(response.data)) {
            state.itemSets = response.data;
          }

        });

      };


      //
      // Lifecycle Hooks
      //

      onMounted(() => {

        if (route.query.sort_by && (route.query.sort_by.length > 0)) {
          state.sortByParam = route.query.sort_by;
        }

        if (route.query.sort_order && (route.query.sort_order.length > 0)) {
          state.sortOrderParam = route.query.sort_order;
        }

        if (route.query.display_mode && (route.query.display_mode.length > 0)) {
          state.displayMode = route.query.display_mode;
        }

        loadItemsets();

        if (store.state.collections) {
          updateCollectionDescription();
        }
      });

      // Same route / Same component
      onBeforeRouteUpdate((to, from, next) => {

          // Changement de mode d'affichage de la liste des items

          const toDisplayMode = to.query.display_mode ? to.query.display_mode : props.defaultDisplayMode;
          const toQuerySortBy = to.query.sort_by ? to.query.sort_by : 'title';
          const toQuerySortOrder = to.query.sort_order ? to.query.sort_order : 'asc';

          if ((state.sortByParam !== toQuerySortBy) || (state.sortOrderParam !== toQuerySortOrder)) {

            // 2.1 Changement de l'ordre des items
            state.sortByParam = toQuerySortBy;
            state.sortOrderParam = toQuerySortOrder;
            state.pageNo = isNaN(to.query.p) ? 1 : parseInt(to.query.p);
            state.displayMode = toDisplayMode;

            loadItemsets();
            next();

          } else {

            // 2.2 Pagination
            state.pageNo = isNaN(to.query.p) ? 1 : parseInt(to.query.p);
            state.displayMode = toDisplayMode;
            next();

          }
      });

      watch(() => store.state.collections, () => {
        updateCollectionDescription();
      });

      watch(() => store.state.currentLanguage, () => {
        updateCollectionDescription();
        loadItemsets();
      });


      //
      // State
      //

      const state = reactive({

        collectionDescriptions: null,
        isCollectionDescriptionVisible:false,
        collectionDescriptionVisibleCssClass: computed( () => {
          return state.isCollectionDescriptionVisible ? "opened" : "";
        }),

        displayMode: props.defaultDisplayMode,
        displayModeCssClass: computed(() => state.displayMode + "-mode"),

        sortByParam: 'title',
        sortOrderParam: 'asc',

        // Mode Galerie/Liste des sélections
        pageNo: 1,
        pageCount: computed(() => Math.ceil( state.itemSets.length / itemsByPage )),

        itemSets: [],
        itemSetIds: computed(() => {
          let i, itemSetIds = [];
          let itemSets = state.itemSets;
          const n = itemSets.length;
          for(i=0;i<n;i++) {
            itemSetIds.push(parseInt(itemSets[i]['o:id']));
          }
          return itemSetIds;
        }),
        displayedItemSets: computed( () => {
          const startItemNo = itemsByPage * (state.pageNo - 1);
          const endItemNo = Math.min(startItemNo + itemsByPage, state.itemSets.length);
          let i, itemSets = [];
          for(i=startItemNo;i<endItemNo;i++) {
            itemSets.push(state.items[i]);
          }
          return itemSets;
        }),
        displayedItemSetsIds: computed(() => {
          let i, itemSetIds = [];
          let itemSets = state.displayedItemSets;
          const n = itemSets.length;
          for(i=0;i<n;i++) {
            itemSetIds.push(itemSets[i]['o:id']);
          }
          return itemSetIds;
        }),

        isListDisplayMode: computed(() => { return state.displayMode === "list" }),
        isGalleryDisplayMode: computed(() => { return state.displayMode === "gallery" }),
        isItemsDisplayMode: computed(() => { return state.displayMode === "items" }),

        backItemSetRoute: computed(() => {
          return {
            name: props.itemSetsRouteName,
            query: {
              sort_param: state.sortByParam,
              sort_order: state.sortOrderParam,
              display_mode: state.displayMode,
              p: state.pageNo
            }
          }
        }),

        galleryItemSetRoute: computed(() => {
          return router.resolve( {
              name: props.itemSetsRouteName,
              query: {
                sort_param: state.sortByParam,
                sort_order: state.sortOrderParam,
                display_mode: 'gallery',
                p: state.pageNo
              }
          });
        }),
        listItemSetRoute: computed(() => {
          return router.resolve( {
              name: props.itemSetsRouteName,
              query: {
                sort_param: state.sortByParam,
                sort_order: state.sortOrderParam,
                display_mode: 'list',
                p: state.pageNo
              }
          });
        }),

        userIsConnected: computed(() => store.state.currentUser.logged )
      });

      return {
        state,
        updateCollectionDescription,
        openCollectionDescriptions
      }
    }
  }

</script>
