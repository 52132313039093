<template>
  <div class="advanced-search-panel filter-by-collection" :class="panelCssClass">
    <div class="advanced-search-panel-header">
      <h3><a href="#" @click="toggleSearchPanel($event)">{{ $t("Collection") }}</a></h3>
    </div>
    <ul class="list-items-categories">
      <li class="list-items-category">
        <CollectionsCheckboxGroup
            category-title="Encyclopédie"
            category-name="encyclopedie"
            :multiple="true"
            :collections="state.encyclopedieCollections"
            @changefiltercollection="changeEncyclopedieCollections"
        />
        <CollectionsCheckboxGroup
            v-for="collectionInfos in state.facetsCollections"
            :key="collectionInfos.id"
            :category-title="collectionInfos.title"
            :category-code="collectionInfos.code"
            :category-name="collectionInfos.name"
            :collections="[ collectionInfos ]"
            :multiple="false"
            @changefiltercollection="changeFacetsCollections"
        />
      </li>
    </ul>
  </div>
</template>

<script>
  import {reactive, watch} from "vue";
  import {useStore} from "vuex";
  import CollectionsCheckboxGroup from "./CollectionsCheckboxGroup";

  export default {
    name: 'CollectionsFilterPanel',
    components: {
      CollectionsCheckboxGroup
    },
    props: {
      advancedQuery: {
        type: String,
        required: false
      },
      resetQuery: {
        type: Number,
        required: false,
        default: 0
      },
      settings: {
        type: Object,
        required: false
      },
    },
    emits: [
      "change",
      "change-settings"
    ],
    computed:{
      panelCssClass() {
        return this.state.isPanelOpened ? "" : "search-panel-closed";
      }
    },
    methods:{
      toggleSearchPanel($event) {
        $event.preventDefault();
        this.state.isPanelOpened = !this.state.isPanelOpened;
        this.$emit("change-settings", { opened: this.state.isPanelOpened })
      }
    },
    setup(props, context) {

      const store = useStore();

      /* Functions */

      const prepareDataForCheckbox = function( collections, selectedIds) {
        let i, collection, collectionId;
        const n = collections.length;
        let collectionsForCheckbox = [];
        for(i=0;i<n;i++)
        {
          collection = collections[i];
          if (collection.facet) {
            collectionId = parseInt(collection.id);
            collectionsForCheckbox.push({
              id: collection.id,
              title: store.getters.collectionTitle(collectionId),
              code: store.getters.collectionCode(collectionId),
              icon: collection.icon,
              colorNo: collection.colorNo,
              checked: (selectedIds.length === 0) || (selectedIds.indexOf(collection.id) !== -1)
            });
          }
        }

        return collectionsForCheckbox;
      };

      const updateDataForCheckbox = function( collectionsForCheckbox, selectedIds ) {
        let i, collection;
        const n = collectionsForCheckbox.length;
        for(i=0;i<n;i++) {
          collection = collectionsForCheckbox[i];
          collection.checked = (selectedIds.length === 0) || (selectedIds.indexOf(collection.id) !== -1);
        }
      };

      const getCheckedIds = function(collections) {
        let i, collection;
        const n = collections.length;
        let ids = [];
        for(i=0;i<n;i++) {
          collection = collections[i];
          if (collection.checked) {
            ids.push(collection.id);
          }
        }
        return ids;
      };

      const getCollectionIds = function() {

        var collectionsIds = [];

        // On récupère dans les paramètres de l'URL les collections sélectionnées
        if (props.advancedQuery) {
          ('?' + props.advancedQuery ).replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            if (key === "item_set_id[]") {
              collectionsIds.push(value);
            }
          });
        }
        return collectionsIds
      };


      /* State */
      const state = reactive({
        selectedEncyclopedieCollectionIds:null,
        selectedFacetsCollectionIds:null,
        isPanelOpened: props.settings && props.settings.opened === true
      });



      //
      // Collections
      //

      // Liste des ids présents dans l'URL (= à cocher):
      const collectionsIds = getCollectionIds();

      state.encyclopedieCollections = prepareDataForCheckbox(store.getters.encyclopedieCollectionInfos(), collectionsIds);
      state.facetsCollections = prepareDataForCheckbox(store.getters.facetsCollectionInfos(), collectionsIds);

      // Ids de chaque catégorie
      state.selectedEncyclopedieCollectionIds = getCheckedIds(state.encyclopedieCollections);
      state.selectedFacetsCollectionIds = getCheckedIds(state.facetsCollections);

      const updateCheckedIds = function() {
        console.log("updateCheckedIds")
        const collectionsIds = getCollectionIds();
        updateDataForCheckbox( state.encyclopedieCollections, collectionsIds);
        updateDataForCheckbox( state.facetsCollections, collectionsIds);

        state.selectedEncyclopedieCollectionIds = getCheckedIds(state.encyclopedieCollections);
        state.selectedFacetsCollectionIds = getCheckedIds(state.facetsCollections);
      };

      watch(props, () => {
        updateCheckedIds();
        onChangeCheckbox();
      });



      /* Checkbox Events */

      const changeEncyclopedieCollections = function( collectionsIds ) {
        state.selectedEncyclopedieCollectionIds = collectionsIds;
        onChangeCheckbox();
      };

      const changeFacetsCollections = function( afterCollectionsIds, beforeCollectionsIds ) {

        // console.log('AVANT', beforeCollectionsIds, "APRES", afterCollectionsIds);

        // On retire la liste avant
        let i, n = beforeCollectionsIds.length, collectionId, pos;
        for(i=0; i<n; i++) {
          collectionId = beforeCollectionsIds[i];
          pos = state.selectedFacetsCollectionIds.indexOf(collectionId);
          if (pos !== -1) {
            state.selectedFacetsCollectionIds.splice(pos, 1);
          }
        }

        // On ajoute la liste après
        n = afterCollectionsIds.length;
        for(i=0; i<n; i++) {
          collectionId = afterCollectionsIds[i];
          pos = state.selectedFacetsCollectionIds.indexOf(collectionId);
          if (pos === -1) {
            state.selectedFacetsCollectionIds.push(collectionId);
          }
        }

        // console.log('changeFacetsCollections', state.selectedFacetsCollectionIds)

        onChangeCheckbox();
      };

      const onChangeCheckbox = function() {

        const ids = [
            state.selectedEncyclopedieCollectionIds,
            state.selectedFacetsCollectionIds
        ];

        const allIds = [].concat(...ids);
        context.emit("change", allIds);
      };

      onChangeCheckbox();

      return {
        state,
        changeEncyclopedieCollections,
        changeFacetsCollections
      }
    }
  }
</script>
