<template>

  <div>

    <!-- Pagination Top -->
    <div class="list-pagination top-list-pagination with-padding">
      <div class="pagination-sort-options">

        <ListSortMenu
                @changesortquery="changeSortQuery"
                :sortByParam="state.sortByParam"
                :sortOrderParam="state.sortOrderParam"
        />
      </div>

      <ListCount
              :element-count="state.itemsCount"
              class="cards-count"
      />

      <ListPagination
              v-if="state.pageCount > 1"
              :pageCount="state.pageCount"
              :pageNo="state.pageNo"
              @change-pagination-page="displayPage"
      />
    </div>


    <div class="list-items-and-facets with-padding">

      <div class="list-items-parent">

        <div class="list-pagination list-pagination-mobile">
          <div class="pagination-sort-options">
            <ListSortMenu
                    @changesortquery="changeSortQuery"
                    :sortByParam="state.sortByParam"
                    :sortOrderParam="state.sortOrderParam"
            />
          </div>
        </div>

        <!-- Items -->
        <ul class="list-items" v-if="state.displayedItems.length">
          <li
                  v-for="item in state.displayedItems"
                  :key="item.id"
                  class="list-item"
                  :class="listItemCssClass(item)"
          >

            <router-link class="item-title" :to="itemRoute(item)">
              <span>
                {{ item.title }}
              </span>
            </router-link>

          </li>
        </ul>

        <div class="no-results with-padding" v-if="state.displayedItems.length === 0">Aucun résultat</div>
      </div>
    </div>

    <!-- Pagination Bottom -->
    <div class="list-pagination bottom-list-pagination with-padding" v-if="state.pageCount > 0">
      <div class="pagination-sort-options">
      </div>
      <ListPagination
              v-if="state.pageCount > 1"
              :pageCount="state.pageCount"
              :pageNo="state.pageNo"
              @change-pagination-page="displayPage"
      />
    </div>
  </div>

</template>

<script>

  import { reactive, computed } from 'vue'
  import {useStore} from "vuex";
  import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";

  import ListPagination from '../Lists/ListPagination';
  import ListCount from "../Lists/ListCount";
  import ListSortMenu from "../Lists/ListSortMenu";
  import {JsonReader} from "@/js/jsonReader";

  export default {
    name: 'CuratedCollectionsListItems',
    components: {
      ListSortMenu,
      ListPagination,
      ListCount
    },
    props: {
      items: {
        type: Array,
        required: true
      },
      queryRouteName: {
        type: String,
        required: false
      },
      itemRouteFolder: {
        type: String,
        required: false
      },
      pageNo: {
        type: Number,
        required: false,
        default:1
      },
      itemsByPage: {
        type: Number,
        required: false,
        default:15
      },
      titleFieldName: {
        type:String,
        required: false,
        default:'o:title'
      },
      fromDisplayMode: {
        type:String,
        required: false,
        default:'gallery'
      },
    },
    data() {
      return {
        isSortMenuOpened:false
      }
    },
    setup(props) {

      const route = useRoute();
      const router =  useRouter();
      const store = useStore();
      const jsonReader = new JsonReader();

      const state = reactive({
        pageNo: props.pageNo,
        pageCount: computed(() => props.items ? Math.ceil( props.items.length / props.itemsByPage ) : 0),
        displayedItems: computed( () => {
          if (! props.items) {
            return [];
          } else {
            const startItemNo = props.itemsByPage * (state.pageNo - 1);
            const endItemNo = Math.min(startItemNo + props.itemsByPage, props.items.length);
            const currentLanguage = store.state.currentLanguage;

            let i, items = [], item, defaultTitle;
            for(i=startItemNo;i<endItemNo;i++) {
              item = props.items[i];
              item.id = item['o:id'];

              // o:title (RQ : il est en français quelle que soit la langue)
              defaultTitle = item[props.titleFieldName];

              if (! currentLanguage || (currentLanguage === "") || (currentLanguage === "fr")) {
                item.title = defaultTitle;
              }

              if ((typeof item.title !== "string") || (item.title.length === 0)) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                jsonReader.json = item;
                item.title = jsonReader.getLocalizedMetaDataValue('dcterms:alternative', currentLanguage, defaultTitle);
              }

              items.push(item);
            }
            return items;
          }
        }),
        displayedItemsIds: computed(() => {
          let i, itemsIds = [];
          let items = state.displayedItems;
          const n = items.length;
          for(i=0;i<n;i++) {
            itemsIds.push(items[i].id);
          }
          return itemsIds;
        }),
        itemsCount: computed(() => props.items.length),
        resultsCount: computed(() => {
          const resultsCount = props.items.length;
          if (resultsCount === 0) {
            return "Aucun résultat";
          } else if (resultsCount === 1) {
            return "1 résultat";
          } else {
            return resultsCount + " résultats";
          }
        }),

        sortByParam: 'title',
        sortOrderParam: 'asc',

        listTitle: props.listTitle,
        listDescriptions:props.listDescriptions || [],

        userIsConnected: computed(() => store.state.currentUser.logged ),
      });

      onBeforeRouteUpdate((to) => {
        state.pageNo = isNaN(to.query.p) ? 1 : parseInt(to.query.p);
      });

      const changeSortQuery = function( changes ) {
        router.push({
          name: 'Curated ItemSets',
          query : {
            sort_by : changes.sortBy,
            sort_order : changes.sortOrder,
            display_mode: route.query.display_mode
          }
        });
      };


      return {
        state,
        changeSortQuery
      }
    },
    computed:{
      currentRoutePath() {
        return this.$route.path;
      },
    },
    methods:{
      listItemCssClass(item) {
        return item.colorNo ? "list-item-category-" + item.colorNo : "";
      },
      clickItem($event, itemId) {
        $event.preventDefault();
        this.$emit('openitem', itemId);
      },
      displayPage(pageNo) {
        // On modifie la route en cours avec le paramètre p=...
        // Ce changement déclenchera, via onBeforeUpdate
        // de ce composant ListeItems, l'affichage de la nouvelle page

        let routeCopy = {
          name : this.$route.name,
          query : { ...this.$route.query },
          params : { ...this.$route.params }
        };

        // Nouvelle page
        routeCopy.query.p = pageNo;

        this.$router.push(routeCopy);
      },
      itemRoute(item) {

        const hrefWithRouterBase = this.$router.resolve({
          name: this.queryRouteName,
          params: { itemset: item.id },
          query: { from_mode: this.fromDisplayMode, lang: this.$store.state.currentLanguage }
        }).href;

        return hrefWithRouterBase.split(this.$store.state.publicPath).join('/');
      },

    }
}
</script>
