import * as d3 from "d3";
import { wrap } from './viz-utils.js';
import { JsonReader } from './jsonReader.js';

function VizItem() {

    this.width = 1024;
    this.height = 600;

    this.zoomMin = 0.2;
    this.zoomMax = 5;
    this.zoom = this.zoomMin;

    this.availableLinkTypes = [];

    // Positions de base sur le graphe
    this.linkTypePositions = [];

    // Variations de position sur le graphe
    this.linkTypePositionVariations = [
        0,
        - Math.PI / 6,
        + Math.PI / 6,
        - Math.PI / 12,
        + Math.PI / 12
    ];

    this.apiRoot = "/api";
    this.isLoading = false;

    this.currentLanguage = "fr";
    this.collectionsApiURL = "";
    this.keyIdentity = "";

    // Infos from Settings
    this.colorsByCategory = [];
    this.colorNosByCategory = [];
    this.iconsByCategory = [];
    this.settingsByCollectionName = [];
    this.categoryKeyPrefix = "c";

    this.categoryIconColor = "#384651";

    this.jsonReader = new JsonReader();
}

Object.assign( VizItem.prototype, {

    init: function( parentElementId ) {

        const t = this;

        this.initialized = true;

        this.parentElement = document.getElementById(parentElementId);

        // Elément SVG parent
        this.svg = d3
            .select("#" + parentElementId)
            .append('svg')
            .attr("width", "100%")
            .attr("height", "100%");

        this.centerElement = this.svg.append('g')
            .attr('class', 'visualization_center');

        // Fond captant les événements du zoom
        this.centerElement
            .append('rect')
            .attr('class', 'zoom-event-target')
            .attr('fill', 'rgba(255,0,0,0')
            .attr('width', this.width)
            .attr('height', this.height)
            .attr('x', -this.width/2)
            .attr('y', -this.height/2);

        // Parent des images et des markers ( flèches )
        this.defs = this.svg.append('svg:defs');

        /* Icônes SVG */
        this.addIconsInSVGDefinitions();

        let i, n = this.collections.length, collection;
        for(i=0;i<n;i++) {
            collection = this.collections[i];
            collection.color = this.getColorOfCategory(collection.id);
            collection.colorNo = this.getColorNoOfCategory(collection.id);
            collection.icon = this.getNameOfCategory(collection.id);

            // Icônes des collections (personnes, concepts, ...)
            this.addIconsForCategoriesInSVGDefinitions(collection);
        }

        // Flèches des liens (markers)
        this.addMarkersInSVGDefinitions();

        // Calques du SVG :
        const parentVisualization = this.centerElement.append('g')
            .attr('class', 'parent');

        parentVisualization.on("click", function(event) {
            event.stopPropagation();
        });

        this.svg.on("click", function() {
            t.deselectItems();
            t.hideShortNotice();
        });

        this.parentLines = parentVisualization.append('g').attr('class', 'parent_lines');
        this.parentCircles = parentVisualization.append('g').attr('class', 'parent_circles');

        // Drag and Drop + Zoom
        this.zoomableElement = parentVisualization;
        this.zoomBehavior = d3.zoom();
        this.centerElement.call(
            this.zoomBehavior
                .extent([[0,0],[300,300]])
                .on("zoom", function (event) {
                    parentVisualization.attr("transform", event.transform);
                })
        );

        this.updateCenterPosition();
        this.initCollectionsData();
        this.initItemData();
        this.resetLinkTypes();
    },

    zoomIn: function () {
        if (this.zoom < this.zoomMax) {
            this.zoom = Math.min(this.zoomMax, this.zoom * 1.2);
            this.centerElement.call(this.zoomBehavior.transform, d3.zoomIdentity.scale(this.zoom));
            // this.zoomBehavior.scaleBy(this.zoomableElement, 1.2, [0, 0]);
        }
    },

    zoomOut: function () {
        if (this.zoom > this.zoomMin) {
            this.zoom = Math.max(this.zoomMin, this.zoom / 1.2);
            this.centerElement.call(this.zoomBehavior.transform, d3.zoomIdentity.scale(this.zoom));
            // this.zoomBehavior.scaleBy(this.zoomableElement, 1 / 1.2, [0, 0]);
        }
    },

    zoomLevelIsMax: function () {
        return this.zoom === this.zoomMax;
    },

    zoomLevelIsMin: function () {
        return this.zoom === this.zoomMin;
    },

    zoomReset: function () {
        if (this.zoomBehavior) {
            this.zoom = 1;
            this.centerElement.call(this.zoomBehavior.transform, d3.zoomIdentity);

            // this.centerElement.transition().duration(750).call(this.zoomBehavior.transform, d3.zoomIdentity);
            // this.centerElement.call(this.zoomBehavior.transform, d3.zoomIdentity);
            // this.zoomBehavior.scaleTo(this.zoomableElement, 1, [0, 0]);
        }
    },

    setLinkTypesSettings: function (linkTypesJson) {
        this.linkTypesSettings = linkTypesJson;

        const terms = []; // Tableau linéaire des termes


        if (Array.isArray(linkTypesJson)) {
            const termsIndexes = [];
            let n = this.linkTypesSettings.length;
            let i, linkTypeSetting, linkTypeTerm;
            for(i=0; i<n; i++) {
                linkTypeSetting = linkTypesJson[i];
                linkTypeTerm = linkTypeSetting.term;
                if (linkTypeTerm) {
                    terms.push(linkTypeTerm);
                    termsIndexes.push(i);
                }
            }

            // On répartira les terms par tranche d'angle :
            n = termsIndexes.length;
            if (n > 0) {
                let linkTypeIndex;
                const angleByTerm = 2 * Math.PI / n;
                for(i=0; i<n; i++) {
                    linkTypeTerm = terms[i];
                    linkTypeIndex = termsIndexes[i];
                    linkTypeSetting = this.linkTypesSettings[linkTypeIndex];

                    this.linkTypePositions[linkTypeTerm] = - i * angleByTerm;
                }
            }
        }

        this.availableLinkTypes = terms;
        this.activeLinkTypes = terms.concat();
    },

    resetLinkTypes: function () {
        // Liens affichés sur le graphe
        // RQ : Ce tableau peut être modifié de l'extérieur et entrainera un rafraichissement du graphe
        this.activelinkTypes = this.availableLinkTypes.concat();
    },

    initCollectionsData: function () {

        // Liste des collections ( contenant la liste des ids de chaque collection)
        // Tableau linéaire d'objets, un par collection : [ { id:500, items:[ { id:121, title:"Titre Item 121" }, ... ], ...  ]
        // Sert pour retrouver la collection à laquelle appartient un item pour afficher l'icône correspondante
        // Le résultat est mémorisé dans 'itemsCollection'
        this.collections = [];

        // Sauvegarde de la collection des items (pour éviter de faire la même recherche,
        // dans le tableau précédent 'collections', à chaque UpdateCircle)
        // Tableau associatif : key itemId --> value CollectionId
        this.itemsCollection = [];

    },

    initItemData: function () {

        // Tableau linéaire des noeuds D3.js
        this.nodes = [];

        // Tableau linéaire des traits D3.js
        this.edges = [];

        // Tableau associatif des noeuds du panier - clé type+id (ex : "items218" )
        // servant à ne pas créer deux noeuds identiques
        // clé : 'items'+id => valeur : liste des liens de cet item
        this.basketNodes = [];
    },

    clear: function () {

        if (this.simulation) {
            this.simulation.stop();
        }

        this.initItemData();
        this.clearSVG();
        this.updateCenterPosition();
        this.zoomReset();
        this.resetLinkTypes();

        this.centralItemId = null;
    },

    resize: function ( newWidth, newHeight ) {

        this.width = newWidth;
        this.height = newHeight;

        this.centerElement.select('rect.zoom-event-target')
            .attr('width', this.width)
            .attr('height', this.height)
            .attr('x', -this.width/2)
            .attr('y', -this.height/2);

        this.updateCenterPosition();
    },

    updateCenterPosition: function () {
        // Position du centre de la visualisation
        this.cX = this.width/2;
        this.cY = this.height/2;
        this.centerElement.attr("transform", "translate("+this.cX+","+this.cY+")");
    },

    getItemCollection: function (itemId) {
        const itemKey = "item" + itemId;
        let collection = this.itemsCollection[itemKey];
        if (collection === undefined) {
            collection = this.findItemCollection(itemId);
            if (collection) {
                this.itemsCollection[itemKey] = collection;
            }
        }
        return collection;
    },

    findItemCollection: function (itemId) {
        if (this.collections) {
            let i, collection;
            const itemIdAsInt = parseInt(itemId), n = this.collections.length;
            for(i=0;i<n;i++)
            {
                collection = this.collections[i];
                if (collection.items) {
                    let j, item;
                    const m = collection.items.length;
                    for(j=0;j<m;j++) {
                        item = collection.items[j];
                        if (parseInt(item['o:id']) === itemIdAsInt) {
                            return collection;
                        }
                    }
                }
            }
        }
    },

    findCollection: function (collectionId) {
        if (this.collections) {
            let i, n = this.collections.length, collection;
            for(i=0;i<n;i++)
            {
                collection = this.collections[i];
                if (collection.id === collectionId) {
                    return collection;
                }
            }
        }
    },

    getCollectionSettingsByName: function (categoryName) {
        return this.settingsByCollectionName[this.categoryKeyPrefix + categoryName];
    },

    getNextIndex: function () {

        if (this.nodes.length === 0) {
            return 0;
        }

        return Math.max.apply(Math, this.getNodeIndexes()) + 1;
    },

    getNodeIndexes: function () {

        let i, n = this.nodes.length, node, indexes = [];
        for (i = 0; i < n; i++) {
            node = this.nodes[i];
            indexes.push(node.index);
        }

        return indexes;
    },

    getBasketNodeKeys: function () {

        let i, n = this.nodes.length, node, nodeKey, keys = [];
        for (i = 0; i < n; i++) {
            node = this.nodes[i];
            if (node.id && node.basket) {
                nodeKey = this.getNodeKey(node.id);
                keys.push(nodeKey);
            }
        }

        return keys;
    },

    getBasketIds: function () {

        let i, n = this.nodes.length, node, ids = [];
        for (i = 0; i < n; i++) {
            node = this.nodes[i];
            if (node.id && node.basket) {
                ids.push( parseInt(node.id));
            }
        }

        return ids;
    },

    getNodeItemIds: function () {

        let i, n = this.nodes.length, node, ids = [];
        for (i = 0; i < n; i++) {
            node = this.nodes[i];
            if (node.id) {
                ids.push(parseInt(node.id));
            }
        }

        return ids;
    },

    getNodeKey: function (itemId) {
        return "items" + itemId;
    },

    findNodeByIndex: function (index) {

        let i, n = this.nodes.length, node;
        for (i = 0; i < n; i++) {
            node = this.nodes[i];
            if (node.index === index) {
                return node;
            }
        }
    },

    findNodeByNodeKey: function (nodeKey) {
        let i, n = this.nodes.length, node, key;
        for (i = 0; i < n; i++) {
            node = this.nodes[i];
            key = this.getNodeKey(node.id);
            if (key === nodeKey) {
                return node;
            }
        }
        return null;
    },

    findNodeByIndexes: function (indexes) {
        let i, n = this.nodes.length, node, foundNodes = [];
        for (i = 0; i < n; i++) {
            node = this.nodes[i];
            if (indexes.indexOf(node.index) !== -1) {
                foundNodes.push(node);
            }
        }
        return foundNodes;
    },

    findEdgeBySourceAndTarget: function (sourceIndex, targetIndex) {

        let i, n = this.edges.length, edge, edgeSourceIndex, edgeTargetIndex;
        for (i = 0; i < n; i++) {
            edge = this.edges[i];

            edgeSourceIndex = isNaN(edge.source) ? edge.source.index : edge.source;
            edgeTargetIndex = isNaN(edge.target) ? edge.target.index : edge.target;

            // console.log(" ----  findEdgeBySourceAndTarget", edgeSourceIndex, edgeTargetIndex, "==",  sourceIndex, targetIndex);

            if (((edgeSourceIndex === sourceIndex) && (edgeTargetIndex === targetIndex))
                || ((edgeSourceIndex === targetIndex) && (edgeTargetIndex === sourceIndex))) {

                // console.log("findEdgeBySourceAndTarget EXISTE", sourceIndex, targetIndex);

                return edge;
            }
        }

        // console.log("findEdgeBySourceAndTarget NEW", sourceIndex, targetIndex);

        return false;
    },

    findNodeEdgesByNodeIndex: function (nodeIndex) {

        let i, n = this.edges.length, edge, edgeSourceIndex, edgeTargetIndex;
        let nodeEdges = [];
        for (i = 0; i < n; i++) {
            edge = this.edges[i];
            edgeSourceIndex = isNaN(edge.source) ? edge.source.index : edge.source;
            edgeTargetIndex = isNaN(edge.target) ? edge.target.index : edge.target;
            if ((edgeSourceIndex === nodeIndex) || (edgeTargetIndex === nodeIndex)) {
                nodeEdges.push(edge);
            }
        }

        return nodeEdges;
    },

    setItemAsRootNode: function (itemId) {
        let i, n = this.nodes.length, node;
        for (i = 0; i < n; i++) {
            node = this.nodes[i];
            node.root = node.id === itemId;
        }
    },

    initToBasketWithItem: function () {
        const itemsIds = this.getBasketIds();
        this.parentElement.dispatchEvent( new CustomEvent( "init-basket", {
            bubbles: true,
            cancelable: true,
            detail: {
                ids: itemsIds
            }
        }));
    },

    addItemToBasket: function (itemId, clickedNode) {

        // console.log("addItemToBasket ---------------------------", itemId);

        clickedNode.basket = true;
        clickedNode.temporary = false;

        // On immobilise le noeud cliqué
        clickedNode.fx = clickedNode.x;
        clickedNode.fy = clickedNode.y;

        this.inactiveItems();
        this.removeTemporaryNodes();

        if (this.simulation) {
            this.simulation.stop();
        }

        this.parentElement.dispatchEvent( new CustomEvent( "add-to-basket", {
            bubbles: true,
            cancelable: true,
            detail: {
                id: parseInt(clickedNode.id)
            }
        }));

        // console.log("addItemToBasket", clickedNode);

        //
        var t = this;

        this.loadItemFromJson(itemId, clickedNode, function() {
            t.activeItemById(itemId)
        });
    },

    removeItemFromBasket: function (nodeToRemove) {

        // Le noeud ne fera plus partie du panier
        nodeToRemove.basket = false;

        // On doit faire la liste des enfants de tous les autres noeuds du panier
        // pour ne pas supprimer des noeuds partagés avec l'item supprimé
        // ou l'item supprimé lui-même s'il est un enfant "object" d'un autre item du panier

        let i, node, basketNodeKey, childNodes, j, childNode;
        let nodeIndexesToMaintain = [];
        for (i = 0; i < this.nodes.length; i++) {
            node = this.nodes[i];

            // Liste des items du panier
            if (node.basket) {

                if (nodeIndexesToMaintain.indexOf(node.index) === -1) {
                    nodeIndexesToMaintain.push(node.index);
                }

                // On liste les enfants à conserver i.e les enfants des items du panier
                basketNodeKey = this.getNodeKey(node.id);
                childNodes = this.basketNodes[basketNodeKey].children;

                if (childNodes !== undefined) {
                    for (j = 0; j < childNodes.length; j++) {
                        childNode = childNodes[j];
                        if (nodeIndexesToMaintain.indexOf(childNode.index) === -1) {
                            nodeIndexesToMaintain.push(childNode.index);
                        }
                    }
                } else {
                    console.log("removeItemFromBasket no children **************** ", basketNodeKey);
                }
            }
        }

        // console.log("nodeIndexesToMaintain", nodeIndexesToMaintain);

        // On liste les indexes des noeuds à supprimer
        // ( = différence entre le tableau de tous les noeuds et le précécédent tableau des noeuds à conserver)
        let allNodesIndex = this.getNodeIndexes();
        let nodeIndex, nodesIndexesToRemove = [];
        for (i = 0; i < allNodesIndex.length; i++) {
            nodeIndex = allNodesIndex[i];
            if (nodeIndexesToMaintain.indexOf(nodeIndex) === -1) {
                nodesIndexesToRemove.push(nodeIndex)
            }
        }

        // if (0) {
        // console.log("nodeIndexes", allNodesIndex);
        // console.log("nodeIndexesToMaintain", nodeIndexesToMaintain);
        // console.log("nodeIndexesToRemove", nodesIndexesToRemove);
        // }

        // Dans le tableau associatif, on retire les enfants du noeud que l'on retire du panier
        let nodeKey = this.getNodeKey(nodeToRemove.id);
        if (this.basketNodes[nodeKey] !== undefined) {
            delete this.basketNodes[nodeKey];
        }

        // On retire le tableau des enfants des noeuds que l'on supprime
        for (i = 0; i < nodesIndexesToRemove.length; i++) {
            nodeIndex = nodesIndexesToRemove[i];
            node = this.findNodeByIndex(nodeIndex);
            nodeKey = this.getNodeKey(node.id);

            // On retire les noeuds du tableau associatif des enfants
            childNode = this.basketNodes[nodeKey];
            if (childNode !== undefined) {
                delete this.basketNodes[nodeKey];
            }
        }

        // On met à jour la liste des noeuds
        this.nodes = this.findNodeByIndexes(nodeIndexesToMaintain);

        // On conserve les liens s'ils n'ont pas de point commun avec les nodes à supprimer
        // et si les deux items qu'ils lient ne sont pas dans le panier
        let allEdges = this.edges, edge, filteredEdges = [], edgeSource, edgeTarget, nodeEdgeSource, nodeEdgeTarget;
        for (i = 0; i < allEdges.length; i++) {
            edge = allEdges[i];
            edgeSource = edge.source.index;
            edgeTarget = edge.target.index;
            if ((nodesIndexesToRemove.indexOf(edgeSource) === -1) && (nodesIndexesToRemove.indexOf(edgeTarget) === -1)) {
                nodeEdgeSource = this.findNodeByIndex(edgeSource);
                nodeEdgeTarget = this.findNodeByIndex(edgeTarget);
                if (nodeEdgeSource.basket || nodeEdgeTarget.basket) {
                    filteredEdges.push(edge);
                }
            }
        }

        this.edges = filteredEdges;
        this.updateBasket();
        this.renderSVG();

        this.parentElement.dispatchEvent( new CustomEvent( "remove-from-basket", {
            bubbles: true,
            cancelable: true,
            detail: {
                id: parseInt(nodeToRemove.id)
            }
        }));
    },

    updateBasket: function() {
        this.basketData = [];
        let parentKey, wc = this.basketNodes;
        for (parentKey in wc) {
            if (Object.prototype.hasOwnProperty.call(wc, parentKey)) {
                this.basketData.push( wc[parentKey].data );
            }
        }
    },

    // Retourne la liste des nodeKey des parents d'un noeud
    findBasketParentNodesFromNodeKey: function (nodeKey) {

        let parentKey, childrenDescriptions, wc = this.basketNodes, parentNodes = [];
        for (parentKey in wc) {
            if (Object.prototype.hasOwnProperty.call(wc, parentKey)) {
                childrenDescriptions = wc[parentKey].children;
                if (childrenDescriptions) {
                    let j, m = childrenDescriptions.length, childDescription, parentNode;
                    for (j = 0; j < m; j++) {
                        childDescription = childrenDescriptions[j];
                        if (childDescription.nodeKey === nodeKey) {
                            parentNode = this.findNodeByNodeKey(parentKey);
                            parentNodes.push(parentNode);
                        }
                    }
                }
            }
        }
        return parentNodes;
    },

    hasParentInBasket: function (nodeKey) {
        return this.findBasketParentNodesFromNodeKey(nodeKey).length > 0;
    },

    findParentNodeKeysFromNodeKey: function (itemNodeKey) {
        let parentNodeKeys = [];
        let i, j, itemJson, basketItemId, basketNodeKey, n = this.basketData.length;
        for (i=0;i<n;i++) {

            itemJson = this.basketData[i];
            basketItemId = itemJson["o:id"];
            basketNodeKey = this.getNodeKey(basketItemId);

            const nodeKeys = this.getNodeKeysFromItemData(itemJson, "o:object");
            let childNodeKey;
            for (j=0;j<nodeKeys.length;j++) {
                childNodeKey = nodeKeys[j];
                if (parentNodeKeys[childNodeKey]) {
                    parentNodeKeys[childNodeKey].push(basketNodeKey);
                } else {
                    parentNodeKeys[childNodeKey] = [basketNodeKey];
                }
            }
        }

        if ( ! parentNodeKeys[itemNodeKey]) return [];
        else return parentNodeKeys[itemNodeKey];
    },


    removeChildrenNodeKeyToNodeKeys: function (nodeKey) {
        let parentKey, childrenDescriptions, wc = this.basketNodes;
        for (parentKey in wc) {
            if (Object.prototype.hasOwnProperty.call(wc, parentKey)) {
                childrenDescriptions = wc[parentKey].children;
                if (childrenDescriptions) {
                    let j, m = childrenDescriptions.length, childDescription, filteredChildDescriptions = [];
                    for (j = 0; j < m; j++) {
                        childDescription = childrenDescriptions[j];
                        if (childDescription.nodeKey !== nodeKey) {
                            filteredChildDescriptions.push(childDescription);
                        }
                    }
                    if (filteredChildDescriptions.length < m) {
                        wc[parentKey].children = filteredChildDescriptions;
                    }
                }
            }
        }
    },

    removeTemporaryNodes: function () {

        let node, i, nodeKey, nodeIndex, nodesIndexesToRemove = [], nodeIndexesToMaintain = [];
        const n = this.nodes.length;

        for (i = 0; i < n; i++) {
            node = this.nodes[i];
            nodeIndex = node.index;
            if (node.temporary === true) {
                nodesIndexesToRemove.push(nodeIndex);
                nodeKey = this.getNodeKey(node.id);
                this.removeChildrenNodeKeyToNodeKeys(nodeKey);
            } else {
                nodeIndexesToMaintain.push(nodeIndex);
            }
        }

        // On met à jour la liste des noeuds
        this.nodes = this.findNodeByIndexes(nodeIndexesToMaintain);

        // On conserve les liens s'ils n'ont pas de point commun avec les nodes à supprimer
        // et si les deux items qu'ils lient ne sont pas dans le panier
        let allEdges = this.edges, edge, filteredEdges = [], edgeSource, edgeTarget;
        for (i = 0; i < allEdges.length; i++) {
            edge = allEdges[i];
            if (edge.temporary !== true)
            {
                edgeSource = edge.source.index;
                edgeTarget = edge.target.index;
                if ((nodesIndexesToRemove.indexOf(edgeSource) === -1) && (nodesIndexesToRemove.indexOf(edgeTarget) === -1)) {
                    // let nodeEdgeSource = this.findNodeByIndex(edgeSource);
                    // let nodeEdgeTarget = this.findNodeByIndex(edgeTarget);
                    filteredEdges.push(edge);
                }
            }
        }

        this.edges = filteredEdges;
        this.renderSVG();
    },

    // S'assure que la clé d'un nouvel enfant n'est pas déjà présent dans le tableau des enfants en cours de création
    addNodeKeyToNewNodes: function (newNodes, nodeKey, index, isObject) {

        // Liste des clés existantes
        let keys = [], k, key;
        for (k = 0; k < newNodes.length; k++) {
            key = newNodes[k].nodeKey;
            if (keys.indexOf(key) === -1) {
                keys.push(key);
            }
        }

        // On ajoute si la clé n'est pas déjà présente
        if (keys.indexOf(nodeKey) === -1) {
            newNodes.push({
                nodeKey: nodeKey,
                index: index,
                forward: isObject
            });
        }
    },

    updateEdgeDirection: function (edge, edgeDirection) {
        let currentEdgeDirection = edge.direction;
        if ((currentEdgeDirection === undefined) || (currentEdgeDirection.length === 0)) {
            edge.direction = [edgeDirection];
        } else {
            let i, n = currentEdgeDirection.length, direction, directionFound = false;
            for (i = 0; i < n; i++) {
                direction = currentEdgeDirection[i];
                if (((direction.source === edgeDirection.source) && (direction.target === edgeDirection.target) && (direction.forward === edgeDirection.forward))
                    || ((direction.source === edgeDirection.target) && (direction.target === edgeDirection.source) && (direction.forward === ! edgeDirection.forward))) {
                    directionFound = true;
                    break;
                }
            }
            if (!directionFound) {
                edge.direction.push(edgeDirection);
            }
        }
    },

    // Retourne la liste des clés (nodeKeys) des items du Json
    // relation = "o:object", "o:subject"
    getNodeKeysFromItemData: function (itemJson, relation) {

        if (relation === undefined) {
            relation = "o:object";
        }

        var nodeKeys = [];
        let key, keyValues, ressourceProps, ressourceType, nodeId, nodeKey;

        const allRelationShips = itemJson["o-module-api-info:append"];
        const relationShips = allRelationShips[relation];

        for (key in relationShips) {

            if (Object.prototype.hasOwnProperty.call(relationShips, key)) {

                keyValues = relationShips[key];

                // RQ : Les propriétés du JSON ont des valeurs de type Array. On boucle donc sur cette liste de liens :
                let i, n = keyValues.length;
                for (i = 0; i < n; i++) {
                    ressourceProps = keyValues[i];
                    ressourceType = ressourceProps["o:type"];

                    // if ( (ressourceType === "o:Item") || (ressourceType === "resource:item")) {
                    if (this.isAllowedResourceType(ressourceType)) {
                        nodeId = ressourceProps["o:id"];
                        nodeKey = this.getNodeKey(nodeId);
                        nodeKeys.push(nodeKey);
                    }
                }
            }
        }

        return nodeKeys;
    },

    isAllowedResourceType: function(resourceType) {
        return (resourceType === "o:Item") || (resourceType === "resource:item") || (resourceType.indexOf("customvocab:") === 0);
    },

    getCollectionsURL: function() {
        return  this.collectionsApiURL+this.keyIdentity;
    },

    getItemJsonURL: function (itemId) {
        return this.apiRoot + '/items/'+itemId+'?append[0]=urls&append[1]=objects&append[2]=subjects&short_title=dcterms:alternative,bibo:shortTitle&'+this.keyIdentity;
    },

    getItemsJsonURL: function (itemsIds) {
        const n = itemsIds.length;
        let i, query = "";
        for(i=0;i<n;i++) {
            query += (i === 0 ? "" : "&");
            query += "id[]=" + itemsIds[i];
        }

        return this.apiRoot + '/items?'+query+'&append[0]=urls&append[1]=objects&append[2]=subjects&short_title=dcterms:alternative,bibo:shortTitle&'+this.keyIdentity;
    },

    loadCollectionsAndItem: function (itemId) {

        const t = this;

        if (t.collections && (t.collections.length > 0)) {
            t.loadItemFromJson(itemId, null, t.initToBasketWithItem);
        }
        else
        {
            t.isLoading = true;

            const url = this.getCollectionsURL();
            d3.json(url).then(function (json) {

                t.isLoading = false;
                t.collections = json.data;
                t.itemsCollection = [];

                // On s'assure que cet item appartient bien à une collection
                if (t.findItemCollection(itemId)) {
                    t.loadItemFromJson(itemId, null, t.initToBasketWithItem);
                } else {
                    t.close();
                }
            });
        }
    },

    loadCollectionsAndItems: function (itemsIds) {

        const t = this;

        if (t.collections && (t.collections.length > 0)) {
            t.loadItemsFromJson(itemsIds, null, t.initToBasketWithItem);
        }
        else
        {
            const url = this.getCollectionsURL();
            t.isLoading = true;

            d3.json(url).then(function (json) {
                t.isLoading = false;
                t.collections = json.data;
                t.itemsCollection = [];
                t.loadItemsFromJson(itemsIds, null, t.initToBasketWithItem);
            });
        }
    },

    loadItemFromJson: function (itemId, itemNode, callback) {

        const url = this.getItemJsonURL(itemId);

        let t = this;
        t.isLoading = true;

        d3.json(url).then(function (data) {
            t.isLoading = false;
            t.noticeLoaded(itemId, data);
            t.centralItemId = parseInt(itemId);
            t.createNodeFromJson(itemId, data, itemNode);
            t.dispatchLinkTypes();
            t.updateBasket();
            t.renderSVG();
            if (callback) {
                callback.apply(t);
            }
        });
    },

    loadItemsFromJson: function (itemsIds, itemNode, callback) {

        // Retourne un tableau des json des items
        let url = this.getItemsJsonURL(itemsIds);

        let t = this;
        t.isLoading = true;

        d3.json(url).then(function (data) {
            t.isLoading = false;

            // On trie les données dans l'ordre des ids
            t.basketData = data.sort(function(a, b) {
                return itemsIds.indexOf(a['o:id']) < itemsIds.indexOf(b['o:id']) ? -1 : +1;
            });

            t.clear();
            t.centralItemId = parseInt(itemsIds[0]);
            t.prepareBasketData();
            t.renderSVG();
            if (callback) {
                callback.apply(t);
            }

        });
    },

    loadTemporaryItemsFromJson: function (itemNode) {

        const itemId = itemNode.id;

        let t = this;
        t.isLoading = true;

        let url = this.getItemJsonURL(itemId);

        d3.json(url).then(function (data) {
            t.isLoading = false;
            t.createTemporaryNodesFromJson(itemId, data, itemNode );
            t.activeItemByIndex(itemNode.index);
            t.renderSVG();
        });
    },

    getLocalizedTitleTermFromJson: function (itemJson, term = 'dcterms:alternative') {
        let title;
        const titlesObj = itemJson[term];
        if (Array.isArray(titlesObj)) {
            let n = titlesObj.length, titleObj, titleLanguage;
            for (let i = 0; i < n; i++) {
                titleObj = titlesObj[i];
                titleLanguage = titleObj['@language'];
                if (titleLanguage && (titleLanguage.substr(0,2).toLowerCase() === this.currentLanguage)) {
                    title = titleObj['@value'];
                }
            }
            if (! title && (n > 0)) {
                title = titlesObj[0]['@value']
            }
        }
        return title;
    },

    getTitleFromJson: function (itemJson) {

        this.jsonReader.json = itemJson;

        let title = this.jsonReader.getLocalizedMetaDataValue('dcterms:alternative', this.currentLanguage);

        if ((typeof title !== "string") || (title.length === 0)) {
            title = this.getLocalizedTitleTermFromJson(itemJson, 'short_title');
        }

        if ((typeof title !== "string") || (title.length === 0)) {
            title = itemJson['o:title'];
        }

        return this.capitalizeText( title );
    },

    capitalizeText: function (title) {
        return title.charAt(0).toUpperCase() + title.slice(1);
    },

    createNodeFromJson: function (itemId, itemJson, itemNode) {

        let newNodeKey = this.getNodeKey(itemId);
        let newNodeIndex, newNodeKeys = [];

        // console.log( "createNodeFromJson itemId=", itemId, newNodeKey, "itemNode", itemNode, "data", itemJson );

        // Prochain index
        // let index = this.getNextIndex();

        if (! itemNode) {

            // Nouvel item, sans lien avec un précédent (ex : le premier) :
            newNodeIndex = this.getNextIndex();

            itemNode = {
                basket: true,
                id: itemId,
                title: this.getTitleFromJson(itemJson),
                root: itemId === this.centralItemId,
                index: newNodeIndex
            };

            // console.log( "createNodeFromJson itemId=", itemId, this.centralItemId, itemNode.root)

            this.nodes.push(itemNode);

            // Met à jour la représentation de l'item ( item et ses enfants)
            this.addNodeKeyToNewNodes(newNodeKeys, newNodeKey, newNodeIndex, true);
        }

        this.basketNodes[newNodeKey] = {
            children: [],
            data : itemJson
        };

        // Liens objects :
        const linksJson = itemJson["o-module-api-info:append"];
        if (linksJson) {
            const objectslinksJson = linksJson["o:object"];
            if (objectslinksJson) {
                this.createNodesForLinksByType(objectslinksJson, itemNode, true);
            }
            /*
            const subjectslinksJson = linksJson["o:subject"];
            if (subjectslinksJson) {
                this.createNodesForLinksByType(subjectslinksJson, itemNode, false, true);
            }
            */
        }
    },

    // Evénement indiquant les différents types de liens presénts dans le graphe
    // Permettra de masquer les choix dans le sélecteur de la vue parente (VisualisationItem)
    dispatchLinkTypes: function() {
        const n = this.nodes.length;
        let i, nodeJson, linkTypes = [];

        for(i=0; i<n; i++) {
            nodeJson = this.nodes[i];
            if (nodeJson.linkType && linkTypes.indexOf(nodeJson.linkType) === -1) {
                linkTypes.push(nodeJson.linkType);
            }
        }

        this.parentElement.dispatchEvent( new CustomEvent( "link-types", {
            bubbles: true,
            cancelable: true,
            detail: {
                linkTypes
            }
        }));
    },

    createTemporaryNodesFromJson: function (itemId, itemJson, itemNode) {

        // Liens subjects :
        const linksJson = itemJson["o-module-api-info:append"];
        if (linksJson) {
            const objectslinksJson = linksJson["o:object"];
            if (objectslinksJson) {
                this.createNodesForLinksByType(objectslinksJson, itemNode, true, true);
            }
            const subjectslinksJson = linksJson["o:subject"];
            if (subjectslinksJson) {
                this.createNodesForLinksByType(subjectslinksJson, itemNode, false, true);
            }
        }
    },

    createNodesForLinksByType: function ( linksJson, fromNode, areObjects, areTemporary ) {

        fromNode.nodeKey = this.getNodeKey(fromNode.id);

        const fromNodeIsInBasket = fromNode.basket;
        const temporary = areTemporary === true;

        let newNodeKeys;

        if (fromNodeIsInBasket) {
            const basketNodeDetails = this.basketNodes[fromNode.nodeKey];
            if ((basketNodeDetails !== undefined) && (basketNodeDetails.children !== undefined)) {
                newNodeKeys = basketNodeDetails.children
            } else {
                newNodeKeys = [];
            }
        }

        // console.log("createNodesForLinksByType from", fromNode.id, fromNode.nodeKey, "in basket", fromNodeIsInBasket);

        let keyValues, ressourceProps, ressourceType, linkType, nodeId, nodeTitle, nodeKey, nodeCollectionId;
        let edge, edgeDirection;

        // Prochain index
        let index = this.getNextIndex();

        let nodeAngle, nodeAngleVariation;

        const positionVariationsCount = this.linkTypePositionVariations.length;

        for (linkType in linksJson) {

            if (Object.prototype.hasOwnProperty.call(linksJson, linkType)) {

                // Seuls certains types de liens sont représentés : générique, associé, proche, spécifique
                if (this.activelinkTypes.indexOf(linkType) !== -1) {

                    // console.log(this.linkTypes, linkType, this.linkTypes.indexOf(linkType));

                    keyValues = linksJson[linkType];

                    // RQ : Les propriétés du JSON ont des valeurs de type Array. On boucle donc sur cette liste de liens :
                    let i, n = keyValues.length;
                    for (i = 0; i < n; i++) {

                        ressourceProps = keyValues[i];
                        ressourceType = ressourceProps["o:type"];

                        // if ( (ressourceType === "o:Item") || (ressourceType === "resource:item"))

                        if (this.isAllowedResourceType(ressourceType))
                        {
                            nodeId = ressourceProps["o:id"];
                            nodeCollectionId = this.getItemCollection(nodeId);

                            // On n'affiche que les noeuds des collections de l'encyclopédie
                            if (nodeCollectionId) {
                                nodeTitle = this.getTitleFromJson(ressourceProps);
                                nodeKey = this.getNodeKey(nodeId);

                                // Angle de base + variation d'angle
                                nodeAngle = this.linkTypePositions[linkType];
                                nodeAngleVariation = this.linkTypePositionVariations[i % positionVariationsCount];

                                // console.log( nodeAngle/Math.PI * 180, linkType, i, nodeTitle);
                                // console.log("------------ linkType", linkType, nodeId, nodeAngle, nodeTitle);

                                let edgeSource, edgeTarget;

                                // Si le noeud n'existe pas déjà :
                                const existingNode = this.findNodeByNodeKey(nodeKey);
                                if (! existingNode) {

                                    // console.log("createNodesForLinksByType new", i, nodeId, nodeTitle, nodeKey, nodeAngle, nodeAngleVariation);

                                    // Ajout du noeud-enfant
                                    this.nodes.push({
                                        data: ressourceProps,
                                        id: nodeId,
                                        title: nodeTitle,
                                        index: index,
                                        nodeKey: nodeKey,
                                        temporary: temporary,
                                        angle: nodeAngle,
                                        angleVariation: nodeAngleVariation,
                                        parentNode: fromNode,
                                        linkTypeIndex: i,
                                        linkType: linkType,
                                        linkForward: areObjects
                                    });

                                    // Relation à l'item central
                                    edge = this.findEdgeBySourceAndTarget(fromNode.index, index);
                                    edgeDirection = {
                                        source: fromNode.nodeKey,
                                        target: nodeKey,
                                        type: linkType,
                                        dashed : this.getEdgeStyleByNodeType(linkType, "dashStyle"),
                                        color: this.getEdgeStyleByNodeType(linkType, "strokeStyle", "#E9E9E9"),
                                        forward: areObjects
                                    };

                                    if (edge === false)
                                    {
                                        this.edges.push({
                                            source: fromNode.index,
                                            target: index,
                                            direction: [edgeDirection],
                                            temporary: temporary,
                                            type: linkType
                                        });
                                    } else {
                                        // Le lien existe déja : on ajoute la direction si besoin
                                        edge.temporary = temporary;
                                        this.updateEdgeDirection(edge, edgeDirection);
                                    }

                                    if (fromNodeIsInBasket) {
                                        // Si le parent est dans le panier, on met à jour la liste de ses enfants
                                        this.addNodeKeyToNewNodes(newNodeKeys, nodeKey, index, areObjects);
                                    }

                                    index++;
                                }
                                else
                                {
                                    // Le noeud existe déjà

                                    // S'il a été crée sans connaître son parent, il lui manque certaines données
                                    // pour calculer la position par la suite :
                                    if (! existingNode.parentNode )
                                    {
                                        existingNode.parentNode = fromNode;
                                        existingNode.linkTypeIndex = i;
                                        existingNode.linkType = linkType;
                                        existingNode.linkForward = areObjects;
                                        existingNode.angle = nodeAngle;
                                        existingNode.angleVariation = nodeAngleVariation;
                                        existingNode.data = ressourceProps;
                                    }

                                    // On crée le lien s'il n'existe pas déjà
                                    edgeSource = fromNode.index;
                                    edgeTarget = existingNode.index;


                                    edgeDirection = {
                                        source: fromNode.nodeKey,
                                        target: nodeKey,
                                        type: linkType,
                                        dashed : this.getEdgeStyleByNodeType(linkType, "dashStyle"),
                                        color: this.getEdgeStyleByNodeType(linkType, "strokeStyle", "#E9E9E9"),
                                        forward: areObjects
                                    }


                                    // console.log("createNodesForLinksByType exists", nodeId, nodeTitle, nodeKey, fromNode.nodeKey, "-->", nodeKey, edgeDirection.forward);

                                    edge = this.findEdgeBySourceAndTarget(edgeSource, edgeTarget);
                                    if (edge === false)
                                    {
                                        this.edges.push({
                                            source: edgeSource,
                                            target: edgeTarget,
                                            direction: [edgeDirection],
                                            temporary: temporary,
                                            type: linkType
                                        });
                                    } else {

                                        // Le lien existe déja : on ajoute la direction si besoin

                                        // const edgeTemporary =  (existingNode.temporary === true) || (fromNode.temporary === true);
                                        // edge.temporary = edgeTemporary;

                                        this.updateEdgeDirection(edge, edgeDirection);
                                    }

                                    if (fromNodeIsInBasket) {
                                        // Si le parent est dans le panier, on met à jour la liste de ses enfants
                                        this.addNodeKeyToNewNodes(newNodeKeys, nodeKey, existingNode.index, areObjects);
                                    }
                                }


                            }
                        }
                    }
                }
            }
        }

        if (fromNodeIsInBasket) {
            // Si le parent est dans le panier, on met à jour la liste de ses enfants
            if (this.basketNodes[fromNode.nodeKey] === undefined) {
                this.basketNodes[fromNode.nodeKey] = {
                }
            }
            this.basketNodes[fromNode.nodeKey].children = newNodeKeys;
        }
    },

    getEdgeStyleByNodeType: function(linkType, linkTypeStyle = "dashStyle", defaultValue = "") {
        let linkTypeSetting = defaultValue;
        if (Array.isArray(this.linkTypesSettings)) {
            const n = this.linkTypesSettings.length;
            let i, linkTypeSettings;
            for(i=0; i<n; i++) {
                linkTypeSettings = this.linkTypesSettings[i];
                if (linkTypeSettings.term === linkType) {
                    linkTypeSetting = linkTypeSettings[linkTypeStyle] ? linkTypeSettings[linkTypeStyle] : defaultValue;
                    break;
                }
            }
        }
        return linkTypeSetting;
    },


    //
    // Création du rendu SVG
    //

    clearSVG: function () {

        if (this.parentLines) {
            let lineSelector = this.parentLines.selectAll('line.edge').data(this.edges);
            lineSelector.exit().remove();
        }

        if (this.parentCircles) {
            let circleSelector = this.parentCircles.selectAll('g.circle').data(this.nodes);
            circleSelector.on('click', null);
            circleSelector.exit().remove();
        }
    },

    renderSVG: function () {

        //
        // D3 simulation de forces
        //

        const simulation = this.simulation = d3.forceSimulation()
            .nodes(this.nodes);

        const link_force = d3.forceLink(this.edges)
            .strength(0)
            .distance(50)
            .id(function (d) {
                return d.index;
            });

        const t = this;
        const nodes = this.nodes;

        const forceRestorePosition = function() {
            var i, n = nodes.length, node;
            for (i = 0; i < n; ++i) {
                node = nodes[i];
                if (isNaN(node.x)) {
                    node.x = node.dx;
                }
                if (isNaN(node.y)) {
                    node.y = node.dy;
                }

                node.vx = (node.dx  - node.x)/20;
                node.vy = (node.dy  - node.y)/20;
            }
        };

        simulation
            .force("link", link_force)
            .force("restorePosition", forceRestorePosition)
            .force("collide", d3.forceCollide().radius(65).iterations(3))
            .stop();

        //
        // D3 lignes
        //

        // LINES : Enter, update, exit
        let lineSelector = this.parentLines.selectAll('line.edge').data(this.edges);
        let lineSelectorEnter = lineSelector.enter();

        lineSelectorEnter
            .append('line')
            .attr('class', 'edge')
            .call( function (d) { t.updateLine.call(t, d)} );

        lineSelector.call(function (d) { t.updateLine.call(t, d)} );
        lineSelector.exit().remove();


        //
        // D3 Cercles
        //

        // CIRCLES : Enter, update, exit
        let circleSelector = this.parentCircles.selectAll('g.circle').data(this.nodes);
        let circleSelectorEnter = circleSelector.enter();
        let nodesEnter = circleSelectorEnter.append('g').attr("class", "circle");

        nodesEnter.append('circle')
            .attr('r', 10)
            .on("click", function (event, d)
            {
                t.parentElement.dispatchEvent( new CustomEvent( "open-graph", {
                    bubbles: true,
                    cancelable: true,
                    detail: {
                        id: d.id,
                        event,
                        node: d
                    }
                }));

                /*
                // Permet d'afficher les noeuds-enfants d'un item actif (non-temporaire) du graphe
                if (d.temporary === true) {
                    event.stopPropagation();
                } else {
                    t.selectItem(d);
                }
                 */
            });

        nodesEnter.append('use')
            .attr('class', "icon_category")
            .attr('pointer-events', 'none')
            .style('transform', "scale(0.56)");

        nodesEnter.append('text')
            .attr('x', 36)
            .attr('y', -15)
            .attr('pointer-events', 'none')
            .style('font', '13px "Lato", sans-serif')
            .style('color', '#999999');

        let iconsGroup = nodesEnter.append('g')
            .attr('class', "icons");

        let iconsPlus = iconsGroup.append('g')
            .attr('class', "icon_plus")
            .attr('cursor', 'pointer');

        iconsPlus.append('use')
            .attr('xlink:href', "#plus-over");

        iconsPlus.append('use')
            .attr('xlink:href', "#plus")
            .on("click", function (event, d)
            {
                event.stopPropagation();
                const node = t.findNodeByIndex(d.index);
                t.addItemToBasket(d.id, node);
            })
            .on("mouseover", function (event)
            {
                event.target.setAttribute('opacity', 0);
            })
            .on("mouseout", function (event)
            {
                event.target.setAttribute('opacity', 1);
            });


        let iconsMoins = iconsGroup.append('g')
            .attr('class', "icon_moins")
            .attr('cursor', 'pointer' );

        iconsMoins.append('use')
            .attr('xlink:href', "#moins-over");

        iconsMoins.append('use')
            .attr('xlink:href', "#moins")
            .on("click", function (event, d)
            {
                event.stopPropagation();
                const node = t.findNodeByIndex(d.index);
                t.removeItemFromBasket(node);
            })
            .on("mouseover", function (event)
            {
                event.target.setAttribute('opacity', 0);
            })
            .on("mouseout", function (event)
            {
                event.target.setAttribute('opacity', 1);
            });

        let iconsNotice = iconsGroup.append('g')
            .attr('class', "icon_notice")
            .style('cursor', 'pointer' )
            .style('transform', "translate(36px, 0)");

        iconsNotice.append('use')
            .attr('xlink:href', "#notice-over");

        iconsNotice.append('use')
            .attr('xlink:href', "#notice")
            .on("click", function (event, d)
            {
                event.stopPropagation();
                t.openNotice(d);
            })
            .on("mouseover", function (event, d)
            {
                event.target.setAttribute('opacity', 0);
                t.openShortNotice(d, event);
            })
            .on("mouseout", function (event)
            {
                event.target.setAttribute('opacity', 1);
                t.hideShortNoticeAfterDelay();
            });


        // ajout des icônes :
        // positionnement des icônes en fonction
        // https://stackoverflow.com/questions/18147915/get-width-height-of-svg-element

        nodesEnter.call( function (d) {
            t.initCircle.call(t, d);
            t.updateCircle.call(t, d);
        });

        circleSelector.call(function (d) {
            t.updateCircle.call(t, d);
        });

        circleSelector.exit().remove();

        //add drag capabilities
        let drag_handler = d3.drag()
            .on("start", function (event, d) { t.drag_start.call(t, event, d)} )
            .on("drag", function (event, d) { t.drag_drag.call(t, event, d)} )
            .on("end", function (event, d) { t.drag_end.call(t, event, d)} );

        drag_handler(nodesEnter);

        let allLines = this.svg.selectAll('line.edge');
        let allCircles = this.svg.selectAll('g.circle');

        simulation.alphaTarget(0).restart();

        simulation.on('tick', function () {

            let i, n = nodes.length, node;
            for(i=0;i<n;i++) {
                node = nodes[i];
                if (isNaN(node.x)) {
                    node.x = node.dx;
                }
                if (isNaN(node.y)) {
                    node.y = node.dy;
                }
            }

            allLines
                .attr('x1', function (d) {
                    return d.source.x;
                })
                .attr('y1', function (d) {
                    return d.source.y;
                })
                .attr('x2', function (d) {
                    return d.target.x;
                })
                .attr('y2', function (d) {
                    return d.target.y;
                });

            allCircles.attr('transform', function (d) {
                return 'translate(' + d.x + ',' + d.y + ')';
            });

        });
    },

    drag_start: function (event, d) {
        d.vx = 0;
        d.vy = 0;
    },

    drag_drag: function (event, d) {
        d.fx = event.x;
        d.fy = event.y;
    },

    drag_end: function (event, d) {
        d.fx = null;
        d.fy = null;
    },

    noticeLoaded: function (itemId, itemJson) {
        const t = this;
        this.parentElement.dispatchEvent( new CustomEvent( "notice-loaded", {
            bubbles: true,
            cancelable: true,
            detail: {
                id: itemId,
                title: t.getTitleFromJson(itemJson)
            }
        }));
    },

    openNotice: function (d) {
        this.emitOpenNoticeEvent('open-notice', d);
    },

    openShortNotice: function (d, event) {
        this.emitOpenNoticeEvent('open-short-notice', d, event);
    },

    emitOpenNoticeEvent: function (eventName, d, mouseEvent) {

        const t = this;
        const node = t.findNodeByIndex(d.index);
        const itemId = node.id;
        const itemJsonURL = t.getItemJsonURL(itemId);

        d3.json(itemJsonURL).then(function (data) {

            t.parentElement.dispatchEvent( new CustomEvent( eventName, {
                bubbles: true,
                cancelable: true,
                detail: {
                    id: itemId,
                    json: data,
                    mouseEvent,
                    node: d
                }
            }));


        });
    },

    hideShortNoticeAfterDelay: function () {
        this.hideShortNotice(true);
    },

    hideShortNotice: function (withDelay = false) {
        this.parentElement.dispatchEvent( new CustomEvent( "close-notice", {
            bubbles: true,
            cancelable: true,
            detail: {
                withDelay
            }
        }));
    },

    close: function () {
        this.parentElement.dispatchEvent( new CustomEvent( "close", {
            bubbles: true,
            cancelable: true,
        }));
    },

    initCircle: function (selection) {

        let t = this;
        const defaultEdgeDistance = 220;

        selection.each(function (g_data) {

            const collection = t.getItemCollection(g_data.id);
            const collectionId = collection ? collection.id : "";

            let position, originX, originY, distance, angle, parentNode;

            const versionNo = 1;
            if (versionNo === 0) {

                // v1 :
                position = t.gridPositions[g_data.index];

            } else {

                // NEW

                originX = 0;
                originY = 0;

                if (g_data.root)
                {
                    position = {
                        x: 0,
                        y: 0,
                    }
                }
                else
                {

                    // Distance :

                    if (! g_data.linkTypeIndex) {
                        g_data.linkTypeIndex = 0;
                    }

                    const indexModulo = g_data.linkTypeIndex % 3;
                    const refDistance = indexModulo === 0 ? defaultEdgeDistance * 0.2 : 0;
                    const objectSubjectFactor = g_data.linkForward ? 1 : 1.5;

                    distance = defaultEdgeDistance * objectSubjectFactor + refDistance * Math.ceil(g_data.linkTypeIndex / 3);

                    // Angle

                    if (isNaN(g_data.angle)) {
                        g_data.angle = 0;
                        g_data.angleVariation = Math.PI / 10;
                    }

                    angle = g_data.angle;

                    parentNode = g_data.parentNode;
                    if (parentNode && ! parentNode.root)
                    {
                        originX = !isNaN(parentNode.dx) ? parentNode.dx : 0;
                        originY = !isNaN(parentNode.dx) ? parentNode.dy : 0;
                        angle = g_data.angleVariation;

                        if (parentNode.compoundAngle) {
                            angle += parentNode.compoundAngle;
                        }
                    }
                    else
                    {
                        angle = g_data.angle + g_data.angleVariation;
                    }

                    g_data.compoundAngle = angle;

                    // Position

                    position = {
                        x: originX + distance * Math.cos(angle),
                        y: originY + distance * Math.sin(angle),
                    };

                    // console.log("distance", distance, "angle", angle, "originX", originX, "originY", originY, g_data)
                }
            }

            // console.log("g_data.angle", g_data.id, g_data.angle, g_data.linkTypeIndex, position.x, position.y)

            g_data.x = g_data.dx = position.x;
            g_data.y = g_data.dy = position.y;
            g_data.vx = g_data.vy = 0;

            let g_circle = d3.select(this)
                .attr('item-id', function (d) {
                    return d.id;
                })
                .attr('collection-id', collectionId)
                .on("mouseover", function() {
                    d3.select(this).select('text')
                        .style('fill-opacity', 1);
                })
                .on("mouseout", function(event, d) {
                    d3.select(this).select('text')
                        .style('fill-opacity', function (){
                            if ((d.temporary === true) && ! d.basket ) {
                                return 0.25;
                            } else {
                                return 1;
                            }
                        })
                });

            g_circle.selectAll('use.icon_category').each(function () {
                d3.select(this)
                    .attr('xlink:href', "#Item" + t.getNameOfCategory(collectionId) + collectionId)
                    .style('opacity', 0)
                    .transition()
                    .duration(500)
                    .style('opacity', function() {
                        return g_data.temporary === true ? 0.25 : 1;
                    });
            });

            g_circle.selectAll('text').each(function () {
                d3.select(this)
                    .text(function () {
                        return g_data.title;
                    })
                    .call(wrap, 100)
                    .call(function(o) {
                        g_data.textHeight = o.node().getBBox().height;
                    })
                    .style('fill', '#F8F8F8')
                    .style('opacity', 0)
                    .transition()
                    .duration(500)
                    .style('opacity', 1)
                ;
            });

            g_circle.selectAll('.icons').each(function () {
                d3.select(this)
                    .style('opacity', 0)
                    .transition()
                    .delay(200)
                    .duration(500)
                    .style('opacity', 1)
            });

        });

    },

    updateCircle: function (selection) {

        let t = this;

        selection.each(function (g_data) {

            const itemId = g_data.id;
            const itemNodeKey = g_data.nodeKey;

            const collection = t.getItemCollection(itemId);
            const collectionId = collection ? collection.id : "";
            const collectionVisible = collection ? collection.visible !== false : true;
            const hasParentInBasket = t.hasParentInBasket(itemNodeKey);

            // console.log("updateCircle", collection);
            // console.log("updateCircle item", itemId, itemNodeKey, "collection", collectionId, g_data);
            // const collectionTitle = collection ? collection.title : "";
            // console.log("updateCircle collection", g_data.id, itemNodeKey, collectionId, collectionTitle, collectionVisible, "hasParentInBasket", hasParentInBasket, g_data.basket);

            let g_circle = d3.select(this)
                .attr('item-id', function (d) {
                    return d.id;
                })
                .attr('collection-id', collectionId);

            g_circle.selectAll('circle').each(function () {
                d3.select(this)
                    .style('fill', function () {
                        return g_data.temporary === true ? "#434e57" : "#B1B1B1";
                    })
                    .style('stroke', function () {
                        if (g_data.temporary === true) {
                            return "rgb(57,68,77)";
                        } else {
                            return g_data.basket ? "white" : "transparent";
                        }
                    })
                    .style('stroke-dasharray', function () {
                        return g_data.temporary === true ? '4 2' : '';
                    })
                    .style('stroke-width', function () {
                        return g_data.basket ? 4 : 1;
                    })
                    .style('cursor', "pointer")
                    .attr('r', function () {
                        return g_data.basket ? 20 : 20;
                    })
                    .attr('class', function () {
                        return 'item-circle item-collection-'+collectionId + (g_data.temporary === true ? ' temporary' : '');
                    })
                    .attr('data-id', g_data.id)
                    .attr('data-index', g_data.index)
            });

            g_circle.selectAll('use.icon_category').each(function () {
                d3.select(this)
                    .attr('xlink:href', "#ItemCategory" + collectionId)
                    .style('opacity', function () {
                        return g_data.temporary === true ? 0.25 : 1;
                    })
                    .attr('visibility', collectionVisible ? "visible" : "hidden")
            });

            g_circle.selectAll('text').each(function () {
                d3.select(this)
                    .text(function () {
                        return g_data.title;
                    })
                    .call(wrap, 100)
                    .call(function(o) {
                        g_data.textHeight = o.node().getBBox().height;
                    })
                    .style('fill-opacity', function () {
                        if ((g_data.temporary === true) && ! g_data.basket ) {
                            return 0.25;
                        } else {
                            return 1;
                        }
                    })
                    .attr('visibility', collectionVisible ? "visible" : "hidden")
                    .call(function(o) {
                        g_data.textHeight = o.node().getBBox().height;
                    })
                ;
            });

            g_circle.selectAll('.icons').each(function () {
                d3.select(this)
                    .attr('visibility', collectionVisible ? "visible" : "hidden")
                    .call(function(o) {
                        const iconsX = g_data.root ? 0 : ( g_data.basket || hasParentInBasket ? 36 : 0 );
                        const iconsY = g_data.textHeight - 18;
                        o.attr('transform', 'translate(' + iconsX + ',' + iconsY + ')' );
                    })
            });

            // L'icône + ne sera pas présent sur un item si aucun de ses parents n'est dans le panier
            g_circle.selectAll('.icon_plus').each(function () {
                d3.select(this)
                    .attr('visibility', !g_data.root && hasParentInBasket  &&  !g_data.basket ? 'visible' : 'hidden')
            });

            g_circle.selectAll('.icon_moins').each(function () {
                d3.select(this)
                    .attr('visibility', g_data.root || ! g_data.basket ? 'hidden' : 'visible')
            });

        });
    },

    updateLine: function (selection) {

        selection.each(function (data) {

            // console.log(data)

            let edgeDirection = data.direction;
            let firstEdgeDirection = edgeDirection[0];
            let isActive = data.active === true;

            let isTargetInBasket = data.target.basket === true;

            d3.select(this)
                .attr('id', 'line'+ data.index)
                .attr('type', firstEdgeDirection.type)
                .style('stroke', function () {
                    return isActive ? '#FFFFFF' : '#E9E9E9';
                })
                .style('stroke-opacity', function () {
                    if (data.temporary === true) {
                        return 0.25;
                    } else {
                        return 1;
                    }
                })
                .style('stroke-width', 1)
                .style('stroke-dasharray', function () {
                    // Selon le type de lien
                    return firstEdgeDirection.dashed;
                })
                .style('stroke', function () {
                    // Selon le type de lien
                    return firstEdgeDirection.color;
                })
                .attr('marker-start-OUT', function () {
                    // WARNING : Les extrémités ont été désactivées. Pour réactiver : marker-start-OUT => marker-start
                    /*
                    if (edgeGenericType && firstEdgeDirection.forward) {
                        return 'url(#backward)'
                    }
                    */

                    if ((edgeDirection.length > 1) || ! firstEdgeDirection.forward ) {
                        return 'url(#backward)'
                    }
                })
                .attr('marker-end-OUT', function () {
                    // WARNING : Les extrémités ont été désactivées. Pour réactiver : marker-end-OUT => marker-end
                    if ((edgeDirection.length > 1) || firstEdgeDirection.forward ) {
                        return isTargetInBasket ? 'url(#forward_basket)' : 'url(#forward)';
                    }
                })
                .attr('source', firstEdgeDirection.source)
                .attr('target', firstEdgeDirection.target);
        });
    },

    filterCategory: function(collectionId, visible) {
        const t = this;
        const collection = this.findCollection(collectionId);
        if (collection) {
            collection.visible = visible;
            this.parentCircles.selectAll('g.circle')
                .call(function (d) { t.updateCircle.call(t, d)} );
        }
    },

    selectItem: function(d) {

        this.deselectItems();
        this.activeItemByIndex(d.index);

        if (d.temporary !== true) {
            // Récupération des liens issus d'autres items ( 'subjects' )
            this.loadTemporaryItemsFromJson(d);
        }
    },

    activeItemByIndex: function(index) {
        // Mise en couleur des liens associé à l'item cliqué
        const nodeEdges = this.findNodeEdgesByNodeIndex(index), n = nodeEdges.length;
        let i, nodeEdge;
        for(i=0;i<n;i++){
            nodeEdge = nodeEdges[i];
            nodeEdge.active = true;
        }
        this.redrawEdges();
    },

    activeItemById: function(itemId) {
        const nodeKey = this.getNodeKey(itemId);
        const node = this.findNodeByNodeKey(nodeKey);
        if (node) {
            this.activeItemByIndex(node.index);
        }
    },

    inactiveItems: function() {
        const nodeEdges = this.edges, n = nodeEdges.length;
        let i, nodeEdge;
        for(i=0;i<n;i++){
            nodeEdge = nodeEdges[i];
            nodeEdge.active = false;
        }

        this.redrawEdges();
    },

    deselectItems: function() {
        this.inactiveItems();
        this.removeTemporaryNodes();

        /*
        console.log( "basketNodes", this.basketNodes );
        console.log( "basketData", this.basketData.length, this.basketData );
        console.log( "nodes", this.nodes );
        console.log( "edges", this.edges );
        */
    },

    redrawEdges: function() {
        const t = this;
        const lineSelector = this.parentLines.selectAll('line.edge');
        lineSelector.call(function (d) { t.updateLine.call(t, d)} );
    },

    filterByLinkType: function( linkTypes ) {
        this.clear();
        this.activelinkTypes = linkTypes;
        this.prepareBasketData();
        this.renderSVG();
    },

    prepareBasketData: function() {

        const n = this.basketData.length;
        let i, basketItemId, basketNodeKey, itemJson, basketNode;

        // On recrée le noeud de chaque item du panier, et ses enfants
        for (i=0;i<n;i++) {

            itemJson = this.basketData[i];
            basketItemId = itemJson["o:id"];
            basketNodeKey = this.getNodeKey(basketItemId);
            basketNode = this.findNodeByNodeKey ( basketNodeKey );

            if (!this.centralItemId) {
                this.centralItemId = basketItemId;
            }

            if (basketNode) {
                basketNode.basket = true;
            }

            this.createNodeFromJson(basketItemId, itemJson, basketNode);
        }
    },

    getColorOfCategory: function(categoryId) {
        const color = this.colorsByCategory[this.categoryKeyPrefix + categoryId];
        return color ? color : "#CCCCCC";
    },

    getColorNoOfCategory: function(categoryId) {
        return this.colorNosByCategory[this.categoryKeyPrefix + categoryId];
    },

    getNameOfCategory: function(categoryId) {
        return this.iconsByCategory[this.categoryKeyPrefix + categoryId];
    },

    addMinusIconInSVGDefinitions: function(parent, fillColor = "#f7a448") {
        const childrenElement = parent.append('g');
        childrenElement
            .append('rect')
            .attr('fill', "transparent")
            .attr('width', 15)
            .attr('height', 15);
        childrenElement
            .append('path')
            .attr('d', "M16.3105529,9.00482326 C16.8930727,9.00482326 17.3544283,9.4659459 17.3544283,10.0484656 C17.3544283,10.6309854 16.8930727,11.092108 16.3105529,11.092108 L3.68944707,11.1165739 C3.10692732,11.1165739 2.64580469,10.6309854 2.64580469,10.0726985 C2.64580469,9.49017872 3.10692732,9.02905609 3.68944707,9.02905609 L16.3105529,9.00482326 Z M17.0631684,2.93683156 C18.8834261,4.75732227 20,7.25726402 20,10.0242328 C20,12.7912016 18.8834261,15.2911434 17.0631684,17.0874013 C15.2426777,18.8834261 12.7669688,20 10,20 C7.2330312,20 4.73308945,18.8834261 2.91283174,17.0631684 C1.09234103,15.2426777 0,12.7669688 0,10 C0,7.2330312 1.11657385,4.73308945 2.93706457,2.91259874 C4.73308945,1.11657385 7.2330312,0 10,0 C12.7669688,0 15.2669105,1.11657385 17.0631684,2.93683156 Z" )
            .attr('fill-rule', "evenodd")
            .attr('fill', fillColor);
    },

    addPlusIconInSVGDefinitions: function(parent, fillColor = "#f7a448") {
        const childrenElement = parent.append('g');
        childrenElement
            .append('rect')
            .attr('fill', "transparent")
            .attr('width', 15)
            .attr('height', 15);
        childrenElement
            .append('path')
            .attr('d', "M8.95635762,3.73791272 C8.95635762,3.15539297 9.41748025,2.69427034 10,2.69427034 C10.5825197,2.69427034 11.0436424,3.17962579 11.0436424,3.73791272 L11.0436424,9.00482326 L16.3105529,9.00482326 C16.8930727,9.00482326 17.3544283,9.4659459 17.3544283,10.0484656 C17.3544283,10.6309854 16.8930727,11.092108 16.3105529,11.092108 L11.0678752,11.092108 L11.0678752,16.3592516 C11.0678752,16.9417713 10.5825197,17.402894 10.0242328,17.402894 C9.44171307,17.402894 8.98059044,16.9417713 8.98059044,16.3592516 L8.98059044,11.1165739 L3.68944707,11.1165739 C3.10692732,11.1165739 2.64580469,10.6309854 2.64580469,10.0726985 C2.64580469,9.49017872 3.10692732,9.02905609 3.68944707,9.02905609 L8.95635762,9.02905609 L8.95635762,3.73791272 Z M10,0 C12.7669688,0 15.2669105,1.11657385 17.0631684,2.93683156 C18.8834261,4.75732227 20,7.25726402 20,10.0242328 C20,12.7912016 18.8834261,15.2911434 17.0631684,17.0874013 C15.2426777,18.8834261 12.7669688,20 10,20 C7.2330312,20 4.73308945,18.8834261 2.91283174,17.0631684 C1.09234103,15.2426777 0,12.7669688 0,10 C0,7.2330312 1.11657385,4.73308945 2.93706457,2.91259874 C4.73308945,1.11657385 7.2330312,0 10,0 L10,0 Z" )
            .attr('fill-rule', "evenodd")
            .attr('fill', fillColor)
            .attr('stroke', "none");
    },

    addNoticeIconInSVGDefinitions: function(parent, fillColor = "#f7a448") {
        parent.append('rect')
            .attr('fill', 'rgba(255,0,0,0)')
            .attr('width', 18)
            .attr('height', 20)
            .append('polygon')
            .attr('points', "12.95828 19.3251 16.87508 15.4083 12.95828 15.4083")
            .attr('fill', fillColor);
        const childrenElement = parent.append('g');
        childrenElement
            .append("path")
            .attr("d", "M11.70828,6.925 L5.62488,6.925 C5.16668,6.925 4.79168,6.55 4.79168,6.0916 C4.79168,5.6334 5.16668,5.2584 5.62488,5.2584 L11.75008,5.2584 C12.20828,5.2584 12.58328,5.6334 12.58328,6.0916 C12.58328,6.55 12.16668,6.925 11.70828,6.925 M11.70828,11.1334 L5.62488,11.1334 C5.16668,11.1334 4.79168,10.7584 4.79168,10.3 C4.79168,9.8416 5.16668,9.4666 5.62488,9.4666 L11.75008,9.4666 C12.20828,9.4666 12.58328,9.8416 12.58328,10.3 C12.58328,10.7584 12.16668,11.1334 11.70828,11.1334 M16.50008,0.175 L0.83328,0.175 C0.37488,0.175 -0.00012,0.55 -0.00012,1.0084 L-0.00012,18.9666 C-0.00012,19.425 0.37488,19.8 0.83328,19.8 L11.33328,19.8 L11.33328,14.55 C11.33328,14.0916 11.70828,13.7166 12.16668,13.7166 L17.37508,13.7166 L17.37508,1.0084 C17.33328,0.55 16.95828,0.175 16.50008,0.175")
            .attr('fill', fillColor);

    },

    addIconsInSVGDefinitions: function() {

        // Moins
        const moinsIcon = this.defs.append('g').attr('id', "moins");
        this.addMinusIconInSVGDefinitions(moinsIcon);

        // Moins Over
        const moinsOverIcon = this.defs.append('g').attr('id', "moins-over");
        this.addMinusIconInSVGDefinitions(moinsOverIcon, "#FFFFFF");

        // Plus
        const plusIcon = this.defs.append('g').attr('id', "plus");
        this.addPlusIconInSVGDefinitions(plusIcon);

        // Plus Over
        const plusOverIcon = this.defs.append('g').attr('id', "plus-over");
        this.addPlusIconInSVGDefinitions(plusOverIcon, "#FFFFFF");

        // Notice
        const noticeIcon = this.defs.append('g').attr('id', "notice");
        this.addNoticeIconInSVGDefinitions(noticeIcon);

        // Notice Over
        const noticeOverIcon = this.defs.append('g').attr('id', "notice-over");
        this.addNoticeIconInSVGDefinitions(noticeOverIcon, "#FFFFFF");
    },

    addIconsForCategoriesInSVGDefinitions: function(collection) {

        const itemName = "ItemCategory" + collection.id;

        let svgIcon, svgIconContent, svgIconPattern;

        svgIcon = this.defs.append('g');
        svgIcon.attr('id', itemName);

        svgIconPattern = svgIcon
            .append('pattern')
            .attr("patternUnits", "userSpaceOnUse")
            .attr("width", "67")
            .attr("height", "67")
            .attr("id", itemName + "-pattern")

        svgIconPattern
            .append('image')
            .attr("width", "67")
            .attr("height", "67")
            .attr("xlink:href", collection.icon)

        svgIconContent = svgIcon
            .append('g')
            .attr("transform", "translate(-33.5,-33.5)");

        svgIconContent
            .append("circle")
            .attr('fill', collection.color)
            .attr('cx', "33.5")
            .attr('cy', "33.5")
            .attr('r', "33.5");

        svgIconContent
            .append("rect")
            .attr("width", "67")
            .attr("height", "67")
            .attr('fill', "url(#" + itemName + "-pattern)")

    },

    addMarkersInSVGDefinitions: function() {

        const markerDescriptions = [
            { name: 'forward',  path: 'M 0,0 L -5,-3 M 0,0 L -5, 3 Z', viewbox: '-5 -3 10 6', refX: +10 },
            { name: 'forward_basket',  path: 'M 0,0 L -5,-3 M 0,0 L -5,3 Z', viewbox: '-5 -3 10 6', refX: +10 },
            { name: 'backward', path: 'M 0,0 L +5,-3 M 0,0 L 5,3 Z', viewbox: '-5 -3 10 6', refX: -10 }
        ];

        this.defs.selectAll('marker')
            .data(markerDescriptions)
            .enter()
            .append('svg:marker')
            .attr('id', function(d){ return d.name})
            .attr('class', 'marker')
            .attr('fill', 'none')
            .attr('stroke', '#E9E9E9') // current-stroke works on Firefox only...
            .attr('stroke-opacity', function(d){ return d.name === 'backward' ? 0.25 : 1 })
            .attr('markerHeight', 12)
            .attr('markerWidth', 40)
            .attr('markerUnits', 'strokeWidth')
            .attr('orient', 'auto')
            .attr('refX', function(d){ return d.refX })
            .attr('refY', 0)
            .attr('viewBox', function(d){ return d.viewbox })
            .append('svg:path')
            .attr('d', function(d){ return d.path });
    }
});

export { VizItem }