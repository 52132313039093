<template>
  <div class="account-items-panel account-search-panel" :class="panelOpenedCssClass">
    <div class="account-items-header">
      <a href="#" @click="toggleClose($event)">
        <h3>{{ $t("Mon historique de recherche") }}</h3>
        <div class="account-items-close"></div>
      </a>
    </div>
    <div class="account-items" :class="accountItemsCssClass">
      <div  v-if="searches.length > 0">
        <!-- p class="panel-description">Cliquez sur une sélection historique pour la voir et l’annoter.</p -->
        <ul class="account-search-items">
          <li class="account-search-item search-selection-item"
              v-for="search in searches"
              v-bind:key="search.id">

              <router-link :to="searchUrl(search)" >
                <p>{{ search.name }}</p>
                <p>{{ $t('Documentation du') }} {{ searchDate(search) }}</p>
              </router-link>
              <div class="buttons">
                <a class="account-item-delete-tool button delete-icon" href="#" @click="deleteSearch($event, search)"></a>
              </div>

          </li>
        </ul>
      </div>
      <div v-else class="account-column-warning">{{ $t("Votre historique de recherche est vide") }}</div>
    </div>
  </div>
</template>

<script>


  export default {
    name: 'UserSearches',
    components: {
    },
    props: {
      opened: {
        type: Boolean,
        required: true
      },
    },
    emits:[
      'toggle-searches-panel'
    ],
    computed: {
      panelOpenedCssClass() {
        return this.opened ? "account-items-opened" : "";
      },
      searches() {
        return this.$store.state.searches;
      },
      accountItemsCssClass() {
        return this.searches.length > 0 ? "" : "is-empty";
      }
    },
    methods: {
      toggleClose($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.$emit('toggle-searches-panel')
      },
      searchUrl(search) {
        const hrefWithRouterBase = this.$router.resolve({ name: 'Recherche Avancée', params: { s: search.query }}).href;
        return hrefWithRouterBase.split(this.$store.state.publicPath).join('/');
      },
      searchDate(search) {
        const dateJS = search.created;
        if (dateJS)
        {
          const fullYear = dateJS.getFullYear();

          let day = dateJS.getDate();
          if (day < 10 ) {
            day = "0" + day;
          }

          let month = dateJS.getMonth() + 1;
          if (month < 10 ) {
            month = "0" + month;
          }
          return day + "/" + month + "/" + fullYear;
        }
        return "";
      },
      deleteSearch($event, search) {
        $event.preventDefault();

        // Ici l'id est une référence à la sélection ( selection_resources sur le serveur) : selectionId, created
        this.$store.dispatch( "deleteSearch" , { name: search.name, id: search.id } );

      }
    }
  }

</script>
