<template>
  <div class="side-menu-parent with-padding">
    <div class="side-menu">

      <ul :class="state.language">
        <li
            v-for="(menuItem, index) in state.menuItems"
            :key="index"
        >
          <!-- Liens vers l'extérieur -->
          <a
              v-if="menuItem.external"
              :href="menuItem.url"
              target="_blank"
          >
          {{ $t( menuItem.label ) }}
          </a>

          <!-- Séparateur -->
          <hr v-if="menuItem.separator" />

          <!-- Liens internes -->
          <router-link
              v-else
              :to="menuItem.url">
          {{ $t( menuItem.label ) }}
          </router-link>
        </li>
      </ul>

    </div>
  </div>
</template>

<script>

  import {useStore} from "vuex";
  import {useRouter, useRoute} from 'vue-router';
  import {reactive, watch} from "vue";

  export default {
  name: 'UserMenu',
  setup() {

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const languageCssClass = function(language) {
      return language === store.state.currentLanguage ? "current-language" : "";
    };

    const selectLanguage = function($event, newLanguage) {
      $event.preventDefault();

      let routeCopy = {
        name : route.name,
        query : { ...route.query },
        params : { ...route.params }
      };

      routeCopy.query.lang = newLanguage;

      router.push(routeCopy);
    };

    const state = reactive({
      language: store.state.currentLanguage,
      menuItems: null,
    });

    const updateMenuContent = function() {

      // Pages et URLs de la navigation
      const menuLinks = store.getters.siteNavigation;
      const homePageId = store.getters.siteHomepage;
      const currentLanguage = store.state.currentLanguage;

      // Pages existantes
      const sitePages = store.state.sitePages;

      if (Array.isArray(menuLinks)) {

        const n = menuLinks.length;
        let i, j, linkType, link, linkURL, pageId, sitePage, pageURL;

        let menuItems = [];

        const hasSitePages = Array.isArray(sitePages);

        for (i = 0; i < n; i++)
        {
          link = menuLinks[i];
          linkType = link.type;

          if (linkType === "page") {

            // PAGES ajoutée à la navigation
            pageId = link.data.id;

            // On mémorise le chemin avec le slug de la page :
            if (hasSitePages) {
              for(j=0; j< sitePages.length; j++)
              {
                sitePage = sitePages[j];
                if (sitePage.id === pageId)
                {
                  pageURL = pageId === homePageId ? '/' : '/pages/' + sitePage.slug;
                  pageURL += currentLanguage ? "?lang=" + currentLanguage : "";

                  menuItems.push({
                    url: pageURL,
                    label: sitePage.title,
                    external: false,
                  });
                }
              }
            }

          } else {

            // URLs ajoutées à la navigation

            linkURL = link.data.url;
            if (linkURL)
            {
              // Lien externe / interne ?
              link.data.external = linkURL.indexOf('/');

              menuItems.push(link.data)
            }
            else
            {
              menuItems.push({
                type: "separator",
                external: false,
                separator: true
              })
            }

          }
        }

        state.menuItems = menuItems;
      }
    }


    //
    // Lifecycle hooks
    //

    watch(() => store.state.siteParams, () => {
      updateMenuContent();
    });

    watch(() => store.state.sitePages, () => {
      updateMenuContent();
    });

    // Récupération des pages si elles n'ont pas déjà été télchargées
    const sitePages = store.state.sitePages;
    if (! Array.isArray(sitePages)) {
      store.dispatch("getPagesInfos");
    }

    return {
      languageCssClass,
      selectLanguage,
      state
    }

  }
}
</script>
