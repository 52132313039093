<template>
  <div id="svg-parent" class="svg-parent"></div>
  <!-- Graphe SVG des Categories  -->

  <!-- Zoom du graphe des catégories -->
  <ul class="constellation-zoom-buttons zoom-buttons">
    <li class="constellation-zoom-plus" :class="zoomInCssClass">
      <a href="#" @click="zoomIn($event)"></a>
    </li>
    <li class="constellation-zoom-moins" :class="zoomOutCssClass">
      <a href="#" @click="zoomOut($event)"></a>
    </li>
    <li class="constellation-zoom-reload">
      <a href="#" @click="zoomReset($event)"></a>
    </li>
  </ul>

  <Loading v-if="isLoading" />

  <!-- Filtres de catégories -->
  <div class="constellation-footer-background" @click="closeFilterCategoriesMenu($event)"></div>
  <div class="constellation-footer with-padding">
    <div class="constellation-filters-toggle"><a href="#" @click="toggleFilterCategoriesMenu($event)"></a></div>
    <div class="constellation-filters">
      <ul class="collections-filters">
        <li v-for="collection in collections"
            :class="'constellation-filter-color-' + collection.colorNo"
            :key="collection.id"
        >
          <label class="checkbox-label">
            <input type="checkbox" checked="checked" :value="collection.id" @click="selectCategory($event)" />
            <span class="checkmark"></span>
            {{ getCategoryTitle(collection.id) }}
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

  import { VizCategories } from '../../js/viz-categories.js';
  import {getCollectionsByItemSetApiURL} from "../../js/api";
  import { JsonReader } from '../../js/jsonReader.js';
  import Loading from "../Loading";


  const vizCategories = new VizCategories();
  const constellationParentId = "svg-parent";
  const jsonReader = new JsonReader();

  export default {
    name: 'VisualisationEncyclopedie',
    components: {
      Loading
    },
    props: {
      feedMode: {
        type: String,
        required: false,
        default:"auto"
      },
      itemsByCollection: {
        type: Array,
        required: false
      }
    },
    emits: [
      "changecategoriesfiltermenu",
      "selectitem",
      "itemsloadedfromserver"
    ],
    data() {
      return {
        allItems: null,
        filterCategoriesMenuIsOpened: false,
        zoomLevelIsMax: false,
        zoomLevelIsMin: true,
        isLoading:false,
        collections: null
      }
    },
    computed: {
      zoomInCssClass() {
        return this.zoomLevelIsMax === true ? "" : "active";
      },
      zoomOutCssClass() {
        return this.zoomLevelIsMin === true ? "" : "active";
      }
    },
    methods : {
      zoomIn($event) {
        $event.preventDefault();
        $event.stopPropagation();
        vizCategories.zoomIn();
        this.updateZoomButtons();
      },
      zoomOut($event) {
        $event.preventDefault();
        $event.stopPropagation();
        vizCategories.zoomOut();
        this.updateZoomButtons();
      },
      zoomReset($event) {
        $event.preventDefault();
        $event.stopPropagation();
        vizCategories.zoomReset();
        this.updateZoomButtons();
      },
      updateZoomButtons() {
        this.zoomLevelIsMin = vizCategories.zoomLevelIsMin();
        this.zoomLevelIsMax = vizCategories.zoomLevelIsMax();
      },
      toggleFilterCategoriesMenu($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.filterCategoriesMenuIsOpened = !this.filterCategoriesMenuIsOpened;
        this.$emit("changecategoriesfiltermenu", this.filterCategoriesMenuIsOpened);
      },
      closeFilterCategoriesMenu($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.filterCategoriesMenuIsOpened = false;
        this.$emit("changecategoriesfiltermenu", this.filterCategoriesMenuIsOpened);
      },
      prepareItemsForList(){

        this.isLoading = false;

        // Liste des catégories (et pour chacune de ses items)
        const categories = vizCategories.getCollections(), n = categories.length;
        let i, categoryInfos, categoryTitle, categoryIcon, categoryColorNo, items, j, itemsCount, itemInfos, itemId;

        // On constitue la liste de tous les items (de toutes les catégories)
        let allItems = [];
        let allItemIds = [];

        for(i=0;i<n;i++) {

          categoryInfos = categories[i];
          categoryTitle = categoryInfos.title;
          categoryIcon = categoryInfos.icon;
          categoryColorNo = categoryInfos.colorNo;

          items = categoryInfos.items;
          itemsCount = items.length;

          for(j=0;j<itemsCount;j++) {

            itemInfos = items[j];
            itemId = parseInt(itemInfos["o:id"]);

            allItems.push({
              id: itemId,
              title: this.getTitleFromJson(itemInfos),

              category: categoryTitle,
              icon: categoryIcon,
              colorNo: categoryColorNo,
              itemset: categoryInfos.id
            });

            allItemIds.push(itemId);
          }
        }

        this.allItems = allItems;

        const idFromRoute = this.$route.params.id;
        if ((idFromRoute !== undefined) && (this.$route.name === "Graphe Collection") ) {
          vizCategories.activeCollection(idFromRoute);
        }

        this.$emit("itemsloadedfromserver", allItems, allItemIds);
      },
      getTitleFromJson: function (itemJson) {

        jsonReader.json = itemJson;

        let title = jsonReader.getMetaDataFirstValue('dcterms:alternative');

        if ((typeof title !== "string") || (title.length === 0)) {
          title = itemJson['o:title'];
        }

        if ((typeof title !== "string") || (title.length === 0)) {
          title = jsonReader.getMetaDataFirstValue('dcterms:title');
        }

        return this.capitalizeText( title);
      },

      capitalizeText: function (title = '') {
        return title.charAt(0).toUpperCase() + title.slice(1);
      },

      selectCategory($event) {

        $event.stopPropagation();

        const collectionId = $event.target.value;
        const collectionVisible = $event.target.checked;

        vizCategories.filterCollection(collectionId, collectionVisible);
      },
      resizeVisualization() {
        const constellationParent = document.getElementById(constellationParentId);
        const w = constellationParent.offsetWidth;
        const h = constellationParent.offsetHeight;
        vizCategories.resize(w, h);
      },
      selectItem(event) {
        const eventDetail = event.detail;
        this.$emit("selectitem", eventDetail.itemId);
      },
      getCategoryTitle(categoryId) {
        return this.collectionsTitles['c' + categoryId];
      },
      createCollectioVizualisation() {
        const store = this.$store;
        if (store.state.collections)
        {
          /* Catégories de l'encyclopédie documentaire */
          const constellationParent = document.getElementById(constellationParentId);
          const constellationWith = constellationParent.offsetWidth;
          const constellationHeight = constellationParent.offsetHeight;

          store.dispatch('clearVisualisationBasket');

          // Données des settings
          const encyclopedieCollectionInfos = store.getters.encyclopedieCollectionInfos();

          let i, collectionInfos;
          const n = encyclopedieCollectionInfos.length;

          const collectionsIds = [];
          const collectionsIcons = [];

          // Tableaux associatifs des paramètres des différentes catégories :
          const COLLECTION_ARRAY_KEY = "c";
          const collectionIconsAssocArray = [];
          const collectionColorsAssocArray = [];
          const collectionColorNosAssocArray = [];
          const collectionTitlesAssocArray = store.getters.localizedCollectionsTitles('dcterms:title');

          for(i=0; i<n; i++) {
            collectionInfos = encyclopedieCollectionInfos[i];

            collectionsIds.push(collectionInfos.id);
            collectionsIcons.push(collectionInfos.icon);

            collectionColorsAssocArray[COLLECTION_ARRAY_KEY + collectionInfos.id] = collectionInfos.color;
            collectionColorNosAssocArray[COLLECTION_ARRAY_KEY + collectionInfos.id] = collectionInfos.colorNo;
            collectionIconsAssocArray[COLLECTION_ARRAY_KEY + collectionInfos.id] = collectionInfos.icon;
          }

          // URL permettant de récupérer les donnes de l'API pour les différentes collections
          vizCategories.collectionsApiURL = getCollectionsByItemSetApiURL(store.getters.apiRoot, collectionsIds);
          vizCategories.keyIdentity = store.getters.identityAndCredentialParameters;

          // Liste des slugs des catégories de l'encyclopédie
          vizCategories.categories = collectionsIcons;

          // Ces listes servent également pour les filtres
          this.collections = encyclopedieCollectionInfos;
          this.collectionsTitles = collectionTitlesAssocArray;

          vizCategories.colorsByCategory = collectionColorsAssocArray;
          vizCategories.colorNosByCategory = collectionColorNosAssocArray;
          vizCategories.iconsByCategory = collectionIconsAssocArray;
          vizCategories.titlesByCategory = collectionTitlesAssocArray;

          vizCategories.currentLanguage = store.state.currentLanguage;

          vizCategories.width = constellationWith;
          vizCategories.height = constellationHeight;

          if (this.feedMode === "auto")
          {
            this.isLoading = true;
            vizCategories.clear();
            vizCategories.loadCollections(constellationParentId);
          }

          /* Listeners */
          constellationParent.addEventListener('categoriesLoadingComplete', this.prepareItemsForList);
          constellationParent.addEventListener('itemClicked', this.selectItem);

          window.addEventListener('resize', this.resizeVisualization);
        }

      }
    },
    mounted() {
      this.createCollectioVizualisation();
    },
    updated() {
      this.resizeVisualization
    },
    beforeUnmount() {

      vizCategories.clear();

      const constellationParent = document.getElementById(constellationParentId);
      constellationParent.removeEventListener('categoriesLoadingComplete', this.prepareItemsForList);
      constellationParent.removeEventListener('itemClicked', this.selectItem);
      window.removeEventListener('resize', this.resizeVisualization);
    },
    watch: {
      itemsByCollection:  function (items) {

          this.allItems = items;

          setTimeout(function() {
            vizCategories.clear();
            vizCategories.setCollections(items);
            vizCategories.init( constellationParentId );
          }, 200)

      },
      '$store.state.collections': function() {
        this.createCollectioVizualisation();
      },
      '$store.state.currentLanguage': function() {
        this.createCollectioVizualisation();
      }
    }
  }

</script>
