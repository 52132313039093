<template>

  <Loading class="loading-animation-media" v-if="state.isLoading" />

  <div class="notice-item-image"  v-if="state.isMediaImage">
    <div class="notice-item-image-content">
        <a :href="state.mediaPath" class="notice-item-image-for-gallery">
          <img :src="state.mediaLargeImage" :alt="state.mediaTitle">
          <!-- <p class="notice-item-image-caption">Crédits</p> -->
        </a>
      <p class="notice-item-image-caption">{{ state.mediaTitle }}</p>
    </div>
  </div>

  <div class="notice-item-video"  v-if="state.isMediaYouTube">
    <p class="notice-item-image-caption">{{ state.mediaTitle }}</p>
    <iframe :src="state.mediaPath" width="100%" height="250px" frameborder="0" />
  </div>

  <PDFJSViewer
          v-if="state.isMediaPDF"
          class="pdf-content"
          :path="state.pdfViewer"
          :fileName="state.mediaPath"
  />

  <div class="article-content notice-media-html" v-if="state.mediaHTML"
       v-html="state.mediaHTML">
  </div>

</template>

<script>

  import {reactive, computed, onMounted} from "vue";
  import { getMediaApiURL } from "../../js/api";
  import { useStore } from "vuex";
  import axios from 'axios'

  import PDFJSViewer from "../PDFJSViewer";
  import Loading from "../Loading";

  export default {

    name: 'Media',
    components: {
      PDFJSViewer,
      Loading
    },
    props: {
      mediaId: {
        type: Number,
        required: false,
        default:null
      }
    },
    emits: [
      'loaded'
    ],
    setup(props, context) {

      const store = useStore();

      // Chemin du viewer PDF
      const pdfViewer = store.state.pdfViewer;

      // Api infos
      const apiRoot = store.getters.apiRoot;
      const keyIdentity = store.getters.identityAndCredentialParameters;

      const loadMedia = function(mediaId) {

        state.isLoading = true;

        // console.log("-------------- loadMedia", mediaId);
        const apiURL = getMediaApiURL(apiRoot, mediaId);

        // API du media de l'item:
        return axios
          .get(apiURL + keyIdentity )
          .then(function(response){

            const mediaJson = response.data;

            // console.log(mediaJson);

            state.mediaType = mediaJson["o:media_type"];
            state.mediaTitle = mediaJson["o:title"];

            if (! state.mediaType) {

              state.isLoading = false;

              const ingester = mediaJson["o:ingester"];
              if (ingester === "youtube")
              {
                // Video YouTube
                state.mediaType = "youtube";

                const videoSource = mediaJson["o:source"];
                const vIndex = videoSource.indexOf('v=');
                if (vIndex !== -1) {
                  state.mediaPath = document.location.protocol + '//www.youtube.com/embed/' + videoSource.substr(vIndex + 2);
                } else {
                  state.mediaPath = videoSource;
                }

              } else {

                // Media HTML
                state.mediaHTML = mediaJson.data ? mediaJson.data.html : "";

              }

            } else {

              state.mediaThumbnails = mediaJson["o:thumbnail_urls"];
              if (state.mediaThumbnails) {
                state.mediaLargeImage = state.mediaThumbnails["large"];
              }

              state.isLoading = false;
              state.isMediaLoadingFailed = false;
              state.hasMedia = true;

              let mediaOriginal = mediaJson["o:original_url"];
              if (mediaOriginal)
              {
                // Image ou PDF
                state.mediaPath = mediaOriginal;

                // Pour Light Gallery :
                if (state.isMediaImage) {

                  // Dans le navigateur et LightGallery, on ne peut pas visionner tous les formats
                  const viewableExtensions = ["jpg", "jpeg", "gif", "png"];

                  // Si l'image n'est pas visible dans le navigateur, on prend la plus grande vignette Web
                  const mediaOriginalExtension = mediaOriginal.split('.').pop();
                  if (viewableExtensions.indexOf(mediaOriginalExtension) === - 1) {
                    state.mediaPath = state.mediaLargeImage;
                  }

                  context.emit('loaded');
                }
              }

            }

            return mediaJson;
          })
          .catch(error => {
            console.log(error);
            state.isMediaLoadingFailed = true;
            state.isLoading = false;
          });
      };


      //
      // State
      //

      const state = reactive({

        isLoading: false,
        mediaType:null,
        mediaTitle:null,
        mediaPath:null,
        mediaHTML:null,
        mediaThumbnails:[],
        mediaLargeImage: null,

        isMediaLoadingFailed:false,
        isMediaImage: computed(() => state.mediaType && state.mediaType.indexOf("image/") === 0),
        isMediaPDF: computed(() => state.mediaType && state.mediaType === "application/pdf"),
        isMediaYouTube: computed(() => state.mediaType && state.mediaType === "youtube"),
        isMediaHTML: computed(() => state.mediaHTML && state.mediaHTML.length > 0),

        mediaParentCssClass: computed(() => state.isMediaPDF ? "notice-pdf-viewer" : "" ),

        pdfViewer
      });

      onMounted(() => {
        if (! isNaN(props.mediaId)) {
          loadMedia(props.mediaId);
        }
      });

      return {
        state
      };

    }
  }

</script>
