<template>
  <div class="coins" v-for="(coinHtml, index) in state.coinsFragments"
              :key="index"
              v-html="coinHtml"
  />
</template>

<script>

  import {useStore} from "vuex";
  import {onMounted, reactive, watch} from "vue";
  import {getCoinsApiURL} from "../js/api";

  export default {
    name: 'Coins',
    props: {
      query: {
        type: String,
        required: true
      }
    },
    setup(props) {

      const store = useStore();

      const printCoins = function() {

        // Get list of json of items
        const apiURL = getCoinsApiURL(store.getters.apiRoot, props.query);

        store.dispatch('getApi', apiURL).then(function(response){

          let coinsFragments = [];
          for(let prop in response.data) {
            if (Object.prototype.hasOwnProperty.call(response.data, prop)) {
              coinsFragments.push(response.data[prop]);
              // console.log(response.data[prop]);
            }
          }

          state.coinsFragments = coinsFragments;
          // console.log(state.coinsFragments);

          // Zotero
          document.dispatchEvent(new Event('ZoteroItemUpdated', {
            bubbles: true,
            cancelable: true
          }))

        });

      };

      watch( props, () => {
        printCoins();
      });

      onMounted(() => {
        printCoins();
      });

      const state = reactive({
        coinsFragments: null,
      });

      return {
        state
      }

    }
  }

</script>
