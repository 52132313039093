<template>
  <div v-if="elementCount > 0">{{ elementCount }} {{ elementLabelWithPlural }}</div>
</template>

<script>

  export default {
    name: 'ListCount',
    props: {
      elementCount: {
        type: Number,
        required: true,
      },
      elementLabel: {
        type: String,
        required: false,
        default:"document"
      },
      elementPluralLabel: {
        type: String,
        required: false,
        default:"documents"
      }
    },
    computed: {
      elementLabelWithPlural() {
        return  this.elementCount > 1 ? this.$t(this.elementPluralLabel) : this.$t(this.elementLabel);
      },
    },
  }
</script>
