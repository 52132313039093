<template>
  <a :download="getNoticeFileName()"  @click="exportNotice($event)" style="cursor: pointer">{{ linkLabel }}</a>
</template>


<script>

  import {getItemExportApiURL, getItemExportURL} from "../../js/api";
  import axios from "axios";

  export default {
    name: 'NoticeDownloadLink',
    props: {
      linkLabel: {
        type: String,
        required: false,
        defaut: "Télécharger"
      },
      resourceId: {
        type: Number,
        required: true,
      },
      output: {
        type: String,
        required: false,
        defaut: "csv"
      }
    },
    computed: {
    },
    methods: {
      exportNotice($event) {
        // Récupération du contenu, puis relance du click
        if( ! $event.target.getAttribute('href')) {
          $event.preventDefault();

          // Récupération du contenu :
          const apiURL = this.getNoticeURL();
          axios.get(apiURL)
                  .then(function (response) {
                      // Relance du click, une fois le contenu associé au lien download
                      $event.target.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(response.data));
                      $event.target.click();
                  })
                  .catch(error => {
                    console.log(error);
                    document.location = this.getNoticeAlternativeURL();
                  });
        }
      },
      getNoticeFileName() {
        return "item" + this.resourceId + '.' + this.output;
      },
      getNoticeURL() {
        const siteId = this.$store.state.settings.site.id;
        return getItemExportApiURL(this.$store.getters.apiRoot, this.resourceId, this.output, siteId);
      },
      getNoticeAlternativeURL() {
        return getItemExportURL(this.$store.getters.apiRoot, this.resourceId, this.output);
      }
    }
  }

</script>
