<template>
  <div class="annotations" v-if="state.annotations.length || state.isUserConnected">

    <h2>{{ $t("Annotations") }}</h2>

    <NewAnnotation
            v-if="state.isUserConnected"
            @add-new-annotation="addNewAnnotation"
    />

    <div class="annotations-thread">
      <ul>
        <li v-for="annotation in state.annotations" v-bind:key="annotation.id">
          <CurrentUserAnnotation
                  v-if="isCurrentUser(annotation.userId)"
                  v-bind:annotationId="annotation.id"
                  v-bind:annotationContent="annotation.content"
                  v-bind:annotationDate="annotation.date"
                  v-bind:userId="annotation.userId"
                  v-bind:userName="annotation.userName"
                  @delete-annotation="deleteAnnotation"
                  @update-annotation="updateAnnotation"
          />
          <OtherUserAnnotation
                  v-else
                  v-bind:annotationId="annotation.id"
                  v-bind:annotationContent="annotation.content"
                  v-bind:annotationDate="annotation.date"
                  v-bind:userId="annotation.userId"
                  v-bind:userName="annotation.userName"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

  import {computed, onMounted, reactive, watch} from "vue";
  import {useStore} from "vuex";
  import axios from "axios";

  import {getItemAnnotationsApiURL, getAnnotationApiURL} from "../../js/api";
  import {mySQLToDateJs, dateJsToHuman} from "../../js/viz-utils";

  import NewAnnotation from "./NewAnnotation";
  import CurrentUserAnnotation from "./CurrentUserAnnotation";
  import OtherUserAnnotation from "./OtherUserAnnotation";

  export default {
    name: 'Annotations',
    components: {
      OtherUserAnnotation,
      CurrentUserAnnotation,
      NewAnnotation
    },
    props: {
      itemId: {
        type: Number,
        required: true,
      }
    },
    methods: {
    },
    setup(props) {

      const store = useStore();

      const state = reactive({
        userId: computed(() => parseInt(store.getters.userId) ),
        isUserConnected: computed(() => store.getters.isUserConnected ),
        userName: computed(() => store.getters.userName ? store.getters.userName : "" ),
        annotations: []
      });

      const isCurrentUser = function(userId) {
        return state.userId === parseInt(userId);
      };

      const getAnnotationContent = function( annotationJson ) {
        const annotationContentBody = annotationJson['oa:hasBody'][0];
        if (annotationContentBody) {
          const annotationRdfValue = annotationContentBody["rdf:value"];
          if (annotationRdfValue) {
            return annotationRdfValue[0]['@value'];
          }
        }
        return "";
      };

      const deleteAnnotation = function( annotation ) {

        const annotationToDeleteId = annotation.id;

        const apiRoot = store.getters.apiRoot;
        const keyIdentity = "?" + store.getters.identityAndCredentialParameters.substr(1);
        const apiURL = getAnnotationApiURL(apiRoot, annotationToDeleteId);

        return axios
            .delete(apiURL + keyIdentity )
            .then(function(){

              // On doit la supprimer de la liste des annotations
              const n = state.annotations.length;
              let i, annotation, pos = -1;

              for(i=0;i<n;i++) {
                annotation = state.annotations[i];
                if (annotation.id === annotationToDeleteId) {
                  pos = i;
                  break;
                }
              }

              if (pos !== -1) {
                // Suppression de la liste
                state.annotations.splice(pos, 1);
              }
            });
      };

      const updateAnnotation = function( annotation ) {

        const apiRoot = store.getters.apiRoot;
        const keyIdentity = "?" + store.getters.identityAndCredentialParameters.substr(1);
        const apiURL = getAnnotationApiURL(apiRoot, annotation.id);

        const data = {
          'oa:motivatedBy' : [{'@value' : 'commenting'}],
          'oa:hasBody' : [{'rdf:value' : [{'@value': annotation.content }] }],
          'oa:hasTarget' : [{'oa:hasSource' : [{'value_resource_id' : props.itemId}] }],
        };

        return axios
            .patch(apiURL + keyIdentity, data)
            .then(function(response){

              const annotationUpdated = response.data;
              const annotationContent = getAnnotationContent(annotationUpdated);
              const annotationModified = mySQLToDateJs ( annotationUpdated['o:modified']['@value'] );
              const annotationId = parseInt(annotationUpdated['o:id']);

              // On doit la mettre à jour dans la liste des annotations
              const n = state.annotations.length;
              let i, annotation;

              for(i=0;i<n;i++) {
                annotation = state.annotations[i];
                if (annotation.id === annotationId) {
                  state.annotations.content = annotationContent;
                  state.annotations.modified = annotationModified;
                  state.annotations.date = dateJsToHuman(annotationModified);
                  break;
                }
              }
            });
      };

      const addNewAnnotation = function( annotation ) {

        const apiRoot = store.getters.apiRoot;
        const keyIdentity = store.getters.identityAndCredentialParameters;
        const apiURL = getItemAnnotationsApiURL(apiRoot, props.itemId);

        const data = {
          'oa:motivatedBy' : [{'@value' : 'commenting'}],
          'oa:hasBody' : [{'rdf:value' : [{'@value': annotation.content }] }],
          'oa:hasTarget' : [{'oa:hasSource' : [{'value_resource_id' : props.itemId}] }],
        };

        return axios
          .post(apiURL + keyIdentity, data )
          .then(function(response){

            const annotation = response.data;
            const annotationContent = getAnnotationContent(annotation);
            const annotationCreated = mySQLToDateJs ( annotation['o:created']['@value'] );
            const annotationId = parseInt(annotation['o:id']);
            const annotationOwnerId = annotation['o:owner']['o:id'];
            const annotationOwnerName = annotation['o:owner']['o:name'];

            // Ajout dans la liste
            state.annotations.push({
              id: annotationId,
              content: annotationContent,
              created: annotationCreated,
              date: dateJsToHuman(annotationCreated),
              userId: annotationOwnerId,
              userName: annotationOwnerName,
            });

        });
      };

      const loadAnnotations = function() {

        const apiRoot = store.getters.apiRoot;
        const keyIdentity = store.getters.identityAndCredentialParameters;
        const apiURL = getItemAnnotationsApiURL(apiRoot, props.itemId);

        return axios.get(apiURL + keyIdentity ).then(function(response){

          const annotations = response.data;
          const n = annotations.length;
          let i, annotation, annotationId, annotationCreated, annotationContent, annotationOwnerId, annotationOwnerName;
          state.annotations = [];

          for(i=0;i<n;i++) {

            annotation = annotations[i];

            annotationId = parseInt(annotation['o:id']);
            annotationCreated = mySQLToDateJs ( annotation['o:created']['@value'] );
            annotationContent = getAnnotationContent(annotation);
            annotationOwnerId = annotation['o:owner']['o:id'];
            annotationOwnerName = annotation['o:owner']['o:name'];

            state.annotations.push({
              id: annotationId,
              content: annotationContent,
              created: annotationCreated,
              date: dateJsToHuman(annotationCreated),
              userId: annotationOwnerId,
              userName: annotationOwnerName,
            });
          }
        });

      };

      watch( props, () => {
        loadAnnotations();
      });

      onMounted(() => {
        loadAnnotations();
      });

      return {
        state,
        isCurrentUser,
        addNewAnnotation,
        deleteAnnotation,
        updateAnnotation
      };

    }

  }

</script>
