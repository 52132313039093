import { createRouter, createWebHistory } from 'vue-router'

import Logout from '../views/Logout.vue'
import Contacts from '../views/Contacts.vue'
import Encyclopedie from '../views/Encyclopedie.vue'
import SitePage from '../views/SitePage.vue'
import Search from '../views/Search.vue'
import Browse from '../views/Browse.vue'
import User from '../views/User.vue'
import UserPanel from '../views/UserPanel.vue'
import NotFound from '../views/NotFound.vue'
import Contribuer from "../views/Contribuer";
import ItemView from "../components/Views/ItemView";
import CollectionView from "../components/Views/CollectionView";
import CuratedItemSets from "../views/CuratedItemSets.vue";
import CuratedItemSet from "../views/CuratedItemSet.vue";
import Subject from "../views/Subject";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: SitePage,
    meta: { title: "Accueil" }
  },
  {
    path: '/page/index',
    redirect:  { name: 'Home' }
  },
  {
    path: '/login',
    name: 'Home Login',
    component: SitePage,
    meta: { title: "Connexion" }
  },
  {
    path: '/register',
    name: 'Home Register',
    component: SitePage,
    meta: { title: "Inscription" }
  },
  {
    path: '/logout',
    name: 'Home Logout',
    component: Logout,
    meta: { title: "Déconnexion" }
  },
  {
    path: '/contact',
    name: 'Contacts',
    component: Contacts
  },
  {
    path: '/encyclopedie',
    name: 'Encyclopedie',
    component: Encyclopedie
  },
  {
    path: '/item/:id',
    name: 'Item',
    component: ItemView
  },
  {
    path: '/collection/:id',
    name: 'Collection',
    component: CollectionView
  },
  {
    path: '/item-set/:id',
    name: 'ItemSet',
    component: CollectionView
  },
  {
    path: '/encyclopedie/item/:id', /* notice pleine page */
    name: 'Notice Item',
    component: Encyclopedie
  },
  {
    path: '/encyclopedie/item/:id', /* notice popup, au-dessus du graphe */
    name: 'Notice Item Modal',
    component: Encyclopedie
  },
  {
    path: '/encyclopedie/item/:id',
    name: 'Graphe Item',
    component: Encyclopedie
  },
  {
    path: '/encyclopedie/items-graph/:s',
    name: 'Graphe Items',
    component: Encyclopedie
  },
  {
    path: '/encyclopedie/collection/:id',
    name: 'Graphe Collection',
    component: Encyclopedie
  },
  {
    path: '/curated-collections',
    name: 'Curated ItemSets',
    component: CuratedItemSets
  },
  {
    path: '/curated-collections/collection/:itemset',
    name: 'Curated ItemSet',
    component: CuratedItemSet,
  },
  {
    path: '/s/:slug?/item-set/:itemset/curated',
    name: 'Omeka Curated ItemSet',
    component: CuratedItemSet,
  },
  {
    path: '/curated-collections/collection/:itemset/item/:id',
    name: 'Curated ItemSet Item',
    component: CuratedItemSet
  },
  {
    path: '/search',
    query: 'q',
    name: 'Recherche',
    component: Search
  },
  {
    path: '/search/:s',
    name: 'Recherche Avancée',
    component: Search
  },
  {
    path: '/search/item/:id/query/:s',
    name: 'Recherche Avancée Item',
    component: Search
  },
  {
    path: '/browse',
    query: 'metadata',
    name: 'Parcourir',
    component: Browse
  },
  {
    path: '/browse/item/:id',
    query: 'metadata',
    name: 'Parcourir Item',
    component: Browse
  },
  {
    path: '/subject/:subject',
    name: 'Subject',
    component: Subject
  },
  {
    path: '/pages/:slug',
    name: 'Pages',
    component: SitePage
  },
  {
    path: '/page/:slug',
    name: 'Page',
    component: SitePage
  },
  {
    path: '/mon-compte',
    name: 'UserAccount',
    component: User
  },
  {
    path: '/mon-compte/:slug',
    name: 'UserAccount Panel',
    component: UserPanel
  },
  {
    path: '/mon-compte/contribuer',
    name: 'UserAccount Contribuer',
    component: Contribuer
  },
  { path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound
  }

];

function createRouterInstance( baseURL, additionalRoutes ) {

  const router = createRouter({
    history: createWebHistory(baseURL),
    routes: routes.concat(additionalRoutes)
  });

  // console.log("routes", routes.concat(additionalRoutes))

  return router;
}

export { createRouterInstance }
