<template>
  <div class="view">
    <div class="breadcrumbs-and-title">
      <div class="breadcrumbs">
        <BreadCrumb :paths="breadcrumbs" />
      </div>
      <h1>{{ $t("Recherche") }}</h1>
    </div>
    <div class="contents" :class="withPopUpCssClass">
      <div class="contents-wrapper with-visualization" :class="displayModeCssClass">
        <div class="constellation" :class="constellationCssClass">

          <!-- Graphe SVG des collections de l'encyclopédie -->
          <VisualisationEncyclopedie
                  feedMode="manual"
                  :itemsByCollection="state.itemsForEncyclopedia"
                  @selectitem="openItemVisualization"
                  @changecategoriesfiltermenu="changeCategoriesFilterMenu"
          />

          <!-- Onglets et panier -->
          <div class="contents-header with-padding">
            <ul class="contents-mode">
              <!-- Toggle Graphe / Liste / Notices -->
              <li class="contents-list-mode">
                <router-link :to="state.listItemSetRoute">
                  {{ $t("Liste") }}
                </router-link>
              </li>
              <li class="contents-notice-mode">
                <router-link :to="state.noticeItemRoute">
                  {{ $t("Notices") }}
                </router-link>
              </li>
              <!--
              <li class="contents-graph-mode">
                <router-link :to="state.graphItemSetRoute">
                  {{ $t("Notices de l'encyclopédie documentaire") }}
                </router-link>
              </li>
              -->
            </ul>
            <!-- Panier -->
            <div class="constellation-basket" v-if="state.userIsConnected">
              <router-link to="/mon-compte/mon-panier">{{ basketCount }}</router-link>
            </div>
          </div>

          <Loading class="notice-item-content with-padding" v-if="state.isLoading" />

          <!-- Mode Liste -->
          <div class="contents-list-mode-content with-sort-options" v-if="! state.isLoading" >

            <!-- Graphe SVG des Categories  -->
            <div id="svg-parent" class="svg-parent" v-if="state.isGraphDisplayMode"></div>

            <!-- Mode Liste -->
            <ListItems
                    v-if="state.isListDisplayMode"
                    :facets=true
                    :items="state.items"
                    :advancedQuery="state.getSearchQuery"
                    :pageNo="state.pageNo"
                    :queryRouteName="itemRouteName"
                    @opendetailednotice="openDetailedNotice"
                    @openitemvisualization="openItemVisualization"
            />

        </div>

          <!-- Mode Notice -->
          <NoticesItems
                  v-if="state.isNoticeDisplayMode"
                  :items-ids="state.itemsIds"
                  :notice-item-id="state.noticeItemId"
                  :notice-item-type="state.noticeItemType"
                  :changeRouteWithPreviousAndNext="state.changeRouteWithPreviousAndNext"
                  :openGraphInPopUp="true"
                  @open-item-graph="openItemVisualization"
                  @opendetailednotice="openDetailedNotice"
                  @item-loaded="itemLoaded"
          />

          <!-- Graphe SVG d'un Item -->
          <VisualisationItem
              :class="itemVisualizationCssClass"
              :itemId="itemVisualizationItemId"
              @opendetailednotice="openDetailedNotice"
              @closeitemvisualization="closeItemVisualization"
              @notice-loaded="itemLoaded"
          />

        </div>
      </div>
    </div>

    <NoticePopUp
            v-if="detailedNoticeItemIsOpened"
            v-bind:itemId="detailedNoticeId"
            v-bind:itemType="detailedNoticeType"
            @close-detailed-item-notice="closeDetailedNotice"
            :class="detailedNoticeCssClass"
    />

  </div>
</template>
<script>

  import {computed, onMounted, reactive} from "vue";
  import { useStore } from "vuex";
  import {onBeforeRouteLeave, onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";
  import {getCollectionsListApiURL, getSearchApiURL} from "../js/api";
  import axios from "axios";

  import ListItems from "../components/Lists/ListItems";
  import Loading from "../components/Loading";
  import NoticePopUp from "../components/Notices/NoticePopUp";
  import NoticesItems from "../components/Lists/NoticesItems";
  import BreadCrumb from "../components/Header/BreadCrumb";
  import VisualisationEncyclopedie from "../components/Visualization/VisualisationEncyclopedie";
  import VisualisationItem from '../components/Visualization/VisualisationItem';

  export default {
    components: {
      NoticesItems,
      ListItems,
      Loading,
      NoticePopUp,
      BreadCrumb,
      VisualisationItem,
      VisualisationEncyclopedie
    },
    props: {
      itemSetRouteName: {
        type: String,
        required: false,
        default:'Recherche Avancée'
      },
      itemRouteName: {
        type: String,
        required: false,
        default:'Recherche Avancée Item'
      },
      defaultDisplayMode: {
        type: String,
        required: false,
        default:"list"
      },
    },
    data() {
      return {
        detailedNoticeItemIsOpened:false,
        detailedNoticeId:null,
        detailedNoticeType:null,
        itemVisualizationItemId:null,
        itemVisualizationIsOpened:null,
        filterCategoriesMenuIsOpened:false
      }
    },
    computed: {
      constellationCssClass() {
        return this.filterCategoriesMenuIsOpened === true ? "with-filters" : "";
      },
      detailedNoticeCssClass() {
        return this.detailedNoticeItemIsOpened ? "opened" : "";
      },
      itemVisualizationCssClass() {
        return this.itemVisualizationIsOpened === true ? "opened" : "closed";
      },
      displayModeCssClass() {
        return this.state.displayMode + "-mode";
      },
      withPopUpCssClass() {
        return this.detailedNoticeItemIsOpened || this.itemVisualizationIsOpened ? "with-popup" : "";
      },
      currentRoutePath() {
        return this.$route.path;
      },
      basketCount() {
        return this.$store.state.basket.length;
      },
      breadcrumbs() {
        if ((this.state.displayMode === "notice") && this.state.noticeItemTitle) {
          return [
            { name: 'Home', label:'Accueil' },
            { name: 'Recherche Avancée', label:'Recherche', params: this.state.listItemParams, query: this.state.listItemQuery },
            { label: this.state.noticeItemTitle }
          ]
        } else {
          return [
            { name: 'Home', label:'Accueil' },
            { label: 'Recherche' }
          ]
        }
      }
    },
    methods: {
      openDetailedNotice(itemId) {
        this.detailedNoticeId = itemId;
        this.detailedNoticeType = "item";
        this.detailedNoticeItemIsOpened = true;
      },
      closeDetailedNotice() {
        this.detailedNoticeId = null;
        this.detailedNoticeType = null;
        this.detailedNoticeItemIsOpened = false;
      },
      openItemVisualization(itemId) {
        this.itemVisualizationIsOpened = true;
        this.itemVisualizationItemId = parseInt(itemId);
      },
      closeItemVisualization() {
        this.itemVisualizationItemId = null;
        this.itemVisualizationIsOpened = false;
      },
      changeCategoriesFilterMenu(menuIsOpened) {
        this.filterCategoriesMenuIsOpened = menuIsOpened;
      }
    },
    setup(props) {

      const route = useRoute();
      const router = useRouter();
      const store = useStore();

      //
      // Appel à l'api de recherche
      //

      const loadResultsWithURL = function(apiURL, currentItemId) {

        state.isLoading = true;

        const keyIdentity = store.getters.identityAndCredentialParameters;

        // Chargement des résultats :
        axios.get(apiURL + keyIdentity).then(function(response){

          // Analyse des résultats de l'api de recherche

          // On mémorise pour chaque item :Catégories
          // - dans quelle partie il apparaît : corpus, bibliographie, iconographie, encyclopedie, td ( pour l'hyperlien )
          // - dans quelle catégorie de l'encyclopédie il apparaît : personnes, organisations, ... ( pour la couleur de la pastille )

          const items = response.data;
          const n = items.length;
          let i, item, firstItemId, firstItemType, currentItemType, itemSets, firstItemSetId, collectionSettings, collectionCode;
          let collectionRef, encyclopedieCollectionForViz, encyclopedieByCollection = [];
          let itemsIds = [], itemId;

          for(i=0;i<n;i++)
          {
            item = items[i];
            itemId = parseInt( item['o:id']);

            // On prépare la liste des ids pour l'onglet des notices
            itemsIds.push(itemId);

            // On note la route de chaque item (vers le corpus, l'encyclopédie, etc...)
            itemSets = item["o:item_set"];
            if (itemSets && itemSets.length)
            {
              firstItemSetId = itemSets[0]['o:id'];
              if (firstItemSetId) {

                collectionCode = store.getters.collectionCode(firstItemSetId);
                collectionSettings = store.getters.collectionsSettings(firstItemSetId);

                // Choix de la vue pour l'ouverture d'un item dans la liste :
                // - En passant 'route', un item s'ouvrira dans son espace ( encyclopédie, corpus, ...)
                // - En ne le passant pas, la liste déclenche une nouvelle route passée en paramètre au composant
                // ce qui permet d'ouvrir l'item dans l'onglet Notices du composant actuel ( cf _route )

                if (collectionSettings)
                {
                  item.route = collectionSettings.route ? collectionSettings.route : '';
                  item.type = collectionSettings.itemType;
                  item.itemset = collectionSettings.id;
                  item.icon = collectionSettings.icon;
                  item.colorNo = collectionSettings.colorNo;
                }
                else
                {
                  item.route = '';
                  item.type = "item";
                  item.itemset = null;
                  item.icon = null;
                  item.colorNo = null;
                }

                item.id = itemId;
                item.collectionCode = item.icon ? '' : collectionCode;

                // console.log(item, collectionCode)

                // On prépare les données pour le graphe des items de l'encyclopedie
                if (item.icon)
                {
                  collectionRef = item.icon + item.itemset;
                  encyclopedieCollectionForViz = encyclopedieByCollection[collectionRef];

                  // Si on n'a pas encore rencontré la collection de l'item :
                  if (! encyclopedieCollectionForViz) {
                    encyclopedieCollectionForViz = encyclopedieByCollection[collectionRef] = {
                      id: collectionSettings.id,
                      title: collectionSettings.title,
                      total:0,
                      items:[]
                    };
                  }

                  encyclopedieCollectionForViz.items.push(item);
                  encyclopedieCollectionForViz.total = encyclopedieCollectionForViz.items.length;
                }
              }
            }

            // Premier item
            if (firstItemId === undefined) {
              firstItemId = itemId;
              firstItemType = item.type;
            }

            if (itemId === currentItemId) {
              currentItemType = item.type;
            }
          }

          state.items = items;
          state.itemsCount = items.length;
          state.itemsIds = itemsIds;

          if (currentItemId) {
            state.noticeItemId = currentItemId;
            state.noticeItemType = currentItemType;
          } else {
            state.noticeItemId = firstItemId === undefined ? 0 : firstItemId;
            state.noticeItemType = firstItemType;
          }

          state.isLoading = false;

          // console.log('encyclopedieByCollection', encyclopedieByCollection);

          // Graphe :
          let itemsForEncyclopedia = [];
          for (collectionRef in encyclopedieByCollection) {
            if (Object.prototype.hasOwnProperty.call(encyclopedieByCollection, collectionRef)) {
              itemsForEncyclopedia.push(encyclopedieByCollection[collectionRef]);
            }
          }
          state.itemsForEncyclopedia = itemsForEncyclopedia;

          // console.log('itemsForEncyclopedia', state.itemsForEncyclopedia.length);
        });
      };

      // Recherche simple ( RQ : on ajoute systématiquement le filtre sur les collections: state.queryItemSets )
      // q: contenu du champ de saisie de l'en-tête
      const loadResultsBasicSearch = function(q, itemId) {

        state.q = q || route.query.q;
        state.s = null;

        let apiURL = getSearchApiURL( store.getters.apiRoot, state.q + state.queryItemSets, state.sortByParam, state.sortOrderParam) ;

        // On passe l'ensemble des collections filtres (encyclopédie + oeuvres + iconographie ...)
        // pour ne pas retourner d'items provenant des autres collections :
        apiURL += store.getters.searchCollectionsQueryParameters();

        // On s'assure d'avoir la données des collections en mémoire, avant d'appeler la reuqête de la recherche
        if (state.collections) {
          loadResultsWithURL(apiURL, itemId);
        } else {
          const collectionsApiURL = getCollectionsListApiURL(store.getters.apiRoot);
          store.dispatch('getApi', collectionsApiURL).then(function(){
            loadResultsWithURL(apiURL, itemId);
          });
        }
      };

      // Recherche avancée (suite à la modification des filtres, ou fenêtre de recherche avancée)
      const loadResultsAdvancedSearch = function(s, itemId) {

        const toS = s || route.params.s;
        state.s = toS;
        state.q = null;

        let apiURL = store.getters.apiRoot + '/items?' + toS;
        apiURL += "&sort_by=" + (state.sortByParam ? state.sortByParam : 'title');
        apiURL += "&sort_order=" + (state.sortOrderParam ? state.sortOrderParam : "asc");

        // Si aucune collection n'est passée dans la recherche,
        // on passe l'ensemble des collections filtres (encyclopédie + oeuvres + iconographie ...)
        // pour ne pas retourner d'items provenant des autres collections :
        if (toS.indexOf('item_set_id') === - 1) {
          apiURL += store.getters.searchCollectionsQueryParameters();
        }

        // On s'assure d'avoir la données des collections en mémoire, avant d'appeler la requête de la recherche
        if (state.collections) {
          loadResultsWithURL(apiURL, itemId);
        } else {
          const collectionsApiURL = getCollectionsListApiURL(store.getters.apiRoot);
          store.dispatch('getApi', collectionsApiURL).then(function(){
            loadResultsWithURL(apiURL, itemId);
          });
        }
      };

      // q : recherche simple via le champ de saisie
      // s : recherche avancée
      const loadResults = function(s, q, itemId = null) {
        if (s) {
          const searchParams = new URLSearchParams(s);
          const qs = searchParams.get('q');
          if (qs) {
            loadResultsBasicSearch(qs, itemId);
          } else {
            loadResultsAdvancedSearch(s);
          }
        } else if (q) {
          loadResultsBasicSearch(q, itemId);
        }
      };

      const itemLoaded = function(item) {
        state.noticeItemTitle = item.title;
      };

      const getItemData = function(itemId, property = null) {
        if (Array.isArray(state.items)) {
          const n = state.items.length;
          let i, item;

          for(i=0;i<n;i++)
          {
            item = state.items[i];
            if (itemId === parseInt( item['o:id'])) {
              if (property) {
                if (item[property]) return item[property];
              } else {
                return item;
              }
            }
          }
        }
      }


      //
      // Lifecycle Hooks
      //

      onMounted(() => {

        // Liste des collections pour la requête recherche basique
        state.queryItemSets = store.state.queryItemSets;

        // Paramètres de l'URL : display mode, sort, ...
        state.sortByParam = route.query.sort_by ? route.query.sort_by : "title";
        state.sortOrderParam = route.query.sort_order ? route.query.sort_order : "asc";

        if (route.name === props.itemRouteName) {
          state.displayMode = "notice";
        } else {
          if (route.query.display_mode && (route.query.display_mode.length > 0)) {
            state.displayMode = route.query.display_mode ? route.query.display_mode : props.defaultDisplayMode;
          }
        }

        // Chargement des résultats
         if (route.name === "Recherche" || route.name === props.itemSetRouteName) {
           loadResults(route.params.s, route.query.q)
        } else if (route.name === props.itemRouteName) {
           const currentItemId = parseInt(route.params.id);
           loadResults(route.params.s, route.query.q, currentItemId)
         }
      });

      // Different route / Same component
      onBeforeRouteLeave((to, from, next) => {

        // console.log('onBeforeRouteLeave', to, from, props.itemSetRouteName);

        if (to.name === props.itemRouteName)
        {
          // From List view to Item view
          state.noticeItemId = parseInt(to.params.id);
          state.noticeItemType = getItemData(state.noticeItemId, "type");
          state.displayMode = "notice";
          next();
        }
        else if ((to.name === props.itemSetRouteName) || (to.name === "Recherche"))
        {
          // From Item view to List view
          state.noticeItemId = 0;
          state.displayMode = to.query.display_mode ? to.query.display_mode : props.defaultDisplayMode;

          state.sortByParam = route.query.sort_by ? route.query.sort_by : "title";
          state.sortOrderParam = route.query.sort_order ? route.query.sort_order : "asc";

          loadResults(to.params.s, to.query.q)

          next();
        }
        else
        {
          // Different route with different component (home, etc...)
          next();
        }
      });

      // Same route / Same component
      onBeforeRouteUpdate((to, from, next) => {

        if (to.name === props.itemRouteName)
        {
          // Item view
          state.noticeItemId = parseInt(to.params.id);
          state.noticeItemType = getItemData(state.noticeItemId, "type");
          state.displayMode = "notice";
          next();

        }
        else if ((to.name === props.itemSetRouteName) || (to.name === "Recherche"))
        {
          // List view
          state.noticeItemId = 0;
          state.displayMode = to.query.display_mode ? to.query.display_mode : props.defaultDisplayMode;

          // q : recherche simple via le champ de saisie
          // s : recherche avancée

          const toQueryQ = to.query.q;
          const toParamS = to.params.s;

          let toQuerySortBy = to.query.sort_by;
          let toQuerySortOrder = to.query.sort_order;

          if ((state.sortByParam !== toQuerySortBy) || (state.sortOrderParam !== toQuerySortOrder) || (state.s !== toParamS) || (state.q !== toQueryQ)) {

            // 1.1 Changement de l'ordre des items
            state.sortByParam = toQuerySortBy;
            state.sortOrderParam = toQuerySortOrder;
            // state.pageNo = isNaN(to.query.p) ? 1 : parseInt(to.query.p);

            loadResults(toParamS, toQueryQ)

            next();

          } else {

            // 1.2 Pagination
            // state.pageNo = isNaN(to.query.p) ? 1 : parseInt(to.query.p);
            next();

          }

        }
        else
        {
          // Different route with different component (home, etc...)
          next();
        }

      });


      //
      // State
      //

      const state = reactive({
        items:null,
        itemsForEncyclopedia:[],
        q: route.query.q,
        s:null,

        noticeItemId:0,
        noticeItemTitle: null,
        noticeItemType: 'item',

        isLoading: true,
        userIsConnected: computed(() => store.state.currentUser.logged ),
        getSearchQuery: computed(() => {
          return state.s !== null ? state.s : ('?q=' + state.q);
        }),

        sortByParam: 'title',
        sortOrderParam: 'asc',
        pageNo: computed(() => isNaN(route.query.p) ? 1 : parseInt(route.query.p) ),

        sortByParamForItemSets: 'title',
        sortOrderParamForItemSets: 'asc',

        displayMode: props.defaultDisplayMode,
        displayModeCssClass: computed(() => state.displayMode + "-mode"),

        isListDisplayMode: computed(() => { return state.displayMode === "list" }),
        isNoticeDisplayMode: computed(() => { return state.displayMode === "notice" }),
        isGraphDisplayMode: computed(() => { return state.displayMode === "graph" }),
        changeRouteWithPreviousAndNext:true,

        graphItemSetRoute: computed(() => {
          return router.resolve( {
            name: props.itemSetRouteName,
            params: {
              s: state.s || 'q=' + state.q,
            },
            query: {
              display_mode: 'graph',
              q: state.q
            }
          });
        }),

        listItemSetRoute: computed(() => {
          return router.resolve( {
            name: props.itemSetRouteName,
            params: {
              s: state.s || 'q=' + state.q
            },
            query: {
              sort_param: state.sortByParamForItemSets,
              sort_order: state.sortOrderParamForItemSets,
              display_mode: 'list',
              p: state.pageNo,
              q: state.q
            }
          });
        }),

        listItemParams: computed(() => {
          return { s : state.s || 'q=' + state.q };
        }),

        listItemQuery: computed(() => {
          return {
            sort_param: state.sortByParamForItemSets,
            sort_order: state.sortOrderParamForItemSets,
            display_mode: 'list',
            p: state.pageNo,
            q: state.q
          };
        }),

        noticeItemRoute: computed(() => {
          return router.resolve( {
            name: props.itemRouteName,
            params: {
              s: state.s || 'q=' + state.q,
              id: state.noticeItemId
            },
            query: {
              sort_param: state.sortByParam,
              sort_order: state.sortOrderParam,
              display_mode: 'notice',
              q: state.q
            }
          });
        }),
      });

      return {
        state,
        itemLoaded
      }
    }
  }
</script>