import * as d3 from "d3";
import { wrap } from './viz-utils.js';
import { JsonReader } from './jsonReader.js';

function VizCategories() {

    this.width = 1024;
    this.height = 600;

    this.circleRadius = 33.5;
    this.circleScale = 0.6;
    this.itemSize = 3.5;


    //
    // Infos from Settings
    //

    // Linear array : list of slugs
    this.categories = [];

    // Associative arrays (and key prefix):
    this.categoryKeyPrefix = "c";
    this.colorsByCategory = [];
    this.colorNosByCategory = [];
    this.iconsByCategory = [];
    this.titlesByCategory = [];



    this.zoomMin = 1;
    this.zoomMax = 5;
    this.zoom = this.zoomMin;

    this.collectionsApiURL = "";
    this.keyIdentity = "";

    this.currentLanguage = "fr";

    this.activeCollectionId = null;
    this.categoryIconColor = "#384651";

    this.jsonReader = new JsonReader();
}

Object.assign( VizCategories.prototype, {

    getSVGForVisualisation: function() {
        return this.svg;
    },

    getCollectionsURL: function() {
        return  this.collectionsApiURL+this.keyIdentity;
    },

    loadCollections: function(parentElementId) {

        const url = this.getCollectionsURL();
        const t = this;

        // Chargement des catégories
        d3.json(url).then( function (json) {
            t.collections = json.data;
            t.init( parentElementId );
            t.parentElement.dispatchEvent( new CustomEvent( "categoriesLoadingComplete"));
        });
    },

    getCollections: function() {
        return this.collections;
    },

    setCollections: function ( collections ) {
        this.collections = collections;
    },

    getCollectionById: function (collectionId) {

        const n = this.collections.length;
        let i, collection;

        collectionId = parseInt(collectionId);

        for(i=0;i<n;i++) {
            collection = this.collections[i];
            if ( parseInt(collection.id) === collectionId) {
                return collection;
            }
        }
    },

    getColorOfCategory: function(categoryId) {
        const color = this.colorsByCategory[this.categoryKeyPrefix + categoryId];
        return color ? color : "#CCCCCC";
    },

    getColorNoOfCategory: function(categoryId) {
        return this.colorNosByCategory[this.categoryKeyPrefix + categoryId];
    },

    getIconOfCategory: function(categoryId) {
        return this.iconsByCategory[this.categoryKeyPrefix + categoryId];
    },

    getTitleOfCategory: function(categoryId) {
        return this.titlesByCategory[this.categoryKeyPrefix + categoryId];
    },

    getLocalizedTitleTermFromJson: function (itemJson, term = 'dcterms:alternative') {
        let title;
        const titlesObj = itemJson[term];
        if (Array.isArray(titlesObj)) {
            let n = titlesObj.length, titleObj, titleLanguage;
            for (let i = 0; i < n; i++) {
                titleObj = titlesObj[i];
                titleLanguage = titleObj['@language'];
                if (titleLanguage && (titleLanguage.substr(0,2).toLowerCase() === this.currentLanguage)) {
                    title = titleObj['@value'];
                }
            }
            if (! title && (n > 0)) {
                title = titlesObj[0]['@value']
            }
        }
        return title;
    },

    getTitleFromJson: function (itemJson) {

        this.jsonReader.json = itemJson;

        let title = this.jsonReader.getLocalizedMetaDataValue('dcterms:alternative', this.currentLanguage);

        if ((typeof title !== "string") || (title.length === 0)) {
            title = this.getLocalizedTitleTermFromJson(itemJson, 'short_title');
        }

        if ((typeof title !== "string") || (title.length === 0)) {
            title = itemJson['o:title'];
        }

        return this.capitalizeText( title );
    },

    capitalizeText: function (title) {
        return title.charAt(0).toUpperCase() + title.slice(1);
    },

    init: function( parentElementId ) {

        const t = this;

        this.parentElement = document.getElementById(parentElementId);

        this.svg = d3
            .select("#" + parentElementId)
            .append('svg')
            .attr("width", "100%")
            .attr("height", "100%");

        this.defs = this.svg.append('svg:defs');


        // Icônes des collections
        let i, n = this.collections.length, collection;
        if (n === 0) return;

        for(i=0;i<n;i++) {
            collection = this.collections[i];
            collection.color = this.getColorOfCategory(collection.id);
            collection.colorNo = this.getColorNoOfCategory(collection.id);
            collection.icon = this.getIconOfCategory(collection.id);

            // Icônes des collections (personnes, concepts, ...)
            this.addIconsForCategoriesInSVGDefinitions(collection);
        }

        // Répartition angulaire des collections
        this.computeProportions();

        let collectionCircleX, collectionCircleY, collectionColor, collectionTitle;

        this.centerElement = this.svg.append('g')
            .attr('class', 'ellipsis_center');

        // Fond captant les événements du drag and drop (via d3.zoom)
        this.centerElement
            .append('rect')
            .attr('class', 'zoom-event-target')
            .attr('fill', 'rgba(255,0,0,0')
            .attr('width', this.width)
            .attr('height', this.height)
            .attr('x', -this.width/2)
            .attr('y', -this.height/2);

        const parentVisualization = this.centerElement.append('g').attr('class', 'parent');
        this.draggableElement = parentVisualization;

        // D3 : enter
        let circleSelectorEnter = this.collectionsGroups = parentVisualization
            .selectAll('g')
            .data(this.collections)
            .enter()
            .append('g')
            .attr('class', function(d) {
                return 'collection collection' + d.id;
            })
            .call(function (d) { t.computeEllipsis.call(t, d)} );

        this.centerElement
            .attr("transform", "translate("+this.cX+","+this.cY+")");


        // Rayons de l'ellipse
        const innerRadiusX = this.innerRadiusX;
        const innerRadiusY = this.innerRadiusY;

        const lineProportion = this.getCategoryLineRadiusPercentage();
        const titleProportion = this.getCategoryTitleRadiusPercentage();

        // Pour chaque groupe de la collection :
        circleSelectorEnter.each(function(d) {

            const zoneGroup = d3.select(this);

            collection = d;
            collectionColor    = collection.color;
            collectionCircleX  = collection.x;
            collectionCircleY  = collection.y;

            // Title from Json api
            collectionTitle    = t.getTitleOfCategory(collection.id);
            if (! collectionTitle) {
                // Title from settings
                collectionTitle = collection.title || collection.id;
            }

            /*
            ellipsisMidAngle   = collection.ellipsisMidAngle;
            ellipsisStartAngle = collection.ellipsisStartAngle;
            ellipsisEndAngle   = collection.ellipsisEndAngle;
             */

            // Ligne ( séparateur )
            zoneGroup.append('line')
                .attr('class', 'separator-line')
                .attr('x1', 0)
                .attr('y1', 0)
                .attr('x2', innerRadiusX * Math.cos(collection.angle))
                .attr('y2', innerRadiusY * Math.sin(collection.angle))
                .style('stroke', "black")
                .style('stroke-width', 1)
                .style('opacity', 0);

            // Ligne ( vers le centre de la collection )
            zoneGroup.append('line')
                .attr('class', 'center-line')
                .attr('x1', 0)
                .attr('y1', 0)
                .attr('x2', collectionCircleX * lineProportion)
                .attr('y2', collectionCircleY * lineProportion)
                .style('stroke', "#979797")
                .style('stroke-width', 0.5)
                .style('vector-effect', "non-scaling-stroke");

            // Cercle au centre de la collection ( Icône )
            let midAngle2 = zoneGroup.append('g')
                .attr('class', 'collection-center')
                .attr("transform", "translate("+collectionCircleX+","+collectionCircleY+")")
                .style('cursor', 'pointer')
                .on('click', function() {
                    t.activeCollection(d.id);
                });

            midAngle2.append('use')
                .attr('xlink:href', "#" + t.getCollectionCircleId(collection));

            // Libellé de la collection
            zoneGroup.append('text')
                .attr('class', 'collection-title')
                .attr('x', collectionCircleX * titleProportion)
                .attr('y', collectionCircleY * titleProportion)
                .style('font', '14px "Lato", sans-serif')
                .style('text-transform', 'uppercase')
                .style('fill', collectionColor)
                .attr('transform', 'translate(-'+ 4 * collectionTitle.length +',2)')
                .text(collectionTitle);

            // Items
            let itemsCount = collection.total;
            let itemsParameters;
            let itemId, itemTitle, itemTitleIsOneWord;

            const itemSize = t.itemSize;

            let itemsGroup = zoneGroup.append('g')
                .attr('class', 'items-parent')
                .attr("transform", "translate("+collectionCircleX+","+collectionCircleY+")");

            let scalableItemsGroup = itemsGroup.append('g')
                .attr('class', 'items');

            collection.itemsElement = scalableItemsGroup;

            // Items de la collection
            let j;
            for (j=0;j<itemsCount;j++) {

                itemsParameters = collection.items[j];
                itemId = itemsParameters["o:id"];

                // Titre court, ou titre complet
                itemTitle = t.getTitleFromJson(itemsParameters);

                itemTitleIsOneWord = itemTitle.indexOf(' ') === -1;

                let itemGroup = scalableItemsGroup.append('g')
                    .attr("class", "itemGroup")
                    .attr("data-collection-id", collection.id)
                    .attr("data-item-index", j)
                    .attr("data-item-id", itemId)
                    .attr("data-x", itemsParameters.itemX)
                    .attr("data-y", itemsParameters.itemY)
                    .attr("data-x-active", itemsParameters.itemXActive)
                    .attr("data-y-active", itemsParameters.itemYActive);

                itemsParameters.svg = itemGroup;

                itemGroup.append('circle')
                    .attr('r', itemSize)
                    .style('stroke', "black")
                    .style('stroke-width', 0)
                    .style('opacity', j===0 ? 1 : 1) /* DEBUG */
                    .style('fill', collectionColor)
                ;

                itemGroup.append('text')
                    .attr('x', itemTitleIsOneWord ? -2 : 7)
                    .attr('y', itemTitleIsOneWord ? 14 : 0)
                    .style('font', '8px "Lato", sans-serif') // tient compte du scale de la collection : 1.5 --> 12px
                    .style('fill', "#F8F8F8")
                    .style('opacity', 0.4)
                    .text(itemTitle)
                    .call(wrap, 60);

                itemGroup.call(function (d) { t.animateItems.call(t, d, 0)} );

                itemGroup.on("mouseover", function() {
                    t.hiliteItem.call(t, itemGroup, true);
                });

                itemGroup.on("mouseout", function() {
                    t.hiliteItem.call(t, itemGroup, false);
                });

                itemGroup.on("click", function() {
                    t.openItemVisualisation.call(t, itemGroup);
                });

                // itemAngle += 2.4 * itemEndAngle * itemDirection;

            }
        });

        // Drag and Drop (via le zoom)
        this.zoomBehavior = d3.zoom();
        this.centerElement
            .call(this.zoomBehavior
                .extent([[0,0],[300,300]])
                .on("zoom", function (event) {
                    parentVisualization.attr("transform", "translate(" + event.transform.x + "," +  event.transform.y + ")");
                })
            )
            .on("wheel.zoom", null);

        // Zoom via la molette
        this.centerElement.on("wheel", function(event) {
            t.zoomWheel(event);
        });
    },

    initCollectionsData: function () {
        this.collections = [];
    },

    clearSVG: function () {

        if (this.collectionsGroups) {

            this.collectionsGroups
                .selectAll('g.items')
                .remove();

            this.collectionsGroups
                .selectAll('line.separator-line')
                .remove();

            this.collectionsGroups
                .selectAll('line.center-line')
                .remove();

            this.collectionsGroups
                .selectAll('text.collection-title')
                .remove();

            this.collectionsGroups
                .selectAll('g.collection')
                .remove();

            const collectionsSelector = this.collectionsGroups
                .selectAll('g')
                .data(this.collections);

            collectionsSelector.exit().remove();

            this.svg
                .selectAll('g.parent')
                .remove();

            this.svg.remove();
        }
    },

    clear: function () {
        if (this.simulation) {
            this.simulation.stop();
        }

        this.initCollectionsData();
        this.clearSVG();
    },

    openItemVisualisation: function ( itemGroup ) {
        this.dispatchEventToOpenItem( itemGroup.attr("data-item-id") );
    },

    dispatchEventToOpenItem: function(itemId) {

        if (! this.parentElement) return;

        this.parentElement.dispatchEvent( new CustomEvent( "itemClicked", {
            bubbles: true,
            cancelable: true,
            detail: {
                itemId: itemId
            }
        }));

    },

    hiliteItem: function ( itemGroup, isActive ) {

        const collectionId = itemGroup.attr("data-collection-id");
        const collection = this.getCollectionById(collectionId);
        if (collection.active || this.isZoomed() )
        {
            collection.itemsElement.selectAll('.itemGroup')
                .attr('cursor', 'default')
                .attr('opacity', 0.75)
                .select('text')
                .style('fill', "#F8F8F8")
                .style('opacity', 0.4);

            if (isActive === true)
            {
                itemGroup
                    .attr('cursor', 'pointer')
                    .attr('opacity', 1)
                    .select('text')
                    .style('fill', "#FFFFFF")
                    .style('opacity', 1);
            }
        }
    },

    resize: function ( newWidth, newHeight ) {

        this.width = newWidth;
        this.height = newHeight;

        if (this.centerElement)
        {
            this.centerElement.select('rect.zoom-event-target')
                .attr('width', this.width)
                .attr('height', this.height)
                .attr('x', -this.width/2)
                .attr('y', -this.height/2);
        }

        const t = this;

        if (this.collectionsGroups)
        {
            this.collectionsGroups
                .call(function (d) { t.computeEllipsis.call(t, d)} )
                .call(function (d) { t.updateEllipsis.call(t, d)} );
        }
    },

    computeProportions: function() {

        // RQ : les calculs de répartition des collections dans l'espace
        // ne dépendent pas de la largeur/hauteur de la page

        const deux_pi = 2 * Math.PI;

        let i, n = this.collections.length, collection;
        let proportionAngle = 0;

        let itemsTotal = 0;
        for (i=0;i<n;i++)
        {
            collection = this.collections[i];
            if (collection.visible !== false) {
                itemsTotal += collection.total;
            }
        }

        // On calcule les angles de chacune des collections
        let minProportion = 0.075, maxProportion = 0, maxProportionIndex = 0, removeFromMaxProportion = 0;
        for (i=0;i<n;i++)
        {
            collection = this.collections[i];
            if (collection.visible !== false) {

                collection.proportion = collection.total / itemsTotal;

                if( collection.proportion > maxProportion){
                    maxProportion = collection.proportion;
                    maxProportionIndex = i;
                }

                if (collection.proportion < minProportion){
                    removeFromMaxProportion += minProportion - collection.proportion;
                    collection.proportion = minProportion;
                }
            }
        }

        this.collections[maxProportionIndex].proportion -= removeFromMaxProportion;

        let cumulAngle = 0;
        let angleOffset = Math.PI;

        for (i=0;i<n;i++)
        {
            collection = this.collections[i];

            if (collection.visible !== false)
            {
                proportionAngle = collection.proportion * deux_pi;

                cumulAngle += proportionAngle;

                collection.angle = cumulAngle;
                collection.ellipsisMidAngle   = cumulAngle - proportionAngle / 2;
                collection.ellipsisStartAngle = cumulAngle - deux_pi / 100;
                collection.ellipsisEndAngle   = cumulAngle - proportionAngle + deux_pi / 100;

                // On décale les angles pour avoir le premier segment à gauche
                collection.angle += angleOffset;
                collection.ellipsisMidAngle   += angleOffset;
                collection.ellipsisStartAngle += angleOffset;
                collection.ellipsisEndAngle   += angleOffset;
            }
        }
    },

    // Calcule la position des items de chaque collection

    computeEllipsis: function (selection) {

        // Position du centre de la visualisation
        this.cX = this.width/2;
        this.cY = this.height/2;

        // Rayons de l'ellipse
        this.innerRadiusX = this.cX * 0.45;
        this.innerRadiusY = this.cY * 0.45;

        const innerRadiusX = this.innerRadiusX;
        const innerRadiusY = this.innerRadiusY;

        const t = this;
        const deux_pi = 2 * Math.PI;

        let collectionX, collectionY;
        let ellipsisMidAngle;
        let itemsParameters;

        // Nodes pour simulation D3 : répulsion
        let nodes = [];

        selection.each(function (collection) {

            // angle = collection.angle;
            // endAngleX = innerRadiusX * Math.cos(angle);
            // endAngleY = innerRadiusY * Math.sin(angle);
            // ellipsisStartAngle = collection.ellipsisStartAngle;
            // ellipsisEndAngle   = collection.ellipsisEndAngle;

            ellipsisMidAngle   = collection.ellipsisMidAngle;

            collectionX = collection.x = innerRadiusX * Math.cos(ellipsisMidAngle);
            collectionY = collection.y = innerRadiusY * Math.sin(ellipsisMidAngle);

            /*
            ellipsisStartX = innerRadiusX * Math.cos(ellipsisStartAngle);
            ellipsisStartY = innerRadiusY * Math.sin(ellipsisStartAngle);
            ellipsisEndX = innerRadiusX * Math.cos(ellipsisEndAngle);
            ellipsisEndY = innerRadiusY * Math.sin(ellipsisEndAngle);
             */

            nodes.push({
                id: nodes.length,
                x: collectionX,
                y: collectionY,
                index: nodes.length
            });


            // Items de la collection

            let j, itemsCount = collection.total;

            const itemSize = t.itemSize;
            const collectionCircleRadius = t.circleRadius * t.circleScale;

            let itemRadius, itemRadiusActive;

            itemRadius = collectionCircleRadius + itemSize * 4;

            let itemAngle, itemEndAngle;
            let itemX, itemY;

            // Les items se répartissent autour de l'angle médian
            let itemEllipsisStartAngle = ellipsisMidAngle - 0.60 * deux_pi / 2;
            let itemEllipsisEndAngle = ellipsisMidAngle + 0.60 * deux_pi / 2;

            itemRadiusActive = collectionCircleRadius + 60 + itemSize * 2;

            let itemAngleActive;
            let itemXActive, itemYActive;


            if ( ! collection.items ) {
                collection.items = [];
            }

            let no = 0, rangNo = 0;
            let ecart = 5;

            for (j=0;j<itemsCount;j++) {

                itemEndAngle = Math.asin(itemSize / itemRadius);

                // Pour centrer les items autour de l'angle médian ( et non en partant d'un côté)
                // on alterne le positionnement des items : un à droite, un à gauche, etc...

                if ( j % 2 === 0 ) {

                    itemAngle = ellipsisMidAngle + ecart * no / 2 * itemEndAngle;

                    if (itemAngle > (itemEllipsisEndAngle -  Math.sqrt(rangNo * 0.2) - 2 * itemEndAngle ) ) {
                        rangNo++;
                        ecart += 0.25;
                        itemRadius += itemSize * (5 + rangNo);
                        itemEndAngle = Math.asin(itemSize / itemRadius);
                        no = 0;
                        itemAngle = ellipsisMidAngle + ecart * no / 2 * itemEndAngle;
                    }

                    no++;

                } else {

                    itemAngle = ellipsisMidAngle - ecart * (no + 1) / 2 * itemEndAngle;

                    if (itemAngle < itemEllipsisStartAngle + Math.sqrt(rangNo * 0.2) + 2 * itemEndAngle) {
                        rangNo++;
                        ecart += 0.25;
                        itemRadius += itemSize * (5 + rangNo);
                        itemEndAngle = Math.asin(itemSize / itemRadius);
                        no = 0;
                        itemAngle = ellipsisMidAngle - ecart * (no + 1) / 2 * itemEndAngle;
                    }

                    no++;
                }

                // Pour ne pas avoir un alignement sur un cercle, on applique une sinusoïde aux positions des items

                // A. Version par défaut ( réduite )

                let sinus = 1 - 0.1 * Math.sin( (itemAngle - ellipsisMidAngle) * 10 * Math.sqrt(rangNo * 0.5 ) );
                let radius = ( itemRadius + rangNo * 4 ) * sinus;

                itemX = Math.floor(radius * Math.cos(itemAngle));
                itemY = Math.floor(radius * Math.sin(itemAngle));

                // B. Version active ( déployée, avec les titres visibles )

                let sinusActive = 1 - 0.35 * Math.sin( (itemAngle - ellipsisMidAngle) * 10 * Math.sqrt((rangNo + 1) * 0.5 ) );
                let radiusActive = ( itemRadius + rangNo * 20 ) * sinusActive ;

                itemRadiusActive = rangNo === 0 ? radiusActive + 60 : radiusActive + 100;
                itemAngleActive = ellipsisMidAngle - ( ellipsisMidAngle - itemAngle ) / (rangNo === 0 ? 1.25 : 1.65);

                itemXActive = Math.floor(itemRadiusActive * Math.cos(itemAngleActive));
                itemYActive = Math.floor(itemRadiusActive * Math.sin(itemAngleActive));


                if (! collection.items[j]) {
                    collection.items[j] = {};
                }

                itemsParameters = collection.items[j];
                itemsParameters.itemX = itemX;
                itemsParameters.itemY = itemY;
                itemsParameters.itemXActive = itemXActive;
                itemsParameters.itemYActive = itemYActive;

                nodes.push({
                    id: nodes.length,
                    index: nodes.length,
                    collectionX : collectionX,
                    collectionY : collectionY,
                    x: collectionX + 1.85 * (itemXActive + 30),
                    y: collectionY + 1.85 * (itemYActive + 30),
                    item: itemsParameters
                });

            }
        });

        this.simulation = d3.forceSimulation();

        const forceCollide = d3.forceCollide()
            .radius(function(d){
                return d.item ? 50 : 90;
            })
            .iterations(3);

        this.simulation
            .nodes(nodes)
            .force("collide", forceCollide)
            .alphaTarget(0)
            .restart();

        let nbTicks = 0;

        this.simulation.on('tick', function () {
            let i, n = nodes.length, node;
            for(i=0;i<n;i++)
            {
                node = nodes[i];

                if (node.item) {

                    node.item.itemXActive = (node.x - node.collectionX) / 1.5 - 20;
                    node.item.itemYActive = (node.y - node.collectionY) / 1.5 - 20;

                    if (node.item.svg) {
                        node.item.svg
                            .attr("data-x-active", node.item.itemXActive)
                            .attr("data-y-active", node.item.itemYActive)
                        // .attr("transform", "translate("+ node.item.itemXActive + ", " + node.item.itemYActive + ")")
                    }

                    if (i === 0) {
                        console.log(i, node.item.svg ? 1 : 0, node.x, node.y);
                    }

                }
            }

            nbTicks++;

            if (nbTicks > 15) {
                t.simulation.stop();
            }
        });

    },

    // Ajuste les positions des cercles et traits des collections ( suite à resize par exemple )
    updateEllipsis: function (selection) {

        // Les traits sont plus ou moins longs selon la largeur de l'écran
        const lineProportion = this.getCategoryLineRadiusPercentage();
        const titleProportion = this.getCategoryTitleRadiusPercentage();

        this.centerElement
            .attr("transform", "translate("+this.cX+","+this.cY+")");

        const t = this;

        selection.each(function (collection) {

            const collectionAngle = collection.angle;
            const collectionX = collection.x;
            const collectionY = collection.y;

            const element = d3.select(this);

            element.select('.separator-line')
                .attr('x2', t.innerRadiusX * Math.cos(collectionAngle))
                .attr('y2', t.innerRadiusY * Math.sin(collectionAngle));

            element.selectAll('.center-line')
                .attr('x2', collectionX * lineProportion)
                .attr('y2', collectionY * lineProportion);

            element.selectAll('.collection-center')
                .attr("transform", "translate("+ collectionX +","+ collectionY +")");

            element.selectAll('.collection-title')
                .attr('x', collectionX * titleProportion )
                .attr('y', collectionY * titleProportion);

            element.selectAll('.items-parent')
                .attr("transform", "translate("+ collectionX +","+ collectionY +")");

        });
    },

    getCategoryLineRadiusPercentage: function () {
        return this.width < 1000 ? 0.4 : 0.5;
    },

    getCategoryTitleRadiusPercentage: function () {
        return this.width < 1000 ? 0.58 : 0.66;
    },

    animateUpdateEllipsis: function (selection) {

        // Les traits sont plus ou moins longs selon la largeur de l'écran
        const lineProportion = this.getCategoryLineRadiusPercentage();
        const titleProportion = this.getCategoryTitleRadiusPercentage();

        const t = this;

        selection.each(function (collection) {

            const collectionAngle = collection.angle;
            const collectionX = collection.x;
            const collectionY = collection.y;

            const element = d3.select(this);
            const easing = d3.easeCubicInOut;
            const duration = 600;

            element.select('.separator-line')
                .transition()
                .ease(easing)
                .duration(duration)
                .attr('x2', t.innerRadiusX * Math.cos(collectionAngle))
                .attr('y2', t.innerRadiusY * Math.sin(collectionAngle));

            element.selectAll('.center-line')
                .transition()
                .ease(easing)
                .duration(duration)
                .attr('x2', collectionX * lineProportion)
                .attr('y2', collectionY * lineProportion);

            element.selectAll('.collection-center')
                .transition()
                .ease(easing)
                .duration(duration)
                .attr("transform", "translate("+ collectionX +","+ collectionY +")");

            element.selectAll('.collection-title')
                .transition()
                .ease(easing)
                .duration(duration)
                .attr('x', collectionX * titleProportion )
                .attr('y', collectionY * titleProportion );

            element.selectAll('.items-parent')
                .transition()
                .ease(easing)
                .duration(duration)
                .attr("transform", "translate("+ collectionX +","+ collectionY +")");

        });
    },

    applyZoom: function ( duration, delay ) {

        const t = this;

        this.centerElement.selectAll('g.itemGroup')
            .call(function (d) { t.zoomItems.call(t, d, duration, delay); } );

        this.dispatchEventForZoomChange();
    },

    zoomIn: function () {
        this.zoom = Math.min(this.zoom + 1, this.zoomMax);
        this.applyZoom(100);
    },

    zoomOut: function () {
        this.zoom = Math.max(this.zoom - 1, this.zoomMin);
        this.applyZoom(100);
    },

    zoomLevelIsMax: function () {
        return this.zoom === this.zoomMax;
    },

    zoomLevelIsMin: function () {
        return this.zoom === this.zoomMin;
    },

    isZoomed: function() {
        return this.zoom > this.zoomMin;
    },

    zoomWheel: function(event) {
        this.zoom -= event.deltaY * 0.1;
        this.zoom = Math.max(this.zoomMin, Math.min(this.zoomMax, this.zoom ));
        this.applyZoom(100);
    },

    zoomReset: function () {

        this.zoom = this.zoomMin;
        this.activeCollectionId = null;

        const n = this.collections.length;
        let i;
        for(i=0;i<n;i++) {
            this.collections[i].active = false;
        }

        // Effet de zoom sur les points
        this.applyZoom(500, 200);

        // Reset du drag and drop du parent l'ensemble
        this.centerElement.transition().ease(d3.easeCubicInOut).duration(750).call(this.zoomBehavior.transform, d3.zoomIdentity);
    },

    filterCollection: function (collectionId, visible) {

        const t = this;

        const collection = this.getCollectionById ( collectionId );
        collection.visible = visible;

        const collectionElement = this.centerElement.select('.collection' + collectionId);

        collectionElement
            .style('visibility', collection.visible ? 'visible' : 'hidden');

        if (collection.visible !== false)
        {
            collectionElement
                .attr("opacity", 0)
                .transition()
                .duration(800)
                .delay(400)
                .ease(d3.easeCubicOut)
                .attr("opacity", 1);
        }

        this.computeProportions();

        this.collectionsGroups
            .call(function (d) { t.computeEllipsis.call(t, d)} )
            .call(function (d) { t.animateUpdateEllipsis.call(t, d)} );

        this.centerElement.selectAll('g.itemGroup')
            .call(function (d) { t.updateItemsAttributes.call(t, d); } )
            .call(function (d) { t.animateItems.call(t, d); } );


    },

    activeCollection: function (collectionId) {

        this.activeCollectionId = collectionId;

        const t = this;
        const n = this.collections.length;
        let i;
        for(i=0;i<n;i++) {
            this.collections[i].active = false;
        }

        const collection = this.getCollectionById ( collectionId );
        collection.active = true;

        // Reset du drag and drop du parent l'ensemble
        this.centerElement.transition().ease(d3.easeCubicInOut).duration(750).call(this.zoomBehavior.transform, d3.zoomIdentity.translate(- collection.x, -collection.y));

        this.centerElement.selectAll('g.items')
            .transition()
            .duration(200)
            .attr("transform", "scale(1)" );

        collection.itemsElement
            .transition()
            .duration(200)
            .attr("transform", "scale(1.5)" );

        this.centerElement.selectAll('g.itemGroup').call(function (d) { t.animateItems.call(t, d); } );

        this.dispatchEventForZoomChange();
    },

    updateItemsAttributes: function (selection) {

        selection.each(function (collection) {

            const itemElement = d3.select(this);
            const itemIndex = parseInt( itemElement.attr("data-item-index") );
            const itemsParameters = collection.items[itemIndex];

            itemElement
                .attr("data-x", itemsParameters.itemX)
                .attr("data-y", itemsParameters.itemY)
                .attr("data-x-active", itemsParameters.itemXActive)
                .attr("data-y-active", itemsParameters.itemYActive);
        });

    },

    animateItems: function (selection, transitionDuration) {

        selection.each(function (collection) {

            if (collection.visible !== false) {

                let itemElement = d3.select(this);
                let itemX, itemY, titleActive, easing, duration, delay;

                if (collection.active) {
                    itemX = itemElement.attr("data-x-active");
                    itemY = itemElement.attr("data-y-active");
                    titleActive = "visible";
                    easing = d3.easeElastic;
                    duration = 1600;
                    delay = 200 * Math.random();
                } else {
                    itemX = itemElement.attr("data-x");
                    itemY = itemElement.attr("data-y");
                    titleActive = "hidden";
                    easing = d3.easeCubicInOut;
                    duration = isNaN(transitionDuration) ? 600 :  transitionDuration;
                    delay = 0;
                }

                itemElement
                    .transition()
                    .duration(duration)
                    .delay(delay)
                    .ease(easing)
                    .attr("transform", "translate(" + itemX + "," + itemY + ")")
                    .selectAll('text').attr('visibility', titleActive);
            }
        });
    },

    zoomItems: function (selection, transitionDuration, transitionDelay) {

        if (transitionDuration === undefined) {
            transitionDuration = 0;
        }

        if (transitionDelay === undefined) {
            transitionDelay = 0;
        }

        const easing = d3.easeCubicInOut;

        const percent = (this.zoom - this.zoomMin) / (this.zoomMax - this.zoomMin);
        let titleActive = this.zoom > this.zoomMax - 1 ? "visible" : "hidden";

        const scale = 1 + 0.5 * percent;

        this.centerElement.selectAll('g.items')
            .transition()
            .duration(transitionDuration)
            .delay(transitionDelay)
            .ease(easing)
            .attr("transform", "scale("+ scale +")" );

        // console.log("this.zoom", this.zoom, percent);

        selection.each(function (collection) {

            if (collection.visible !== false) {

                let itemElement = d3.select(this);

                let itemActiveX = parseInt( itemElement.attr("data-x-active"));
                let itemActiveY = parseInt( itemElement.attr("data-y-active"));

                let itemX = parseInt( itemElement.attr("data-x"));
                let itemY = parseInt( itemElement.attr("data-y"));

                let itemZoomX = itemX + (itemActiveX - itemX) * percent;
                let itemZoomY = itemY + (itemActiveY - itemY) * percent;

                itemElement
                    .transition()
                    .duration(transitionDuration)
                    .delay(transitionDelay)
                    .ease(easing)
                    .attr("transform", "translate(" + itemZoomX + "," + itemZoomY + ")")
                    .selectAll('text').attr('visibility', titleActive);
            }
        });
    },

    dispatchEventForZoomChange: function() {

        if (! this.svg) return;

        this.svg.dispatch( "zoomChanged", {
            bubbles: true,
            cancelable: true,
            detail: {
                zoomed: this.isZoomed(),
                activeCollectionId: this.activeCollectionId
            }
        });

    },

    getCollectionCircleId: function(collection) {
        return "circle-category" + collection.id;
    },

    addIconsForCategoriesInSVGDefinitions: function(collection) {

        const collectionName = this.getCollectionCircleId(collection);

        let svgIcon, svgIconContent, svgIconPattern;

        const circleRadius = this.circleRadius;
        const circleScale = this.circleScale;
        const circlePosition = circleRadius * circleScale;
        const circleRadiusTransform = "translate(-" + circlePosition + ",-" + circlePosition + ") scale("+ circleScale +", " + circleScale + ")";
        const circleRadiusTransformOrigin = "50%, 50%";

        svgIcon = this.defs.append('g');
        svgIcon.attr('id', collectionName);

        svgIconPattern = svgIcon
            .append('pattern')
            .attr("patternUnits", "userSpaceOnUse")
            .attr("width", "67")
            .attr("height", "67")
            .attr("id", collectionName + "-pattern")

        svgIconPattern
            .append('image')
            .attr("width", "67")
            .attr("height", "67")
            .attr("xlink:href", collection.icon)

        svgIconContent = svgIcon
            .append('g')
            .attr("transform-origin", circleRadiusTransformOrigin)
            .attr("transform", circleRadiusTransform);

        svgIconContent
            .append("circle")
            .attr('fill', collection.color)
            .attr('cx', circleRadius)
            .attr('cy', circleRadius)
            .attr('r', circleRadius);

        svgIconContent
            .append("rect")
            .attr("width", "67")
            .attr("height", "67")
            .attr('fill', "url(#" + collectionName + "-pattern)")

    },
});

export {VizCategories}