<template>
  <div class="account-items-panel" :class="panelOpenedCssClass">
    <div class="account-items-header">
      <a href="#" @click="toggleClose($event)">
        <h3>{{ $t("Mon panier de notices") }}</h3>
        <div class="account-items-close"></div>
      </a>
    </div>
    <div class="account-items" :class="accountItemsCssClass">
      <div v-if="basketItems.length > 0">
        <div class="account-columns-items">
          <div class="account-column-title">{{ $t("Titre de la notice") }}</div>
          <div class="account-column-date">{{ $t("Date de l’ajout") }}</div>
          <div class="account-item-tools"></div>
        </div>
        <div>
          <div class="account-item" v-for="item in basketItems" v-bind:key="item.id">
            <div class="account-item-title list-item" :class="listItemCssClass(item)">
              <router-link class="item-title" :to="itemRoute(item)">
                <span>{{ item.title }}</span>
              </router-link>
            </div>
            <div class="account-item-date">{{ basketDate(item) }}</div>
            <div class="account-item-tools buttons">
              <a class="account-item-delete-tool button delete-icon"
                 href="#"
                 @click="removeItemFromBasket($event, item)"
              >
              </a>
              <NoticeDownloadLink
                      class="account-item-download-tool button download-icon"
                      linkLabel=""
                      output="txt"
                      :resourceId="item.id"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="account-column-warning">{{ $t("Votre panier est vide") }}</div>
    </div>

  </div>
</template>

<script>

  import NoticeDownloadLink from "../Notices/NoticeDownloadLink";

  export default {
    name: 'UserBasket',
    components: {
      NoticeDownloadLink
    },
    props: {
      opened: {
        type: Boolean,
        required: true
      },
    },
    emits:[
      'toggle-basket-panel'
    ],
    computed: {
      panelOpenedCssClass() {
        return this.opened ? "account-items-opened" : "";
      },
      basketItems() {
        return this.$store.state.basket;
      },
      accountItemsCssClass() {
        return this.basketItems.length > 0 ? "" : "is-empty";
      }
    },
    methods: {
      toggleClose($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.$emit('toggle-basket-panel')
      },
      basketDate(basketItem) {
        const dateJS = basketItem.created;
        if (dateJS)
        {
          const fullYear = dateJS.getFullYear();

          let day = dateJS.getDate();
          if (day < 10 ) {
            day = "0" + day;
          }

          let month = dateJS.getMonth() + 1;
          if (month < 10 ) {
            month = "0" + month;
          }
          return day + "/" + month + "/" + fullYear;
        }
        return "";
      },
      removeItemFromBasket($event, item) {
        $event.preventDefault();
        // Ici item est une référence à la sélection ( selection_resources sur le serveur) : selectionId, created
        // les infos sont complétées par les champs de l'item-omeka : id, title, route, type, encyclocategoryRef ( cf main.js )
        this.$store.dispatch( "removeFromBasket" , { type: item.type, id: parseInt(item.id), selectionId: item.selectionId } );
      },
      listItemCssClass(item) {
        return item.colorNo ? "list-item-category-" + item.colorNo : "";
      },
      itemRoute(item) {
        return item.route + "/item/" + item.id;
      }
    }
  }

</script>
