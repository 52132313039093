<template>
  <div>
    <iframe height="100%" width=100% :src="getFilePath" ></iframe>
  </div>
</template>

<script>

  export default {
    name: 'PDFJSViewer',
    props: {
      fileName: String,
      path:String
    },
    computed:{
      getFilePath() {
        return this.$store.state.serverPath + this.path +'?file=' + this.fileName
      }
    }
  }

</script>
<style scoped>
  div {
    height: 70vh;
    min-width: 400px;
  }
</style>