
function JsonReader(json, resourceTemplateLabels) {
    this.json = json;
    this.labelsByTerm = resourceTemplateLabels;
}

Object.assign( JsonReader.prototype, {

    get: function(jsonFieldName) {
        return this.json[jsonFieldName];
    },

    set: function(jsonFieldName, json) {
        if (Array.isArray(this.json[jsonFieldName]) && Array.isArray(json)) {
            this.json[jsonFieldName] = this.json[jsonFieldName].concat(json);
        } else {
            this.json[jsonFieldName] = json;
        }
    },

    has: function(jsonFieldName) {
        return this.json[jsonFieldName] !== undefined;
    },

    resourceTemplateHas: function(term) {
        return this.labelsByTerm && this.labelsByTerm[term] !== undefined;
    },

    getAsNumber: function(jsonFieldName) {
        return parseInt(this.json[jsonFieldName]);
    },

    isSingleLiteral: function (jsonFieldName) {
        if (this.json) {
            const fields = this.json[jsonFieldName];
            if (fields) {
                const n = fields.length;
                if (n === 1) {
                    return fields[0]['type'] === 'literal'
                }
            }
        }
        return false;
    },

    isArrayLiteral: function (jsonFieldName) {
        if (this.json) {
            const fields = this.json[jsonFieldName];
            if (fields) {
                const n = fields.length;
                let i, type;
                for(i=0;i<n;i++) {
                    type = fields[0]['type'];
                    if ((type !== 'literal') && (type !== 'html')) {
                        return false;
                    }
                }
            }
        }
        return true;
    },

    getObjectFieldValues: function (jsonFieldName, valuePropertyName = "@value", idPropertyName = "o:id") {
        let values = [];
        if (this.json) {
            const fields = this.json[jsonFieldName];
            if (fields) {
                const n = fields.length;
                let i, value, id, type, locale;
                for(i=0; i<n; i++) {
                    id = fields[i][idPropertyName];
                    value = fields[i][valuePropertyName];
                    type = fields[i]['type'];

                    // If data from items api, it doen't have an 'type" propoerty
                    // Used when updating authors notice with their linked notices & articles (via dcterms-creator)
                    if ((type === undefined) && id) {
                        type = 'resource:item';
                    }

                    // Ex : dcterms:sources (without label)
                    if ((type === 'uri') && ! value && id ) {
                        value = id;
                    }

                    if (value && !id) {
                        id = value;
                    }

                    if (value && id) {

                        let valueObj = {
                            id,
                            value,
                            type,
                            fieldName: jsonFieldName,
                            propertyName: valuePropertyName
                        };

                        locale = fields[i]['@language'];
                        if (locale) {
                            valueObj.language = locale.substring(0, 2).toLowerCase();
                        }

                        values.push(valueObj);
                    }
                }
            }
        }
        return values;
    },

    getFieldValue: function (jsonFieldName, valuePropertyName = "@value", transformFunction = null) {
        if (this.json) {
            const field = this.json[jsonFieldName];
            if (field) {
                const value = field[valuePropertyName];
                return transformFunction ? transformFunction(value) : value;
            } else {
                console.error("[JSONReader] Ce champ n'existe pas : ", jsonFieldName);
            }
        }
    },

    getLiteralFieldValues: function (jsonFieldName, valuePropertyName = "@value", transformFunction = null) {
        let values = [];
        if (this.json) {
            const fields = this.json[jsonFieldName];
            // console.log('getLiteralFieldValues', jsonFieldName, valuePropertyName, this.json);
            if (fields) {
                const n = fields.length;
                let i, value, locale;
                for(i=0; i<n; i++) {
                    value = fields[i][valuePropertyName];
                    if (value) {
                        locale = fields[i]['@language'];
                        if (locale) {
                            locale = locale.substring(0, 2).toLowerCase();
                        }
                        values.push({
                            type: "literal",
                            language: locale,
                            value: transformFunction ? transformFunction(value) : value
                        });
                    }
                }
            }
        }
        return values;
    },

    getLiteralFieldStringValues: function (jsonFieldName, valuePropertyName= "@value") {
        const values = this.getLiteralFieldValues(jsonFieldName, valuePropertyName);
        const n = values.length, stringValues = [];
        let i;
        for(i=0;i<n;i++) {
            stringValues.push(values[i].value);
        }
        return stringValues;
    },

    getLiteralFirstValue: function (jsonFieldName, valuePropertyName= "@value", defaultValue = "") {
        const values = this.getLiteralFieldValues(jsonFieldName, valuePropertyName);
        return values.length > 0 ? values[0].value : defaultValue;
    },

    getThumbnailURL: function (size){
        if (this.json) {
            const appendProperties = this.json['o-module-api-info:append'];
            if (appendProperties) {
                // Thumbnail
                const thumbnailProperties = appendProperties['o:thumbnail'];
                if (thumbnailProperties) {
                    return thumbnailProperties['o:asset_url'];
                }
                // Image
                const medias = appendProperties['o:media'];
                if (medias && medias.length > 0) {
                    const firstMedia = medias[0];
                    const thumbnails = firstMedia['o:thumbnail_urls'];
                    if (thumbnails && thumbnails[size]) {
                        return thumbnails[size];
                    }
                }
            }
        }
        return "";
    },

    getMetaDataLabel: function (metadata, defaultValue = '') {
        return this.resourceTemplateTermLabel(metadata, defaultValue);
    },

    getMetaDataFirstValue: function (metadata, defaultValue = '') {
        const values = this.getMetaDataValue(metadata);
        if (values) {
            if (Array.isArray(values) && (values.length > 0) && values[0].value) {
                return values[0].value;
            } else if ((typeof values === 'string') && values.length) {
                return values;
            }
        }
        return defaultValue;
    },

    getLocalizedMetaDataValue: function (metadata, language, defaultValue = '') {
        const values = this.getMetaDataValue(metadata);
        if (values) {
            let value;
            if (Array.isArray(values) ) {
                let n = values.length, valueObj, valueLanguage;
                for (let i = 0; i < n; i++) {
                    valueObj = values[i];
                    valueLanguage = valueObj.language || valueObj['@language'];
                    if (valueLanguage && (valueLanguage.substr(0,2).toLowerCase() === language)) {
                        value = valueObj.value;
                    }
                }
                // If there is no localized value, takes the first value :
                if (! value && (n > 0)) {
                    value = values[0].value;
                }

                return value !== undefined ? value : defaultValue;

            } else if ((typeof values === 'string') && (values.length)) {
                return values;
            }
        }
        return defaultValue;
    },

    getMetaDataValue: function (metadata) {

        switch(metadata) {

            // Cas particuliers :

            // Dates
            case 'o:created':
            case 'o:modified':
            case 'dcterms:issued':
            case 'bio:birth':
            case 'bio:death':
                return this.formatDate(this.getLiteralFirstValue(metadata));

            // Tableau de dates
            case 'dcterms:date':
                return this.getLiteralFieldValues(metadata, '@value', this.formatDate );

            // Liste de liens (on exclut les valeurs de type 'literal')
            case 'dcterms:subject':
            case 'dcterms:requires':
            case 'dcterms:isRequiredBy':
            case 'dcterms:relation':
            case 'extracttext:extracted_text':
            case 'dcterms:isPartOf':
            case 'dcterms:hasPart':
            case 'foaf:member':
            case 'foaf:maker':
            case 'foaf:membershipClass':
            case 'bibo:uri':
            case 'dcterms:references':
            case 'bibo:cites':
                return [].concat(
                    this.getObjectFieldValues(metadata, "display_title", "value_resource_id"),
                    this.getObjectFieldValues(metadata, 'o:label', '@id'),
                    this.getObjectFieldValues(metadata, 'o:title', 'o:id'));

            // RQ : o:title + o:id is used when updating authors notice with their linked notices & articles (via dcterms-creator) retrieved from items api

            // Cas général :
            case 'dcterms:description':
            case 'dcterms:spatial':
            case 'dcterms:temporal':
            case 'dcterms:publisher':
            case 'bibo:pages':
            case 'foaf:topic_interest':
            case 'dcterms:abstract':
            case 'dcterms:type':
            case 'dcterms:provenance':
            case 'dcterms:language':
            case 'dcterms:format':
            case 'dcterms:rightsHolder':
            case 'dcterms:contributor':
            case 'bibo:numPages':
            case 'bibo:locator':
            case 'bibo:shortDescription':
            case 'bibo:volume':
            case 'bibo:issue':
            case 'bibo:page':
            case 'foaf:firstName':
            case 'foaf:family_name':
            case 'foaf:gender':
            case 'bio:parent':
            case 'dcterms:title':
            case 'dcterms:alternative':
            case 'dcterms:creator':
            default:

                if (this.isSingleLiteral(metadata)) {
                    var textValue = this.getLiteralFirstValue(metadata) || this.getLiteralFirstValue(metadata, "display_title");
                    if (textValue && textValue.length) {
                        // Simple valeur textuelle
                        return textValue;
                    }
                } else if (this.isArrayLiteral(metadata)) {
                    // Liste de valeurs textuelles
                    return [].concat(
                        this.getLiteralFieldValues(metadata, '@value'),
                        this.getLiteralFieldValues(metadata, 'display_title'),
                        this.getLiteralFieldValues(metadata, 'o:label'));
                } else {
                    // Liens (objet : label/value)
                    return [].concat(
                        this.getLiteralFieldValues(metadata, '@value'),
                        this.getObjectFieldValues(metadata, "display_title", "value_resource_id"),
                        this.getObjectFieldValues(metadata, 'o:label', '@id')
                    );
                }
        }
    },

    getMetaDataTitle: function () {
        return this.resourceTemplateTitle;
    },

    // Tableau associatif des terms du modèle de ressources (term -> label alternatif )
    resourceTemplateTermLabel: function (term, defaultValue = '') {
        if (this.labelsByTerm) {
            if (this.labelsByTerm[term]) return this.labelsByTerm[term];
        }
        return defaultValue;
    },

    formatDate: function(dateStr) {
        if ( dateStr.indexOf('-') !== -1 ) {
            return dateStr.split('-').reverse().join('/');
        }
        return dateStr.split('.').reverse().join('/');
    }

});

export { JsonReader }