<template>
  <div class="popup-annotation-item-edit-title">
    <div class="popup-annotation-item-edit-content">
      <input type="text"
             placeholder="Donnez un titre à votre sélection"
             v-model="title"
             @keyup="onKeyUpInput($event)"
             @input="onChangeInput($event)"
      />
      <div class="buttons">
        <button @click="onSaveInput()">Enregistrer</button>
        <button @click="onCancelInput()">Annuler</button>
      </div>
    </div>
    <div v-if="message.length" class="popup-annotation-item-edit-message">
      <span>{{ message }}</span>
      <a href="#" @click="validateMessage">Ok</a>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'VisualisationItem',
    emits: [
      "save-with-visualization-title",
      "validate-message",
      "hide-message",
      "change-visualization-title"
    ],
    data() {
      return {
        title: ""
      }
    },
    props: {
      message: {
        type: String,
        required: false,
        default:""
      }
    },
    methods: {
      onKeyUpInput($event) {
        if ($event.keyCode === 13 ) {
          $event.preventDefault();
          this.$emit("save-with-visualization-title", this.title);
        }
      },
      onSaveInput() {
        this.$emit("save-with-visualization-title", this.title);
      },
      onChangeInput() {
        this.$emit("change-visualization-title", this.title);
      },
      onCancelInput() {
        this.$emit("hide-message", this.title);
      },
      validateMessage($event) {
        $event.preventDefault();
        if (this.title.length > 0) {
          this.$emit("validate-message", this.title);
        }
      }
    }
  }

</script>
