<template>

  <ListNoticesCuratedCollectionView
          pageTitle="Sélections thématiques"
          itemSetsRouteName="Curated ItemSets"
          itemSetRouteName="Curated ItemSet"
          default-display-mode="list"
  />

</template>

<script>

  import ListNoticesCuratedCollectionView from "../components/Curation/ListNoticesCuratedCollectionView";

  export default {
    components: { ListNoticesCuratedCollectionView }
  }

</script>