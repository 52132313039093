<template>
  <div class="view">
    <div class="breadcrumbs-and-title">
      <div class="breadcrumbs">
        <BreadCrumb :paths="breadcrumbs" />
      </div>
      <h1>
        {{ $t(state.collectionTitle) }}
        <a
           v-if="state.collectionDescriptions"
           @click="openCollectionDescriptions($event)"
           class="collection-description-toggle"
           href="#"></a>
      </h1>
    </div>
    <div class="contents" :class="withPopUpCssClass">
      <div class="contents-wrapper" :class="state.displayModeCssClass">

        <div class="collection-header" v-if="state.collectionDescriptions">
          <CollectionDescription
            class="with-padding"
            :class="state.collectionDescriptionVisibleCssClass"
            :descriptions="state.collectionDescriptions"
          />
        </div>

        <div class="contents-header with-padding">
          <ul class="contents-mode">
            <li
                v-if="listDisplayModeAvailable"
                class="contents-list-mode"
            >
              <router-link :to="state.listItemSetRoute">
                {{ $t("Liste") }}
              </router-link>
            </li>
            <li
              v-if="galleryDisplayModeAvailable"
              class="contents-gallery-mode"
            >
              <router-link :to="state.galleryItemSetRoute">
                {{ $t("Vignettes") }}
              </router-link>
            </li>
            <li class="contents-notice-mode">
              <router-link :to="state.noticeItemRoute">
                {{ $t(state.noticeItemTabLabel) }}
              </router-link>
            </li>
          </ul>
        </div>

        <Loading class="notice-item-content with-padding no-header" v-if="state.isLoading" />

        <div v-if="! state.isLoading">

          <!-- Mode Vignette -->
          <div v-if="state.isGalleryDisplayMode">
            <CollectionGallery
                    :item-set-id="state.collectionId"
                    :pageNo="state.pageNo"
                    :itemsByPage="state.treeViewItemsByPage"
                    :itemsCount="state.itemsIds.length"
                    :itemRouteName="itemRouteName"
                    :noticeItemType="state.noticeItemType"
                    @click-item="clickItem"
                    @opendetailednotice="openDetailedNotice"
                    @downloaditemnotice="openDownloadNoticePopUp"
                    class="contents" />
          </div>

          <!-- Mode Tree -->
          <div v-if="state.isListDisplayMode" class="contents-list-mode-content">
            <div class="contents-list-mode-content">
              <TreeView
                      :item-set-id="state.collectionId"
                      :pageNo="state.treeViewPageNo"
                      :itemsByPage="state.treeViewItemsByPage"
                      :itemsCount="state.itemsIds.length"
                      :itemRouteName="itemRouteName"
                      :showSortOptions="false"
                      @opendetailednotice="openDetailedNotice"
                      @downloaditemnotice="openDownloadNoticePopUp"
              />
            </div>
          </div>

          <!-- Mode Notice -->
          <NoticesItems
                  v-if="state.isNoticeDisplayMode"
                  :noticeItemId="state.noticeItemId"
                  :noticeItemType="state.noticeItemType"
                  :itemsIds="state.itemsIds"
                  @opendetailednotice="openDetailedNotice"
                  @item-loaded="itemLoaded"
          />

        </div>

      </div>
    </div>

    <NoticePopUp
            v-if="detailedNoticeItemIsOpened"
            :itemId="detailedNoticeId"
            :itemType="detailedNoticeType"
            :class="detailedNoticeCssClass"
            :itemsIds="state.itemsIds"
            @close-detailed-item-notice="closeDetailedNotice"
    />

    <NoticeDownloadPopUp
            :itemId="state.popUpItemId"
            :itemType="state.popUpItemType"
            :class="downloadNoticePopupCssClass"
            @closedownloaditemnotice="closeDownloadNoticePopUp"
    />

  </div>

</template>

<script>

  import {computed, onMounted, reactive, watch} from 'vue'
  import CollectionGallery from "@/components/Lists/CollectionGallery";
  import NoticePopUp from "@/components/Notices/NoticePopUp";
  import NoticeDownloadPopUp from "@/components/Notices/NoticeDownloadPopUp";
  import BreadCrumb from "@/components/Header/BreadCrumb";
  import CollectionDescription from "@/components/Header/CollectionDescription";
  import TreeView from "@/components/Trees/TreeView";
  import NoticesItems from "@/components/Lists/NoticesItems";
  import Loading from "@/components/Loading";

  import {useStore} from "vuex";
  import {onBeforeRouteUpdate, onBeforeRouteLeave, useRoute, useRouter} from "vue-router";
  import {getCollectionsApiURL, getCollectionsItemIdsApiURL} from "@/js/api";
  import {getLocalizedValue} from "@/js/functions";

  const itemsByPage = 15;

  export default {
    name: 'GalleryTreeNoticesView',
    components: {
      CollectionDescription,
      CollectionGallery,
      TreeView,
      NoticesItems,
      NoticePopUp,
      NoticeDownloadPopUp,
      Loading,
      BreadCrumb
    },
    props: {
      pageTitle: {
        type: String,
        default: "",
        required: false
      },
      category: {
        type: String,
        required: false
      },
      itemSetId: {
        type: Number,
        required: false
      },
      itemSetRouteName: {
        type: String,
        required: false,
        default:'Collection'
      },
      itemRouteName: {
        type: String,
        required: false,
        default:'Item'
      },
      noticeItemId: {
        type: Number,
        required: false,
        default:0
      },
      noticeItemType: {
        type: String,
        required: false,
        default:'item'
      },
      defaultDisplayMode: {
        type: String,
        required: false,
        default:"list"
      },
      listDisplayModeAvailable: {
        type: Boolean,
        required: false,
        default:true
      },
      galleryDisplayModeAvailable: {
        type: Boolean,
        required: false,
        default:true
      },
    },
    data() {
      return {

        // Données de la popup éventuelle :
        detailedNoticeItemIsOpened:false,
        detailedNoticeId:null,
        detailedNoticeType:null,

        downloadNoticePopupIsOpened:false,
        downloadNoticeItemId:null,
        downloadNoticeItemType: this.noticeItemType,
      }
    },
    computed: {
      detailedNoticeCssClass() {
        return this.detailedNoticeItemIsOpened ? "opened" : "";
      },
      withPopUpCssClass() {
        return this.detailedNoticeItemIsOpened ? "with-popup" : "";
      },
      downloadNoticePopupCssClass() {
        return this.downloadNoticePopupIsOpened === true ? "opened" : "";
      },
      breadcrumbs() {
        if ((this.state.displayMode === "notice") && this.state.noticeItemTitle) {
          // Item
          return [
            { name: 'Home', label:'Accueil' },
            { name: this.itemSetRouteName, label:this.state.collectionTitle, query: this.state.listItemQuery },
            { label: this.state.noticeItemTitle }
          ]
        } else {
          // Collection
          return [
            { name: 'Home', label:'Accueil' },
            { label: this.state.collectionTitle }
          ]
        }
      }
    },
    methods: {
      clickItem(item) {
        this.$router.push({ name: this.itemRouteName, params: { id: item.itemId } })
      },
      openItem() {
      },
      openDetailedNotice(itemId) {
        this.detailedNoticeId = itemId;
        this.detailedNoticeType = this.noticeItemType;
        this.detailedNoticeItemIsOpened = true;
      },
      closeDetailedNotice() {
        this.detailedNoticeId = null;
        this.detailedNoticeType = null;
        this.detailedNoticeItemIsOpened = false;
      },
      openDownloadNoticePopUp(item) {
        this.downloadNoticePopupIsOpened = true;
        this.state.popUpItemId = item.id;
      },
      closeDownloadNoticePopUp() {
        this.downloadNoticePopupIsOpened = false;
      }
    },
    setup(props) {

      const route = useRoute();
      const router = useRouter();
      const store = useStore();

      let collectionId = parseInt(props.itemSetId);

      if (isNaN(collectionId)) {
        // TEMP
        const collectionsInfos = store.getters.collectionInfosByProp('name', props.category);
        if (collectionsInfos) {
          collectionId = collectionsInfos.id;
        }
      }

      const updateCollectionDescription = function() {
        state.collectionJson = store.getters.collectionJson(collectionId);
      };

      const openCollectionDescriptions = function($event) {
        $event.preventDefault();
        state.isCollectionDescriptionVisible = ! state.isCollectionDescriptionVisible;
      };

      // Loads all items :
      const loadItems = function(itemId) {

        state.isLoading = true;

        const apiURL = getCollectionsApiURL(
                store.getters.apiRoot,
                [collectionId],
                "",
                state.sortByParam,
                state.sortOrderParam,
        );

        store.dispatch('getApi', apiURL).then(function(response){

          state.isLoading = false;

          if (Array.isArray(response.data)) {
            state.items = response.data;
          }

          if (! isNaN(itemId)) {
            state.noticeItemId = parseInt(itemId);
            state.displayMode = 'notice';
          } else if (state.items.length) {
            state.noticeItemId = state.displayedItemsIds[0];
          } else {
            state.noticeItemId = 0;
          }
        });

      };

      const loadItemsIds = function() {

        state.sortByParam = props.sortBy;
        state.sortOrderParam = props.sortOrder;

        const options = "&hasMedia=1";
        const apiURL = getCollectionsItemIdsApiURL(
                store.getters.apiRoot,
                [collectionId],
                options,
                state.sortByParam,
                state.sortOrderParam
        );

        store.dispatch('getApi', apiURL).then(function(response){
          state.itemsIds = response.data.items;
        });
      };

      const itemLoaded = function(item) {
        state.noticeItemTitle = item.title;
      };


      //
      // Lifecycle Hooks
      //

      onMounted(() => {

        if (store.state.collections) {
          updateCollectionDescription();
        }

        if (route.query.sort_by && (route.query.sort_by.length > 0)) {
          state.sortByParam = route.query.sort_by;
        }

        if (route.query.sort_order && (route.query.sort_order.length > 0)) {
          state.sortOrderParam = route.query.sort_order;
        }

        if (route.query.display_mode && (route.query.display_mode.length > 0)) {
          state.displayMode = route.query.display_mode;
        }

        const toPage = isNaN(route.query.p) ? 1 : parseInt(route.query.p);
        if (state.displayMode === "list") {
          state.treeViewPageNo = toPage;
        } else if (state.displayMode === "gallery") {
          state.pageNo = toPage;
        }

        loadItems(route.params.id);
        loadItemsIds();

        // console.log("state.collectionId", state.collectionId);
      });

      watch(() => store.state.collections, () => {
        updateCollectionDescription();
      });

      // Different route / Same component
      onBeforeRouteLeave((to, from, next) => {

        if (to.name === props.itemRouteName)
        {
          // From List/Gallery view to Item view
          state.noticeItemId = parseInt(to.params.id);
          state.displayMode = "notice";
          next();
        }
        else if (to.name === props.itemSetRouteName)
        {
          // From Item view to List/Gallery view
          state.displayMode = to.query.display_mode || props.defaultDisplayMode;

          const toPage = isNaN(to.query.p) ? 1 : parseInt(to.query.p);
          if (state.displayMode === "list") {
            state.treeViewPageNo = toPage;
          } else if (state.displayMode === "gallery") {
            state.pageNo = toPage;
          }

          next();
        }
        else
        {
          // Different route with different component (home, etc...)
          next();
        }
      });

      // Same route / Same component
      onBeforeRouteUpdate((to, from, next) => {

        const toDisplayMode = to.query.display_mode ? to.query.display_mode : props.defaultDisplayMode;
        const toPage = isNaN(to.query.p) ? 1 : parseInt(to.query.p);

        const toQuerySortBy = to.query.sort_by ? to.query.sort_by : 'title';
        const toQuerySortOrder = to.query.sort_order ? to.query.sort_order : 'asc';

        if (to.name === props.itemRouteName) {

          // 1. Changement d'item (à partir d'un item)
          state.noticeItemId = parseInt(to.params.id);
          state.displayMode = 'notice';
          next();

        } else if (to.name === props.itemSetRouteName) {

          // 2. Changement de mode d'affichage de la liste des items

          state.displayMode = toDisplayMode;

          if (state.displayMode === "list") {
            state.treeViewPageNo = toPage;
          } else if (state.displayMode === "gallery") {
            state.pageNo = toPage;
          }

          if ((state.sortByParam !== toQuerySortBy) || (state.sortOrderParam !== toQuerySortOrder)) {

            // 2.1 Changement de l'ordre des items
            state.sortByParam = toQuerySortBy;
            state.sortOrderParam = toQuerySortOrder;

            loadItems();
            next();

          } else {

            // 2.2 Pagination
            next();

          }

        }
      });


      //
      // State
      //

      const state = reactive({
        collectionId,
        collectionJson: null,
        collectionTitle: computed(() => {
          if (! state.collectionJson) {
            return props.pageTitle
          } else {
            return getLocalizedValue(state.collectionJson['dcterms:title'], store.state.currentLanguage)
          }
        }),
        collectionDescriptions: computed(() => {
          if (state.collectionJson) {
            return state.collectionJson['dcterms:description']
          }
          return false;
        }),

        isCollectionDescriptionVisible:false,
        collectionDescriptionVisibleCssClass: computed( () => {
            return state.isCollectionDescriptionVisible ? "opened" : "";
        }),

        displayMode: props.noticeItemId || route.params.id ? "notice" : props.defaultDisplayMode,
        displayModeCssClass: computed(() => state.displayMode + "-mode"),

        // Item in notice view
        noticeItemId: props.noticeItemId,
        noticeItemType: props.noticeItemType,
        noticeItemTabLabel: props.noticeItemType === 'article' ? 'Articles' : 'Notices',
        noticeItemTitle: null,

        // Item in PopUp or downloaded
        popUpItemId: 0,
        popUpItemType: props.noticeItemType,

        treeViewItemsByPage: itemsByPage,
        treeViewPageNo: 1,

        pageNo: 1,
        items: [],
        itemsIds: [],

        displayedItems: computed( () => {
          const startItemNo = itemsByPage * (state.pageNo - 1);
          const endItemNo = Math.min(startItemNo + itemsByPage, state.items.length);
          let i, items = [];
          for(i=startItemNo;i<endItemNo;i++) {
            items.push(state.items[i]);
          }
          return items;
        }),

        displayedItemsIds: computed(() => {
          let i, itemsIds = [];
          let items = state.displayedItems;
          const n = items.length;
          for(i=0;i<n;i++) {
            itemsIds.push(items[i]['o:id']);
          }
          return itemsIds;
        }),

        isListDisplayMode: computed(() => { return state.displayMode === "list" }),
        isGalleryDisplayMode: computed(() => { return state.displayMode === "gallery" }),
        isNoticeDisplayMode: computed(() => { return state.displayMode === "notice" }),

        sortByParam: 'title',
        sortOrderParam: 'asc',

        galleryItemSetRoute: computed(() => {
          return router.resolve( {
              name: props.itemSetRouteName,
              query: {
                sort_param: state.sortByParam,
                sort_order: state.sortOrderParam,
                display_mode: 'gallery',
                p: 1
              }
            });
          }),

        listItemSetRoute: computed(() => {
          return router.resolve( {
              name: props.itemSetRouteName,
              query: {
                sort_param: state.sortByParam,
                sort_order: state.sortOrderParam,
                display_mode: 'list',
                p: 1
              }
            });
          }),

        listItemQuery: computed(() => {
          return {
            sort_param: state.sortByParam,
            sort_order: state.sortOrderParam,
            display_mode: 'list',
            p: state.pageNo
          };
        }),

         noticeItemRoute: computed(() => {
            return router.resolve( {
              name: props.itemRouteName,
              params: { id : state.noticeItemId },
              query: {
                sort_param: state.sortByParam,
                sort_order: state.sortOrderParam,
                display_mode: 'notice'
              }
            });
        }),

        userIsConnected: computed(() => store.state.currentUser.logged )
      });


      return {
        state,
        openCollectionDescriptions,
        itemLoaded
      }
    }
  }

</script>
