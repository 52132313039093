<template>
  <div class="account-items-panel" :class="panelOpenedCssClass">
    <div class="account-items-header">
      <a href="#" @click="toggleClose($event)">
        <h3>{{ $t("Mes annotations") }}</h3>
        <div class="account-items-close"></div>
      </a>
    </div>
    <div class="account-items">
      <h4>{{ $t("Annotations") }}</h4>
      <div v-if="state.annotations.length > 0">
        <div class="account-columns-items">
          <div class="account-column-title">{{ $t("Titre de l’annotation") }}</div>
          <div class="account-column-date">{{ $t("Date de l’annotation") }}</div>
          <div class="account-item-tools"></div>
        </div>
        <ul>
          <li class="account-item" v-for="annotation in state.annotations" v-bind:key="annotation.id">
            <div class="account-item-title">
              <router-link :to="itemUrl(annotation)">
                {{ annotation.content }}
              </router-link>
            </div>
            <div class="account-item-date">{{ annotation.date }}</div>
            <div class="account-item-tools buttons">
              <a class="account-item-delete-tool button delete-icon"
                 href="#"
                 @click="deleteAnnotation($event, annotation)"
              >
              </a>
              <NoticeDownloadLink
                      class="account-item-download-tool button download-icon"
                      linkLabel=""
                      output="txt"
                      :resourceId="annotation.id"
              />
            </div>
          </li>
        </ul>
      </div>
      <div v-else  class="account-column-warning">{{ $t("Aucune annotation") }}</div>
    </div>
  </div>
</template>

<script>

  import {useStore} from "vuex";
  import {computed, onMounted, reactive} from "vue";
  import {mySQLToDateJs, dateJsToDDMMYYYY, dateJsToHuman} from "../../js/viz-utils";
  import {getAnnotationApiURL, getUserAnnotationsApiURL, getUserItemsApiURL} from "../../js/api";
  import axios from "axios";
  import NoticeDownloadLink from "../Notices/NoticeDownloadLink";

  export default {
    name: 'UserContribution',
    components: {
      NoticeDownloadLink
    },
    props: {
      opened: {
        type: Boolean,
        required: true
      },
    },
    emits:[
      'toggle-contributions-panel'
    ],
    computed: {
      panelOpenedCssClass() {
        return this.opened ? "account-items-opened" : "";
      }
    },
    methods: {
      toggleClose($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.$emit('toggle-contributions-panel')
      },
      itemUrl(annotation) {
        const hrefWithRouterBase = this.$router.resolve({
          name: 'Item',
          params: { id: annotation.itemId },
          query: { lang: this.$store.state.currentLanguage }
        }).href;
        return hrefWithRouterBase.split(this.$store.state.publicPath).join('/');
      },
    },
    setup() {

      const store = useStore();

      const state = reactive({
        userId: computed(() => parseInt(store.getters.userId) ),
        isUserConnected: computed(() => store.getters.isUserConnected ),
        annotations: [],
        articles: []
      });

      const getAnnotationContent = function( annotationJson ) {
        const annotationContentBody = annotationJson['oa:hasBody'][0];
        if (annotationContentBody) {
          const annotationRdfValue = annotationContentBody["rdf:value"];
          if (annotationRdfValue) {
            return annotationRdfValue[0]['@value'];
          }
        }
        return "";
      };

      const getAnnotationItemId = function( annotationJson ) {
        const annotationTarget = annotationJson['oa:hasTarget'][0];
        if (annotationTarget) {
          const annotationHasSource = annotationTarget["oa:hasSource"];
          if (annotationHasSource) {
            return annotationHasSource[0]['value_resource_id'];
          }
        }
        return 0;
      };

      const loadAnnotations = function() {

        state.annotations = [];

        const apiRoot = store.getters.apiRoot;
        const keyIdentity = store.getters.identityAndCredentialParameters;
        const apiURL = getUserAnnotationsApiURL(apiRoot, store.state.currentUser.id);

        return axios.get(apiURL + keyIdentity ).then(function(response){

          const annotations = response.data;
          const n = annotations.length;
          let i, annotation;
          let annotationId, annotationCreated, annotationContent, annotationOwnerId, annotationOwnerName, annotationItemId;

          state.annotations = [];

          for(i=0;i<n;i++) {

            annotation = annotations[i];

            annotationId = parseInt(annotation['o:id']);
            annotationItemId = parseInt(getAnnotationItemId(annotation));
            annotationCreated = mySQLToDateJs ( annotation['o:created']['@value'] );
            annotationContent = getAnnotationContent(annotation);
            annotationOwnerId = annotation['o:owner']['o:id'];
            annotationOwnerName = annotation['o:owner']['o:name'];

            state.annotations.push({
              id: annotationId,
              itemId: annotationItemId,
              content: annotationContent,
              created: annotationCreated,
              date: dateJsToHuman(annotationCreated),
              userId: annotationOwnerId,
              userName: annotationOwnerName,
            });
          }
        });

      };

      const deleteAnnotation = function( $event, annotation ) {

        $event.preventDefault();

        const annotationToDeleteId = annotation.id;

        const apiRoot = store.getters.apiRoot;
        const keyIdentity = "?" + store.getters.identityAndCredentialParameters.substr(1);
        const apiURL = getAnnotationApiURL(apiRoot, annotationToDeleteId);

        return axios
                .delete(apiURL + keyIdentity )
                .then(function(){

                  // On doit la supprimer de la liste des annotations
                  const n = state.annotations.length;
                  let i, annotation, pos = -1;

                  for(i=0;i<n;i++) {
                    annotation = state.annotations[i];
                    if (annotation.id === annotationToDeleteId) {
                      pos = i;
                      break;
                    }
                  }

                  if (pos !== -1) {
                    // Suppression de la liste
                    state.annotations.splice(pos, 1);
                  }
                });
      };

      const transformDate = function(dateMySQL) {
        return dateJsToDDMMYYYY(mySQLToDateJs(dateMySQL));
      };

      const loadArticles = function() {

        state.annotations = [];

        const apiRoot = store.getters.apiRoot;
        const keyIdentity = store.getters.identityAndCredentialParameters;
        const apiURL = getUserItemsApiURL(apiRoot, store.state.currentUser.name);

        return axios.get(apiURL + keyIdentity ).then(function(response){

          const items = response.data;
          const n = items.length;
          let i, item, articles = [];

          for(i=0;i<n;i++) {
            item = items[i];

            articles.push({
              id: item["o:id"],
              itemId: item["o:id"],
              date: transformDate(item["o:created"]["@value"]),
              title: item["o:title"]
            })
          }

          state.articles = articles;

        });
      };

      onMounted(() => {
        loadAnnotations();
        loadArticles();
      });

      return {
        state,
        deleteAnnotation
      };

    }
  }

</script>
