<template>
  <div class="view">
    <div class="breadcrumbs-and-title">
      <div class="breadcrumbs">
        <BreadCrumb :paths="breadcrumbs" />
      </div>
      <h1>
        {{ $t(state.collectionTitle) }}
        <a
                v-if="state.collectionDescriptions"
                @click="openCollectionDescriptions($event)"
                class="collection-description-toggle"
                href="#">
        </a>
      </h1>
    </div>

    <div class="contents" :class="withPopUpCssClass">
      <div class="contents-wrapper list-mode">

        <div class="collection">

          <div class="collection-header" v-if="state.collectionDescriptions">
            <CollectionDescription
                class="with-padding"
                :class="state.collectionDescriptionVisibleCssClass"
                :descriptions="state.collectionDescriptions"
            />
          </div>

          <Loading class="notice-item-content with-padding no-header" v-if="state.isLoading" />

          <!-- Mode Liste -->
          <div class="contents-list-mode-content with-sort-options" v-if="! state.isLoading" >
            <ListItems
                :items="state.items"
                :advancedQuery="state.getSearchQuery"
                itemRouteFolder="/item"
                @openitem="openItem"
                @opendetailednotice="openDetailedNotice"
                @downloaditemnotice="openDownloadNoticePopUp"
            />
          </div>

        </div>
      </div>
    </div>

    <NoticePopUp
            v-if="detailedNoticeItemIsOpened"
            v-bind:itemId="detailedNoticeId"
            v-bind:itemType="detailedNoticeType"
            @close-detailed-item-notice="closeDetailedNotice"
            :class="detailedNoticeCssClass"
    />

    <NoticeDownloadPopUp
            v-if="downloadNoticeItemId"
            :itemId="downloadNoticeItemId"
            :itemType="downloadNoticeItemType"
            :class="downloadNoticePopupCssClass"
            @closedownloaditemnotice="closeDownloadNoticePopUp"
    />

  </div>
</template>
<script>

  import {computed, onMounted, reactive, watch} from "vue";
  import {onBeforeRouteUpdate, useRoute} from "vue-router";
  import { useStore } from "vuex";

  import ListItems from "../Lists/ListItems";
  import Loading from "../Loading";
  import NoticePopUp from "../Notices/NoticePopUp";
  import NoticeDownloadPopUp from "../Notices/NoticeDownloadPopUp";
  import BreadCrumb from "../Header/BreadCrumb";
  import CollectionDescription from "../Header/CollectionDescription";
  import {getCollectionApiURL, getCollectionsApiURL, getSortQueryParameters} from "@/js/api.js";
  import {getLocalizedValue} from "@/js/functions";
  import {JsonReader} from "../../js/jsonReader";

  export default {
    components: {
      BreadCrumb,
      CollectionDescription,
      ListItems,
      Loading,
      NoticePopUp,
      NoticeDownloadPopUp
    },
    emits: [
      "openitem"
    ],
    props: {
      collectionId: {
        type: Number,
        required: false,
        default: 0
      },
      titleFieldName: {
        type:String,
        required: false,
        default:'o:title'
      },
    },
    data() {
      return {
        detailedNoticeItemIsOpened:false,
        detailedNoticeId:null,
        detailedNoticeType:null,

        downloadNoticePopupIsOpened:false,
        downloadNoticeItemId:null,
        downloadNoticeItemType: 'item'
      }
    },
    computed: {
      detailedNoticeCssClass() {
        return this.detailedNoticeItemIsOpened ? "opened" : "";
      },
      withPopUpCssClass() {
        return this.detailedNoticeItemIsOpened ? "with-popup" : "";
      },
      downloadNoticePopupCssClass() {
        return this.downloadNoticePopupIsOpened === true ? "opened" : "";
      },
      breadcrumbs() {
        return [
          { name: 'Home', label:'Accueil' },
          { label: this.state.collectionTitle }
        ]
      }
    },
    methods: {
      openDetailedNotice(itemId) {
        this.detailedNoticeId = itemId;
        this.detailedNoticeType = "item";
        this.detailedNoticeItemIsOpened = true;
      },
      closeDetailedNotice() {
        this.detailedNoticeId = null;
        this.detailedNoticeType = null;
        this.detailedNoticeItemIsOpened = false;
      },
      openItem() {
      },
      openDownloadNoticePopUp(item) {
        this.downloadNoticePopupIsOpened = true;
        this.downloadNoticeItemId = item.id;
      },
      closeDownloadNoticePopUp() {
        this.downloadNoticePopupIsOpened = false;
        this.downloadNoticeItemId = null;
      }
    },
    setup(props) {

      const route = useRoute();
      const store = useStore();
      const jsonReader = new JsonReader();

      const updateCollectionDescription = function() {
        if(! isNaN(state.collectionId)) {

          // Appel à l'api
          const apiURL = getCollectionApiURL(store.getters.apiRoot, state.collectionId);

          store.dispatch('getApi', apiURL).then(function(response){
            const collectionJson = response.data;
            state.collectionTitle = getLocalizedValue(collectionJson['dcterms:title'], store.state.currentLanguage, collectionJson['o:title']);
            state.collectionDescriptions = collectionJson['dcterms:description'];
          });
        }
      };

      const openCollectionDescriptions = function($event) {
        $event.preventDefault();
        state.isCollectionDescriptionVisible = ! state.isCollectionDescriptionVisible;
      };

      const updateDisplayedItems = function() {

        const currentLanguage = store.state.currentLanguage;
        const items = state.items;

        let i, item, defaultTitle;
        for(i=0;i<items.length;i++)
        {
          item = items[i];
          item.id = item['o:id'];

          // o:title (RQ : il est en français quelle que soit la langue)
          defaultTitle = item[props.titleFieldName];

          if (!currentLanguage || (currentLanguage === "") || (currentLanguage === "fr")) {
            item.title = defaultTitle;
          }

          if ((typeof item.title !== "string") || (item.title.length === 0) || (currentLanguage !== "fr")) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            jsonReader.json = item;
            item.title = jsonReader.getLocalizedMetaDataValue('dcterms:alternative', currentLanguage, defaultTitle);
          }
        }
      };

      const loadResults = function() {

        state.isLoading = true;

        if (route.query.sort_by && (route.query.sort_by.length > 0)) {
          state.sortByParam = route.query.sort_by;
        }

        if (route.query.sort_order && (route.query.sort_order.length > 0)) {
          state.sortOrderParam = route.query.sort_order;
        }

        const options = "";
        let collectionsIds = [ state.collectionId ];

        const apiURL = getCollectionsApiURL(
                store.getters.apiRoot,
                collectionsIds,
                options,
                state.sortByParam,
                state.sortOrderParam
        );

        store.dispatch('getApi', apiURL).then(function(response){

          state.items = response.data;
          state.isLoading = false;

          let i, api_items = state.items, item, itemSets, firstItemSetId, collectionSettings;

          for (i = 0; i < api_items.length; i++) {
            item = api_items[i];
            item.id = item['o:id'];
            item.title = item[props.titleFieldName];

            // Récupération de l'icône et de la couleur de la collection
            itemSets = item["o:item_set"];
            if (itemSets && itemSets.length) {
              firstItemSetId = itemSets[0]['o:id'];
              if (firstItemSetId) {

                collectionSettings = store.getters.collectionsSettings(firstItemSetId);
                item.icon = collectionSettings ? collectionSettings.icon : null;
                item.colorNo = collectionSettings ? collectionSettings.colorNo : null;

                // Choix de la vue pour l'ouverture d'un item dans la liste :
                // - En passant 'route', un item s'ouvrira dans son espace ( encyclopédie, corpus, ...)
                // - En ne le passant pas, la liste déclenche une nouvelle route passée en paramètre au composant
                // ce qui permet d'ouvrir l'item dans l'onglet Notices du composant actuel ( cf _route )
                item._route = collectionSettings ? collectionSettings.route : '';

                item.itemset = state.itemSetId;
                item.type = collectionSettings ? collectionSettings.itemType : "item";
              }
            }
          }

          updateDisplayedItems();
        });
      };


      //
      // Lifecycle Hooks
      //

      onMounted(() => {

        if (props.collectionId === null) return;

        if (props.collectionId > 0) {
          state.collectionId = props.collectionId;
        } else {
          state.collectionId = parseInt(route.params.id);
        }

        if (route.query.sort_by && (route.query.sort_by.length > 0)) {
          state.sortByParam = route.query.sort_by;
        }

        if (route.query.sort_order && (route.query.sort_order.length > 0)) {
          state.sortOrderParam = route.query.sort_order;
        }

        loadResults();

        // Récupération du titre et description de la collection
        updateCollectionDescription();
      });

      onBeforeRouteUpdate((to, from, next) => {

        if ((state.collectionId !== to.params.id) || (state.sortByParam !== to.query.sort_by) || (state.sortOrderParam !== to.query.sort_order))
        {
          state.collectionId = parseInt(route.params.id);
          state.sortByParam = to.query.sort_by;
          state.sortOrderParam = to.query.sort_order;

          loadResults();

          next();

        }
      });

      watch(() => store.state.collections, () => {
        updateCollectionDescription();
      });

      watch(() => store.state.currentLanguage, () => {
        updateCollectionDescription();
        updateDisplayedItems();
      });


      //
      // State
      //

      const state = reactive({

        collectionDescriptions: null,
        isCollectionDescriptionVisible:false,
        collectionDescriptionVisibleCssClass: computed( () => {
          return state.isCollectionDescriptionVisible ? "opened" : "";
        }),

        collectionId:0,
        collectionTitle:"",
        items:null,
        isLoading: true,
        queryRouteName:"",
        sortByParam: 'title',
        sortOrderParam: 'asc',
        getSearchQuery: computed(() => getSortQueryParameters(state.sortByParam, state.sortOrderParam ))
      });

      return {
        state,
        openCollectionDescriptions
      }
    }
  }
</script>