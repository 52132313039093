
function getItemApiURL(apiRoot, itemId) {
    return apiRoot + "/items/" + itemId + "?append[0]=urls";
}

function getItemExportURL(downloadRoot, itemId, output = 'csv') {
    return downloadRoot + "/item/" + itemId + "." + output;
}

function getItemExportApiURL(apiRoot, itemId, output = 'csv', siteId = 1) {
    return apiRoot + "/infos/items/" + itemId + "?output=" + output + "&site_id=" + siteId;
}

function getItemPDFExportApiURL(downloadRoot, itemId) {
    return downloadRoot + "/pdf/" + itemId;
}

function getItemAdminURL(adminRoot, itemId) {
    return adminRoot + "/item/" + itemId;
}

function getQueryParameterForIds(itemsIds) {
    const n = itemsIds.length;
    let i, query = "";
    let itemId, ids = []; // checks if unique
    for(i=0;i<n;i++) {
        itemId = itemsIds[i];
        if (ids.indexOf(itemId) === -1) {
            ids.push(itemId);
            query += (i === 0 ? "" : "&");
            query += "id[]=" + itemId;
        }
    }
    return query;
}

function getItemsApiURL(apiRoot, itemsIds, options = "") {
    return apiRoot + "/items?" + getQueryParameterForIds(itemsIds) + options;
}

function getSelectionQuery(itemsIds) {
    const n = itemsIds.length;
    let i, query = "";
    for(i=0;i<n;i++) {
        query += (i === 0 ? "" : "&");
        query += "id[]=" + itemsIds[i];
    }
    return query;
}

function getMediaApiURL(apiRoot, mediaId) {
    return apiRoot + "/media/" + mediaId;
}

function getMediasApiURL(apiRoot, mediaIds, options = "") {
    return apiRoot + "/media?" + getQueryParameterForIds(mediaIds) + options;
}

// Paramètres : ?append[]=term&append[]=label&append[]=comment  ou  ?append[]=all
function getResourceTemplatesApiURL(apiRoot) {
    return apiRoot + "/resource_templates?append[]=term&append[]=label"
}

// ?append[]=term&append[]=label&append[]=comment ou ?append[]=all
function getResourceTemplateApiURL(apiRoot, templateId) {
    return apiRoot + "/resource_templates/"+ templateId +"?append[]=term&append[]=label"
}

function getLoginApiURL(apiRoot) {
    return apiRoot + "/login";
}

function getLogoutApiURL(apiRoot) {
    return apiRoot + "/logout";
}

function getRegisterApiURL(apiRoot) {
    return apiRoot + "/register";
}

function getForgotPasswordApiURL(apiRoot) {
    return apiRoot + "/forgot-password";
}

function getUserApiURL(apiRoot, email) {
    return apiRoot + "/users?email=" + email;
}

function getInfosUserApiURL(apiRoot) {
    return apiRoot + "/users/me?";
}

function updateUserApiURL(apiRoot) {
    return apiRoot + "/users/me?";
}

function getSitesApiURL(apiRoot) {
    return apiRoot + "/sites";
}

function getSiteApiURL(apiRoot, siteId = 1) {
    return apiRoot + "/sites/"+ siteId;
}

function getPagesApiURL(apiRoot) {
    return apiRoot + "/site_pages"
}

function getSitePagesApiURL(apiRoot, siteId = 1) {
    return apiRoot + "/site_pages?site_id="+ siteId
}

function getSitePageApiURL(apiRoot, pageId, siteId = 1) {
    return apiRoot + "/site_pages/"+pageId + "?site_id="+ siteId + "&append[]=html"; // &append[]=blocks
}

function getCollectionsListApiURL(apiRoot, options = "", sortByParam = 'title', sortOrderParam = 'asc') {
    let url = apiRoot + "/item_sets";
    url += getSortQueryParameters(sortByParam, sortOrderParam, true);
    url += options;
    return url;
}

function getCollectionApiURL(apiRoot, collectionId) {
    return apiRoot + "/item_sets/"+collectionId;
}

function getCuratedCollectionsApiURL(apiRoot, options = "", sortByParam = 'title', sortOrderParam = 'asc') {
    let url = apiRoot + "/item_sets?property[0][joiner]=and&property[0][property]=curation:selected&property[0][type]=ex";
    url += getSortQueryParameters(sortByParam, sortOrderParam);
    url += options;
    return url;
}

function getSortQueryParameters(sortByParam = '', sortOrderParam = '', firstParams = false) {

    let query = '';

    if (sortByParam && (sortByParam.length > 0)) {
        query += '&sort_by=' + sortByParam;
    }

    if (sortOrderParam && (sortOrderParam.length > 0)) {
        query += '&sort_order=' + sortOrderParam;
    }

    if (firstParams) {
        query = "?" + query.substr(1);
    }

    return query;
}

// Returns items of specified collections
function getCollectionsApiURL(apiRoot, collectionIds, options = "", sortByParam = 'title', sortOrderParam = 'asc') {
    let i, url = apiRoot + "/items?";
    const n = collectionIds.length;
    for(i=0;i<n;i++) {
        url += "item_set_id[]=" + collectionIds[i] + (i < n - 1 ? "&" : "");
    }

    url += getSortQueryParameters(sortByParam, sortOrderParam);
    url += options;

    return url;
}

function getCollectionsItemIdsApiURL(apiRoot, collectionIds, options = "", sortByParam = 'title', sortOrderParam = 'asc') {
    let i, url = apiRoot + "/infos/ids?types=items&";
    const n = collectionIds.length;
    for(i=0;i<n;i++) {
        url += "item_set_id[]=" + collectionIds[i] + (i < n - 1 ? "&" : "");
    }
    url += getSortQueryParameters(sortByParam, sortOrderParam);
    url += options;

    return url;
}

function getCollectionsByItemSetApiURL(apiRoot, collectionIds) {
    let i, url = apiRoot + "/infos/items?";
    const n = collectionIds.length;
    for(i=0;i<n;i++) {
        url += "item_set_id[]=" + collectionIds[i] + "&";
    }
    url += "fields=o:title,dcterms:alternative&output=by_itemset";

    return url;
}

function getCollectionLinkedMediaApiURL(apiRoot, collectionIds) {
    let i, property;
    let url = apiRoot + '/media?';
    const n = collectionIds.length;

    for(i=0;i<n;i++) {
        property = 'property['+ i +']';
        url += '&' + property + '[joiner]=or';
        url += '&' + property + '[property]=rdf:type';
        url += '&' + property + '[type]=res';
        url += '&' + property + '[text]='  + collectionIds[i];
    }

    return url;
}

// Ex : resourceType = dcterms:creator, resourceId = 686
function getLinkedResourcesApiURL(apiRoot, resourceType, resourceId, collectionIds, options = "", sortByParam = 'title', sortOrderParam = 'asc') {
    let i, url = apiRoot + "/items?";
    url += "property[0][joiner]=and&property[0][property]="+ resourceType +"&property[0][type]=res&property[0][text]=" + resourceId;

    const n = collectionIds.length;
    for(i=0;i<n;i++) {
        url += "&item_set_id[]=" + collectionIds[i];
    }
    url += getSortQueryParameters(sortByParam, sortOrderParam);
    url += options;

    return url;

}

function getSearchApiURL(apiRoot, search, sortByParam = 'title', sortOrderParam = 'asc') {

    let url = apiRoot + '/items?';

    url += 'property[0][property]=&property[0][type]=in&property[0][text]='+ search;
    url += getSortQueryParameters(sortByParam, sortOrderParam);

    return url;
}

function getAdvancedSearchQuery(fieldSets = null, collectionsIds = null, fulltext_search = null) {

    let i, n, query = "", indexOffset = 0;

    if (fulltext_search && (fulltext_search.length > 0)) {
        query += '&property[0][property]=&property[0][type]=in&property[0][text]='+fulltext_search;
        indexOffset = 1;
    }

    if (fieldSets) {
        let fieldSet, fieldSetIndex, fieldSetText, fieldSetProperty, fieldSetJoiner, fieldSetType;
        n = fieldSets.length;
        for(i=0;i<n;i++) {
            fieldSet = fieldSets[i];
            fieldSetIndex = i + indexOffset;
            fieldSetText = fieldSet.text;
            fieldSetProperty = fieldSet.property;
                if (fieldSetText && fieldSetText.length && fieldSetProperty)
            {
                query += '&property[' + fieldSetIndex + '][property]=' + fieldSetProperty;
                query += '&property[' + fieldSetIndex + '][text]=' + fieldSetText;

                fieldSetJoiner = fieldSet.joiner ? fieldSet.joiner : 'and';
                query += '&property[' + fieldSetIndex + '][joiner]=' + fieldSetJoiner;

                fieldSetType = fieldSet.type ? fieldSet.type : 'in';
                query += '&property[' + fieldSetIndex + '][type]=' + fieldSetType;
            }
        }
    }

    if (collectionsIds) {
        n = collectionsIds.length;
        for(i=0;i<n;i++) {
            query += '&item_set_id[]=' + collectionsIds[i];
        }
    }

    // On retire le premier '&'
    if (query.indexOf('&') === 0) {
        query = query.substr(1);
    }

    return query;
}

function getReferencesURL(apiRoot, metadata = "dcterms:title", letter = null, collectionsIds = null, customVocabsIds = null, lang= "", metadataSlug = "refs") {

    let url = apiRoot + '/references?metadata['+ metadataSlug +']=' + metadata;

    url += "&list_by_max=999";

    const fields = ["o:id", "o:item_set", "dcterms:title", "o:title"];
    let i, n = fields.length;
    for(i=0;i<n;i++) {
        url += '&fields[]=' + fields[i];
    }

    if (letter) {
        url += "&filters[begin]=" + letter;
    }

    if (collectionsIds) {
        let i, n = collectionsIds.length;
        for(i=0;i<n;i++) {
            url += '&item_set_id[]=' + collectionsIds[i];
        }
    }

    if (customVocabsIds) {
        let i, n = customVocabsIds.length;
        for(i=0;i<n;i++) {
            url += '&filters[datatypes][]=customvocab:' + customVocabsIds[i];
        }
    }

    if (lang && lang.length) {
        if (Array.isArray(lang)) {
            let i, n = lang.length;
            for(i=0;i<n;i++) {
                url += '&locale[]=' + lang[i];
            }
        } else {
            url += '&locale=' + lang;
        }
    }

    // url += '&filters[languages][]=null&filters[languages][]=' + lang;

    return url;
}

function getSelectionResourcesApiURL(apiRoot, userId) {
    return apiRoot + "/selection_resources?owner_id=" + userId;
}

function getSelectionResourcesItemApiURL(apiRoot, userId, selectionId) {
    return apiRoot + "/selection_resources/" + selectionId + "?owner_id=" + userId;
}

function getSelectionsApiURL(apiRoot, userId) {
    return apiRoot + "/selections?owner_id=" + userId;
}

function getSelectionsItemApiURL(apiRoot, userId, selectionId) {
    return apiRoot + "/selections/" + selectionId + "?owner_id=" + userId;
}

function getBasketApiURL(apiRoot, userId) {
    return apiRoot + "/selection_resources?selection_id=0&owner_id=" + userId;
}

function getMappingItemsApiURL(apiRoot, query) {
    return apiRoot + "/infos/mappings?site_id=1" + query;
}

function getTimelineItemsApiURL(apiRoot, timelineId) {
    return apiRoot + "/timeline/" + timelineId + "?output=knightlab";
}

function getContactMessageApiURL(apiRoot) {
    return apiRoot + "/contact_messages?";
}

function getAnnotationsApiURL(apiRoot) {
    return apiRoot + "/annotations";
}

function getAnnotationApiURL(apiRoot, annotationId) {
    return apiRoot + "/annotations/"+annotationId;
}

function getItemAnnotationsApiURL(apiRoot, itemId) {
    return apiRoot + "/annotations?resource_id=" + itemId + "&append=owner_name";
}

function getUserAnnotationsApiURL(apiRoot, userId) {
    return apiRoot + "/annotations?owner_id=" + userId;
}

function getUserArticlesApiURL(apiRoot, userName, collectionId) {
    return apiRoot + "/items?item_set_id[]=" + collectionId + "&property[0][property]=dcterms:creator&property[0][type]=eq&property[0][text]=" + userName;
}

function getUserItemsApiURL(apiRoot, userName) {
    return apiRoot + "/items?property[0][property]=dcterms:creator&property[0][type]=eq&property[0][text]=" + userName;
}

function getTreeRootNodesCountApiURL(apiRoot, collectionId) {
    let url = apiRoot + '/infos/items?item_set_id=' + collectionId;
    url += '&property[0][joiner]=and&property[0][type]=nex&property[0][property]=dcterms:isPartOf';
    return url;
}

function getTreeApiURL(apiRoot, collectionId, page = 1, per_page = 10, options = "", sortByParam = 'title', sortOrderParam = 'asc', with_media = true) {
    let url = apiRoot + '/infos/items?tree_child=dcterms:hasPart&tree_title=o:title&tree_name=bibo:section&output=tree';

    url += '&tree_parent=dcterms:isPartOf';

    if (with_media !== false) {
        url += '&with_media=1';
    }

    url += '&item_set_id='+ collectionId;

    if (per_page > 0) {
        url += '&page='+ page;
        url += '&per_page='+ per_page;
    }

    url += options;
    url += getSortQueryParameters(sortByParam, sortOrderParam);

    return url;
}

function getCoinsApiURL(apiRoot, query) {
    return apiRoot + "/infos/coins?" + query;
}

export {
    getItemExportURL,
    getItemExportApiURL,
    getItemPDFExportApiURL,
    getItemAdminURL,
    getItemApiURL,
    getItemsApiURL,
    getSelectionQuery,
    getBasketApiURL,
    getMediaApiURL,
    getMediasApiURL,
    getResourceTemplatesApiURL,
    getResourceTemplateApiURL,
    getLoginApiURL,
    getLogoutApiURL,
    getRegisterApiURL,
    getForgotPasswordApiURL,
    getUserApiURL,
    getInfosUserApiURL,
    updateUserApiURL,
    getSitesApiURL,
    getSiteApiURL,
    getPagesApiURL,
    getSitePagesApiURL,
    getSitePageApiURL,
    getSortQueryParameters,
    getCollectionsListApiURL,
    getCollectionApiURL,
    getCollectionsApiURL,
    getCollectionsItemIdsApiURL,
    getCollectionsByItemSetApiURL,
    getCollectionLinkedMediaApiURL,
    getCuratedCollectionsApiURL,
    getSearchApiURL,
    getAdvancedSearchQuery,
    getReferencesURL,
    getSelectionResourcesApiURL,
    getSelectionResourcesItemApiURL,
    getSelectionsApiURL,
    getSelectionsItemApiURL,
    getMappingItemsApiURL,
    getTimelineItemsApiURL,
    getContactMessageApiURL,
    getAnnotationApiURL,
    getAnnotationsApiURL,
    getItemAnnotationsApiURL,
    getUserAnnotationsApiURL,
    getUserArticlesApiURL,
    getUserItemsApiURL,
    getTreeApiURL,
    getTreeRootNodesCountApiURL,
    getLinkedResourcesApiURL,
    getCoinsApiURL,
    getQueryParameterForIds
}