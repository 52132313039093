<template>
    <router-link
            :to="{ name:'Curated ItemSet', params: { itemset: state.collectionId }, query: { from_mode : fromDisplayMode} }"
            class="selected-items-card"
    >
      <div
            class="selected-items-card-image"
            :class="state.thumbnail ? '': 'no-thumbnail'"
      >
        <img
            v-if="state.thumbnail"
            :src="state.thumbnail"
            alt=""
        />
      </div>
      <div class="selected-items-card-infos">
        <h3>{{ state.title }}</h3>
        <div class="selected-items-card-description">
          <ul>
            <li v-for="collectionSelectionDescription in state.descriptions" v-bind:key="collectionSelectionDescription">
              {{ collectionSelectionDescription }}
            </li>
          </ul>
        </div>
        <div class="selected-items-card-documents-count">
          {{ state.itemsCount }}
        </div>
      </div>
    </router-link>
</template>

<script>

  import {useStore} from "vuex";
  import {computed, onMounted, reactive} from "vue";
  import {getCollectionsApiURL} from "../../js/api";
  import {getLocalizedValue} from "../../js/functions";

  export default {
    name: 'CuratedCollectionsCard',
    props: {
      collectionJson: {
        type: Object,
        required: true
      },
      fromDisplayMode: {
        type:String,
        required: false,
        default:'gallery'
      }
    },
    setup(props) {

      const store = useStore();

      const updateItemsCount = function() {
        if (!state.collectionJson.itemCount) {

          // Appel à l'api
          const apiURL = getCollectionsApiURL(store.getters.apiRoot, [props.collectionJson['o:id']]);

          store.dispatch('getApi', apiURL).then(function(response){
            state.itemsCount = response.data.length + ' ' + 'document' + (response.data.length > 1 ? 's' : '');
            state.collectionJson.itemCount = state.itemsCount;
          });

        } else {

          state.itemsCount = state.collectionJson.itemCount;

        }
      };

      onMounted(() => {
        updateItemsCount();
      });

      const state = reactive({
        collectionJson: props.collectionJson,
        collectionId: computed(() => props.collectionJson['o:id'] ),
        title: computed(() => {
          return getLocalizedValue(props.collectionJson['dcterms:title'], store.state.currentLanguage, props.collectionJson['o:title'])
        }),
        thumbnail: computed(() => {
          const collectionThumbnail = props.collectionJson['thumbnail_display_urls'];
          return collectionThumbnail && collectionThumbnail['medium'] ? collectionThumbnail['medium'] : null;
        }),
        descriptions: computed(() => {
          return [ getLocalizedValue(props.collectionJson['dcterms:description'], store.state.currentLanguage) ];
        }),
        itemsCount:''
      });

      return {
        state
      }
    }

  }

</script>
