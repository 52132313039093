<template>

  <div class="contents-list-mode-content with-padding">

    <!-- Sélecteur de catégories Tablette -->
    <ul class="list-items-menu-mobile-selector" id="list-items-collections-mobile-selector">
      <li class="selector-previous-button">
        <a href="#" @click="selectPreviousCollectionInMobileMenu($event)"></a>
      </li>

      <ul class="list-items-menu-mobile">
        <li v-for="collection in state.categoriesForMobileMenu"
            :key="collection.id"
            :class="categoryForMobileMenuCssClass(collection)">
          {{ getCategoryTitle(collection.id) }}
        </li>
      </ul>

      <li class="selector-next-button">
        <a href="#" @click="selectNextCollectionInMobileMenu($event)"></a>
      </li>
    </ul>

    <!-- Pagination Top -->
    <div class="list-pagination top-list-pagination">
      <div class="pagination-sort-options">
        <a class="pagination-sort-asc-desc" href="#"></a>
      </div>
      <ListCount
              :element-count="state.itemsCount"
              elementLabel = "notice"
              class="cards-count"
      />
      <ListPagination
              v-if="state.pageCount > 1"
              :pageCount="state.pageCount"
              :pageNo="state.pageNo"
              @change-pagination-page="displayPage"
      />
    </div>

    <div class="list-items-and-collections">
      <div class="collections-with-slider">
        <div class="collections-slider" id="collections-slider">
          <a href="#"
             class="collections-slider-handle"
             id="collections-slider-handle"
             v-bind:style="{ top: sliderCssTopInPixels }">
          </a>
        </div>
        <ul class="list-items-collections">
          <li v-for="collection in state.collections"
              :class="'list-items-collection-' + collection.icon + ' list-items-category-' + collection.colorNo"
              :key="collection.id"
          >
            <router-link :to="getCategoryRoute(collection.id)">
              {{ getCategoryTitle(collection.id) }}
            </router-link>
          </li>
        </ul>
      </div>
      <ul class="list-items">
        <li
          v-for="item in state.displayedItems"
          :key="item.id"
          class="list-item"
          :class="listItemCssClass(item)"
        >
          <router-link class="item-title" :to="itemRoute(item)">
            <span>
              {{ item.title }}
            </span>
          </router-link>
          <span class="item-icons">
            <a class="notice-graphe"
               :href="itemRoute(item)"
               v-if="showItemGraphButton && item.icon"
               @click="openItemVisualization($event, item.id)"
            ></a>
            <a class="notice-icon" :href="itemRoute(item)"
               v-if="showItemNoticeButton"
              @click="clickItem($event, item.id)">
            </a>
            <a class="basket-icon"
               :href="itemRoute(item)"
               v-if="state.userIsConnected"
               @click="toggleItemInBasket($event, item.id)"
               :class="itemInBasketCssClass(item.id)"
            >
            </a>
            <NoticePDFDownloadLink
                v-if="state.userIsConnected"
                class="download"
                :resource-id="item.id"
                :title="$t('Exporter en PDF')" />
          </span>
        </li>
      </ul>
    </div>

    <!-- Pagination Bottom -->
    <div class="list-pagination bottom-list-pagination">
      <div class="pagination-sort-options">
      </div>
      <ListPagination
              v-if="state.pageCount > 1"
              :pageCount="state.pageCount"
              :pageNo="state.pageNo"
              @change-pagination-page="displayPage"
      />
    </div>
    <Coins v-if="state.coinQuery" :query="state.coinQuery" />
  </div>

</template>

<script>

  import { reactive, computed, onMounted, onUnmounted, onUpdated, watch, watchEffect } from 'vue'
  import {useRoute, useRouter} from "vue-router";
  import {useStore} from "vuex";

  import ListPagination from './ListPagination';
  import ListCount from "./ListCount";
  import Coins from "../Coins";
  import NoticePDFDownloadLink from "../Notices/NoticePDFDownloadLink";
  import {getQueryParameterForIds} from "../../js/api";

  const categoriesSliderDefautTop = 10;
  const categoriesSliderStep = 50;

  export default {
    name: 'ListItemsWithCategoryFilter',
    components: {
      ListPagination,
      ListCount,
      Coins,
      NoticePDFDownloadLink
    },
    props: {
      items: {
        type: Array,
        required: true,
      },
      pageNo: {
        type: Number,
        required: false,
        default:1
      },
      itemSet: {
        type: Number,
        required: false,
        default:0
      },
      itemsByPage: {
        type: Number,
        required: false,
        default:8
      },
      showItemGraphButton: {
        type: Boolean,
        required: false,
        default:false
      },
      showItemNoticeButton: {
        type: Boolean,
        required: false,
        default:false
      },
    },
    emits: [
      "opendetailednotice",
      "openitemvisualization",
      "downloaditemnotice",
    ],
    data() {
      return {
        categorySliderIndex: 0,
        categorySliderHandleTop:0,
        isSortMenuOpened:false,
        itemId: null
      }
    },
    computed:{
      sliderCssTopInPixels() {
        let categoriesSliderTop;
        if (this.state.categorySliderIndex !== -1) {
          categoriesSliderTop = categoriesSliderDefautTop +  this.state.categorySliderIndex * categoriesSliderStep;
        } else {
          categoriesSliderTop = this.state.categorySliderHandleTop;
        }
        return categoriesSliderTop +  "px";
      },
    },
    methods:{
      listItemCssClass(item) {
        return (item.icon ? "list-item-category-" + item.icon : "") + " " + (item.colorNo ? "list-item-category-" + item.colorNo : "");
      },
      getCategoryTitle(categoryId) {
        // Returns itemSet ID from category "name" defined in settings
        return this.state.collectionsTitles['c' + categoryId];
      },
      clickItem($event, itemId) {
        $event.preventDefault();
        this.openDetailedNotice(itemId);
      },
      itemRoute(item) {
        return this.$router.resolve( {
          name: 'Notice Item',
          params: { id : item.id },
          query: {
            display_mode: 'notice'
          }
        });
      },
      openDetailedNotice(itemId) {
        // EVENT --> PARENT
        this.$emit('opendetailednotice', itemId);
      },
      openItemVisualization($event, itemId) {
        $event.preventDefault();
        // EVENT --> PARENT
        this.$emit('openitemvisualization', itemId);
      }
    },
    setup(props, context) {

      const store = useStore();
      const route = useRoute();
      const router = useRouter();

      const stopWatcher = watchEffect(() => {
        if (props.items) {
          // console.log(`List items: ` + props.items.length);
        }
      });

      let categoriesSlider, categoriesSliderHeight,categoriesSliderHandle, categoriesSliderHandleHeight;

      const categorySliderEnterFrame = function($event) {
        const moudeDownY = $event.clientY - categoriesSlider.getBoundingClientRect().top;
        state.categorySliderHandleTop = Math.max(0, Math.min(moudeDownY, categoriesSliderHeight - categoriesSliderHandleHeight));
        state.categorySliderIndex = -1;
      };

      const updateSliderSize = function() {
        categoriesSlider = document.getElementById("collections-slider");
        categoriesSliderHeight = categoriesSlider.offsetHeight;

        categoriesSliderHandle = document.getElementById("collections-slider-handle");
        categoriesSliderHandleHeight = categoriesSliderHandle.offsetHeight;
      };

      // Lifecycle Hooks

      onMounted(() => {

        updateSliderSize();

        categoriesSliderHandle.addEventListener("mousedown", function($event) {
          $event.preventDefault();
          $event.stopPropagation();
          document.body.addEventListener("mousemove", categorySliderEnterFrame);
        });

        categoriesSliderHandle.addEventListener("click", function($event) {
          $event.preventDefault();
          $event.stopPropagation();
        });

        document.body.addEventListener("mouseup", function() {
          if (state.categorySliderIndex === -1)
          {
            document.body.removeEventListener("mousemove", categorySliderEnterFrame);

            let top = parseInt(state.categorySliderHandleTop) - categoriesSliderDefautTop;
            top = Math.max(0, Math.min(top, categoriesSliderHeight - categoriesSliderDefautTop));
            state.categorySliderIndex = Math.floor((top + 20) /categoriesSliderStep);

            let categoryId;
            if (state.categorySliderIndex <= 0) {
              categoryId = state.categories[0];
            } else if (state.categorySliderIndex > state.categories.length - 1) {
              categoryId = state.categories[state.categories.length - 1];
            } else {
              categoryId = state.categories[state.categorySliderIndex];
            }

            displayPage(state.firstPageForSliderCategory, categoryId);
          }
        });

      });

      onUpdated(() => {
        updateSliderSize();
      });

      onUnmounted(() => {
        stopWatcher();
        document.body.removeEventListener("mousemove", categorySliderEnterFrame);
      });

      watch(() => props.pageNo, () => {
        state.pageNo = props.pageNo;
      });

      watch(() => props.itemSet, (itemset) => {
        // On positionne le curseur en fonction de la catégorie choisie
        let i, categoryId;
        const items = props.items, n = items.length;
        for(i=0;i<n;i++) {
          categoryId = items[i].itemset
          if (categoryId === itemset) {
            break;
          }
        }
        state.categorySliderIndex = state.categories.indexOf(categoryId.toString());
      });

      //
      // Infos des settings : collections de l'encyclopédie
      //

      const encyclopedieCollectionInfos = store.getters.encyclopedieCollectionInfos();
      const n = encyclopedieCollectionInfos.length;

      let i, infos;

      const categoriesForMobileMenu = [];
      const collectionsIds = [];

      for(i=0;i<n;i++)
      {
        infos = encyclopedieCollectionInfos[i];
        collectionsIds.push(infos.id);

        categoriesForMobileMenu.push({
          id: infos.id,
          icon: infos.icon,
          colorNo: infos.colorNo,
          title: infos.title,
          selected: i === 0
        });
      }


      // Reactive state ( props and computed )

      const state = reactive({
        pageNo: props.pageNo,
        pageCount: computed(() => props.items ? Math.ceil( props.items.length / props.itemsByPage ) : 0),
        collections: encyclopedieCollectionInfos,
        collectionsTitles: store.getters.localizedCollectionsTitles('dcterms:title'),
        categories: collectionsIds,
        categoriesForMobileMenu: categoriesForMobileMenu,
        categorySliderIndex:0,
        categorySliderHandleTop:0,
        displayedItems: computed( () => {
          const startItemNo = props.itemsByPage * (state.pageNo - 1);
          const endItemNo = Math.min(startItemNo + props.itemsByPage, props.items.length);
          let i, items = [];
          for(i=startItemNo;i<endItemNo;i++) {
            items.push(props.items[i]);
          }
          return items;
        }),
        displayedItemsIds: computed(() => {
          let i, itemsIds = [];
          let items = state.displayedItems;
          const n = items.length;
          for(i=0;i<n;i++) {
            itemsIds.push(items[i].id);
          }
          return itemsIds;
        }),
        itemsCount: computed(() => props.items.length),
        firstPageForSliderCategory: computed( () =>  {
          const categoryId = parseInt(state.categories[state.categorySliderIndex]);
          let i, pageNo = 1;
          const items = props.items, n = items.length;
          for(i=0;i<n;i++) {
            if (items[i].itemset === categoryId ) {
              pageNo = Math.floor(i / props.itemsByPage) + 1;
              break;
            }
          }
          return pageNo;
        }),
        categoryMobileIndex:0,
        userIsConnected: computed(() => store.state.currentUser.logged ),
        coinQuery: computed(() => getQueryParameterForIds(state.displayedItemsIds))
      });

      const itemInBasketCssClass = function(itemId) {
        return store.getters.itemInBasket(itemId, "item") ? "selected" : "";
      };

      const categoryForMobileMenuCssClass = (item) => {
        return ("list-items-collection-" + item.icon) + (" list-items-category-" + item.colorNo) + (item.selected ? " selected" : "");
      };

      const toggleItemInBasket = function($event, itemId) {
        $event.preventDefault();
        store.dispatch( store.getters.itemInBasket(itemId, "item") ? "removeFromBasket" : "addToBasket", { type: "item", id: parseInt(itemId) } );
      };

      const downloadItemNotice = function($event, itemId) {
        $event.preventDefault();
        context.emit("downloaditemnotice", { id: itemId });
      };

      const updateCollectionMobileMenu = () => {
        const n = state.categoriesForMobileMenu.length;
        let i, infos, selectedCollectionId;
        for(i=0;i<n;i++) {
            infos = state.categoriesForMobileMenu[i];
            infos.selected = i === state.categoryMobileIndex;
            if (i === state.categoryMobileIndex) {
              selectedCollectionId = infos.id;
            }
        }
        displayListCategory(selectedCollectionId);
      };

      const selectPreviousCollectionInMobileMenu = ($event) => {
        $event.preventDefault();
        state.categoryMobileIndex = state.categoryMobileIndex > 0 ? state.categoryMobileIndex - 1 : encyclopedieCollectionInfos.length - 1;
        updateCollectionMobileMenu();
      };

      const selectNextCollectionInMobileMenu = ($event) => {
        $event.preventDefault();
        state.categoryMobileIndex = state.categoryMobileIndex < encyclopedieCollectionInfos.length - 1 ? state.categoryMobileIndex + 1 : 0;
        updateCollectionMobileMenu();
      };

      const getCategoryRoute = function(collectionId) {
        let i, pageNo = 1;
        const items = props.items, n = items.length;
        const collectionIdNum = parseInt(collectionId);
        for(i=0;i<n;i++) {
          if (items[i].itemset === collectionIdNum ) {
            pageNo = Math.floor(i / props.itemsByPage) + 1;
            break;
          }
        }
        return router.resolve( {
          name: "Encyclopedie",
          query: {
            display_mode: 'list',
            p: pageNo,
            itemset: collectionId
          }
        });
      };

      const displayListCategory = function (categoryId) {
        state.categorySliderIndex = state.categories.indexOf(categoryId);
        displayPage(state.firstPageForSliderCategory);
      };

      const displayPage = function(pageNo, categoryId) {
        if (state.pageNo !== pageNo) {
          state.pageNo = pageNo;

          let routeCopy = {
            name: route.name,
            query: {...route.query},
            params: {...route.params}
          };

          // Nouvelle page
          routeCopy.query.p = pageNo;
          routeCopy.query.itemset = categoryId;
          routeCopy.query.display_mode = "list";

          router.push(routeCopy);
        }
      };

      return {
        state,
        toggleItemInBasket,
        itemInBasketCssClass,
        downloadItemNotice,
        selectPreviousCollectionInMobileMenu,
        selectNextCollectionInMobileMenu,
        categoryForMobileMenuCssClass,
        getCategoryRoute,
        displayListCategory,
        displayPage
      }
    },
}
</script>
