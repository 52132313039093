<template>
  <div class="hamburger-parent">
    <div class="hamburger">
      <a href="#"
         class="c-hamburger c-hamburger--htx"
         @click="clickOnNavigationMenu"
      >
        <span>Toggle menu</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hamburger',
  props: {
  },
  emits: [
    'click-on-navigation-menu'
  ],
  methods: {
    clickOnNavigationMenu($event) {
      $event.preventDefault();
      $event.stopPropagation();
      this.$emit('click-on-navigation-menu')
    }
  }
}
</script>
