<template>
  <div class="popup-wrapper">
    <div class="popup-notice-item detailed-notice popup-parent with-padding" :class="popupNoticeCssClass">

      <div class="popup-notice-buttons">
        <div class="notice-back-button" v-if="state.backButton">
          <a href="#" @click="clickOnBackButton($event)">{{ $t("Retour à la notice") }} "{{ state.backButtonItemTitle }}"</a>
        </div>

        <div class="close-btn">
          <a href="#" @click="closeNotice($event)">{{ closeLabel }}</a>
        </div>
      </div>

      <NoticeMedia
              v-if="state.isNotice"
              :itemId="state.itemId"
              :inPopUp="isPopUp"
              :itemsIds="state.itemsIds"
              :openGraphInPopUp="state.openGraphInPopUp"
              @item-loaded="itemLoaded"
              @change-item-notice="changeItem"
              @open-item-graph="openItemGraph"
              @previous-next-item-notice="navigationPreviosNextChangeNoticeItem"
              @downloaditemnotice="openDownloadNoticePopUp"
      />

      <NoticeArticle
              v-if="state.isNoticeArticle"
              :itemId="state.itemId"
              :inPopUp="isPopUp"
              @change-item-notice="changeItem"
              @downloaditemnotice="openDownloadNoticePopUp"
      />

      <NoticeExtrait
              v-if="state.isNoticeExtrait"
              :itemId="state.itemId"
              :inPopUp="isPopUp"
              @change-item-notice="changeItem"
              @downloaditemnotice="openDownloadNoticePopUp"
      />

      <NoticeTDBranch
              v-if="state.isNoticeTDBranch"
              :itemId="state.itemId"
              :itemJson="state.itemJson"
      />

      <div class="close-btn bottom-close-btn"><a href="#" @click="closeNotice($event)">{{ closeLabel }}</a></div>

      <BackToTopButton buttonId="popUpBackToTop" scrollTarget="parent" />

    </div>

    <NoticeDownloadPopUp
            :itemId="itemId"
            :itemType="itemType"
            :class="downloadNoticePopupCssClass"
            @closedownloaditemnotice="closeDownloadNoticePopUp"
    />
  </div>

</template>


<script>

  import {reactive, computed} from "vue";

  import NoticeMedia from "./NoticeMedia";
  import NoticeArticle from "./NoticeArticle";
  import NoticeExtrait from "./NoticeExtrait";
  import NoticeTDBranch from "./NoticeTDBranch";
  import NoticeDownloadPopUp from "./NoticeDownloadPopUp";
  import BackToTopButton from "../BackToTopButton";

  export default {
    name: 'NoticePopUp',
    components: {
      NoticeMedia,
      NoticeArticle,
      NoticeExtrait,
      NoticeTDBranch,
      NoticeDownloadPopUp,
      BackToTopButton
    },
    props: {
      itemId: {
        type: Number,
        required: true,
      },
      itemType: {
        type: String,
        required: true,
      },
      itemJson: {
        type: Object,
        required: false,
        default: null,
      },
      closeButtonLabel: {
        type: String,
        required: false,
        default: 'Fermer',
      },
      itemsIds: {
        type: Array,
        required: false
      },
      openGraphInPopUp: {
        type:Boolean,
        required: false,
        default: false
      },
    },
    emits: [
      'close-detailed-item-notice',
      'item-loaded',
      'open-item-graph'
    ],
    data() {
      return {
        downloadNoticePopupIsOpened:false,
      }
    },
    computed: {
      isPopUp() {
        return true
      },
      hasTools() {
        return true
      },
      hasMedia() {
        return true
      },
      downloadNoticePopupCssClass() {
        return this.downloadNoticePopupIsOpened === true ? "opened" : "";
      },
      popupNoticeCssClass() {
        return this.state.itemType === "media" ? "popup-notice-media" : "";
      },
      closeLabel() {
        return this.$t( this.state.isNoticeTDBranch ? 'Retour au graphe' : this.closeButtonLabel );
      }
    },
    setup(props, context) {

      // Reactive state
      const state = reactive({
        itemId: props.itemId,
        itemType: props.itemType,
        itemJson: props.itemJson,
        isNoticeItem: computed(() => state.itemType === "item"),
        isNoticeMedia: computed(() => state.itemType === "media"),
        isNoticeTDBranch: computed(() => state.itemType === "td-branch"),
        isNotice: computed(() => state.isNoticeItem || state.isNoticeMedia ),
        isNoticeArticle: computed(() => state.itemType === "article"),
        isNoticeExtrait: computed(() => state.itemType === "extrait"),
        displayMode: computed(() => state.isNoticeMedia ? "media" : "reference"),
        backButton: false,
        backButtonItemTitle: '',
        itemsIds: props.itemsIds,
        openGraphInPopUp: props.openGraphInPopUp
      });

      // Clic sur une des notices/articles associées
      const changeItem = function(newItem) {
        // Chargement de la nouvelle notice ( ou du nouvel article, ...)
        state.itemType = newItem.itemType;
        state.itemId = newItem.itemId;
        state.backButton = true;
        state.backButtonItemTitle = newItem.previousItemTitle;
      };

      // Retour à la notice d'origine( ou de l'article d'origine, ...)
      const clickOnBackButton = function($event) {
        $event.preventDefault();
        state.itemType = props.itemType;
        state.itemId = props.itemId;
        state.backButton = false;
        state.backButtonItemTitle = "";
      };

      const navigationPreviosNextChangeNoticeItem = function(itemId){
        state.itemId = itemId;
      };

      const openItemGraph = function(item) {
        console.log('NoticePopUp - open-item-graph', item);
        // EVENT --> PARENT
        context.emit('open-item-graph', item.id);
      };

      return {
        state,
        changeItem,
        clickOnBackButton,
        navigationPreviosNextChangeNoticeItem,
        openItemGraph
      }

    },
    methods: {
      closeNotice($event) {
        $event.preventDefault();
        // EVENT --> PARENT
        this.$emit('close-detailed-item-notice');
      },
      openDownloadNoticePopUp() {
        this.downloadNoticePopupIsOpened = true;
      },
      closeDownloadNoticePopUp() {
        this.downloadNoticePopupIsOpened = false;
      },
      itemLoaded(itemInfos) {
        // EVENT --> PARENT
        this.$emit('item-loaded', itemInfos);
      },
    }
  }

</script>
