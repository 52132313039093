<!-- Connexion : login -->
<template>
  <div class="connexion-menu-parent popup-parent">
    <div class="connexion-menu-bg"></div>
    <div class="connexion-menu">
      <div class="connexion-menu-close">
        <a href="#" @click="closeWindow($event)">{{ $t("Fermer") }}</a>
      </div>
      <div class="form-parent">
        <form @submit="checkForm">
          <h2>{{ $t("Mot de passe oublié") }}</h2>

          <div class="connexion-form-fields">
            <div class="form-fields">
              <label for="email">{{ $t("Email") }} <span class="required">*</span></label>
              <input type="text" id="email" placeholder="username@domain.org" v-model="state.email" />
            </div>
            <div class="form-fields" v-if="state.emailSuccess">
              <label for="email">{{ $t("Token") }} <span class="required">*</span></label>
              <input type="text" id="token" v-model="state.token" />
            </div>
            <div class="form-fields" v-if="state.emailSuccess">
              <label for="password">{{ $t("Nouveau mot de passe") }} <span class="required">*</span></label>
              <input type="password" id="password" placeholder="password" v-model="state.userpassword" />
            </div>
          </div>

          <div style="font-size: 14px;line-height: 1.3; margin-top: 10px; font-weight: bold;">
            {{ $t(state.responseMessage) }}
          </div>

          <ul class="connexion-form-errors">
            <li v-for="item in state.errorMessages" v-bind:key="item">{{ item }}</li>
          </ul>

          <div class="form-buttons">
            <input type="submit" :value="$t('Envoyer')" />
            <a v-if="state.newPasswordSuccess" href="#" class="button" @click="openLoginWindow($event)">{{ $t("Se connecter") }}</a>
          </div>

        </form>
      </div>
    </div>
  </div>
</template>

<script>

  import axios from "axios";
  import {useStore} from "vuex";
  import {getForgotPasswordApiURL} from "../../js/api";
  import {reactive} from "vue";

  export default {
  name: 'ForgottenPasswordWindow',
  props: {
  },
  emits:[
    'close-forgotten-password-window',
    'open-login-window',
  ],
  data() {
    return {
    }
  },
  methods: {
    closeWindow($event) {
      $event.preventDefault();
      $event.stopPropagation();

      this.state.responseMessage = "";
      this.state.email = "";
      this.state.token = "";
      this.state.userpassword = "";
      this.state.emailSuccess = false;
      this.state.newPasswordSuccess = false;

      this.$emit('close-forgotten-password-window');
    },
    openLoginWindow($event) {
      $event.preventDefault();
      $event.stopPropagation();

      this.state.responseMessage = "";
      this.state.email = "";
      this.state.token = "";
      this.state.userpassword = "";
      this.state.emailSuccess = false;
      this.state.newPasswordSuccess = false;

      this.$emit('open-login-window');
    },

  },
  setup(props, context) {

    const store = useStore();

    // Etape 1 : Envoi du mail
    const askForForgottenPassword = function () {

      // Api Omeka (Guest Api Module)
      const apiURL = getForgotPasswordApiURL(store.getters.apiRoot);

      axios.post(apiURL, {
        "email": state.email
      }).then(function (response) {

        const responseData = response.data;

        if (responseData.status === "error") {

          /* ERROR */
          state.errorMessages = [ responseData.message ];

        } else {

          /* SUCCES */

          state.responseMessage = "Un email vient de vous être envoyé";
          state.emailSuccess = true;

          context.emit('close-login-window');
        }

      }).catch(error => {

        /* ERRORS */

        // Cf https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/

        if (error.response) {

          // 1. Client received an error response (5xx, 4xx)

          const errorsObject = error.response.data.errors;
          let i, errorType, errorsArray, errorsCount;
          let errorMessages = [];

          for (errorType in errorsObject) {
            if (Object.prototype.hasOwnProperty.call(errorsObject, errorType)) {
              errorsArray = errorsObject[errorType];
              if (Array.isArray(errorsArray)) {
                errorsCount = errorsArray.length;
                for(i=0;i<errorsCount;i++) {
                  errorMessages.push(errorsArray[i]);
                }
              } else {
                errorMessages.push(errorsArray);
              }
            }
          }

          state.errorMessages = errorMessages;

        } else if (error.request) {
          // 2. Client never received a response, or request never left ( network error, cross-domain ...)
          console.error("forgot password error2", error.request);
        } else {
          // 3. Anything else
          console.error("forgot password error3", error);
        }

      });
    };

    // Etape 2 : Envoi du token, et du nouveau mot de passe
    const sendTokenAndPassword = function () {
      // Api Omeka (Guest Api Module)
      const apiURL = getForgotPasswordApiURL(store.getters.apiRoot);

      axios.post(apiURL, {
        "email": state.email,
        "token": state.token,
        "password": state.userpassword,

      }).then(function (response) {

        const responseData = response.data;

        if (responseData.status === "error") {

          /* ERROR */
          state.errorMessages = [ responseData.message ];

        } else {

          /* SUCCES */

          state.responseMessage = "Félicitations. Vous pouvez maintenant vous connecter avec votre nouveau mot de passe";
          state.newPasswordSuccess = true;

          context.emit('close-login-window');
        }

      }).catch(error => {

        /* ERRORS */

        // Cf https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/

        if (error.response) {

          // 1. Client received an error response (5xx, 4xx)

          const errorsObject = error.response.data.errors;
          let i, errorType, errorsArray, errorsCount;
          let errorMessages = [];

          for (errorType in errorsObject) {
            if (Object.prototype.hasOwnProperty.call(errorsObject, errorType)) {
              errorsArray = errorsObject[errorType];
              if (Array.isArray(errorsArray)) {
                errorsCount = errorsArray.length;
                for(i=0;i<errorsCount;i++) {
                  errorMessages.push(errorsArray[i]);
                }
              } else {
                errorMessages.push(errorsArray);
              }
            }
          }

          state.errorMessages = errorMessages;

        } else if (error.request) {
          // 2. Client never received a response, or request never left ( network error, cross-domain ...)
          console.error("forgot password error2", error.request);
        } else {
          // 3. Anything else
          console.error("forgot password error3", error);
        }

      });
    };


    const checkForm = function ($event) {

      $event.preventDefault();

      console.log(state.emailSuccess)
      if (state.emailSuccess) {
        if (state.email && state.token && state.userpassword) {
          sendTokenAndPassword();
        }
      } else {
        if (state.email) {
          askForForgottenPassword();
        }
      }
    };

    const state = reactive({
      email: '',
      token: '',
      userpassword: '',
      responseMessage: '',
      errorMessages:[],
      emailSuccess: false,
      newPasswordSuccess: false,
    });

    return {
      state,
      checkForm
    }
  }
}
</script>
