<template>

  <nav
      class="item-navigation"
      :class="navigationCssClass"
  >
    <ul v-if="previousItemId > 0 || nextItemId > 0">
      <li>
        <router-link
                v-if="previousItemId > 0"
                :to="itemRoute(previousItemId)"
                class="previous-item"
        >
          <span class="item-navigation-title">{{ state.previousTitle }}</span>
          <span class="item-navigation-label">{{ $t("Précédent") }}</span>
        </router-link>
      </li>
      <li>
        <router-link
                v-if="nextItemId > 0"
                :to="itemRoute(nextItemId)"
                class="next-item"
        >
          <span class="item-navigation-label">{{ $t("Suivant") }}</span>
          <span class="item-navigation-title">{{ state.nextTitle }}</span>
        </router-link>
      </li>
    </ul>

    <a
        href="#"
        class="close-browser-window"
        @click="closeBrowserWindow($event)">
      {{ $t("Fermer la fenêtre") }}
    </a>

  </nav>

</template>

<script>

  import {getItemApiURL} from "../../js/api";
  import {onMounted, reactive, ref, watch} from "vue";
  import axios from "axios";
  import {useStore} from "vuex";
  import {truncate} from "../../js/functions";
  import {useRoute, useRouter} from "vue-router";
  import {JsonReader} from "../../js/jsonReader";

  export default {
    name: 'NavigationItems',
    components: {
    },
    props: {
      previousItemId: {
        type: Number,
        required: true
      },
      nextItemId: {
        type: Number,
        required: true
      },
      collectionPath: {
        type: String,
        required: false
      },
      sortByParam: {
        type: String,
        required: false
      },
      sortOrderParam: {
        type: String,
        required: false
      },
    },
    data() {
      return {
      }
    },
    setup(props) {

      const route = useRoute();
      const router = useRouter();
      const store = useStore();
      const jsonReader = new JsonReader();

      const state = reactive({
        previousTitle:'',
        nextTitle:'',
      });

      // RQ : Le suivant/précédent est masqué dans le cas d'une notice ouverte à partir d'un autre item via un lien externe ( target _blank )
      let navigationCssClass = ref('');

      const itemRoute = function(itemId) {
        const currentRouterName = route.name;

        return router.resolve( {
          name: currentRouterName,
          query: {
            sort_param: this.sortByParam,
            sort_order: this.sortOrderParam
          },
          params:{
            id: itemId
          }
        });

        // return this.collectionPath + "/item/" + itemId + getSortQueryParameters(this.sortByParam, this.sortOrderParam, true );

      };


      // Promise : API de l'item :
      const loadItemJson = function(itemId) {
        const apiRoot = store.getters.apiRoot;
        const keyIdentity = store.getters.identityAndCredentialParameters;
        const apiURL = getItemApiURL(apiRoot, itemId);
        return axios.get(apiURL + keyIdentity);
      };

      const parseItemTitle = function(itemJson) {

        jsonReader.json = itemJson;
        const currentLanguage = store.state.currentLanguage;

        let title = jsonReader.getLocalizedMetaDataValue('dcterms:alternative', currentLanguage);

        if ((typeof title !== "string") || (title.length === 0)) {
          title = jsonReader.getLocalizedMetaDataValue('dcterms:title', currentLanguage);
        }

        if ((typeof title !== "string") || (title.length === 0)) {
          title = itemJson['o:title'];
        }

        return truncate(title);
      };
      
      const updatePreviousTitle = function() {
        const itemId = props.previousItemId;
        if (itemId > 0) {
          loadItemJson(itemId).then(response => {
            state.previousTitle = parseItemTitle(response.data);
          });
        } else {
          state.previousTitle = '';
        }
      };

      const updateNextTitle = function() {
        const itemId = props.nextItemId;
        if (itemId > 0) {
          loadItemJson(itemId).then(response => {
            state.nextTitle = parseItemTitle(response.data);
          });
        } else {
          state.nextTitle = '';
        }
      };

      // JS : Fermer la fenêtre du navigateur ( liens externes d'une notice )
      const closeBrowserWindow = function($event) {
        $event.stopPropagation();
        console.log('close')
        window.close();
      };

      //
      // Lifecycle Hooks
      //

      onMounted(() => {
        navigationCssClass.value = route.query.from_item ? "hide-navigation" : "";
        updatePreviousTitle();
        updateNextTitle();
      });

      watch( () => props.previousItemId, () => {
        updatePreviousTitle();
      });

      watch( () => props.nextItemId, () => {
        updateNextTitle();
      });

      return {
        state,
        itemRoute,
        navigationCssClass,
        closeBrowserWindow
      };
    }
}
</script>
