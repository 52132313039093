<template>
  <div class="view">
    <div class="breadcrumbs-and-title">
      <div class="breadcrumbs">
        <BreadCrumb :paths="breadcrumbs" />
      </div>
      <h1>Contribuer</h1>
    </div>
    <div class="contents">
      <div class="contents-wrapper">

        <Contribution />

      </div>
    </div>
  </div>
</template>
<script>

  import BreadCrumb from "../components/Header/BreadCrumb";
  import Contribution from "../components/UserAccount/Contribution";

  export default {
    components: {Contribution, BreadCrumb},
    computed: {
      breadcrumbs() {
        return [
          { name: 'Home', label:'Accueil' },
          { label:'Contribuer' }
        ]
      }
    }
  }

</script>