<template>
  <a
     :href="getNoticeURL"
     target="_blank"
     download
     style="cursor: pointer"
     :title="linkLabel"></a>
</template>


<script>

  import {getItemPDFExportApiURL} from "../../js/api";

  export default {
    name: 'NoticePDFDownloadLink',
    props: {
      linkLabel: {
        type: String,
        required: false,
        defaut: "Télécharger"
      },
      resourceId: {
        type: Number,
        required: true,
      }
    },
    computed: {
      getNoticeURL() {
        return getItemPDFExportApiURL(this.$store.getters.downloadRoot, this.resourceId);
      }
    }
  }

</script>
