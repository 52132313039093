<template>

  <div class="pagination-sort-asc-desc">
    <a class="pagination-sort-by-menu" href="#" @click="toggleSortMenu">{{ $t('Trier') }}</a>
    <ul class="sort-modes-menu"
        :class="sortMenuCssClass"
        v-bind:key="state.sortByParam+state.sortOrderParam"
    >
      <li v-for="item in state.sortOptions" :class="selectedCssClass(item.sortBy, item.sortOrder)" v-bind:key="item.label">
        <a
            :href="getRouteWithSortQuery(item.sortBy, item.sortOrder)"
           @click="changeSortQuery($event, item.sortBy, item.sortOrder)"
        >
          {{ $t(item.label) }}
        </a>
      </li>
    </ul>
  </div>

</template>

<script>

  import {onMounted, onUnmounted, reactive, watch} from "vue";
  import {useRoute, useRouter} from "vue-router";

  export default {
    name: 'ListSortMenu',
    computed:{
      sortMenuCssClass() {
        return this.state.isSortMenuOpened ? "sort-modes-menu-opened" : "";
      },
    },
    props: {
      noticeItemType: {
        type: String,
        required: false,
        default:'item'
      },
      sortByParam: {
        type: String,
        required: false,
        default: 'title'
      },
      sortOrderParam: {
        type: String,
        required: false,
        default: 'asc'
      },
      resetQuery: {
        type: Number,
        required: false,
        default: 0
      },
    },
    emits: [
      "changesortquery"
    ],
    data() {
      return {
        categorySliderIndex: 0,
        categorySliderHandleTop:0,
        isSortMenuOpened:false,
      }
    },
    setup(props, context) {

      const route = useRoute();
      const router = useRouter();

      const clickOutside = function() {
        state.isSortMenuOpened = false;
      };

      onMounted(() => {
        document.body.addEventListener("click", clickOutside);
      });

      onUnmounted(() => {
        document.body.removeEventListener("click", clickOutside);
      });

      watch(() => props, (newProps) => {
        state.sortByParam = newProps.sortByParam;
        state.sortOrderParam = newProps.sortOrderParam;
      });

      const changeSortQuery = function($event, sortByParam, sortOrderParam) {
        $event.preventDefault();

        state.sortByParam = sortByParam;
        state.sortOrderParam = sortOrderParam;

        context.emit('changesortquery', {
          sortBy: state.sortByParam,
          sortOrder: state.sortOrderParam,
        });

      };

      const getRouteWithSortQuery = function(sortByParam, sortOrderParam) {

        let routeCopy = {
          name : route.name,
          query : { ...route.query },
          params : { ...route.params }
        };

        routeCopy.query.sort_by = sortByParam;
        routeCopy.query.sort_order = sortOrderParam;

        return router.resolve(routeCopy).href
      }

      const selectedCssClass = function(sortByParam, sortOrderParam) {
         return (state.sortByParam === sortByParam) && (state.sortOrderParam === sortOrderParam)  ? "selected" : "";
      };

      const sortOptions = [
        {
          label: "Par date (récent vers ancien)",
          sortBy: "issued",
          sortOrder: "desc",
        },
        {
          label: "Par date (ancien vers récent)",
          sortBy: "issued",
          sortOrder: "asc",
        },
        {
          label: "Par titre (A à Z)",
          sortBy: "title",
          sortOrder: "asc",
        },
        {
          label: "Par titre (Z à A)",
          sortBy: "title",
          sortOrder: "desc",
        }
      ];

      if (props.noticeItemType === "article") {
        sortOptions.push(
            {
              label: "Par auteur (A à Z)",
              sortBy: "author",
              sortOrder: "asc",
            },
            {
              label: "Par auteur (Z à A)",
              sortBy: "author",
              sortOrder: "desc",
            }
        );
      }


      // Reactive state
      const state = reactive({
        isSortMenuOpened:false,
        sortByParam: props.sortByParam,
        sortOrderParam: props.sortOrderParam,
        sortOptions: sortOptions
      });

      watch(() => props.sortByParam, () => {
        state.sortByParam = props.sortByParam;
      });

      watch(() => props.sortOrderParam, () => {
        state.sortOrderParam = props.sortOrderParam;
      });


      return {
        state,
        changeSortQuery,
        getRouteWithSortQuery,
        selectedCssClass
      }

    },
    methods: {

      toggleSortMenu($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.state.isSortMenuOpened = ! this.state.isSortMenuOpened;
      }

    }
}
</script>
