<template>

  <div>

    <!-- Pagination Top -->
    <div class="list-pagination top-list-pagination with-padding">
      <div class="pagination-sort-options">

        <router-link
          v-if="state.backRoute"
          :to="state.backRoute"
          class="list-back-btn"
        >
          {{ $t(state.backRouteLabel) }}
        </router-link>

        <ListSortMenu
                :notice-item-type="state.defaultNoticeItemType"
                :sortByParam="searchState.sortByParam"
                :sortOrderParam="searchState.sortOrderParam"
                :resetQuery="searchState.resetQuery"
                @changesortquery="changeSortQuery"
        />

      </div>
      <ListCount
              :element-count="state.itemsCount"
              class="cards-count"
      />
      <ListPagination
              v-if="state.pageCount > 1"
              :pageCount="state.pageCount"
              :pageNo="state.pageNo"
              @change-pagination-page="displayPage"
      />
    </div>

    <!-- Titre et descriptions (ex: Curation) -->
    <h2
            v-if="state.listTitle.length"
            class="list-item-title with-padding">
      {{ state.listTitle }}
    </h2>

    <CollectionDescription
            v-if="state.listDescriptions.length"
            class="list-item-description with-padding"
            :descriptions="state.listDescriptions"
    />

    <!-- Liste (ev. avec facettes) -->
    <div class="list-items-and-facets with-padding" :class="hasFacetsCssClass">

      <!-- Facettes -->
      <div class="search-facets">

        <a class="search-facets-global-toggle"
           :class="toggleFacetsPanelsCssClass"
           @click="toggleFacetsPanels($event)">
          {{ $t("Filtres") }}
        </a>

        <div class="advanced-txt-search-field filter-by-text" :class="facetsPanelCssClass">
          <div class="advanced-search-panel" :class="panelCssClass">
            <h3><a href="#" @click="toggleSearchPanel($event)">{{ $t('Rechercher...') }}</a></h3>
            <input type="text" name="searchvalue" id="searchTextInput"
                   class="fulltext-search"
                   :placeholder="$t('Rechercher...')"
                   :value="searchState.searchText"
                   @input="changeSearchInput"
                   @keyup="keyEnterSubmitSearch($event)"
            />
          </div>
        </div>

        <SearchFieldsPanel
                :class="facetsPanelCssClass"
                :advancedQuery="searchState.advancedQuery"
                :resetQuery="searchState.resetQuery"
                :settings="searchState.fieldSetsSettings"
                @change="changeFieldSets"
                @change-settings="changeFieldSetsPanelSettings"
        />

        <CollectionsFilterPanel
                :class="facetsPanelCssClass"
                :advancedQuery="searchState.advancedQuery"
                :resetQuery="searchState.resetQuery"
                :settings="searchState.collectionsSettings"
                @change="changeCollectionsCheckBoxes"
                @change-settings="changeCollectionsCheckBoxesSettings"
        />

        <div class="advanced-search-footer" :class="facetsPanelCssClass" >

          <input type="submit" class="run-advanced-search" :value="$t('Appliquer les filtres')" @click="submitSearch($event)">

          <a href="#" class="reset-advanced-search reset-search-button" @click="resetSearch($event)"><span>{{ $t('Réinitialiser') }}</span></a>

          <!-- Sauvegarde de la recherche -->
          <div class="search-name-form" v-if="state.userIsConnected">

            <a href="#"
               class="save-advanced-search save-search-button"
               @click="toggleSaveSearchForm($event)">Sauvegarder</a>

            <input type="text" name="searchvalue" id="searchNameInput"
                   v-if="state.isSearchFormOpened"
                   class="search-name-input"
                   placeholder="Nommer la recherche..."
                   :value="searchState.searchName"
                   @input="changeSearchName"
            />

            <p class="search-name-form-message" v-if="state.searchFormMessage.length">{{ state.searchFormMessage }}</p>

            <a href="#"
               v-if="state.isSearchFormOpened"
               class="save-search-name"
               @click="saveSearch($event)">
              {{ state.searchFormMessageSubmitLabel }}
            </a>
          </div>

        </div>

      </div>

      <div class="list-items-parent">

        <div class="list-pagination list-pagination-mobile">
          <div class="pagination-sort-options">
            <ListSortMenu
                    @changesortquery="changeSortQuery"
                    :sortByParam="searchState.sortByParam"
                    :sortOrderParam="searchState.sortOrderParam"
                    :resetQuery="searchState.resetQuery"
            />
          </div>
        </div>

        <!-- Liste des Items -->
        <ul class="list-items" v-if="state.displayedItems.length">
          <li
                  v-for="item in state.displayedItems"
                  :key="item.id"
                  class="list-item"
                  :class="listItemCssClass(item)"
          >

            <router-link class="item-title" :to="itemRoute(item)">
              <span>
                <span class="item-collection-name-code">{{ item.collectionCode ? item.collectionCode : '' }}</span>
                {{ item.title }}
              </span>
            </router-link>

            <span class="item-icons">
              <a v-if="showItemGraphButton && item.icon"
                 class="notice-graphe"
                 :href="itemURL(item)"
                 @click="openItemVisualization($event, item.id)"
              ></a>
              <a v-if="showItemNoticeButton"
                 class="notice-icon"
                 :href="itemURL(item)"
                 @click="openDetailedNotice($event, item.id)"
              ></a>
              <a v-if="state.userIsConnected"
                 class="basket-icon"
                 @click="toggleItemInBasket($event, item.id)"
                 :class="itemInBasketCssClass(item.id)"
              ></a>
              <NoticePDFDownloadLink
                  v-if="state.userIsConnected"
                  class="download"
                  :resource-id="item.id"
                  :title="$t('Exporter en PDF')" />
            </span>
          </li>
        </ul>

        <div class="no-results with-padding" v-if="state.displayedItems.length === 0">Aucun résultat</div>
      </div>
    </div>

    <!-- Pagination Bottom -->
    <div class="list-pagination bottom-list-pagination with-padding" v-if="state.pageCount > 0">
      <div class="pagination-sort-options">
      </div>
      <ListPagination
              v-if="state.pageCount > 1"
              :pageCount="state.pageCount"
              :pageNo="state.pageNo"
              @change-pagination-page="displayPage"
      />
    </div>

    <Coins v-if="state.coinQuery" :query="state.coinQuery" />
  </div>

</template>

<script>

  import { reactive, computed } from 'vue'
  import {useStore} from "vuex";
  import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";
  import {getAdvancedSearchQuery, getSortQueryParameters, getQueryParameterForIds} from "../../js/api";
  import ListPagination from './ListPagination';
  import ListCount from "./ListCount";
  import ListSortMenu from "./ListSortMenu";
  import SearchFieldsPanel from "../Search/SearchFieldsPanel";
  import CollectionsFilterPanel from "../Search/CollectionsFilterPanel";
  import CollectionDescription from "../Header/CollectionDescription";
  import Coins from "../Coins";
  import NoticePDFDownloadLink from "../Notices/NoticePDFDownloadLink";
  import {JsonReader} from "@/js/jsonReader";

  export default {
    name: 'ListItems',
    components: {
      CollectionDescription,
      CollectionsFilterPanel,
      ListSortMenu,
      ListPagination,
      ListCount,
      SearchFieldsPanel,
      Coins,
      NoticePDFDownloadLink
    },
    props: {
      facets: {
        type: Boolean,
        required: false,
        default:false,
      },
      advancedQuery: {
        type: String,
        required: false
      },
      items: {
        type: Array,
        required: true
      },
      queryRouteName: {
        type: String,
        required: false
      },
      backRoute: {
        type: Object,
        required: false
      },
      backRouteLabel: {
        type: String,
        required: false,
        default:"Retour"
      },
      itemRouteFolder: {
        type: String,
        required: false
      },
      noticeItemType: {
        type: String,
        required: false,
        default:'item'
      },
      pageNo: {
        type: Number,
        required: false,
        default:1
      },
      itemsByPage: {
        type: Number,
        required: false,
        default:15
      },
      titleFieldName: {
        type:String,
        required: false,
        default:'o:title'
      },
      listTitle: {
        type:String,
        required: false,
        default:''
      },
      listDescriptions: {
        type:Array,
        required: false
      },
      showItemGraphButton: {
        type: Boolean,
        required: false,
        default:false
      },
      showItemNoticeButton: {
        type: Boolean,
        required: false,
        default:false
      }
    },
    data() {
      return {
        isSortMenuOpened:false
      }
    },
    emits: [
      "openitem",
      "openitemvisualization",
      "opendetailednotice",
      "downloaditemnotice",
    ],
    setup(props, context) {

      const route = useRoute();
      const router =  useRouter();
      const store = useStore();
      const jsonReader = new JsonReader();

      const state = reactive({
        pageNo: props.pageNo,
        pageCount: computed(() => props.items ? Math.ceil( props.items.length / props.itemsByPage ) : 0),

        displayedItems: computed( () => {
          if (! props.items) {
            return [];
          } else {
            const startItemNo = props.itemsByPage * (state.pageNo - 1);
            const endItemNo = Math.min(startItemNo + props.itemsByPage, props.items.length);
            const currentLanguage = store.state.currentLanguage;

            let i, items = [], item, defaultTitle;

            for (i=startItemNo;i<endItemNo;i++)
            {
              item = props.items[i];
              item.id = item['o:id'];

              // o:title (RQ : il est en français quelle que soit la langue)
              defaultTitle = item[props.titleFieldName];

              if (! currentLanguage || (currentLanguage === "") || (currentLanguage === "fr")) {
                item.title = defaultTitle;
              }

              if ((typeof item.title !== "string") || (item.title.length === 0)) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                jsonReader.json = item;
                item.title = jsonReader.getLocalizedMetaDataValue('dcterms:alternative', currentLanguage, defaultTitle);
              }

              items.push(item);
            }

            return items;
          }
        }),
        displayedItemsIds: computed(() => {
          let i, itemsIds = [];
          let items = state.displayedItems;
          const n = items.length;
          for(i=0;i<n;i++) {
            itemsIds.push(items[i].id);
          }
          return itemsIds;
        }),
        itemsCount: computed(() => props.items.length),
        resultsCount: computed(() => {
          const resultsCount = props.items.length;
          if (resultsCount === 0) {
            return "Aucun résultat";
          } else if (resultsCount === 1) {
            return "1 résultat";
          } else {
            return resultsCount + " résultats";
          }
        }),

        listTitle: props.listTitle,
        listDescriptions:props.listDescriptions || [],
        defaultNoticeItemType: props.noticeItemType,

        backRoute: props.backRoute,
        backRouteLabel: props.backRouteLabel,

        userIsConnected: computed(() => store.state.currentUser.logged ),
        isSearchFormOpened:false,
        searchFormMessage: "",
        searchFormMessageSubmitLabel: computed(() => state.searchFormMessage.length ? "Mettre à jour" : "Enregistrer" ),
        coinQuery: computed(() => getQueryParameterForIds(state.displayedItemsIds))
      });

      const itemInBasketCssClass = function(itemId) {
        return store.getters.itemInBasket(itemId, "item") ? "selected" : "";
      };

      const toggleItemInBasket = function($event, itemId) {
        $event.preventDefault();
        store.dispatch( store.getters.itemInBasket(itemId, "item") ? "removeFromBasket" : "addToBasket", { type: "item", id: parseInt(itemId) } );
      };

      const downloadItemNotice = function($event, itemId) {
        $event.preventDefault();
        context.emit("downloaditemnotice", { id: itemId });
      };


      /* Search State */

      const searchState = reactive({
        selectedCollectionIds: null,
        fieldSets: null,
        searchText: route.query.q,
        sortByParam: route.query.sort_by,
        sortOrderParam: route.query.sort_order,
        advancedQuery: props.advancedQuery,
        resetQuery: 0,
      });

      // On récupère le texte recherché pour initialiser le champ de recherche
      if (props.advancedQuery)
      {
        let initialFieldSetsParams = [];

        props.advancedQuery.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) { initialFieldSetsParams[key] = value; });

        // Récupération du titre cherché :
        let fulltext_search = "";
        if (props.advancedQuery.indexOf('property[0][property]=&') !== -1 ) {
          fulltext_search = initialFieldSetsParams['property[0][text]'];
        } else if (props.advancedQuery.indexOf('q=') !== -1 ) {
          fulltext_search = initialFieldSetsParams['q'];
        }

        searchState.searchText = fulltext_search;
        searchState.sortByParam = route.query.sort_by;
        searchState.sortOrderParam = route.query.sort_order;

        // console.log(searchState.searchText, searchState.sortByParam, searchState.sortOrderParam)
      }

      onBeforeRouteUpdate((to) => {
        state.pageNo = isNaN(to.query.p) ? 1 : parseInt(to.query.p);
      });


      // Fonctions :

      const changeFieldSets = function( fieldSets ) {
        searchState.fieldSets = fieldSets;
      };

      const changeCollectionsCheckBoxes = function( collectionsIds ) {
        searchState.selectedCollectionIds = collectionsIds;
      };

      const changeSearchInput = function() {
        searchState.searchText = document.getElementById('searchTextInput').value;
      };

      const changeSortQuery = function( changes ) {
        searchState.sortByParam = changes.sortBy;
        searchState.sortOrderParam = changes.sortOrder;

        // On conserve la route actuelle, en modifiant les paramètres de tri
        let routeCopy = {
          name : route.name,
          query : { ...route.query },
          params : { ...route.params }
        };

        routeCopy.query.sort_by = searchState.sortByParam;
        routeCopy.query.sort_order = searchState.sortOrderParam;

        router.push(routeCopy);
      };

      const changeFieldSetsPanelSettings = function( settings ) {
        searchState.fieldSetsSettings = settings;
        store.dispatch("updateSearchSettings", { panel: "fieldsets", opened: settings.opened });
      };

      const changeCollectionsCheckBoxesSettings = function( settings ) {
        searchState.fieldSetsSettings = settings;
        store.dispatch("updateSearchSettings", { panel: "collections", opened: settings.opened });
      };

      const toggleFacetsPanels = function($event) {
        $event.preventDefault();
        searchState.isGlobalSearchPanelsOpened = ! searchState.isGlobalSearchPanelsOpened;
        store.dispatch("updateSearchSettings", { panel: "global", opened: searchState.isGlobalSearchPanelsOpened });
      };

      const toggleSearchPanel = function($event) {
        $event.preventDefault();
        searchState.isSearchPanelOpened = ! searchState.isSearchPanelOpened;
        store.dispatch("updateSearchSettings", { panel: "textfield", opened: searchState.isSearchPanelOpened });
      };

      const toggleSaveSearchForm = function($event) {
        $event.preventDefault();
        state.isSearchFormOpened = ! state.isSearchFormOpened;
      };

      const saveSearch = function($event) {
        $event.preventDefault();
        const searchName = document.getElementById('searchNameInput').value;

        // On enregistre la recherche
        // en vérifiant si une autre recherche du même nom existe déjà
        store.dispatch( "addSearch", {
          query: getAdvancedQuery(),
          name: searchName,
          force: state.searchFormMessage.length > 0
        }).then(function (response) {
          if (response.success) {
            state.isSearchFormOpened = false;
          } else {
            searchState.searchName = searchName;
            state.searchFormMessage = response.message ? response.message : "";
          }
        });
      };

      const resetSearch = function($event) {
        $event.preventDefault();

        searchState.selectedCollectionIds = store.getters.searchCollectionsIds();
        searchState.advancedQuery = getAdvancedQuery();
        searchState.resetQuery ++;

        router.push({
          name: "Recherche Avancée",
          params : { s: getAdvancedQuery() },
          query : { sort_by: searchState.sortByParam, sort_order: searchState.sortOrderParam }
        });
      };

      // Etats de l'interface : groupes, facettes et aytres contrôles
      const searchSettings = store.state.searchSettings;
      searchState.fieldSetsSettings = searchSettings.fieldsets;
      searchState.collectionsSettings = searchSettings.collections;
      searchState.isSearchPanelOpened = searchSettings.textfield.opened;
      searchState.isGlobalSearchPanelsOpened = searchSettings.global.opened;

      const changeSearchName = function() {
        state.searchFormMessage = "";
      };

      const keyEnterSubmitSearch = function($event) {
        if ($event.keyCode === 13 ) {
          submitSearch($event);
        }
      };

      const submitSearch = function($event) {
        $event.preventDefault();

        router.push({
          name: "Recherche Avancée",
          params : { s: getAdvancedQuery() },
          query : { sort_by: searchState.sortByParam, sort_order: searchState.sortOrderParam }
        });
      };

      const getAdvancedQuery = function() {
        // console.log(searchState.fieldSets)
        return getAdvancedSearchQuery(searchState.fieldSets, searchState.selectedCollectionIds, searchState.searchText);
      };

      return {
        state,
        itemInBasketCssClass,
        toggleItemInBasket,
        downloadItemNotice,
        searchState,
        changeSearchInput,
        toggleSearchPanel,
        changeFieldSets,
        changeFieldSetsPanelSettings,
        changeCollectionsCheckBoxes,
        changeCollectionsCheckBoxesSettings,
        changeSortQuery,
        resetSearch,
        toggleSaveSearchForm,
        toggleFacetsPanels,
        saveSearch,
        changeSearchName,
        submitSearch,
        keyEnterSubmitSearch
      }
    },
    computed:{
      currentRoutePath() {
        return this.$route.path;
      },
      hasFacetsCssClass() {
        return this.facets ? "with-facets" : "without-facets";
      },
      panelCssClass() {
        return this.searchState.isSearchPanelOpened ? "" : "search-panel-closed";
      },
      facetsPanelCssClass() {
        return this.searchState.isGlobalSearchPanelsOpened ? "" : "facets-panel-closed";
      },
      toggleFacetsPanelsCssClass() {
        return this.searchState.isGlobalSearchPanelsOpened ? "opened" : "";
      }
    },
    methods:{
      listItemCssClass(item) {
        return item.colorNo ? "list-item-category-" + item.colorNo : "";
      },
      clickItem($event, itemId) {
        $event.preventDefault();
        this.$emit('openitem', itemId);
      },
      displayPage(pageNo) {
        // On modifie la route en cours avec le paramètre p=...
        // Ce changement déclenchera, via onBeforeUpdate
        // de ce composant ListeItems, l'affichage de la nouvelle page

        let routeCopy = {
          name : this.$route.name,
          query : { ...this.$route.query },
          params : { ...this.$route.params }
        };

        // Nouvelle page
        routeCopy.query.p = pageNo;

        this.$router.push(routeCopy);
      },
      openDetailedNotice($event, itemId) {
        $event.preventDefault();

        // EVENT --> PARENT
        this.$emit('opendetailednotice', itemId);
      },
      openItemVisualization($event, itemId) {
        $event.preventDefault();

        // EVENT --> PARENT
        this.$emit('openitemvisualization', itemId, this.queryRouteName);
      },
      itemRoute(item) {

        // console.log("itemRoute", this.queryRouteName, this.advancedQuery)

        let url, urlWithSearchParams = false;

        if (this.itemRouteFolder !== undefined) {

          // CollectionView ( route standard ex : '/item/xxx' )
          url = this.itemRouteFolder + "/" + item.id;

        } else if (this.queryRouteName) {

          // Ex : Bibliographie, Corpus, Iconographie ( routeName de la collection + "Item" ), Curation
          const routeName = this.queryRouteName + (this.queryRouteName.indexOf(" Item") !== - 1 ? "" : " Item");

          let hrefWithRouterBase;

          if (this.queryRouteName !== "Recherche Avancée Item") {

            hrefWithRouterBase = this.$router.resolve({
              name: routeName,
              params: { id: item.id, itemset: item.itemset }
            }).href;

          } else {

            // Item retourné par la Recherche avancée

            hrefWithRouterBase = this.$router.resolve({
              name: routeName,
              params: { id: item.id, s: this.advancedQuery.split('?').join('') }
            }).href;

            // console.log("itemRoute4", this.advancedQuery, hrefWithRouterBase)

          }

          url = hrefWithRouterBase.split(this.$store.state.publicPath).join('/');

          urlWithSearchParams = true;

        } else if (item.route !== undefined) {

          // Recherche, Encyclopédie, Parcourir ( chemins spécifiques au site )
          url = item.route + "/item/" + item.id;

        } else {

          // Sinon :
          url = this.$route.path + "/item/" + item.id;

        }

        url += urlWithSearchParams ? "&" : "?";
        url += "lang=" + this.$store.state.currentLanguage;

        // console.log("itemRoute URL", url);

        return url + getSortQueryParameters(this.searchState.sortByParam, this.searchState.sortOrderParam, false);
      },
      itemURL(item) {
        return this.$router.resolve(this.itemRoute(item)).href;
      }
    }
}
</script>
