<template>
  <div class="popup-annotation-item popup-parent">
    <div class="annotations-tools">
      <a
          class="download"
          href="#">Télécharger le contenu</a>
      <a
          v-if="state.isUserConnected"
          class="save-for-later"
          href="#">Enregistrer pour plus tard</a>
      <a
          v-if="state.isUserConnected"
          class="share"
          href="">Créer un lien de partage</a>
    </div>
    <div class="annotations">
      <h2>Annotations</h2>
      <NewAnnotation />
      <div class="annotations-thread">
        <CurrentUserAnnotation />
        <OtherUserAnnotation />
      </div>
    </div>
  </div>
</template>

<script>

  import NewAnnotation from "./NewAnnotation";
  import CurrentUserAnnotation from "./CurrentUserAnnotation";
  import OtherUserAnnotation from "./OtherUserAnnotation";
  import {computed, reactive} from "vue";
  import {useStore} from "vuex";

  export default {
    name: 'Annotations',
    components: {
      OtherUserAnnotation,
      CurrentUserAnnotation,
      NewAnnotation
    },
    props: {
    },
    methods: {
    },
    setup() {

      const store = useStore();

      const state = reactive({
        userId: computed(() => parseInt(store.getters.userId) ),
        isUserConnected: computed(() => store.getters.isUserConnected ),
      });

      return {
        state
      };

    }

  }

</script>
