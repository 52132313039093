
function truncate (str, maxlength = 50) {
    return (str.length + 3) <= maxlength ? str : (str.substring(0, maxlength) + '...');
}

function capitalizeFirstChar (str) {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
}

function getLocalizedValue(metadataValues, language = "fr", defaultValue = "") {
    let value = defaultValue;
    if (Array.isArray(metadataValues)) {
        let i, metadataValue, valueLanguage, n = metadataValues.length;
        for (i = 0; i < n; i++) {
            metadataValue = metadataValues[i];
            valueLanguage = metadataValue['@language'];
            if (valueLanguage && (valueLanguage.substr(0,2).toLowerCase() === language)) {
                value = metadataValue['@value'];
                break;
            }
        }
        if (! value && (n > 0)) {
            value = metadataValues[0]['@value'];
        }
    }
    return value;
}

function isChronologie (pageJson) {
    if (pageJson['o:slug'] === "chronologie") {
        return true;
    } else {
        // Sinon, on regarde si la page a une Timeline
        const pageBlocks = pageJson["o:block"];
        if (pageBlocks) {
            const n = pageBlocks.length;
            let i, pageBlock;
            for (i = 0; i < n; i++) {
                pageBlock = pageBlocks[i];
                if (pageBlock["o:layout"] === "timeline") {
                    return true;
                }
            }
        }
        return false;
    }
}

function isGeographie (pageJson) {
    if (pageJson['o:slug'] === "geographie") {
        return true;
    } else {
        // Sinon, on regarde si la page a une carte
        const pageBlocks = pageJson["o:block"];
        if (pageBlocks) {
            const n = pageBlocks.length;
            let i, pageBlock;
            for (i = 0; i < n; i++) {
                pageBlock = pageBlocks[i];
                if (pageBlock["o:layout"] === "mappingMapQuery") {
                    return true;
                }
            }
        }
        return false;
    }
}

function validateEmail(email) {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export {
    truncate,
    capitalizeFirstChar,
    getLocalizedValue,
    isChronologie,
    isGeographie,
    validateEmail
}