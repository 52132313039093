<template>

    <div class="gallery">

      <div class="list-pagination top-list-pagination with-padding"  >
        <div class="pagination-sort-options">

          <ListSortMenu
              :notice-item-type="state.defaultNoticeItemType"
              :sortByParam="state.sortByParam"
              :sortOrderParam="state.sortOrderParam"
              @changesortquery="changeSortQuery"
          />

        </div>

        <ListCount
                :element-count="itemsCount"
                class="cards-count"
        />
        <ListPagination
              v-if="state.pageCount > 1"
              :pageCount="state.pageCount"
              :pageNo="state.pageNo"
              @change-pagination-page="displayPage"
        />
      </div>

      <Loading class="notice-item-content with-padding no-header" v-if="state.isLoading" />

      <div class="cards with-padding"  v-if="! state.isLoading">
        <div class="card"
             v-for="item in state.items"
             :key="item.id">
          <div class="card-tools">
            <a class="add-to-basket"
               href="#"
               v-if="state.userIsConnected"
               @click="toggleItemInBasket($event, item['o:id'])"
               :class="itemInBasketCssClass(item['o:id'])"
            ></a>
            <NoticePDFDownloadLink
                v-if="state.userIsConnected"
                class="download"
                :resource-id="item['o:id']"
                :title="$t('Exporter en PDF')"
            />
          </div>

          <router-link class="item-title" :to="itemRoute(item['o:id'])">
            <div class="image" :class="thumbnailCssClass(item)">
              <img :src="mediumThumbnail(item)" alt="" v-if="mediumThumbnail(item).length" />
            </div>
            <div class="card-title">
              {{ item.title }}
            </div>
          </router-link>
        </div>
      </div>

      <div class="list-pagination bottom-list-pagination with-padding" v-if="!state.isLoading && (state.pageCount > 0)">
        <div class="pagination-sort-options">
        </div>
        <ListPagination
                v-if="state.pageCount > 1"
                :pageCount="state.pageCount"
                :pageNo="state.pageNo"
                @change-pagination-page="displayPage"
        />
      </div>
      <Coins v-if="state.coinQuery" :query="state.coinQuery" />
    </div>

</template>

<script>

  import {reactive, onMounted, computed, watch} from "vue";
  import Loading from "../../components/Loading";
  import ListPagination from "../../components/Lists/ListPagination";
  import ListCount from "../../components/Lists/ListCount";
  import ListSortMenu from "../../components/Lists/ListSortMenu";
  import Coins from "../Coins";
  import NoticePDFDownloadLink from "../Notices/NoticePDFDownloadLink";
  import {useStore} from "vuex";
  import {getCollectionsApiURL, getQueryParameterForIds, getSortQueryParameters} from "../../js/api";
  import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";
  import {JsonReader} from "../../js/jsonReader";

  export default {

    name: 'CollectionGallery',
    components: {
      ListPagination,
      ListSortMenu,
      ListCount,
      Loading,
      Coins,
      NoticePDFDownloadLink
    },
    props: {
      itemSetId: {
        type: Number,
        required: true,
        default:0
      },
      pageNo: {
        type: Number,
        required: false,
        default:1
      },
      itemsByPage: {
        type: Number,
        required: false,
        default:10
      },
      titleFieldName: {
        type:String,
        required: false,
        default:'o:title'
      },
      itemsCount: {
        type: Number,
        required: false,
        default:0
      },
      itemRouteName: {
        type: String,
        required: false,
        default:'Item'
      },
      noticeItemType: {
        type: String,
        required: true,
        default:"item"
      }
    },
    emits: [
      "downloaditemnotice",
      "click-item"
    ],
    setup(props, context) {

      const route = useRoute();
      const router = useRouter();
      const store = useStore();
      const jsonReader = new JsonReader();

      const updateDisplayedItems = function() {

        const currentLanguage = store.state.currentLanguage;
        const items = state.items;

        let i, item, defaultTitle;
        for(i=0;i<items.length;i++)
        {
          item = items[i];
          item.id = item['o:id'];

          // o:title (RQ : il est en français quelle que soit la langue)
          defaultTitle = item[props.titleFieldName];

          if (!currentLanguage || (currentLanguage === "") || (currentLanguage === "fr")) {
            item.title = defaultTitle;
          }

          if ((typeof item.title !== "string") || (item.title.length === 0) || (currentLanguage !== "fr")) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            jsonReader.json = item;
            item.title = jsonReader.getLocalizedMetaDataValue('dcterms:alternative', currentLanguage, defaultTitle);
          }
        }
      }

      const loadItems = function() {

        state.isLoading = true;

        let options = "";
        options += '&page='+ props.pageNo;
        options += '&per_page='+ props.itemsByPage;

        const apiURL = getCollectionsApiURL(
          store.getters.apiRoot,
          [ props.itemSetId ],
          options,
          state.sortByParam,
          state.sortOrderParam,
        );

        store.dispatch('getApi', apiURL).then(function(response){
          state.items = response.data;
          state.isLoading = false;
          updateDisplayedItems();
        });

      };


      //
      // Lifecycle Hooks
      //

      onMounted(() => {

        if (route.query.sort_by && (route.query.sort_by.length > 0)) {
          state.sortByParam = route.query.sort_by;
        }

        if (route.query.sort_order && (route.query.sort_order.length > 0)) {
          state.sortOrderParam = route.query.sort_order;
        }

        if (props.itemSetId !== 0) {
          loadItems();
        }
      });

      watch(() => props.itemSetId, () => {
        loadItems();
      });

      watch(() => props.pageNo, () => {
        state.pageNo = props.pageNo;
        loadItems();
      });

      watch( () => store.state.currentLanguage, () => {
        updateDisplayedItems();
      });

      onBeforeRouteUpdate((to, from, next) => {

        const toQuerySortBy = to.query.sort_by ? to.query.sort_by : 'title';
        const toQuerySortOrder = to.query.sort_order ? to.query.sort_order : 'asc';

        if ((state.sortByParam !== toQuerySortBy) || (state.sortOrderParam !== toQuerySortOrder)) {

          state.sortByParam = toQuerySortBy;
          state.sortOrderParam = toQuerySortOrder;
          state.pageNo = isNaN(to.query.p) ? 1 : parseInt(to.query.p);

          loadItems();
          next();

        } else {

          // Pagination
          state.pageNo = isNaN(to.query.p) ? 1 : parseInt(to.query.p);
          next();

        }
      });

      const changeSortQuery = function( changes ) {
       router.push({
          name: route.name,
          query : { sort_by : changes.sortBy, sort_order : changes.sortOrder }
        });
      };


      /* Page State */

      const state = reactive({
        items: [],
        pageNo: props.pageNo,
        pageCount: computed(() => Math.ceil( props.itemsCount / props.itemsByPage )),
        displayedItemsIds: computed(() => {
          let i, itemsIds = [];
          let items = state.items;
          const n = items.length;
          for(i=0;i<n;i++) {
            itemsIds.push(items[i]['o:id']);
          }
          return itemsIds;
        }),
        defaultNoticeItemType: props.noticeItemType,
        sortByParam: 'title',
        sortOrderParam: 'asc',
        userIsConnected: computed(() => store.state.currentUser.logged ),
        coinQuery: computed(() => getQueryParameterForIds(state.displayedItemsIds))
      });

      const itemInBasketCssClass = function(itemId) {
        return store.getters.itemInBasket(itemId, "item") ? "selected" : "";
      };

      const toggleItemInBasket = function($event, itemId) {
        $event.preventDefault();
        store.dispatch( store.getters.itemInBasket(itemId, "item") ? "removeFromBasket" : "addToBasket", { type: "item", id: parseInt(itemId) } );
      };

      const downloadItemNotice = function($event, itemId) {
        $event.preventDefault();
        context.emit("downloaditemnotice", { id: itemId });
      };

      return {
        state,
        itemInBasketCssClass,
        toggleItemInBasket,
        downloadItemNotice,
        changeSortQuery
      }

    },
    computed: {
      currentRoutePath() {
        return this.$route.path;
      }
    },
    methods: {
      thumbnailCssClass(item) {
        return this.getThumbnailURL(item, 'medium') ? "with-media" : "without-media";
      },
      mediumThumbnail(item) {
        return this.getThumbnailURL(item, 'medium');
      },
      getThumbnailURL(item, size){
        const thumbnails = item['o:thumbnail_urls'];
        if (thumbnails && thumbnails[size]) {
          return thumbnails[size];
        }
        const thumbnails_display_urls = item['thumbnail_display_urls'];
        if (thumbnails_display_urls && thumbnails_display_urls[size]) {
          return thumbnails_display_urls[size];
        }
        return "";
      },
      clickItem($event, itemId) {
        $event.preventDefault();
        this.$emit('click-item', {
          "itemId"   : itemId
        });
      },
      displayPage(pageNo) {

        let routeCopy = {
          name : this.$route.name,
          query : { ...this.$route.query },
          params : { ...this.$route.params }
        };

        // Nouvelle page
        routeCopy.query.p = pageNo;

        this.$router.push(routeCopy);
      },
      itemRoute(itemId) {

        let itemURL = this.currentRoutePath + "/item/" + itemId + getSortQueryParameters(this.state.sortByParam, this.state.sortOrderParam, true);

        const lang = this.$store.state.currentLanguage;
        if ( lang !== "fr") {
          itemURL  += "&lang=" + lang;
        }

        return itemURL;
      },

    }
  }

</script>
