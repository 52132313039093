<template>

  <div class="list-item list-reference">
    <p>{{ capitalizeText(title) }}</p>
    <ul class="list-items list-items-children">
      <li
          v-for="item in state.items"
          :key="item.id"
          class="list-item"
          :class="listItemCssClass(item)"
      >
        <router-link class="item-title" :to="itemRoute(item)">
          <span class="item-title-parent">
            {{ capitalizeText(title) }}
          </span>
          <span class="item-title-child">
            {{ capitalizeText(itemTitle(item)) }}
          </span>
        </router-link>

        <span class="item-icons">
          <a v-if="showItemGraphButton && item.icon"
             class="notice-graphe"
             href="#"
             @click="openItemVisualization($event, item.id)"
          ></a>
          <a v-if="showItemNoticeButton"
             class="notice-icon"
             href="#"
             @click="openDetailedNotice($event, item.id, item.type)"
          ></a>
          <a class="basket-icon"
             v-if="state.userIsConnected"
             @click="toggleItemInBasket($event, item.id)"
             :class="itemInBasketCssClass(item.id)"
          ></a>
          <NoticePDFDownloadLink
              v-if="state.userIsConnected"
              class="download"
              :resource-id="item.id"
              :title="$t('Exporter en PDF')" />
        </span>
      </li>
    </ul>
  </div>

</template>

<script>

  import { reactive, computed } from 'vue'
  import {useStore} from "vuex";
  import NoticePDFDownloadLink from "../Notices/NoticePDFDownloadLink";

  export default {
    name: 'ListReferenceItems',
    components: {
      NoticePDFDownloadLink
    },
    props: {
      title: {
        type: String,
        required: true
      },
      items: {
        type: Array,
        required: false
      },
      titleFieldName: {
        type:String,
        required: true,
        default:'o:title'
      },
      queryRouteName: {
        type: String,
        required: false
      },
      showItemGraphButton: {
        type: Boolean,
        required: false,
        default:false
      },
      showItemNoticeButton: {
        type: Boolean,
        required: false,
        default:false
      },
    },
    emits: [
      "openitem",
      "openitemvisualization",
      "opendetailednotice",
      "downloaditemnotice"
    ],
    methods:{
      listItemCssClass(item) {
        return item.colorNo ? "list-item-category-" + item.colorNo : "";
      },
      clickItem($event, itemId) {
        $event.preventDefault();
        this.$emit('openitem', itemId);
      },
      openDetailedNotice($event, itemId, itemType) {
        $event.preventDefault();

        // EVENT --> PARENT
        this.$emit('opendetailednotice', itemId, itemType);
      },
      openItemVisualization($event, itemId) {
        $event.preventDefault();

        // EVENT --> PARENT
        this.$emit('openitemvisualization', itemId);
      },
      itemRoute(item) {

        let url;

        if (this.queryRouteName) {

          // Ex : Bibliographie, Corpus, Iconographie ( routeName de la collection + "Item" ), Curation
          const routeName = this.queryRouteName + (this.queryRouteName.indexOf(" Item") !== - 1 ? "" : " Item");

          let hrefWithRouterBase = this.$router.resolve({
            name: routeName,
            params: { id: item.id, s: this.advancedQuery }
          }).href;

          url = hrefWithRouterBase.split(this.$store.state.publicPath).join('/');

        } else if (item.route !== undefined) {

          console.log(item.route)

          // Recherche, Encyclopédie, Parcourir ( chemins spécifiques au site )
          url = item.route + "/item/" + item.id;

        } else {

          // Sinon :
          url = this.$route.path + "/item/" + item.id;

        }

        url +=  "?lang=" + this.$store.state.currentLanguage;

        return url;
      },
      capitalizeText: function (title = '') {
        return title.charAt(0).toUpperCase() + title.slice(1);
      },
    },
    setup(props, context){

      const store = useStore();

      const state = reactive({
        userIsConnected: computed(() => store.state.currentUser.logged ),
        items: props.items
      });

      const itemInBasketCssClass = function(itemId) {
        return store.getters.itemInBasket(itemId, "item") ? "selected" : "";
      };

      const toggleItemInBasket = function($event, itemId) {
        $event.preventDefault();
        store.dispatch( store.getters.itemInBasket(itemId, "item") ? "removeFromBasket" : "addToBasket", { type: "item", id: parseInt(itemId) } );
      };

      const downloadItemNotice = function($event, itemId) {
        $event.preventDefault();
        context.emit("downloaditemnotice", { id: itemId });
      };

      const itemTitle = function(item) {
        const titleMetaData = item[props.titleFieldName];
        if (Array.isArray(titleMetaData) && titleMetaData.length) {
          const firstValue = titleMetaData[0]; // ex : dcterms:title
          if (firstValue.type === "literal") {
            return firstValue["@value"];
          } else if (firstValue["display_title"]) {
            // Exemple : Custom vocab :
            return firstValue["display_title"];
          }
        }
        // Par défaut :
        return titleMetaData; // ex : o:title
      };

      return {
        state,
        itemInBasketCssClass,
        toggleItemInBasket,
        downloadItemNotice,
        itemTitle
      }
    }
  }
</script>
