<template>
  <div class="back-to-top cd-top" :id="buttonId"><a href="#"></a></div>
</template>

<script>
  export default {
    name: 'BackToTopButton',
    data() {
      return {
        offset: 10, // browser window scroll (in pixels) after which the "back to top" link is shown
        offsetOpacity: 10,  //browser window scroll (in pixels) after which the "back to top" link opacity is reduced
        scrollDuration: 700,
        scrolling: false,
      }
    },
    props: {
      buttonId: {
        type: String,
        required: false,
        default:"backToTop"
      },
      scrollTarget: {
        type: String,
        required: false,
        default:"window"
      },
    },
    methods: {
      hasClass(el, className) {
        if (el.classList) return el.classList.contains(className);
        else return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
      },
      addClass(el, className) {
        var classList = className.split(' ');
        if (el.classList) el.classList.add(classList[0]);
        else if (!this.hasClass(el, classList[0])) el.className += " " + classList[0];
        if (classList.length > 1) this.addClass(el, classList.slice(1).join(' '));
      },
      removeClass(el, className) {
        var classList = className.split(' ');
        if (el.classList) el.classList.remove(classList[0]);
        else if(this.hasClass(el, classList[0])) {
          var reg = new RegExp('(\\s|^)' + classList[0] + '(\\s|$)');
          el.className=el.className.replace(reg, ' ');
        }
        if (classList.length > 1) this.removeClass(el, classList.slice(1).join(' '));
      }
    },
    mounted() {

      const t = this;

      const backTop = document.getElementById(this.buttonId);
      if (!backTop) return;

      const targetIsParent = this.scrollTarget === "parent";
      const target = targetIsParent ? backTop.parentElement : window;

      const checkBackToTop = function() {
        var body = targetIsParent ? target : document.body, html = document.documentElement;
        var windowTop = (targetIsParent ? target.scrollTop : window.scrollY) || html.scrollTop;
        var windowHeight = window.innerHeight;
        var documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

        (windowTop > documentHeight - windowHeight - t.offset) ? t.addClass(backTop, 'cd-top--show') : t.removeClass(backTop, 'cd-top--show', 'cd-top--fade-out');
        (windowTop > documentHeight - windowHeight - t.offsetOpacity) && t.addClass(backTop, 'cd-top--fade-out');
        t.scrolling = false;
      };

      const scrollTop = function (duration) {
        var start = target.scrollY || document.documentElement.scrollTop, currentTime = null;
        var animateScroll = function(timestamp){
          if (!currentTime) currentTime = timestamp;
          var progress = timestamp - currentTime;
          var val = Math.max(Math.easeInOutQuad(progress, start, -start, duration), 0);
          target.scrollTo(0, val);
          if (progress < duration) {
            window.requestAnimationFrame(animateScroll);
          }
        };
        window.requestAnimationFrame(animateScroll);
      };


      //update back to top visibility on scrolling
      target.addEventListener("scroll", function() {
         if( ! t.scrolling ) {
          t.scrolling = true;
          (!window.requestAnimationFrame) ? setTimeout(checkBackToTop, 250) : window.requestAnimationFrame(checkBackToTop);
        }
      });

      //smooth scroll to top
      backTop.addEventListener('click', function(event) {
        event.preventDefault();
        (!window.requestAnimationFrame) ? window.scrollTo(0, 0) : scrollTop(t.scrollDuration);
      });

      Math.easeInOutQuad = function (t, b, c, d) {
        t /= d/2;
        if (t < 1) return c/2*t*t + b;
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
      };

    }
  }

</script>
