<template>

  <div
    v-if="isNotEmpty && isString"
    class="notice-item-field notice-item-field-string"
    :class="termCssClass"
  >
    <h3 class="notice-item-field-title">{{ $t(label) }}</h3>
    <p class="notice-item-field-content" :data-term="term">
      {{ value }}
    </p>
  </div>

  <div
    v-else-if="isNotEmpty && isArrayOfLiterals"
    class="notice-item-field notice-item-field-array"
    :class="termCssClass"
  >
    <h3 class="notice-item-field-title">{{ $t(label) }}</h3>

    <ul v-if="hasLanguagesSelector" class="notice-lang-selector hidden">
      <li v-for="language in getLanguages" :key="language">
        <a  href="#"
            @click="selectLanguage($event, language)"
            :class="languageCssClass(language)">
          {{ language }}
        </a>
      </li>
    </ul>

    <ul class="notice-item-field-content no-bullet" :data-term="term">
      <li
              v-for="item in value"
              :key="item"
              :class="contentCssClass(item.language)"
      >
        <div v-if="item.type='html'" v-html="item.value" />
        <div v-else>
          {{ item.value }}
        </div>
      </li>
    </ul>
  </div>

  <div
    v-else-if="isNotEmpty && isArrayOfObjects"
    class="notice-item-field notice-item-field-array-of-objects"
    :class="termCssClass"
  >
    <h3 class="notice-item-field-title">{{ $t(label) }}</h3>
    <ul
            class="notice-item-field-content no-bullet"
            :class="hasSeeAllToggleCssClass"
            :data-term="term"
        >
      <li
              v-for="(item, no) in getValues(value)"
              :key="item.id"
              class="notice-item-field-value"
              :class="listItemCssClass(item, no)"
      >
        <a
            v-if="item.type === 'resource:item'"
            :href="getNoticeURL(item)"
            @click="clickOnNotice($event, item)">
          {{ item.value }}
        </a>
        <a
            v-else-if="item.fieldName ==='dcterms:bibliographicCitation'"
            :href="getNoticeURL(item)"
            target="_blank"
            @click="clickOnNotice($event, item)">
          {{ item.value }}
        </a>
        <a
            v-else-if="item.type.indexOf('customvocab') === 0"
            :href="getNoticeURL(item)"
            target="_blank">
          {{ item.value }}
        </a>
        <a
            v-else-if="item.type === 'see-all'"
            href="#"
            class="notice-item-field-see-all-toggle"
            @click="toggleSeeAll($event)">{{ $t("Voir toutes les notices") }}</a>
        <a
            v-else
            :href="getItemURL(item.id)"
            class="external-link"
            target="_blank">{{ item.value }}
        </a>
      </li>
    </ul>
  </div>

</template>

<script>

  import {getItemApiURL} from "../../js/api";
  import axios from "axios";
  import $ from 'jquery';
  import {validateEmail} from "../../js/functions";

  export default {

    name: 'NoticeMediaItemMetadata',
    props: {
      term: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      value: {
        required: true,
      },
      linksInPopUp: {
        type:Boolean,
        required: false,
        default: false
      },
      seeAllLimit: {
        type:Number,
        required: false,
        default: 30
      },
      fromItem: {
        type:Number,
        required: false
      },
    },
    data() {
      return {
        activeLanguage:"fr",
        seeAllToggleOpened:false
      }
    },
    emits: [
      'clicknoticelink'
    ],
    computed: {
      termCssClass() {
        return this.term.split(':').join('-');
      },
      isNotEmpty() {
        return this.value.length > 0;
      },
      isString() {
        return typeof this.value === 'string';
      },
      isArrayOfLiterals() {
        return Array.isArray(this.value) && ( this.value[0].type === 'literal' );
      },
      isArrayOfObjects() {
        return Array.isArray(this.value) && ( this.value[0].type !== 'literal' );
      },
      hasLanguagesSelector() {
        return this.getLanguages.length > 1;
      },
      getLanguages() {
        const languages = [];
        if (Array.isArray(this.value)) {
          let i, lang, langValue;
          const n = this.value.length;
          for (i=0;i<n;i++) {
            lang = this.value[i].language;
            langValue = this.value[i].value;
            if ((langValue.length)  && (lang !== undefined) && (lang.length > 0) && (languages.indexOf(lang) === -1)) {
              languages.push(lang);
            }
          }
        }
        return languages;
      },
      hasSeeAllToggle() {
        return (this.seeAllLimit > 0) && (this.value.length > this.seeAllLimit);
      },
      hasSeeAllToggleCssClass() {
        return (this.hasSeeAllToggle ? ('with-see-all-toggle' + this.seeAllToggleCssClass) : '') ;
      },
      seeAllToggleCssClass() {
        return this.seeAllToggleOpened ? ' opened' : '';
      },
    },
    methods: {
      getValues(value) {
        if (this.hasSeeAllToggle) {
          // Voir plus
          const extValues = value.concat();
          extValues.splice(this.value.length, 0, {
            type: 'see-all',
          });
          return extValues;
        } else {
          return value;
        }
      },

      toggleSeeAll($event) {
        $event.preventDefault();
        const target = $($event.target).closest('li');
        const lastLinks = target.next();
        this.seeAllToggleOpened = ! this.seeAllToggleOpened;
        this.seeAllToggleOpened ? lastLinks.addClass('opened') : lastLinks.removeClass('opened');
      },
      listItemCssClass(item, no) {
        if (item.type === 'see-all') {
          return 'see-all-toggle-parent'
        } else {
          return no > this.seeAllLimit ? ' see-all-hidden-item' : '';
        }
      },
      getItemURL(itemLinkValue) {
        console.log("itemLinkValue", itemLinkValue, isNaN(itemLinkValue))
        if (isNaN(itemLinkValue)) {
          return ( validateEmail(itemLinkValue) ? "mailto:" : "" ) + itemLinkValue;
        } else {
          return this.$router.resolve({
            name: 'Item',
            params : { id: itemLinkValue },
            query : this.getQueryParamsForURLs()
          }).href;
        }
      },
      getQueryParamsForURLs() {
        // Item de la notice où s'affiche le lien
        const query = { lang: this.$store.state.currentLanguage };
        if (this.fromItem) {
          query.from_item = this.fromItem;
        }
        return query;
      },
      getNoticeURL(item) {
        if (item.fieldName && item.fieldName === "dcterms:subject")
        {
          // Cas particulier des mots-clés : vers la vue KeyWord
          return this.$router.resolve({
              name: "Subject",
              params : { subject: item.value },
              query : this.getQueryParamsForURLs()
          }).href;

          /*
          // V1 :
          // Cas particulier des mots-clés : on génère une URL pour la recherche avancée :
          const fieldSets = {
            property:3, // subject
            joiner: 'and',
            type: 'eq',
            text:item.value,
          };
          return this.$router.resolve("/search/" +  getAdvancedSearchQuery([fieldSets], this.$store.state.queryItemSetsIds)).href;
         */

        }
        else if ((typeof (item.id) === "string") && item.id.indexOf('http') === 0)
        {
          // Cas particulier des liens externes renseignés dans 'id'
          return item.id;
        }
        else
        {
          // Cas général : on affiche le lien vers la notice de l'item
          return this.$router.resolve({
            name: 'Item',
            params : { id: item.id },
            query : this.getQueryParamsForURLs()
          }).href;
        }
      },
      async clickOnNotice($event, item) {
        $event.preventDefault();
        if (this.linksInPopUp) {

          // EVENT --> PARENT
          this.$emit('clicknoticelink', item);

        } else {

          // Ouverture de la page de l'item :
          // On doit retrouver la route de l'item à partir de l'id de sa collection

          const apiRoot = this.$store.getters.apiRoot;
          const keyIdentity = this.$store.getters.identityAndCredentialParameters;

          const itemId = item.id;
          const apiURL = getItemApiURL(apiRoot, itemId);

            // API de l'item :
          const itemJson = await axios.get(apiURL + keyIdentity ).then(function(response){
            return response.data;
          }).catch(error => {
            console.error("click notice link error", error);
          });

          let itemRoute;

          const itemSets = itemJson['o:item_set'];
          if (itemSets.length > 0)
          {
            const itemSet = itemSets[0];
            const itemSetId = itemSet["o:id"];
            const collectionSettings = this.$store.getters.collectionsSettings(itemSetId);

            if (collectionSettings && collectionSettings.route) {
              itemRoute = this.$router.resolve(collectionSettings.route + "/item/" + itemId);
            } else {
              itemRoute = this.$router.resolve("/item/" + itemId);
            }
          }
          else
          {
            itemRoute = this.$router.resolve("/item/" + itemId);
          }

          this.$router.push(itemRoute);
        }

      },

      // Gestion de la langue :

      selectLanguage($event, language) {
        $event.preventDefault();
        this.activeLanguage = language.toLowerCase().substr(0,2);
      },
      languageCssClass(language) {
        return language === this.activeLanguage ? 'active' : '';
      },
      contentCssClass(itemLanguage) {
        return (itemLanguage === undefined) || (itemLanguage === this.activeLanguage) ? '' : 'hidden';
      }
    },
    mounted() {
      this.activeLanguage = this.$store.state.currentLanguage.toLowerCase();
    },
    watch: {
      '$store.state.currentLanguage': function() {
        this.activeLanguage = this.$store.state.currentLanguage.toLowerCase();
      }
    },

  }

</script>
