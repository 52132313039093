<template>

  <ul class="pagination-pages">

    <li class="pagination-first-button"><a href="#" @click="selectFirstPage($event)"></a></li>

    <li
            class="pagination-page"
            v-for="item in pagesNumbers"
            :class="paginationCssClass(item)"
            :key="item+pageNo"
    >
      <a href="#" @click="selectPage($event, item)">{{ item }}</a>
    </li>

    <li class="pagination-last-button"><a href="#" @click="selectLastPage($event)"></a></li>

  </ul>

</template>

<script>

  const limit = 6;

  export default {
    name: 'ListPagination',
    props: {
      pageCount: {
        type: Number,
        required: true,
      },
      pageNo: {
        type: Number,
        required: false,
      }
    },
    emits:[
      'change-pagination-page'
    ],
    computed: {
      pagesNumbers() {
        let i, nos = [];
        for(i=0;i<this.pageCount;i++) {
          nos.push(i+1);
        }
        return nos;
      },
      itemMin(){
        if (this.pageNo < limit) {
          return 1;
        } else if (this.pageNo > this.pageCount - limit) {
          return this.pageCount - limit - 1;
        } else {
          return this.pageNo - limit / 2;
        }
      },
      itemMax(){
        if (this.pageNo > this.pageCount - limit) {
          return this.pageCount;
        } else {
          return this.itemMin + limit;
        }
      }
    },
    methods: {
      paginationCssClass(item) {
        if (item === this.pageNo) {
          return "pagination-active-page";
        } else if ((item === 1) || (item === this.pageCount)) {
           return "";
        } else if ((item === this.itemMin) || (item === this.itemMax)) {
          return "pagination-etc-page";
        } else if ((item < this.itemMin)  || (item > this.itemMax)) {
          return "pagination-hidden-page";
        } else {
          return "";
        }
      },
      selectPage($event, pageNo) {
        $event.preventDefault();
        this.$emit('change-pagination-page', pageNo);
      },
      selectFirstPage($event) {
        $event.preventDefault();
        this.$emit('change-pagination-page', 1);
      },
      selectLastPage($event) {
        $event.preventDefault();
        this.$emit('change-pagination-page', this.pageCount);
      }
    }
  }
</script>
