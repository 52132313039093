<template>

  <GalleryListCuratedCollectionsView
          pageTitle="Sélections thématiques"
          itemSetsRouteName="Curated ItemSets"
          itemSetRouteName="Curated ItemSet"
          default-display-mode="gallery"
  />

</template>

<script>

  import GalleryListCuratedCollectionsView from "../components/Curation/GalleryListCuratedCollectionsView";

  export default {
    components: { GalleryListCuratedCollectionsView }
  }

</script>