<template>
  <div class="account-items-panel account-search-panel" :class="panelOpenedCssClass">
    <div class="account-items-header">
      <a href="#" @click="toggleClose($event)">
        <h3>{{ $t("Mes sélections") }}</h3>
        <div class="account-items-close"></div>
      </a>
    </div>
    <div class="account-items" :class="accountItemsCssClass">
      <div  v-if="selections.length > 0">
        <p class="panel-description">{{ $t("Cliquez sur une sélection historique pour la voir et l’annoter.") }}</p>
        <ul class="account-search-items">
          <li class="account-search-item visualization-selection-item"
              v-for="selection in selections"
              v-bind:key="selection.id"
          >
            <router-link
                    :to="selectionUrl(selection)"
                    class="account-search-item visualization-selection-item"
            >
              <p>{{ selection.name }}</p>
              <p>{{ selectionDate(selection) }}</p>
            </router-link>

            <div class="buttons">
              <a class="account-item-delete-tool button delete-icon" href="#" @click="deleteSelection($event, selection)"></a>
            </div>

          </li>
        </ul>
      </div>
      <div v-else class="account-column-warning">{{ $t("Votre liste de selections est vide") }}</div>
    </div>
  </div>
</template>

<script>

  import {getSelectionQuery} from "../../js/api";

  export default {
    name: 'UserSearches',
    components: {
    },
    props: {
      opened: {
        type: Boolean,
        required: true
      },
    },
    emits:[
      'toggle-searches-panel',
      'toggle-selections-panel'
    ],
    computed: {
      panelOpenedCssClass() {
        return this.opened ? "account-items-opened" : "";
      },
      searches() {
        return this.$store.state.searches;
      },
      selections() {
        return this.$store.state.selectionBaskets;
      },
      accountItemsCssClass() {
        return this.searches.length > 0 ? "" : "is-empty";
      }
    },
    methods: {
      toggleClose($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.$emit('toggle-selections-panel')
      },
      selectionUrl(selection) {
        if (selection.items.length)
        {
          const query = getSelectionQuery(selection.items);
          const hrefWithRouterBase = this.$router.resolve({
            name: 'Graphe Items',
            params: { s: query },
            query: { display_mode: 'graph' }
          }).href;
          return hrefWithRouterBase.split(this.$store.state.publicPath).join('/');
        }
        else {
          return "#";
        }
      },
      selectionDate(selection) {
        const dateJS = selection.created;
        if (dateJS)
        {
          const fullYear = dateJS.getFullYear();

          let day = dateJS.getDate();
          if (day < 10 ) {
            day = "0" + day;
          }

          let month = dateJS.getMonth() + 1;
          if (month < 10 ) {
            month = "0" + month;
          }
          return "Sélection du " + day + "/" + month + "/" + fullYear;
        }
        return "";
      },
      deleteSelection($event, selection) {
        $event.preventDefault();

        // Ici l'id est une référence à la sélection ( selection_resources sur le serveur) : selectionId, created
        this.$store.dispatch( "deleteSelectionBasket" , { name: selection.name, id: selection.id } );
      }
    }
  }

</script>
