import * as d3 from "d3";

function VizTD() {

    this.width = 1024;
    this.height = 600;

    this.zoomMin = 0.2;
    this.zoomMax = 5;
    this.zoom = this.zoomMin;

}

Object.assign( VizTD.prototype, {

    init: function( parentElementId ) {

        this.parentDOMElement = document.getElementById(parentElementId);

        // Elément SVG parent
        this.svg = d3
            .select("#" + parentElementId)
            .append('svg')
            .attr("width", "100%")
            .attr("height", "100%");

        this.centerElement = this.svg.append('g')
            .attr('class', 'visualization_center');

        // Fond captant les événements du zoom
        this.centerElement
            .append('rect')
            .attr('class', 'zoom-event-target')
            .attr('fill', 'rgba(255,0,0,0')
            .attr('width', this.width)
            .attr('height', this.height)
            .attr('x', -this.width/2)
            .attr('y', -this.height/2);


        // Calques du SVG :
        const parentVisualization = this.centerElement.append('g');
        parentVisualization
            .attr('class', 'parent')
            .on("click", function(event) {
                event.stopPropagation();
            });

        this.parentElement = parentVisualization;

        // Drag and Drop + Zoom
        this.zoomableElement = parentVisualization;
        this.zoomBehavior = d3.zoom();
        this.centerElement.call(
            this.zoomBehavior
                .extent([[0,0],[300,300]])
                .on("zoom", function (event) {
                    parentVisualization.attr("transform", event.transform);
                })
        );
    },

    zoomIn: function () {
        if (this.zoom < this.zoomMax) {
            this.zoom = Math.min(this.zoomMax, this.zoom * 1.2);
            this.centerElement.call(this.zoomBehavior.transform, d3.zoomIdentity.scale(this.zoom));
        }
    },

    zoomOut: function () {
        if (this.zoom > this.zoomMin) {
            this.zoom = Math.max(this.zoomMin, this.zoom / 1.2);
            this.centerElement.call(this.zoomBehavior.transform, d3.zoomIdentity.scale(this.zoom));
        }
    },

    zoomLevelIsMax: function () {
        return this.zoom === this.zoomMax;
    },

    zoomLevelIsMin: function () {
        return this.zoom === this.zoomMin;
    },

    zoomReset: function () {
        if (this.zoomBehavior) {
            this.zoom = 1;
            this.centerElement.call(this.zoomBehavior.transform, d3.zoomIdentity);
        }
    },

    resize: function ( newWidth, newHeight ) {

        this.width = newWidth;
        this.height = newHeight;

        this.centerElement.select('rect.zoom-event-target')
            .attr('width', this.width)
            .attr('height', this.height)
            .attr('x', -this.width/2)
            .attr('y', -this.height/2);

        // this.updateCenterPosition();
    },

    updateCenterPosition: function () {
        // Position du centre de la visualisation
        this.cX = this.width/2;
        this.cY = this.height/2;
        this.centerElement.attr("transform", "translate("+this.cX+","+this.cY+")");
    },

    //
    // Création du rendu SVG
    //

    initData: function ( json ) {
        this.data = d3.hierarchy(json)
            .sort((a, b) => d3.ascending(a.data.name, b.data.name))
    },

    renderSVG: function () {

        const svg = this.svg;
        const data = this.data;

        const radius = 3000;

        // const size = Math.max(this.width, this.height);

        this.zoom = 0.5;

        const tree = d3.tree()
            .size([2 * Math.PI, radius]);

        svg
            .style("box-sizing", "border-box")
            .style("font", "12px sans-serif");

        const $this = this;
        const g = this.parentElement;
        const parentDOMElement = this.parentDOMElement;

        const linkgroup = g.append("g")
            .attr("fill", "none")
            .attr("stroke", "#FFF")
            .attr("stroke-opacity", 0.9)
            .attr("stroke-width", 1.5);

        const nodegroup = g.append("g")
            .attr("stroke-linejoin", "round")
            .attr("stroke-width", 3);

        function newdata(animate = true) {

            let root = tree(data);
            let links_data = root.links();
            let links = linkgroup
                .selectAll("path")
                .data(links_data, d => d.source.data.name + "_" + d.target.data.name);

            links.exit().remove();

            links
                .enter()
                .append("path")
                .attr("d", d3.linkRadial()
                    .angle(d => d.x)
                    .radius(0.1));

            let t = d3.transition()
                .duration(animate ? 400 : 0)
                .ease(d3.easeLinear)
                .on("end", function () {
                    const box = g.node().getBBox();
                    svg.transition().duration(1000).attr("viewBox", `${box.x} ${box.y} ${box.width} ${box.height}`);
                    $this.resize(box.width, box.height);
                });

            let alllinks = linkgroup.selectAll("path");
            alllinks
                .transition(t)
                .attr("d", d3.linkRadial()
                    .angle(d => d.x)
                    .radius(d => d.y));

            let nodes_data = root.descendants().reverse();
            let nodes = nodegroup
                .selectAll("g")
                .data(nodes_data, function (d) {
                    if (d.parent) {
                        return d.parent.data.name + d.data.name;
                    }
                    return d.data.name
                });

            nodes.exit().remove();

            let newnodes = nodes
                .enter().append("g");

            let allnodes = animate ? nodegroup.selectAll("g").transition(t) : nodegroup.selectAll("g");
            allnodes
                .attr("transform", d => `
            rotate(${d.x * 180 / Math.PI - 90})
            translate(${d.y},0)
          `);

            newnodes.append("circle")
                .attr("r", 4.5)
                .on("click", function (d) {
                    let altChildren = d.data.altChildren || [];
                    let children = d.data.children;
                    d.data.children = altChildren;
                    d.data.altChildren = children;
                    newdata();
                });

            nodegroup.selectAll("g circle").attr("fill", function (d) {
                let altChildren = d.data.altChildren || [];
                let children = d.data.children;
                return d.children || (children && (children.length > 0 || altChildren.length > 0)) ? "#FFF" : "#CCC"
            });

            newnodes.append("text")
                .style('font', '13px "Lato", sans-serif')
                .attr('fill', '#FFF')
                .attr("dy", "0.31em")
                .text(d => d.data.name)
                .attr('cursor', 'pointer')
                .on("mouseover", function(event, d) {
                    d3.select(this).attr('fill', '#FFA847').text(d.data.title);
                })
                .on("mouseout", function(event, d) {
                    d3.select(this).attr('fill', '#FFFFFF').text(d.data.name);
                })
                .on('click', function (event, d){
                    if (d.data) {
                        event.stopPropagation();
                        parentDOMElement.dispatchEvent( new CustomEvent( "notice", {
                            bubbles: true,
                            cancelable: true,
                            detail: {
                                id: d.data.id,
                                json: d.data
                            }
                        }));
                    }
                })

            nodegroup.selectAll("g text")
                .attr("x", d => d.x < Math.PI === !d.children ? 6 : -6)
                .attr("text-anchor", d => d.x < Math.PI === !d.children ? "start" : "end")
                .attr("transform", d => d.x >= Math.PI ? "rotate(180)" : null);

        }

        newdata(false);

        // this.centerElement.call(this.zoomBehavior.transform, d3.zoomIdentity.scale(this.zoom));
    },

});

export { VizTD }